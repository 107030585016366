import Pool from '../models/Pool';
import NormalizeGame from '../models/NormalizeGame';
import Team, { HighlighField } from '../models/Team';

const RankingFactorsString = ['WP', 'H2H', 'GD', 'GS', 'GA'];

export const getPoolsFromGames = (games: NormalizeGame[]) => {
  const pools: Pool[] = [];
  games.forEach((game: NormalizeGame) => {
    const pool = pools.find((pool: Pool) => pool.pool_id === game.pool_id && pool.division_id === game.division_id);
    if (!pool) {
      pools.push({
        event_id: game.event_id,
        division_id: game.division_id,
        division_name: game.division_name,
        pool_id: game.pool_id,
        pool_name: game.pool_name,
      });
    }
  });
  return pools;
};

export const getRankingFactors = (ranking: string | null, maxDifferential: number | null) => {
  return ranking
    ? JSON.parse(ranking).map((rank: number) => {
        return rank === 3 && maxDifferential ? 'GDwM' : RankingFactorsString[rank - 1];
      })
    : [];
};

const checkHighlightingTieBreakers = (team: Team, opponent: Team, factor: number, maxDifferential?: number | null) => {
  switch (factor) {
    case 1: {
      const teamWP = Math.round(((team.wins + 0.5 * team.ties) / (team.wins + team.ties + team.losses)) * 100) || 0;
      const opponentWP = Math.round(((opponent.wins + 0.5 * opponent.ties) / (opponent.wins + opponent.ties + opponent.losses)) * 100) || 0;
      if (teamWP === opponentWP) {
        return;
      } else {
        return HighlighField.WP;
      }
    }
    case 2: {
      return;
    }
    case 3: {
      const teamGD = team.goalsScored - team.goalsAllowed;
      const opponentGD = opponent.goalsScored - opponent.goalsAllowed;
      if (maxDifferential) {
        if (team.goalsDifferentialMax === opponent.goalsDifferentialMax) {
          return;
        } else {
          return HighlighField.GDwM;
        }
      } else {
        if (teamGD === opponentGD) {
          return;
        } else {
          return HighlighField.GD;
        }
      }
    }
    case 4: {
      if (team.goalsScored === opponent.goalsScored) {
        return;
      } else {
        return HighlighField.GS;
      }
    }
    case 5: {
      if (team.goalsAllowed === opponent.goalsAllowed) {
        return;
      } else {
        return HighlighField.GA;
      }
    }
    case 6: {
      if (team.goalsDifferentialMax === opponent.goalsDifferentialMax) {
        return;
      } else {
        return HighlighField.GDwM;
      }
    }
    default: {
      return;
    }
  }
};

const getFirstRankingFactor = (team: Team, factor: number) => {
  switch (factor) {
    case 1:
    case 2:
      return Math.round(((team.wins + 0.5 * team.ties) / (team.wins + team.ties + team.losses)) * 100) || 0;
    case 3: {
      return team.goalsScored - team.goalsAllowed;
    }
    case 4: {
      return team.goalsScored;
    }
    case 5: {
      return team.goalsAllowed;
    }
    default: {
      return Math.round(((team.wins + 0.5 * team.ties) / (team.wins + team.ties + team.losses)) * 100) || 0;
    }
  }
};

export const highlightTieBreakers = (teams: Team[], rankingFactors?: string | null, maxDifferential?: number | null) => {
  let parsedRankingFactor: number[] = [];
  if (rankingFactors) {
    parsedRankingFactor = JSON.parse(rankingFactors);
  }

  const hightlightedTeams = teams.map((highlightTeam: Team, idx) => {
    const copyTeam: Team = { ...highlightTeam };

    if (idx !== teams.length - 1) {
      const team = getFirstRankingFactor(highlightTeam, parsedRankingFactor[0]);
      const opponentTeam = getFirstRankingFactor(teams[idx + 1], parsedRankingFactor[0]);
      if (team === opponentTeam) {
        parsedRankingFactor.forEach((factor: number) => {
          const highlightField = checkHighlightingTieBreakers(highlightTeam, teams[idx + 1], factor, maxDifferential);
          copyTeam.highlightField = !copyTeam.highlightField && highlightField ? highlightField : copyTeam.highlightField;
        });

        if (!copyTeam.highlightField && maxDifferential) {
          copyTeam.highlightField = checkHighlightingTieBreakers(highlightTeam, teams[idx + 1], 6, maxDifferential);
        }
      }
    }
    return copyTeam;
  });
  return hightlightedTeams;
};
