import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import SectionHeader from '../SectionHeader';
import ConstrainedWidth from '../ConstrainedWidth';
import './styles.css';
import SearchComponent from './SearchComponent';
import EventComponent from './EventComponent';
import MatchUpMaker from './MatchUpMaker';
import { connect } from 'react-redux';
import { Member } from '../../models/Member';
import * as teamActions from '../../redux/actions/teamActions';
import * as sourceDataActions from '../../redux/actions/sourceDataActions';
import { Organization } from '../../models/Organization';
import { GamePublic, Team } from './interfaces';
import CustomButton from '../Common/CustomButton';

interface SourceDataProps {
  member: Member;
  orgs: Organization[];
  headerLabel?: string;
  dispatchGetOrgSummary: () => void;
  dispatchPostPublicGame: (it: GamePublic) => void;
}

const SourceData = ({ member, orgs, headerLabel, dispatchGetOrgSummary, dispatchPostPublicGame }: SourceDataProps) => {
  const [awayTeam, setAwayTeam] = useState<Team>();
  const [homeTeam, setHomeTeam] = useState<Team>();
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [eventDesc, setEventDesc] = useState<Partial<GamePublic>>();
  const [date, setDate] = useState<Date | null>(new Date());
  const [awayTeamScore, setAwayTeamScore] = useState<number | null>(0);
  const [homeTeamScore, setHomeTeamScore] = useState<number | null>(0);

  useEffect(() => {
    dispatchGetOrgSummary();
  }, [dispatchGetOrgSummary]);

  useEffect(() => {
    if (awayTeam || homeTeam || eventDesc || date || awayTeamScore || homeTeamScore) {
      setIsCancel(false);
      setIsChecking(false);
    }
  }, [awayTeam, homeTeam, eventDesc, date, awayTeamScore, homeTeamScore]);

  const onChangeDate = (date: Date | null) => {
    setDate(date);
  };

  const onChangeAwayScore = ({ target: { value } }: any) => setAwayTeamScore(value > -1 ? value : 0);

  const onChangeHomeScore = ({ target: { value } }: any) => setHomeTeamScore(value > -1 ? value : 0);

  const onPasteAsAway = (team: Team | undefined) => {
    setAwayTeam(team);
  };

  const onPasteAsHome = (team: Team | undefined) => {
    setHomeTeam(team);
  };

  const onEventChange = (item: Partial<GamePublic>) => setEventDesc(item);

  const checkAllField = () => {
    return awayTeam?.team_id && homeTeam?.team_id && awayTeam?.team_id !== homeTeam?.team_id && (awayTeamScore !== 0 || homeTeamScore !== 0) && eventDesc?.event_name && eventDesc?.event_producer && eventDesc?.game_city && eventDesc?.game_state && eventDesc?.time_zone;
  };

  const onHandleSave = () => {
    setIsChecking(true);
    if (checkAllField()) {
      dispatchPostPublicGame({
        ...eventDesc,
        division_name: awayTeam?.team_year,
        away_team_id: awayTeam?.team_id,
        home_team_id: homeTeam?.team_id,
        away_team_score: awayTeamScore,
        home_team_score: homeTeamScore,
        game_date: date?.toISOString(),
      } as GamePublic);
    }
  };

  const onHandleCancel = () => {
    setAwayTeamScore(0);
    setHomeTeamScore(0);
    setDate(new Date());
    setIsCancel(true);
    setAwayTeam(undefined);
    setHomeTeam(undefined);
  };

  const onHandleSaveNew = () => {
    onHandleSave();
    onHandleCancel();
  };

  return (
    <>
      <SectionHeader>
        <div className="header">
          <Typography variant="h1" gutterBottom>
            {headerLabel || 'Data Integrity & Requests Home'}
          </Typography>
          <Typography variant="h5" gutterBottom>
            You,{' '}
            <span className="full-name">
              {member.first_name} {member.last_name}
            </span>
            , are in the audit trail for adding this game.
          </Typography>
        </div>
      </SectionHeader>
      <ConstrainedWidth>
        <div className="container">
          <SearchComponent isCancel={isCancel} orgs={orgs} onPasteAsAway={onPasteAsAway} onPasteAsHome={onPasteAsHome} />
          <EventComponent isCancel={isCancel} isChecking={isChecking} onEventChange={onEventChange} />
          <MatchUpMaker isChecking={isChecking} homeTeam={homeTeam} awayTeam={awayTeam} date={date} awayTeamScore={awayTeamScore} homeTeamScore={homeTeamScore} onChangeDate={onChangeDate} onChangeAwayScore={onChangeAwayScore} onChangeHomeScore={onChangeHomeScore} />
          <div className="source-data-btns">
            <CustomButton label={'Save'} width={'100px'} onClick={onHandleSave} />
            <CustomButton label={'Save & New'} width={'100px'} onClick={onHandleSaveNew} />
            <CustomButton label={'Cancel'} width={'100px'} onClick={onHandleCancel} />
          </div>
        </div>
      </ConstrainedWidth>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  member: state.memberReducer.member,
  orgs: state.teamReducer.orgs,
});

const mapDispatchToProps = {
  dispatchGetOrgSummary: () => teamActions.getOrgSummary(),
  dispatchPostPublicGame: (it: GamePublic) => sourceDataActions.postPublicGame(it),
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceData);
