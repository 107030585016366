import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  colorDefault: {
    backgroundColor: ` ${theme.palette.background.default} !important`,
  },
  root: {
    boxShadow: 'none !important',
    borderBottom: `1px solid ${theme.palette.divider} !important`,
    backgroundColor: `${theme.palette.background.default} !important`,
  },
}));

const AppBar: React.FC<AppBarProps> = (props) => {
  const appBarClasses = useStyles(props);

  return (
    <MuiAppBar position="static" classes={appBarClasses} color="default" {...props}>
      {props.children}
    </MuiAppBar>
  );
};

export default AppBar;
