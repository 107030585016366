import { addVideos } from '../../apis/addHighlights';
import fetchAllPlayerHighlightsRating from '../../apis/fetchAllplayerHighlightsRating';
import fetchVideos from '../../apis/fetchVideos';
import fetchPlayerHighlightsRating from '../../apis/fetchplayerHighlightsRating';
import { errorToast, successToast } from '../../components/Common/Toasts/showToasts';
import { AllVideosRatings, RankingsParams, Video, VideosRatings } from '../../models/Videos';
import * as commonActions from './commonActions';

export const FETCH_VIDEOS_REQ_START = 'FETCH_VIDEOS_REQ_START';

export const FETCH_ALL_VIDEOS_RATING = 'FETCH_ALL_VIDEOS_RATING';
export const FETCH_VIDEOS_RATING = 'FETCH_VIDEOS_RATING';
export const FETCH_HIGHEST_RATING = 'FETCH_HIGHEST_RATING';
export const FETCH_MOSTVOTES_RATING = 'FETCH_MOSTVOTES_RATING';
export const FETCH_RECENT_RATING = 'FETCH_RECENT_RATING';

export const fetchVideosStart = () => ({
  type: FETCH_VIDEOS_REQ_START,
});

export const fetchMostRecentSuccess = (data: Video[]) => ({
  type: FETCH_RECENT_RATING,
  data,
});

export const fetchMostVotesSuccess = (data: Video[]) => ({
  type: FETCH_MOSTVOTES_RATING,
  data,
});

export const fetchHighestSuccess = (data: Video[]) => ({
  type: FETCH_HIGHEST_RATING,
  data,
});

export const getAllVideosRating = (data: AllVideosRatings[]) => ({
  type: FETCH_ALL_VIDEOS_RATING,
  data,
});
export const getVideosRating = (data: VideosRatings[]) => ({
  type: FETCH_VIDEOS_RATING,
  data,
});

export function getVideos(payload: RankingsParams) {
  return (dispatch: any) => {
    dispatch(fetchVideosStart());
    fetchVideos(payload)
      .then(async (res: any) => {
        if(payload.sort_param === 'most_recent'){
          dispatch(fetchMostRecentSuccess(res));
        }
        if(payload.sort_param === 'avg_rating'){
          dispatch(fetchHighestSuccess(res));
        }
        if(payload.sort_param === 'most_votes'){
          dispatch(fetchMostVotesSuccess(res));
        }
      })
      .catch((error: any) => {
        dispatch(commonActions.handleError(error));
      });
  };
}

export function getAllVideosRatings() {
  return (dispatch: any) => {
    fetchAllPlayerHighlightsRating()
      .then((res: AllVideosRatings[]) => {
        dispatch(getAllVideosRating(res));
      })
      .catch((error: any) => {
        dispatch(commonActions.handleError(error));
      });
  };
}

export function getVideosRatings() {
  return (dispatch: any) => {
    fetchPlayerHighlightsRating()
      .then((res: VideosRatings[]) => {
        dispatch(getVideosRating(res));
      })
      .catch((error: any) => {
        dispatch(commonActions.handleError(error));
      });
  };
}

export const addNewVideo = (newVideo: Video) => (dispatch: any) => {
  addVideos(newVideo)
    .then((_res: any) => {
      newVideo = { ...newVideo, member_media_id: _res.insertId };
      successToast('Highlight added successfully.');
      // setTimeout(() => {
      //   dispatch(getVideos());
      // }, 3000);
    })
    .catch((_error: any) => {
      dispatch(commonActions.handleError(_error));
      errorToast("Couldn't add highlight.");
    });
};
