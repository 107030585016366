import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import qs from 'qs';
import _ from 'lodash';

interface Team {
  team_id: string;
  team_name: string | null;
  team_division: string | null;
  parent_team_id: string | null;
  sport_id: number | null;
  num_players: number | null;
}

type FetchTeamsOptions = {
  parent_team_id?: string | undefined;
  team_id?: string | undefined;
  sport_id?: number | undefined;
};

export async function fetchTeamsFollowed(opts: FetchTeamsOptions): Promise<Team[]> {
  try {
    let params: any = opts.team_id
      ? {
          team_id: opts.team_id,
        }
      : {
          parent_team_id: opts.parent_team_id,
        };

    if (opts.sport_id) params.sport_id = opts.sport_id;

    const query = qs.stringify(params);

    const data = await fetchAuthorization(`${API_URL}v_teams?${query}`);

    const teams = await data.json();

    const teamsFiltered = _.filter(teams, function (t) {
      return t.team_id !== null;
    });

    const teamsSorted = _.orderBy(teamsFiltered, ['team_year'], ['asc']);

    return teamsSorted;
  } catch (e: any) {
    throw new Error(`Sorry, something went wrong. Error message: ${e.message}`);
  }
}

export default fetchTeamsFollowed;
