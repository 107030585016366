import { fetchSearch, fetchPlayerGameStats } from '../../apis';
import { processPlayerGameStatsJSON } from '../../helpers';

export const FETCH_FACE_TO_FACE_SEARCH_REQ_START = 'FETCH_FACE_TO_FACE_SEARCH_REQ_START';
export const FETCH_FACE_TO_FACE_SEARCH_REQ_SUCCESS = 'FETCH_FACE_TO_FACE_SEARCH_REQ_SUCCESS';
export const FETCH_FACE_TO_FACE_SEARCH_REQ_FAILED = 'FETCH_FACE_TO_FACE_SEARCH_REQ_FAILED';
export const FETCH_GAME_PLAYER_STATES_REQ_START = 'FETCH_GAME_PLAYER_STATES_REQ_START';
export const FETCH_GAME_PLAYER_STATES_REQ_SUCCESS = 'FETCH_GAME_PLAYER_STATES_REQ_SUCCESS';
export const FETCH_GAME_PLAYER_STATES_REQ_FAILED = 'FETCH_GAME_PLAYER_STATES_REQ_FAILED';
export const FETCH_GAME_PLAYER_CHART_STATES_SUCCESS = 'FETCH_GAME_PLAYER_CHART_STATES_SUCCESS';
export const FETCH_GAME_PLAYER_STATES_LEFT_REQ_SUCCESS = 'FETCH_GAME_PLAYER_STATES_LEFT_REQ_SUCCESS';
export const FETCH_GAME_PLAYER_STATES_RIGHT_REQ_SUCCESS = 'FETCH_GAME_PLAYER_STATES_RIGHT_REQ_SUCCESS';
export const FETCH_GAME_PLAYER_CHART_LEFT_STATES_SUCCESS = 'FETCH_GAME_PLAYER_CHART_LEFT_STATES_SUCCESS';
export const FETCH_GAME_PLAYER_CHART_RIGHT_STATES_SUCCESS = 'FETCH_GAME_PLAYER_CHART_RIGHT_STATES_SUCCESS';
export const RESET_PLAYER_DATA = 'RESET_PLAYER_DATA';

export const startSearching = () => ({
  type: FETCH_FACE_TO_FACE_SEARCH_REQ_START,
});

export const getSearchResult = (data: any) => ({
  type: FETCH_FACE_TO_FACE_SEARCH_REQ_SUCCESS,
  data,
});

export const searchingFailed = (error: any) => ({
  type: FETCH_FACE_TO_FACE_SEARCH_REQ_FAILED,
  error,
});

export const getPlayerStats = () => ({
  type: FETCH_GAME_PLAYER_STATES_REQ_START,
});

export const getPlayerStatsLeftSuccess = (data: any) => ({
  type: FETCH_GAME_PLAYER_STATES_LEFT_REQ_SUCCESS,
  data,
});

export const getPlayerStatsRightSuccess = (data: any) => ({
  type: FETCH_GAME_PLAYER_STATES_RIGHT_REQ_SUCCESS,
  data,
});

export const getPlayerStatsFailed = (error: any) => ({
  type: FETCH_GAME_PLAYER_STATES_REQ_FAILED,
  error,
});

export const getPlayerGameChartLeftStats = (data: any) => ({
  type: FETCH_GAME_PLAYER_CHART_LEFT_STATES_SUCCESS,
  data,
});

export const getPlayerGameChartRightStats = (data: any) => ({
  type: FETCH_GAME_PLAYER_CHART_RIGHT_STATES_SUCCESS,
  data,
});

export const resetPlayerData = () => {
  return (dispatch: any) => {
    dispatch({
      type: RESET_PLAYER_DATA,
    });
  };
};

export function handleFacetoFaceSearch(data: any) {
  return (dispatch: any) => {
    dispatch(startSearching());
    fetchSearch(data)
      .then((res: any) => {
        // console.log('handleSearch res', res);
        dispatch(getSearchResult(res));
      })
      .catch((error: any) => {
        console.log('error in handleSearch', error);
        dispatch(searchingFailed(error));
      });
  };
}

export function handlePlayerGameStats(data: any, isLeftClick: boolean, isRightClick: boolean) {
  return (dispatch: any) => {
    dispatch(getPlayerStats());
    fetchPlayerGameStats(data)
      .then(async (res: any) => {
        // console.log('handleSearch res', res);

        const gameStates = await processPlayerGameStatsJSON(res);

        const customChartdata = await [
          {
            chat_value: gameStates.ppr || 0,
            label: 'Player Power Ranking',
            row_index: 1,
          },
          {
            chat_value: gameStates.games_played || 0,
            label: 'Total Games Played',
            row_index: 2,
          },
          {
            chat_value: gameStates.win_percent || 0,
            label: 'Win Percentage',
            row_index: 3,
          },
          {
            chat_value: gameStates.goals_scored || 0,
            label: 'Goals Scored',
            row_index: 4,
          },
          {
            chat_value: gameStates.assists || 0,
            label: 'Assists',
            row_index: 5,
          },
          {
            chat_value: gameStates.fto || 0,
            label: 'Forced Turnovers',
            row_index: 6,
          },
          {
            chat_value: gameStates.GB || 0,
            label: 'Ground Ball',
            row_index: 7,
          },
        ];

        if (isLeftClick) {
          dispatch(getPlayerGameChartLeftStats(customChartdata));
          dispatch(getPlayerStatsLeftSuccess(res));
        }

        if (isRightClick) {
          dispatch(getPlayerGameChartRightStats(customChartdata));
          dispatch(getPlayerStatsRightSuccess(res));
        }
      })
      .catch((error: any) => {
        console.log('error in handlePlayerGameStats', error);
        dispatch(getPlayerStatsFailed(error));
      });
  };
}
