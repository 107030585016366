import { API_URL } from '../constants';
import { HighlightsRatings } from '../models/Highlight';
import fetchAuthorization from './fetchAuthorization';

async function fetchPlayerHighlightsRating(): Promise<HighlightsRatings[]> {

  const response = await fetchAuthorization(`${API_URL}member_media_reactions`);
  const data = await response.json();

  if (!data) {
    throw new Error(`No promo codes were found.`);
  }

  return data;
}

export default fetchPlayerHighlightsRating;