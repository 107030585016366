import React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, Typography, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import useSWR from 'swr';

import { useStyles } from './styles';
import ErrorBlock from '../ErrorBlock';
import { fetchPlayers } from '../../apis';
import PlayersTableEditable from '../Players/PlayersTableEditable';

interface Props {
  player: any;
  teamId: string;
  onClose?: any;
}

const ReviewPlayerRoster: React.FC<Props> = (props) => {
  const { teamId, onClose } = props;
  const classes = useStyles();

  const body: any = {
    team_id: teamId,
  };

  const { data: players, error } = useSWR(`allPlayers:${JSON.stringify(body)}`, () => fetchPlayers(body));

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog open={true} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
        <DialogTitle className={classes.dialogTitle}>
          <div className={classes.popupHead}>
            <Typography variant="h2" className={classes.addPlayerTitle}>
              Review Player Roster
            </Typography>
            <Button variant="contained" color="primary" onClick={handleClose} className={classes.smallButton}>
              Done
            </Button>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {error && <ErrorBlock error={error} />}
          {!players && !error && (
            <div>
              <CircularProgress className={classes.loadingPlayerRoster} size={30} />
            </div>
          )}
          {players && !error && <PlayersTableEditable teamId={teamId} players={players} />}
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  player: state.playerReducer,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPlayerRoster);
