import { apiOrigin } from '../constants';
import { API_URL } from '../constants';
import fetchWithAuthorization from './fetchWithAuthorization';
import fetchAuthorization from './fetchAuthorization';
import qs from 'qs';
import { Member as MemberModel } from '../models/Member';
// There are currently more types like "first", "last", and "email", but
// Dan has stated that he wants those to come from the Member object not
// member_data
type MemberDataFieldName = 'instagram' | 'laxRans_ID' | 'member_type';
interface MemberDataItem {
  field_name: MemberDataFieldName;
  field_value: string;
}

interface ApiMember {
  member_id: string;
  email_address?: string | null;
  first_name: string;
  last_name: string;
  member_data?: MemberDataItem[];
}

type ParsedMemberData = Partial<Record<MemberDataFieldName, string>>;

export type Member = Omit<ApiMember, 'member_data'> & {
  parsedMemberData: ParsedMemberData;
};

async function fetchMember(email: string): Promise<Member> {
  try {
    const response = await fetchWithAuthorization(`${apiOrigin}/api/v1/get-member-by-email/${email}`);
    const data = await response.json();

    if (!data.member) {
      throw new Error('A member with that email was not found.');
    }

    const member = data.member as ApiMember;
    const { member_data, ...other } = member;
    const parsedMember: Member = {
      ...other,
      parsedMemberData: (member_data || []).reduce((acc, item) => {
        acc[item.field_name] = item.field_value;

        return acc;
      }, {} as ParsedMemberData),
    };

    return parsedMember;
  } catch (e: any) {
    throw new Error(`Unable to retrieve any member with the email: "${email}". ${e.message}`);
  }
}

// type MemberOptions = {
//   email?: string | undefined;
// };

export async function fetchMemberForEmailV2(email: string): Promise<MemberModel> {
  try {
    const query = qs.stringify({
      email_address: email,
    });

    const response = await fetchAuthorization(`${API_URL}members?${query}`);

    const result = await response.json();

    if (response.status !== 200) {
      throw new Error(result.error ? result.error : `Status Code: ${response.status}. Something went wrong. Please try again later.`);
    }

    if (result.length) {
      const member = result[0] as MemberModel;
      return member;
    } else {
      throw new Error(`Unable to fetch any member for the email: "${email}"`);
    }
  } catch (e: any) {
    throw new Error(`Unable to fetch any member for the email: "${email}". ${e.message}`);
  }
}

export default fetchMember;
