import { apiOrigin } from '../constants';
import fetchWithAuthorization from './fetchWithAuthorization';

export interface Team {
  team_id: string;
  sport_id: number;
  gender: string;
  team_name: string;
  team_level: string;
  team_year: number;
  parent_team_id: string | null;
  parent_team_name: string | null;
  is_active_YN: number;
  created_by: string;
  updated_by: string | null;
  createdAt: string;
  updatedAt: string;
}

async function fetchTeams(): Promise<Team[]> {
  const response = await fetchWithAuthorization(`${apiOrigin}/api/v1/all_teams`);
  const { teams } = await response.json();

  return teams;
}

export default fetchTeams;
