import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Alert from '@mui/lab/Alert';
import { TextField, Button, Dialog, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useStyles } from './styles';
import ModalTitle from './ModalTitle';
import SearchInput from './SearchInput';
import { geographies } from '../../constants';
import { MemberContext } from '../CognitoAuth';
import * as clubActions from '../../redux/actions/clubActions';
import getVarcharEight from '../../helpers/get-random-id';
import InputSearch from '../Common/InputSearch/InputSearch';
import { Option } from '../../models/lib';

let statesFilter = geographies.map((data: any) => ({
  label: data.state_name,
  value: data.state_abbr,
}));

type Props = {
  club: any;
  dispatchAddClub?: any;
  showAddClub: boolean;
  onClose: () => void;
  isSuccessCallback?: any;
};

const AddClubModal: React.FC<Props> = (props) => {
  const { showAddClub, onClose, club, dispatchAddClub } = props;
  const { isLoadingAddClub } = club;
  const classes = useStyles();
  const { member } = useContext<any>(MemberContext);
  const [clubName, setClubName] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [website, setWebsite] = useState<string>('');
  const [mainContact, setMainContact] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [clubExists, setExistClub] = useState<boolean>(false);
  const sport_id = member ? member.sport_id_default : 1;
  const [selectedSportId, setSportId] = React.useState(sport_id);
  const [filteredData, setFilteredData] = useState<Option[]>([]);
  const Reg = new RegExp(state, 'i');


  const handleClose = () => {
    onClose();
  };

  const isSubmitDisabled = () => {
    if (isLoadingAddClub || clubName === '' || city === '' || state === '' || website === '' || clubExists === true || mainContact === '') {
      return true;
    }
    return false;
  };

  const resetForm = () => {
    setClubName('');
    setCity('');
    setState('all-states');
    setWebsite('');
    setSuccess(false);
    handleClose();
    onClose();
  };

  const onSubmit = () => {
    const club: any = {
      request_id: getVarcharEight(),
      request_type: 'club',
      item_name: clubName,
      item_city: city,
      item_state: state,
      item_website_URL: website,
      item_json: JSON.stringify({ main_contact: mainContact }),
      sport_id: selectedSportId,
    };

    const responseHandler = (success: boolean) => {
      if (success) {
        setSuccess(true);
      }
    };

    dispatchAddClub(club, responseHandler);
  };

  const onSearchResults = (data: any) => {
    if (data.length && clubName !== '') {
      const matchedClubs = data.find((x: any) => (x.search_term as string).toUpperCase() === clubName.toUpperCase());
      setExistClub(!!matchedClubs);
    } else {
      setExistClub(false);
    }
  };

  const getFullName = () => {
    return `${member ? member.first_name : ''} ${member ? member.last_name : ''}`;
  };
  const onChangeSportId = (event: React.MouseEvent<HTMLElement>, newAlignment: number | null) => {
    console.log('newAlignment', newAlignment);

    if (newAlignment) {
      setSportId(newAlignment);
    }
  };
  const OnkeyDown = (e: any) => {
    if (e.key === 'Escape') {
      handleClose();
    }
  };

  const onReset = () => {
    setState('');
  };

  const onChangeSearchValue = (e: any) => {
    setState(e.target.value);
  };

  const onSelectState = (data: Option) => {
    setState(data.value!);
  };
  useEffect(() => {
    setState('');
  },[onClose])

  useEffect(() => {
    const data = statesFilter.filter((data: any) => Reg.test(data.label || ''));
    setFilteredData(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Dialog maxWidth="xs" fullWidth={true} open={showAddClub} onKeyDown={OnkeyDown}>
      <ModalTitle title={'Request A Club To Be Added'} memberName={getFullName()} onClose={onClose} />
      <DialogContent className={classes.dialogContent}>
        <div className={classes.popupForm}>
          <ToggleButtonGroup
            value={selectedSportId}
            exclusive
            onChange={onChangeSportId}
            sx={{
              '& .MuiToggleButton-root.Mui-selected': {
                backgroundColor: '#1c315f',
                color: '#fff !important',
                '&:hover': {
                  backgroundColor: '#1c315f',
                },
              },
            }}
            classes={{
              root: classes.toggle,
            }}
            className={classes.toggleButton}
          >
            <ToggleButton value={1} className={classes.selectedToggleButton}>
              Boys
            </ToggleButton>
            <ToggleButton value={2} className={classes.selectedToggleButton}>
              Girls
            </ToggleButton>
          </ToggleButtonGroup>
          <div className={classes.clubsPopupFormSearchInputWrapper}>
            <SearchInput keyType="org_id" placeholder="Enter Club Name" label="Club Name*" onSearchChange={(key: string) => setClubName(key)} onUpdateResults={onSearchResults} hideAutoComplete={true} styles={{ width: '100%' }} />
          </div>

          {clubExists && (
            <Alert severity="error" className={classes.clubExistError}>
              A club by the name of "{clubName}" already exists! Are you sure?
            </Alert>
          )}
          <div className={`${classes.twoFormInputs} ${classes.clubsPopupFormSearchInputWrapper}`}>
            <div>
              <TextField
                label="City*"
                style={{ margin: 0 }}
                placeholder="Enter City Name"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                autoComplete="false"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className={classes.popupFormInput}
                variant="standard"
              />
            </div>
            <div className={`${classes.popupFormSelect} ${classes.popupFormSelectLast}`}>
              <label>State*</label>
              <InputSearch searchKey={state} placeholder="Select State/Province" filteredData={filteredData} fieldToDisplay="label" onReset={onReset} onChangeSearch={onChangeSearchValue} onSelect={onSelectState} />
            </div>
          </div>
          <div className={classes.clubsPopupFormSearchInputWrapper}>
            <TextField
              label="Website URL (Required)*"
              style={{ margin: 0 }}
              placeholder="Enter Website"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="false"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              className={classes.popupFormInput}
              variant="standard"
            />
          </div>
          <div>
            <TextField
              label="Main Contact Email (Required)*"
              style={{ margin: 0 }}
              placeholder="Enter Email"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="false"
              value={mainContact}
              onChange={(e) => setMainContact(e.target.value)}
              className={classes.popupFormInput}
              variant="standard"
            />
          </div>

          <DialogActions className={classes.playerDialogActions}>
            <Button variant="contained" color="primary" onClick={onSubmit} sx={{ mt: 4 }} className={classes.playerCSVSubmit} disabled={isSubmitDisabled()}>
              {!isLoadingAddClub ? 'Submit For Review' : <CircularProgress size={22} className={classes.buttonProgress} />}
            </Button>
          </DialogActions>
        </div>

        <Dialog open={success} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Thanks for the request!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Your request has been received and the club will be added to our database shortly.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  club: state.clubReducer,
});

const mapDispatchToProps = {
  dispatchAddClub: (data: any, callback: any) => clubActions.addClub(data, callback),
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClubModal);
