const levelStates = [
  {
    label: 'Level',
    value: 'level',
  },
  {
    label: 'Extremely Competitive/Playing to Win',
    value: 'AA',
  },
  {
    label: 'Somewhat Competitive/Developing Players',
    value: 'A',
  },
  {
    label: 'Playing for Fun',
    value: 'B',
  },
];

export default levelStates;
