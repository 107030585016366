import React from 'react';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
interface ErrorLike {
  message: string;
}

type Props = {
  className?: string;
  error: Error | ErrorLike | string;
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: alpha(theme.palette.error.main, 0.2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.error.main}`,
    padding: theme.spacing(2),
  },
}));

const ErrorBlock: React.FC<Props> = ({ className, error }) => {
  const classes = useStyles();

  return <div className={[classes.root, className].filter(Boolean).join(' ')}>{typeof error === 'string' ? error : error.message}</div>;
};

export default ErrorBlock;
