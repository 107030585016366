export const OPEN_ERROR_DIALOG = 'OPEN_ERROR_DIALOG';
export const CLOSE_ERROR_DIALOG = 'CLOSE_ERROR_DIALOG';
export const OPEN_SUCCESS_DIALOG = 'OPEN_SUCCESS_DIALOG';
export const CLOSE_SUCCESS_DIALOG = 'CLOSE_SUCCESS_DIALOG';

export const openErrorDialog = (error: string) => ({
  type: OPEN_ERROR_DIALOG,
  error,
});

export const closeErrorDialog = () => ({
  type: CLOSE_ERROR_DIALOG,
});

export function handleError(error: any) {
  return (dispatch: any) => {
    if (error.message) {
      dispatch(openErrorDialog(error.message));
    } else {
      dispatch(openErrorDialog('Sorry, something went wrong!'));
    }
  };
}

export const openSuccessDialog = (message: string) => ({
  type: OPEN_SUCCESS_DIALOG,
  message,
});

export const closeSuccessDialog = () => ({
  type: CLOSE_SUCCESS_DIALOG,
});

export function handleSuccess(message: any) {
  return (dispatch: any) => {
    if (message) {
      dispatch(openSuccessDialog(message));
    }
  };
}