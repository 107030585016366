import { apiOrigin } from '../constants';
import { API_URL } from '../constants';
import fetchWithAuthorization from './fetchWithAuthorization';
import fetchAuthorization from './fetchAuthorization';

export interface Member {
  first_name?: string;
  last_name?: string;
}

async function updateMember(memberId: string, member: Partial<Member>): Promise<void> {
  try {
    const response = await fetchWithAuthorization(`${apiOrigin}/api/v1/update-member/${memberId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(member),
    });

    const data = await response.json();

    if (!data.success) {
      throw new Error(data.message);
    }

    return;
  } catch (e: any) {
    throw new Error(`Unable to update member: ${e.message}`);
  }
}

export interface MemberData {
  member_type: string;
}

export async function updateMemberV2(data: MemberData, member_id: string): Promise<any> {
  try {
    const response = await fetchAuthorization(`${API_URL}members?member_id=${member_id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (response.status !== 200) {
      throw new Error(result.error ? result.error : `Status Code: ${response.status}. Sorry, something went wrong. Please try again later.`);
    }

    return result;
  } catch (e: any) {
    throw new Error(`Unable to update memeber. ${e.message}`);
  }
}

export default updateMember;
