// import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import qs from 'qs';

export interface TeamRankings {
  team_id: number;
  rank: number;
  team_name: string;
  state: string;
  mRPI: number;
  won: number;
  lost: number;
  winning_percent: string;
  sos: string;
  opp_sos: string;
  agd: string;
  is_following: number;
}

type TeamRankingsOptions = {
  team_year: number;
  team_level?: string | undefined;
  team_name?: string | undefined;
  gender?: string | undefined;
  state?: string | undefined;
  sport_id?: number;
};

export async function fetchTeamRankings(opts: TeamRankingsOptions): Promise<TeamRankings[]> {
  const query = qs.stringify({
    team_year: opts.team_year,
    team_level: opts.team_level,
    team_name: opts.team_name,
    // gender: opts.gender,
    sport_id: opts.sport_id,
    state: opts.state,
  });

  const response = await fetchAuthorization(`https://api.clublacrosse.org/v2/team_rankings?${query}`);

  const result = await response.json();
  if (response.status !== 200) {
    throw new Error(result.error ? result.error : `Status Code: ${response.status}. Sorry, something went wrong. Please try again later.`);
  }
  return result;
}

export default fetchTeamRankings;
