import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MuiTabs, { TabsTypeMap } from '@mui/material/Tabs';

const useStyles = makeStyles((theme) => ({
  indicator: {
    height: '4px !important',
  },
}));

const Tabs: React.FC<TabsTypeMap['props']> = (props) => {
  const tabBarClasses = useStyles(props);

  return (
    <MuiTabs indicatorColor="primary" scrollButtons="auto" textColor="primary" variant="scrollable" classes={tabBarClasses} {...props}>
      {props.children}
    </MuiTabs>
  );
};

export default Tabs;
