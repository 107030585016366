import * as clubActions from './clubActions';
import * as teamActions from './teamActions';
import * as playerActions from './playerActions';
import _fetchRequest from '../../apis/_fetchRequest';

export const UPDATE_CLUB = 'UPDATE_CLUB';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const UPDATE_PLAYER = 'UPDATE_PLAYER';
export const FETCH_GET_CLUB = 'FETCH_GET_CLUB';

export const ADD_TEAM_REQ_START = 'ADD_TEAM_REQ_START';
export const ADD_TEAM_REQ_SUCCESS = 'ADD_TEAM_REQ_SUCCESS';
export const ADD_TEAM_REQ_FAILED = 'ADD_TEAM_REQ_FAILED';

export const updateClub = (data: any) => ({
  type: UPDATE_CLUB,
  data,
});

export const updateTeam = (data: any) => ({
  type: UPDATE_TEAM,
  data,
});

export const updatePlayer = (data: any) => ({
  type: UPDATE_PLAYER,
  data,
});

export const addTeamStart = () => ({
  type: ADD_TEAM_REQ_START,
});

export const addTeamSuccess = () => ({
  type: ADD_TEAM_REQ_SUCCESS,
});

export const addTeamFailed = (error: string) => ({
  type: ADD_TEAM_REQ_FAILED,
  error,
});

export function resetTeamsAndPlayers() {
  return (dispatch: any) => {
    dispatch(teamActions.resetTeams());
    dispatch(playerActions.resetPlayers());
  };
}

export function resetTeamsAndClubs() {
  return (dispatch: any) => {
    dispatch(teamActions.resetTeams());
    dispatch(clubActions.resetClubs());
  };
}

export function resetPlayers() {
  return (dispatch: any) => {
    dispatch(playerActions.resetPlayers());
  };
}
export function resetAll() {
  return (dispatch: any) => {
    dispatch(clubActions.resetClubs());
    dispatch(teamActions.resetTeams());
    dispatch(playerActions.resetPlayers());
  };
}

export const getClub = (clubId: string) => async (dispatch: any) => {
  const club: any = await _fetchRequest('GET', 'org_summary', {
    org_id: clubId,
  });

  dispatch({
    type: FETCH_GET_CLUB,
    payload: club[0],
  });
};

export function addTeam(data: any, callback: any) {
  return (dispatch: any) => {
    dispatch(addTeamStart());
    _fetchRequest('POST', 'public_requests', undefined, data)
      .then((res: any) => {
        dispatch(addTeamSuccess());
        callback(true);
      })
      .catch((error: any) => {
        console.log('error in set ', error);
        dispatch(addTeamFailed(error));
        callback(false);
      });
  };
}
