import { FETCH_GEOGRAPHIES_REQ_SUCCESS } from '../actions/geographiesActions';

let initialState = {
  geographies: [],
};

const geographiesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_GEOGRAPHIES_REQ_SUCCESS:
      return { ...state, geographies: action.data };
    default:
      return state;
  }
};

export default geographiesReducer;
