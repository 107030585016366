import { orderBy } from 'lodash';
import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';

export async function fetchDivisions(eventId: string): Promise<any> {
  try {
    const data = await fetchAuthorization(`${API_URL}event_divisions?event_id=${eventId}`);
    const divisions = await data.json();

    return orderBy(divisions, [(division) => division.division_name]);
  } catch (e: any) {
    throw new Error(`Something went wrong. Error message: ${e.message}`);
  }
}

export default fetchDivisions;
