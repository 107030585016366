import React, { ReactNode } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import AppHeader from '../AppHeader';
import AppFooter from '../AppFooter';
import ErrorDialog from '../ErrorDialog';
import SuccessDialog from '../SuccessDialog';
import Toastr from '../Common/Toasts/index';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    minHeight: 600,
  },
}));

const PageWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <AppHeader />
      <ErrorDialog />
      <SuccessDialog/>
      <Toastr />
      <div data-testid="main" className={classes.main}>
        {children}
      </div>
      <AppFooter />
    </>
  );
};

export default PageWrapper;
