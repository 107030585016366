import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Search, Close as CloseIcon } from '@mui/icons-material';
import { Typography, InputAdornment, TextField, CircularProgress, List, ListItem } from '@mui/material';
import { useStyles } from './styles_search';

import ErrorBlock from '../ErrorBlock';
import * as searchActions from '../../redux/actions/searchActions';

interface Props {
  placeholder: string;
  label: string;
  search?: any;
  keyType: string;
  dispatchSearch?: any;
  isDisabled?: boolean;
  onSelect?: any;
  onSearchChange?: (key: string) => void;
  onUpdateResults?: (data: Array<any>) => void;
  onReset?: any;
  hideAutoComplete?: boolean;
  id?: string;
  styles?: object;
  showCustomStylesForSearch?: any;
  sport_id?: string;
  className?: string;
}

const SearchInput: React.FC<Props> = (props) => {
  const { search, className, sport_id, dispatchSearch, placeholder, label, hideAutoComplete, keyType, isDisabled, onSelect, onSearchChange, onUpdateResults, onReset, id, styles, showCustomStylesForSearch } = props;
  const classes = useStyles();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [searchKey, setSearchKey] = useState('');
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);

  useEffect(() => {
    const data: any = {};
    data.key_type = keyType;
    data.search_term = searchKey;
    if (sport_id) {
      data.sport_id = sport_id;
    }

    if (searchKey && isAutocompleteOpen === true) {
      dispatchSearch(data);
    }
  }, [dispatchSearch, searchKey, isAutocompleteOpen, keyType, sport_id]);

  useEffect(() => {
    // if search selection has been cleared, reset search bar, but don't fetch clubs
    if (search.searchSelection === '') {
      reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.searchSelection]);

  const filteredData = getFilterData();

  function getFilterData() {
    const { searchResults } = search;
    if (searchResults) {
      if (onUpdateResults) onUpdateResults(searchResults); // for add club modal
      return searchResults;
    }
    if (onUpdateResults) onUpdateResults([]); // for add club modal
    return [];
  }

  function useOutsideAlerter(ref: any) {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsAutocompleteOpen(false);
      }
    }

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    });
  }

  const handleSearch = (event: any) => {
    setIsAutocompleteOpen(true);
    setSearchKey(event.target.value);
    if (onSearchChange) onSearchChange(event.target.value); // for add club modal
  };

  const onSelectSearchTerm = (data: any) => {
    setIsAutocompleteOpen(false);
    setSearchKey(data.search_term);
    if (onSearchChange) onSearchChange(data.search_term); // for add club modal
    if (onSelect) {
      onSelect(data);
    }
  };

  const reset = () => {
    setSearchKey('');
    if (onSearchChange) onSearchChange('');
    if (onReset) onReset();
  };

  return (
    <div style={{ ...styles }} className={`${classes.searchSection} ${className}`}>
      <TextField
        id={id ? id : 'playerName'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {searchKey !== '' && <CloseIcon fontSize="small" className={classes.closeIcon} onClick={reset} />}
              {searchKey === '' && <Search fontSize="small" />}
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        label={label}
        placeholder={placeholder}
        onChange={handleSearch}
        value={searchKey}
        className={showCustomStylesForSearch ? showCustomStylesForSearch().root : classes.root}
        autoComplete="off"
        variant="standard"
        disabled={isDisabled}
      />
      {isAutocompleteOpen === true && !hideAutoComplete && (
        <div className={classes.searchResultSection} ref={wrapperRef}>
          <div className={classes.searchResult}>
            <List classes={{ root: classes.listRoot }}>
              {filteredData.length > 0 &&
                !search.isLoadingSearch &&
                filteredData.map((data: any, index: number) => {
                  return (
                    <ListItem key={data.fk_id} button onClick={() => onSelectSearchTerm(data)}>
                      <Typography variant="h5" className={classes.searchTerm}>
                        {data.search_term}
                      </Typography>
                      {!sport_id && <Typography>{data.details}</Typography>}
                    </ListItem>
                  );
                })}
              {search.error && <ErrorBlock error={search.error} />}
              {search.isLoadingSearch && <CircularProgress classes={{ root: classes.loader }} size={20} />}
              {filteredData.length === 0 && !search.isLoadingSearch && !search.error && (
                <Typography variant="h5" gutterBottom classes={{ root: classes.dataNotFound }}>
                  No results found.
                </Typography>
              )}
            </List>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  search: state.searchReducer,
});

const mapDispatchToProps = {
  dispatchSearch: (data: any) => searchActions.handleSearch(data),
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
