// Data Lab - Team Lab Stats (game-level)
// NOTE: consolidated team-level stats are done dynamically from TeamLabYear.tsx
export async function processTeamGameStats(data: any) {
  if (data) {
    let games: any[] = [];

    for await (const item of data) {
      try {
        let copyItem = Object.assign({}, item);

        // Parse stats from String to Array
        let parsedJsonGameResults: any;

        if (copyItem.json_game_results !== null) {
          let jsonStr = copyItem.json_game_results;
          parsedJsonGameResults = JSON.parse(jsonStr);
        }

        delete copyItem.json_game_results;

        let teamStats = parsedJsonGameResults !== undefined ? parsedJsonGameResults : null;
        if (teamStats && teamStats.length > 0) {
          teamStats.forEach((stat: any) => {
            let finalTeamData = {
              ...copyItem,
            };

            finalTeamData.game_id = stat.game_id;
            finalTeamData.game_date = stat.game_date;

            finalTeamData.goals_scored = stat.team_score ? +stat.team_score : -1;
            finalTeamData.goals_against = stat.opponent_team_score ? +stat.opponent_team_score : -1;
            finalTeamData.assists = stat.assists ? +stat.assists : -1;
            finalTeamData.forced_turnovers = stat.forced_turnovers ? +stat.forced_turnovers : -1;
            finalTeamData.ground_balls = stat.ground_balls ? +stat.ground_balls : -1;

            // "Win %""
            if (finalTeamData.goals_scored > finalTeamData.goals_against) {
              finalTeamData.win_percent = 100;
            }
            if (finalTeamData.goals_scored < finalTeamData.goals_against) {
              finalTeamData.win_percent = 0;
            }
            if (finalTeamData.goals_scored === finalTeamData.goals_against) {
              finalTeamData.win_percent = 50;
            }

            let totalPoints = 0; // goalsScored + assists
            let totalStats = 0; // Number(goalsScored + assists + fto + GB)

            if (finalTeamData.goals_scored > -1) {
              totalPoints += finalTeamData.goals_scored;
              totalStats += +finalTeamData.goals_scored;
            }

            if (finalTeamData.assists > -1) {
              totalPoints += finalTeamData.assists;
              totalStats += finalTeamData.assists;
            }

            if (finalTeamData.forced_turnovers > -1) {
              totalStats += finalTeamData.forced_turnovers;
            }

            if (finalTeamData.ground_balls > -1) {
              totalStats += finalTeamData.ground_balls;
            }

            finalTeamData.total_points = totalPoints;
            finalTeamData.date_window_points = totalStats;
            games.push(finalTeamData);
          });
        }
      } catch (e: any) {
        console.log(e.message);
      }
    }
    return games;
  } else {
    return [];
  }
}
