import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';

export async function deleteHighlights(
  deletedVideo: (string | number)[],
): Promise<any> {
  try {
    deletedVideo.map(async (video: string | number) => {
      return await fetchAuthorization(
        `${API_URL}member_media?member_media_id=${video}`,
        {
          method: 'DELETE',
        },
      );
    });
  } catch (e: any) {
    throw new Error(`Unable to add players. Error message: ${e.message}`);
  }
}
