import { apiOrigin } from '../constants';
import fetchWithAuthorization from './fetchWithAuthorization';

type MemberDataFieldName = 'instagram' | 'laxRans_ID' | 'member_type';
type MemberData = Partial<Record<MemberDataFieldName, string>>;

async function updateMemberData(memberId: string, memberData: MemberData): Promise<void> {
  try {
    const body = JSON.stringify({
      data: Object.entries(memberData).map(([field_name, field_value]) => ({
        member_id: memberId,
        field_name,
        field_value,
      })),
    });

    const response = await fetchWithAuthorization(`${apiOrigin}/api/v1/save-members-data/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    const data = await response.json();

    if (!data.success) {
      throw new Error(data.message);
    }

    return;
  } catch (e: any) {
    throw new Error(`Unable to update member: ${e.message}`);
  }
}

export default updateMemberData;
