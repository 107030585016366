import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  id: string;
  data?: any[];
  value: any;
  onValueChange: (data: any) => void;
  placeholder?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  formFilter: {
    marginLeft: `${theme.spacing(1)} !important`,
    marginRight: `${theme.spacing(1)} !important`,
  },
  root: {
    width: 300,
  },
  select: {
    fontSize: '12px !important',
    color: `${theme.palette.primary.main} !important`,
    fontWeight: 'bold !important',
    width: 120,
    lineHeight: '1.1876em !important',
  },
  icon: {
    fill: `${theme.palette.primary.main} !important`,
  },
  MuiSelectOutlined: {
    '& .MuiSelect-outlined': {
      padding: "10px 32px 10px 15px !important",
      fontSize: '14px'

    },
    '& fieldset': {
      borderColor: `${theme.palette.primary.main} !important`
    },
    '& .MuiOutlinedInput-input': {
      minHeight: '1.1876em !important',

      '&:focus': {
        backgroundColor: "rgba(0, 0, 0, 0.05) !important",
      }
    },
  },
}));

export default function MaterialuiSelect(props: Props) {
  const classes = useStyles();

  const { value, onValueChange, id, data = [], placeholder } = props;
  return (
    <FormControl className={classes.formFilter} variant="outlined">
      <Select
        id={id}
        value={value}
        onChange={onValueChange}
        className={`${classes.select} ${classes.MuiSelectOutlined}`}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
        displayEmpty
      >
        {placeholder !== '' && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )}
        {data.map((item, i) => (
          <MenuItem value={item.value} key={i}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
