import { API_URL } from '../../constants';
import PlayerUniversity from '../../models/PlayerUniversity';
import fetchAuthorization from '../fetchAuthorization';

export async function fetchPlayerUniversity(memberId: string): Promise<any> {
  try {
    const respUniversities = await fetchAuthorization(`${API_URL}member_universities?member_id=${memberId}`);
    const respAllUniversities = await fetchAuthorization(`${API_URL}universities`);
    const playerUniversities = await respUniversities.json();
    const allUniversities = await respAllUniversities.json();

    const data: PlayerUniversity[] = [];
    allUniversities.forEach((un: any) => {
      const playerUniver = playerUniversities.find((k: PlayerUniversity) => k.university_id === un.university_id && k.sport_id === un.sport_id);
      if (playerUniver) {
        data.push({
          ...playerUniver,
          city: un.city,
          region: un.region,
          state: un.state_abbr,
          short_name: un.short_name,
        } as PlayerUniversity);
      }
    });

    return { playerUniversities: data, allUniversities: allUniversities };
  } catch (e: any) {
    throw new Error(`Something went wrong. Error message: ${e.message}`);
  }
}

export default fetchPlayerUniversity;
