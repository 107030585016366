import { API_URL } from '../constants';
import { AllHighlightsRatings } from '../models/Highlight';
import fetchAuthorization from './fetchAuthorization';

async function fetchAllPlayerHighlightsRating(): Promise<AllHighlightsRatings[]> {

  const response = await fetchAuthorization(`${API_URL}member_media_statistics`);
  const data = await response.json();

  if (!data) {
    throw new Error(`No promo codes were found.`);
  }

  return data;
}

export default fetchAllPlayerHighlightsRating;
