import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { alpha, Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
  active?: boolean;
  noActiveBorder?: boolean;
}
type ClubLacrosseNavLinkProps = NavLinkProps & Props;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: ({ noActiveBorder }: ClubLacrosseNavLinkProps) => ({
      color: `${theme.palette.primary.contrastText} !important`,
      borderBottom: `5px solid ${noActiveBorder ? 'transparent' : theme.palette.error.main} !important`,
    }),
    navlink: {
      borderBottom: '5px solid transparent',
      color: alpha(theme.palette.primary.contrastText, 0.5),
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.primary.contrastText,
        textDecoration: 'underline',
      },
    },
  }),
);

const ClubLacrosseNavLink = (props: ClubLacrosseNavLinkProps) => {
  const { active, noActiveBorder, ...navLinkProps } = props;
  const classes = useStyles(props);
  return (
    <NavLink {...navLinkProps} className={({ isActive }) => (isActive ? `${props.className} ${classes.navlink} ${classes.active}` : `${props.className} ${classes.navlink}`)}>
      {props.children}
    </NavLink>
  );
};

export default ClubLacrosseNavLink;
