import React from 'react';
import Typography from '@mui/material/Typography';
import AddRecordsButton from '../AddRecordsButton';

import { useStyles } from './styles';

type Props = {
  text: string;
  addRecordButton?: boolean;
  recordType?: string;
  buttonText?: string;
  className?: string;
  styles?: object;
};

const EmptyState: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { text, addRecordButton, recordType, buttonText, className, styles } = props;

  return (
    <div className={className} style={{ ...styles }} data-testid="empty-state">
      <Typography className={classes.emptyState} variant="h4">
        {text}
      </Typography>
      {addRecordButton && buttonText && recordType && <AddRecordsButton recordType={recordType} buttonText={buttonText} />}
    </div>
  );
};

export default EmptyState;
