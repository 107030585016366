import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { List, ListItem, Typography, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import ErrorBlock from '../ErrorBlock';

import * as searchActions from '../../redux/actions/searchActions';
import { routes } from '../../constants';
import { useStyles } from './styles';
import { DebounceInput } from 'react-debounce-input';
import './styles.css';
import ClickAwayListener from '@mui/material/ClickAwayListener';

interface Props {
  open: boolean;
  onClose: () => void;
  search: any;
  dispatchSearch: any;
  sportId?: number | undefined;
}

const SearchBar: React.FC<Props> = ({
  onClose,
  dispatchSearch,
  search,
  sportId,
}) => {
  const classes = useStyles();
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    const data: any = {};
    data.search_term = searchName;
    if (sportId) data.sport_id = sportId;

    if (searchName) {
      dispatchSearch(data);
    }
  }, [dispatchSearch, searchName, sportId]);

  const handleClose = () => {
    setSearchName('');
    onClose();
  };

  const handleSearch = (event: any) => {
    setSearchName(event.target.value);
  };

  const filteredData = getFilterData();

  function getFilterData() {
    const { searchResults } = search;
    if (searchResults) {
      const data = searchResults;
      return data;
    }
    return [];
  }

  const getRoute = (data: any) => {
    switch (data.key_type) {
      case 'member_id':
        return routes.playerDetail({ playerId: data.fk_id });
      case 'club_id':
        return routes.clubDetail({ clubId: data.fk_id });
      case 'event_id':
        return routes.eventDetail({ eventId: data.fk_id });
      case 'team_id':
        return routes.teamDetail({ teamId: data.fk_id });
      default:
        return routes.clubDetail({ clubId: data.fk_id });
    }
  };
   const onkeydown = (e:any) => {
    if (e.key === "Escape") {
      handleClose()
    }
   }

  return (
    <div className={classes.dialogContainer}>
      <div className={classes.dialogContent}>
        {searchName ? (
          <CloseIcon
            classes={{ root: classes.closeIcon }}
            onClick={handleClose}
          />
        ) : (
          <div style={{ width: 44 }} />
        )}
        {/* <Input
          placeholder="Search for Clubs, Teams, Events & Players"
          inputProps={{ 'aria-label': 'description' }}
          fullWidth={true}
          classes={{
            root: classes.iconRoot,
            underline: classes.inputUnderline,
          }}
          value={searchName}
          onChange={handleSearch}
          autoFocus={true}
        /> */}
        <DebounceInput
          value={searchName}
          minLength={2}
          debounceTimeout={300}
          onChange={handleSearch}
          placeholder="Global Search for Clubs, Teams, Events & Players"
          inputprops={{ 'aria-label': 'description' }}
          fullwidth={''}
          className={`${classes.inputRoot}`}
          onKeyDown={onkeydown}
        />
        <SearchIcon classes={{ root: classes.searchIcon }} />
      </div>
      {searchName !== '' &&  (
        <ClickAwayListener onClickAway={handleClose}>
          <div className={classes.dialogActionsRoot}>
            <div className={classes.searchResult}>
              <List classes={{ root: classes.listRoot }}>
                {filteredData.length > 0 &&
                  !search.isLoadingSearch &&
                  filteredData.map((data: any, index: number) => {
                    return (
                      <ListItem key={index} button>
                        <Link
                          className={classes.searchTermLink}
                          to={getRoute(data)}
                          onClick={handleClose}
                        >
                          <Typography
                            variant="h5"
                            classes={{ root: classes.searchTerm }}
                          >
                            {data.search_term}
                          </Typography>
                          <Typography>{data.details}</Typography>
                        </Link>
                      </ListItem>
                    );
                  })}
                {search.error && <ErrorBlock error={search.error} />}
                {search.isLoadingSearch && (
                  <CircularProgress
                    classes={{ root: classes.loader }}
                    size={20}
                  />
                )}
                {filteredData.length === 0 &&
                  !search.isLoadingSearch &&
                  !search.error && (
                    <Typography
                      variant="h5"
                      gutterBottom
                      classes={{ root: classes.dataNotFound }}
                    >
                      No results found.
                    </Typography>
                  )}
              </List>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  search: state.searchReducer,
});

const mapDispatchToProps = {
  dispatchSearch: (data: any) => searchActions.handleSearch(data),
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
