import { updateMemberV2 } from '../../apis';
import { IPlayerProfile } from '../../models/PlayerProfile';
import * as commonActions from './commonActions';
import { USLacrosse, USLacrosseDivision } from '../../models/USLacrosse';
import addUSLacrosse from '../../apis/addUSLacrosse';
import { USLacrosseMember } from '../../utils';
import { AUTH_API_URL } from '../../constants';
import fetchUSLacrosse from '../../apis/fetchUSLacrosse';
import fetchUSLacrosseDivision from '../../apis/fetchUSLacrosseDivision';

export const SET_MEMBER_DATA = 'SET_MEMBER_DATA';

export const UPDATE_MEMBER_START = 'UPDATE_MEMBER_START';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAILED = 'UPDATE_MEMBER_FAILED';

export const SET_MEMBER_PROFILE = 'SET_MEMBER_PROFILE';

export const MEMBER_AGE_VERIFY_SUCCESS = 'MEMBER_AGE_VERIFY_SUCCESS';

export const MEMBER_PING_SUCCESS = 'MEMBER_PING_SUCCESS';
export const GET_AUTH_TOKEN_SUCCESS = 'GET_AUTH_TOKEN_SUCCESS';
export const US_MEMBER_AGE_VERIFY_SUCCESS = 'US_MEMBER_AGE_VERIFY_SUCCESS';
export const US_MEMBER_AGE_VERIFY_DIVISION_SUCCESS = 'US_MEMBER_AGE_VERIFY_DIVISION_SUCCESS';

export const updateMemberStart = () => ({
  type: UPDATE_MEMBER_START,
});

export const updateMemberSuccess = (data: any) => ({
  type: UPDATE_MEMBER_SUCCESS,
  data,
});

export const updateMemberFailed = (error: string) => ({
  type: UPDATE_MEMBER_FAILED,
  error,
});

export const setMemberData = (data: any) => ({
  type: SET_MEMBER_DATA,
  data,
});

export const setMemberProfile = (data: IPlayerProfile) => ({
  type: SET_MEMBER_PROFILE,
  data,
});

export const memberPingSuccess = (data: any) => ({
  type: MEMBER_PING_SUCCESS,
  data,
});

export const getAuthTokenSuccess = (data: any) => ({
  type: GET_AUTH_TOKEN_SUCCESS,
  data,
});

export const USMemberAgeVerifySuccess = (data: any) => ({
  type: US_MEMBER_AGE_VERIFY_SUCCESS,
  data,
});
export const USMemberAgeVerifyDivisionSuccess = (data: any) => ({
  type: US_MEMBER_AGE_VERIFY_DIVISION_SUCCESS,
  data,
});

export function updateMember(data: any, id: string, callback: any) {
  return (dispatch: any) => {
    dispatch(updateMemberStart());
    updateMemberV2(data, id)
      .then((res: any) => {
        dispatch(updateMemberSuccess(data));
        if (callback) {
          callback(true);
        }
      })
      .catch((_error: any) => {
        dispatch(updateMemberFailed('Sorry, something went wrong, Please try later!'));
        if (callback) {
          callback(false);
        }
      });
  };
}

export function getAuthenticationToken(callback: any) {
  return (dispatch: any) => {
    fetch(`${AUTH_API_URL}/auth2`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(USLacrosseMember),
    })
      .then((response) => {
        response.json().then((data) => {
          if (data) {
            if (callback) {
              callback(data.access_token);
            }
            dispatch(getAuthTokenSuccess(data));
          }
        });
      })
      .catch((error: any) => {
        if (callback) {
          callback(undefined);
        }
        console.log('error in set ', error);
        dispatch(commonActions.handleError(error));
      });
  };
}

export function getMemberPing(membership_id: string, token: string, member_id: string, callback: any) {
  return (dispatch: any) => {
    fetch(`${AUTH_API_URL}/v1/MemberPing?membership_id=${membership_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })
      .then((res: any) => {
        res.json().then((data: any) => {

          if (data.status_code === 200) {
            let payload = { ...data.output };
            const details = {
              firstname: payload.firstname,
              lastname: payload.lastname,
              email: payload.email,
              birthdate: payload.birthdate,
              zip: payload.postalcode,
              MCID: payload.membership_id,
              mbr_expiration: payload.exp_date,
              age_verified: payload.age_verified,
              member_id: member_id,
            };
            if (callback) {
              callback(details);
            }
            dispatch(memberPingSuccess(data));
          } else {
            if (callback) {
              callback(undefined);
            }
            dispatch(commonActions.handleError({ message: data.output[0] }));
          }
        });
      })
      .catch((error: any) => {
        if (callback) {
          callback(undefined);
        }
        dispatch(commonActions.handleError(error));
      });
  };
}

export function postMemberAgeVerifyInUS_Lacrosse(payload: any, type: 'PUT' | 'POST') {
  return (dispatch: any) => {
    addUSLacrosse(payload, type)
      .then((res: USLacrosse[]) => {
        if (res) {
          dispatch(commonActions.handleSuccess('Age verified successfully/updated with USA Lacrosse!'))
          setTimeout(() => {
            dispatch(getMemberAgeVerify(payload.member_id));
          }, 3000);
        }
      })
      .catch((error: any) => {
        dispatch(commonActions.handleError(error));
      });
  };
}

export function getMemberAgeVerify(member_id: string) {
  return (dispatch: any) => {
    fetchUSLacrosse(member_id)
      .then((res: USLacrosse[]) => {
        if (res.length > 0) {
          dispatch(USMemberAgeVerifySuccess(res[0]));
        }
      })
      .catch((error: any) => {
        dispatch(commonActions.handleError(error));
      });
  };
}

export function getMemberAgeVerifyUS_Lacrosse_Division(payload: any) {
  return (dispatch:any) => {
    fetchUSLacrosseDivision(payload)
      .then((res: USLacrosseDivision[][]) => {
        if(res.length > 0){
          if(res[0][0].Error_Message){
            dispatch(commonActions.handleError({message: res[0][0].Error_Message}));
          }else{
            dispatch(USMemberAgeVerifyDivisionSuccess(res[0][0]));
          }
        }
      })
      .catch((error: any) => {
        dispatch(commonActions.handleError(error));
      });
  };
}

