import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      fontSize: '18px!important',
      fontWeight: 'bold !important',
    },
    dialogDescription: {
      fontSize: '16px !important',
    },
    btnCofirm: {
      fontSize: '14px !important',
    },
  }),
);
