import { API_URL } from '../../constants';
import fetchAuthorization from '../fetchAuthorization';

export async function deleteAccolade(deletedAccolade: string | number): Promise<any> {
  try {
    return await fetchAuthorization(`${API_URL}player_accolades?accolade_id=${deletedAccolade}`, {
      method: 'DELETE',
    });
  } catch (e: any) {
    throw new Error(`Unable to delete accolade. Error message: ${e.message}`);
  }
}
