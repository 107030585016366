import React, { useState, useContext, useEffect } from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, Button, Box, TextField, DialogContentText, DialogActions, FormControl, Select, MenuItem } from '@mui/material';
import { useStyles } from './styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MemberContext, MemberContexts } from '../CognitoAuth';
import { connect, useSelector } from 'react-redux';
import PlayerStat from '../../models/PlayerStat';
import Division from '../../models/Division';
import { PowerRankings } from '../../models/PowerRankings';
import { PlayerTeamMap } from '../../models/PlayerTeamMap';
import { routes } from '../../constants';
import { useNavigate } from 'react-router-dom';
import * as playerActions from '../../redux/actions/playerTeamActions';
import { orderBy } from 'lodash';
// import { blue } from '@mui/material/colors';

type Props = {
  title: string;
  openModel: boolean;
  close: () => void;
  divisions?: any;
  powerRanking?: any;
  dispatchPostPlayerTeam: (data: any, teamName: string, callback: any) => void;
};

const optionData: any = [
  { id: 1, label: 'Attack', value: 'Attack' },
  { id: 2, label: 'Attack/Middie', value: 'Attack/Middie' },
  { id: 6, label: 'Middie', value: 'Middie' },
  { id: 5, label: 'LSM', value: 'LSM' },
  { id: 3, label: 'Defense', value: 'Defense' },
  { id: 4, label: 'FOGO/Draw', value: 'FOGO/Draw' },

  { id: 7, label: 'Goalie', value: 'Goalie' },
];

const AddTeamPlayerEvent: React.FC<Props> = (props) => {
  const { title, openModel, close, divisions, powerRanking, dispatchPostPlayerTeam } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const { member } = useContext<MemberContexts>(MemberContext);
  const [jerseyNumber, setJerseyNumber] = useState('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [submit, setSubmit] = React.useState<boolean>(false);
  const [isJNumberExist, setIsJNumberExist] = React.useState<boolean | undefined>(undefined);
  const [warning, setWarning] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const memberProfile = useSelector((state: any) => state.memberReducer.memberProfile);
  const players = useSelector((state: any) => state.playerReducer.playerSummaryStatsResult);
  const currentPosition = optionData.filter((item: any) => item.value === memberProfile?.player_position);
  const [selectedPosition, setSelectedPosition] = useState(currentPosition[0]?.id!);
  const [activeDivision, setActiveDivision] = useState(null);
  const [activeTeam, setActiveTeam] = useState<string | null>(null);

  const activeDivisionName = divisions?.find((division: Division) => division.division_id === activeDivision)?.division_name;

  const teamName = powerRanking && powerRanking.length > 0 && powerRanking.filter((r: PowerRankings) => r.division_name === activeDivisionName);

  let isSubmitJNumber = false;

  useEffect(() => {
    setSubmit(false);
    if (jerseyNumber === '') {
      setIsJNumberExist(false);
    }
  }, [jerseyNumber]);

  const onClose = () => {
    close();
    setJerseyNumber('');
    setSubmit(false);
    setStartDate('');
    setEndDate('');
    // handleOnThanks();
  };
  const OnkeyDown = (e: any) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  const isSubmitDisabled = () => {
    if (startDate === '' || jerseyNumber === '' || selectedPosition === '' || activeDivision === null || activeTeam === null) {
      return true;
    }
    return false;
  };

  const onSave = () => {
    setSubmit(true);
    const data = players.find((stat: PlayerStat) => stat.jersey_number === jerseyNumber);

    if (isSubmitDisabled()) {
      setWarning(true);
    }
    if (data) {
      setIsJNumberExist(true);
    }

    if (!data) {
      setIsJNumberExist(false);
    }

    if (!data && !isSubmitDisabled()) {
      const assets: Partial<PlayerTeamMap> = {
        created_by: memberProfile.created_by,
        created_datetime: memberProfile.created_datetime,
        end_date: endDate,
        is_active_YN: memberProfile.is_active_YN === 'null' ? 1 : memberProfile.is_active_YN,
        jersey_number: jerseyNumber,
        member_id: memberProfile.member_id,
        player_position: memberProfile.player_position,
        start_date: startDate,
        team_id: activeTeam!,
      };

      const responseHandler = (success: boolean) => {
        if (success) {
          setSuccess(true);
        }
      };

      dispatchPostPlayerTeam(assets, '', responseHandler);

      isSubmitJNumber = true;
      setJerseyNumber('');
      onClose();
    }
  };

  const onChangeJerseyNumber = (event: any) => {
    setJerseyNumber(event?.target.value);
  };

  const handleYesClick = () => {
    setSuccess(false);
    navigate(routes.teamDetail({ teamId: activeTeam ?? '' }));
    setActiveDivision(null);
    setActiveTeam(null);
  };

  const handleOnThanks = () => {
    setSuccess(false);
    // navigate(routes.events());
    setActiveDivision(null);
    setActiveTeam(null);
  };

  return (
    <>
      <Dialog className={classes.add_to_team_modal} open={openModel} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" onKeyDown={OnkeyDown}>
        {member && member.member_type === 'Player' ? (
          <>
            <DialogTitle id="alert-dialog-title" style={{ textAlign: 'left' }}>
              {title}
            </DialogTitle>
            <DialogContent>
              <div style={{ justifyContent: 'center', alignItems: 'end' }} className={classes.addMeToThisTeamTitle}>
                <label htmlFor="">Please confirm your:</label>
              </div>
              <div style={{ justifyContent: 'center', alignItems: 'end' }} className={`${classes.eventpopupFormAddPlayer} ${classes.addMeToThisTeam}`}>
                <div>
                  <label htmlFor="">Division *</label>
                  <FormControl className={classes.formFilter} variant="outlined">
                    <Select
                      value={activeDivision}
                      onChange={(event: any) => {
                        setActiveDivision(event.target.value);
                      }}
                      className={`${classes.select} ${classes.MuiSelectOutlined}`}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                    >
                      {divisions &&
                        divisions.length > 0 &&
                        divisions.map((item: any) => {
                          return (
                            <MenuItem key={item.division_id} value={item.division_id}>
                              {item.division_name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className={classes.addMeToThisTeamSelect}>
                  <label htmlFor="">Team *</label>
                  <FormControl className={classes.formFilter} variant="outlined">
                    <Select
                      value={activeTeam}
                      onChange={(event: any) => {
                        setActiveTeam(event.target.value);
                      }}
                      className={`${classes.select} ${classes.MuiSelectOutlined}`}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                    >
                      {teamName &&
                        teamName.length > 0 &&
                        orderBy(teamName, 'team_name', 'asc').map((item: any) => {
                          return (
                            <MenuItem key={item.team_id} value={item.team_id}>
                              {item.team_name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div style={{ justifyContent: 'center', alignItems: 'end' }} className={`${classes.eventpopupFormAddPlayer} ${classes.addMeToThisTeam}`}>
                <div>
                  <label htmlFor="">Jersey Number *</label>
                  <TextField className={classes.addJerseyNumber} placeholder="Enter Jersey Number" type="number" onChange={onChangeJerseyNumber} value={jerseyNumber} error={isJNumberExist && submit} helperText={isJNumberExist && submit ? `There is already a number ${jerseyNumber} on this team. Please confirm that you are that number.` : ''} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className={classes.addMeToThisTeamSelect}>
                  <label htmlFor="">Position *</label>
                  <FormControl className={classes.formFilter} variant="outlined">
                    <Select
                      value={selectedPosition}
                      onChange={(event: any) => {
                        setSelectedPosition(event.target.value);
                      }}
                      className={`${classes.select} ${classes.MuiSelectOutlined}`}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                    >
                      {optionData.map((item: any) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {isJNumberExist === false && submit && isSubmitJNumber && <Typography>You are now on this team.</Typography>}
              <div className={`${classes.eventpopupFormAddPlayer} ${classes.addMeToThisTeam}`}>
                <div>
                  <label>Start Date *</label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker value={startDate} onChange={(date) => (date ? setStartDate(date) : setStartDate(''))} className={classes.scoreFiled} renderInput={(params) => <TextField {...params} />} inputFormat="MM/dd/yyyy" label="" disablePast={false} />
                  </LocalizationProvider>
                </div>
                <div>
                  <label>End Date</label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker value={endDate} onChange={(date) => (date ? setEndDate(date) : setEndDate(''))} className={classes.scoreFiled} renderInput={(params) => <TextField {...params} />} inputFormat="MM/dd/yyyy" label="" disablePast={false} />
                  </LocalizationProvider>
                </div>
              </div>
              <div>
                <label htmlFor="">Sometimes players switch teams, so the start date tells us when you joined.</label>
              </div>
              <div>
                <label style={{ fontWeight: 600 }} htmlFor="">
                  Leave the End Date empty if you currently play for this team.
                </label>
              </div>
              <div>
                <label style={{ fontWeight: 600 }} htmlFor="">
                  If you need to change this later, please go to "My Teams" under your profile.
                </label>
              </div>
            </DialogContent>
            <Box display="flex" justifyContent="center" mt={1} mb={3} gap={3}>
              <Button onClick={onClose} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button onClick={onSave} color="primary" variant="contained">
                Confirm
              </Button>
            </Box>
          </>
        ) : (
          <>
            <DialogTitle align="center">Hold on... Only players can add themselves to a team!</DialogTitle>
            <DialogTitle align="center">If you are indeed a player, make this change under the "Account/Subscription Details"</DialogTitle>
            <Box display="flex" justifyContent="center" mt={1} mb={3}>
              <Button onClick={onClose} color="primary" variant="contained">
                Close
              </Button>
            </Box>
          </>
        )}
      </Dialog>
      <Dialog open={warning} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ fontSize: '16px' }}>
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">All fields need are required to submit this request.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setWarning(false)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={success} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ fontSize: '16px' }}>
          Success
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">You are now added to your team. Would you like to claim some stats from the games at this event?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnThanks} color="primary" autoFocus variant="outlined">
            No Thanks
          </Button>
          <Button onClick={handleYesClick} color="primary" variant="contained">
            Yes!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapDispatchToProps = {
  dispatchPostPlayerTeam: (data: any, teamName: string, callback: any) => playerActions.postPlayerTeamMap(data, teamName, callback),
};

export default connect(null, mapDispatchToProps)(AddTeamPlayerEvent);
