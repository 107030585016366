const positionStates = [
  {
    label: 'Position',
    value: 'position',
  },
  {
    label: 'Attack',
    value: 'Attack',
  },
  {
    label: 'Goalie',
    value: 'Goalie',
  },
  {
    label: 'Middie',
    value: 'Middie',
  },
  {
    label: 'Defense',
    value: 'Defense',
  },
  {
    label: 'FoGo',
    value: 'FoGo',
  },
  {
    label: 'LSM',
    value: 'LSM',
  },
];

export default positionStates;
