import _fetchRequest from '../../apis/_fetchRequest';
import addCommitmentRequest, { AddCommitment } from '../../apis/addCommitment';
import fetchOrganizationInfo from '../../apis/fetchOrganizationInfo';
import { Universities } from '../../models/Universities';
import * as commonActions from './commonActions';

export const FETCH_ORGANIZATIONS_STARTED = 'FETCH_ORGANIZATIONS_STARTED';
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export const FETCH_COMMITMENT_STARTED = 'FETCH_COMMITMENT_STARTED';
export const FETCH_COMMITMENTDATA_SUCCESS = 'FETCH_COMMITMENTDATA_SUCCESS';
export const FETCH_ORGANIZATIONS_FAILED = 'FETCH_ORGANIZATIONS_FAILED';
export const FETCH_UNIVERSITIES_SUCCESS = 'FETCH_UNIVERSITIES_SUCCESS';
export const FETCH_HIGHSCHOOLS_SUCCESS = 'FETCH_HIGHSCHOOLS_SUCCESS';
export const ADDED_COMMITMENT_SUCCESS = 'ADDED_COMMITMENT_SUCCESS';

export const fetchOrgInfoStarted = () => ({
  type: FETCH_ORGANIZATIONS_STARTED,
});

export const fetchOrgInfoSuccess = (res: any) => ({
  type: FETCH_ORGANIZATIONS_SUCCESS,
  payload: res,
});

export const fetchCommitmentStarted = () => ({
  type: ADDED_COMMITMENT_SUCCESS,
});

export const fetchCommitmentDataSuccess = (res: any) => ({
  type: FETCH_COMMITMENTDATA_SUCCESS,
  payload: res,
});

export const fetchOrgInfoFailed = (error: any) => ({
  type: FETCH_ORGANIZATIONS_FAILED,
  payload: error,
});

export const fetchUniversitiesSuccess = (res: Universities[]) => ({
  type: FETCH_UNIVERSITIES_SUCCESS,
  payload: res,
});

export const fetchHighSchoolsSuccess = (res: HighSchool[]) => ({
  type: FETCH_HIGHSCHOOLS_SUCCESS,
  payload: res,
});

export const addedCommitmentSuccess = (value: boolean) => ({
  type: ADDED_COMMITMENT_SUCCESS,
  payload: value,
})

export function fetchOrganizations(org_id: string) {
  return (dispatch: any) => {
    dispatch(fetchOrgInfoStarted());
    fetchOrganizationInfo(org_id)
      .then((res: any) => {
        dispatch(fetchOrgInfoSuccess(res));
      })
      .catch((error: any) => {
        dispatch(fetchOrgInfoFailed(error));
      });
  };
}

export function fetchCommitmentData(org_id: string, gender_id: string) {
  return (dispatch: any) => {
    dispatch(fetchCommitmentStarted());
    _fetchRequest("GET", `commitments_data?org_id=${org_id}`).then((res: any) => {
      dispatch(fetchCommitmentDataSuccess(res));
    }).catch((error: any) => {
      dispatch(commonActions.handleError(error));
    })
  }
}

export function fetchUniversities (sport_id: number) {
  return (dispatch: any) => {
    _fetchRequest('GET', `universities?sport_id=${sport_id}`).then((res: any) => {
      dispatch(fetchUniversitiesSuccess(res));
    })
    .catch((error: any) => {
      dispatch(commonActions.handleError(error));
    });
  }
}

export function fetchHighSchools (sport_id: number) {
  return (dispatch: any) => {
    _fetchRequest('GET', `lax_high_schools?sport_id=${sport_id}`).then((res: any) => {
      dispatch(fetchHighSchoolsSuccess(res));
    })
    .catch((error: any) => {
      dispatch(commonActions.handleError(error));
    });
  }
}

export function addCommitment (data: AddCommitment) {
  return (dispatch: any) => {
    addCommitmentRequest(data).then((res: any) => {
      dispatch(addedCommitmentSuccess(true));
    })
    .catch((error: any) => {
      dispatch(commonActions.handleError(error));
    });
  }
}