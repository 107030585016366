import { TEST_UPDATE } from '../actions/testActions';

let initialState = {
  isUpdate: false,
};

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TEST_UPDATE:
      return { ...state, isUpdate: true };
    default:
      return state;
  }
};

export default authReducer;
