import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import _ from 'lodash';
import qs from 'qs';

export interface ProspectDays {
  city: string;
  comments: string;
  created_by: string;
  end_date: string;
  event_name: string;
  fee: number | null;
  invited_divisions: string;
  is_active_YN: number;
  prospect_day_id: number;
  registration_url: string;
  sport_id: number;
  start_date: string;
  state: string;
  university_id: string;
  updated_by: string | null;
  updated_datetime: string | null;
  event_lat: number | null;
  event_long: number | null;
}

export type FetchEventsOptions = {
  event_id?: string;
  sport_id?: number | 1;
  org_id?: string;
};

export async function fetchProspectDays(opts: FetchEventsOptions): Promise<ProspectDays[]> {
  try {
    const query = qs.stringify({
      event_id: opts.event_id,
      sport_id: opts.sport_id,
      org_id: opts.org_id,
    });
    const data = await fetchAuthorization(`${API_URL}prospect_days?${query}`);

    const events = await data.json();

    const sortedEvents = _.orderBy(events, [(event) => (event.event_desc ? event.event_desc.toLowerCase() : event.event_desc)]);

    return sortedEvents;
  } catch (e: any) {
    throw new Error(`Sorry, something went wrong. Error message: ${e.message}`);
  }
}

export default fetchProspectDays;
