import { API_URL } from '../../constants';
import fetchAuthorization from '../fetchAuthorization';
import { getErrorMessage } from '../../helpers';

export interface AddPlayerUniversity {
  app_id: number;
  sport_id: number;
  member_id: string;
  association_id: number;
  association_fk_id: string;
  is_active_YN: 1 | 0 | null;
}

export async function addClub(newClub: any): Promise<any> {
  try {
    const newData = {
      app_id: 1,
      sport_id: newClub.sport_id,
      member_id: newClub.member_id,
      association_id: 5,
      association_fk_id: newClub.org_id,
      is_active_YN: 1,
    };
    const response = await fetchAuthorization(`${API_URL}member_follows`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(newData),
    });

    const result = await response.json();

    if (response.status !== 200) {
      const message = getErrorMessage(result);
      throw new Error(message ? message : `Status Code: ${response.status}. Sorry, something went wrong. Please try again later.`);
    }

    return result;
  } catch (e: any) {
    throw new Error(`Unable to add university. ${e.message}`);
  }
}
