export * from './team';
export * from './team-stats';
export * from './team-games';
export * from './player';
export * from './player-game-stats';
export * from './member';
export * from './convert-to-csv';
export * from './clubs';
export * from './validate';
export * from './pools';
export * from './teams-from-games';
export * from './date';
export * from './time';

export const DefaultSelectValues = {
  ALL: 'All',
};

export function getErrorMessage(error: any) {
  console.log('error', error);
  if (typeof error === 'string') {
    return error;
  } else if (error && error.message && typeof error.message === 'string') {
    console.log('message str', typeof error.message);
    return error.message;
  } else if (error && error.message && typeof error.message === 'object' && error.message.sqlMessage !== undefined) {
    console.log('obj str', typeof error.message.sqlMessage);
    return error.message.sqlMessage;
  }
}

export function makeid(length: number) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
