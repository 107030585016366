import { FETCH_ALL_TSCO_EVENTS_SUCCESS, FETCH_TEAM_MAPPED_SUCCESS, FETCH_TEAM_UNMAPPED_SUCCESS } from '../actions/tscoAdminActions';

let initialState = {
  AllTscoEvents: [],
  mappedTeams: [],
  unMappedTeams: [],
};

const tscoAdminReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_ALL_TSCO_EVENTS_SUCCESS:
      return { ...state, AllTscoEvents: action.data };
    case FETCH_TEAM_MAPPED_SUCCESS:
      return { ...state, mappedTeams: action.data };
    case FETCH_TEAM_UNMAPPED_SUCCESS:
      return { ...state, unMappedTeams: action.data };
    default:
      return state;
  }
};

export default tscoAdminReducer;
