import { API_URL } from '../constants';
import { IPlayerProfile } from '../models/PlayerProfile';
import fetchAuthorization from './fetchAuthorization';

export async function fetchPlayerProfile(member_id: string): Promise<IPlayerProfile | null> {
  try {
    const response = await fetchAuthorization(`${API_URL}player_profile?member_id=${member_id}`);

    const playerProfile = await response.json();

    return playerProfile[0] || null;
  } catch (e: any) {
    throw new Error(`Something went wrong. Error message: ${e.message}`);
  }
}

export default fetchPlayerProfile;
