import React from 'react';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import GoogleSigninLightFocus from './btn_google_signin_light_focus_web@2x.png';
import GoogleSigninLightDisabled from './btn_google_signin_light_disabled_web@2x.png';
import GoogleSigninLightNormal from './btn_google_signin_light_normal_web@2x.png';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    backgroundImage: `url(${GoogleSigninLightNormal})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    border: 'none',
    color: 'transparent',
    height: 61,
    width: 244,
    '&:disabled': {
      backgroundImage: `url(${GoogleSigninLightDisabled})`,
    },
    '&:focus': {
      backgroundImage: `url(${GoogleSigninLightFocus})`,
      outline: 'none', // The image contains the focused effect
    },
  },
}));

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>;

const SignInWithGoogle: React.FC<Props> = (props = {}) => {
  const classes = useStyles();
  const { className, ...otherProps } = props;

  return (
    <button
      className={[classes.root, className].filter(Boolean).join(' ')}
      onClick={() =>
        Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Google,
        })
      }
      {...otherProps}
    >
      Sign in with Google
    </button>
  );
};

export default SignInWithGoogle;
