import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import { getErrorMessage } from '../helpers';

export interface AddCommitment {
  player_name: string;
  gender_id: string;
  class_id: string;
  position_name: string;
  school_name: string;
  division_id: string;
  high_school: string;
  hs_state: string;
  club_team: string;
  commitment_date: string;
  supportURL: string;
  is_active_YN: number;
  created_datetime: Date;
}

export async function addCommitmentRequest(commitment: AddCommitment): Promise<any> {
  try {
    const response = await fetchAuthorization(`${API_URL}added_commitments`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(commitment),
    });

    const result = await response.json();

    if (response.status !== 200) {
      const message = getErrorMessage(result);
      throw new Error(message ? message : `Status Code: ${response.status}. Something went wrong. Please try again later.`);
    }

    return result;
  } catch (e: any) {
    throw new Error(`We were unable to post the commitment to our datastore. ${e.message}`);
  }
}

export default addCommitmentRequest;
