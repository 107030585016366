import { TextField, InputAdornment, List, ListItem, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getIcon, Icons } from '../../../helpers/icons';
import { useStyles } from './styles';

interface Props {
  label?: string;
  searchKey: string;
  autoFocus?: boolean;
  error?: boolean;
  showCheck?: boolean;
  placeholder?: string;
  filteredData: any[];
  fieldToDisplay: string;
  customStyles?: any;
  onReset: () => void;
  onSelect: (data: any) => void;
  onChangeSearch: (e: any) => void;
  onCheckClick?: () => void;
}

const   InputSearch = ({ label, searchKey,autoFocus, placeholder, filteredData, fieldToDisplay, customStyles, showCheck, error, onReset, onSelect, onChangeSearch, onCheckClick }: Props) => {
  const classes = useStyles();
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState<boolean>(false);

  useEffect(() => {
    if (searchKey === '') {
      setIsSelect(false);
      setIsAutocompleteOpen(false);
    }
  }, [searchKey]);

  const onSelectItem = (data: any) => {
    setIsSelect(true);
    setIsAutocompleteOpen(false);
    onSelect(data);
  };

  const onResetItem = () => {    
    setIsSelect(false);
    setIsAutocompleteOpen(false);
    onReset()
  };

  const handleSearch = (e: any) => {
    setIsAutocompleteOpen(true);
    onChangeSearch(e);
  };

  return (
    <div className={classes.searchSection}>
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="start" style={{color:"#373737"}}>
              {searchKey !== '' && (
                <>
                  {showCheck && isSelect && (
                    <span onClick={onCheckClick} className={classes.iconBtn} style={{ marginRight: -4, color: 'green' }}>
                      {getIcon(Icons.CHECK_CIRCLE)}
                    </span>
                  )}
                  <span onClick={onResetItem} className={classes.iconBtn}>
                    {getIcon(Icons.CLEAR)}
                  </span>
                </>
              )}
              {searchKey === '' && getIcon(Icons.SEARCH)}
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        label={label || ''}
        placeholder={placeholder}
        onChange={handleSearch}
        value={searchKey}
        autoFocus={autoFocus}
        classes={{
          root: customStyles,
        }}
        className={classes.root}
        autoComplete="off"
        error={error}
        variant="standard"
      />
      {!isSelect && isAutocompleteOpen && searchKey !== '' && (
        <div className={classes.searchResultSection}>
          <div className={classes.searchResult}>
            <List classes={{ root: classes.listRoot }}>
              {filteredData.length > 0 &&
                filteredData.map((data: any, indx: number) => {
                  return (
                    <ListItem key={indx} button onClick={() => onSelectItem(data)}>
                      <Typography variant="h5" className={classes.searchTerm}>
                        {data[fieldToDisplay]}
                      </Typography>
                    </ListItem>
                  );
                })}
              {filteredData.length === 0 && (
                <Typography variant="h5" gutterBottom classes={{ root: classes.dataNotFound }}>
                  No results found.
                </Typography>
              )}
            </List>
          </div>
        </div>
      )}
    </div>
  );
};

export default InputSearch;
