import _fetchRequest from '../../apis/_fetchRequest';
import { GamePublic } from '../../components/SourceData/interfaces';
import getVarcharEight from '../../helpers/get-random-id';

export const postPublicGame = (game: GamePublic) => async () => {
  _fetchRequest('POST', 'games_public', undefined, {
    game_id_pub: getVarcharEight(),
    ...game,
  }).then(() => {
    console.log({ game_id_pub: getVarcharEight(), ...game });
  });
};
