import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
  },
  dialogContainer: {
    alignItems: 'flex-start !important',
    justifyContent: 'space-around !important',
    width: 530,
    margin: 0,
    borderRadius: 0,
    position: 'absolute',
    top: 60,
    right: 'calc((100% - 1280px) / 2 + 236px)',
    boxShadow: 'none',
    color: 'white',
    zIndex: 1,
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: 60,
    overflow: 'hidden',
    background: '#e9effb',
  },
  closeIcon: {
    color: theme.palette.primary.main,
    marginRight: 15,
    cursor: 'pointer',
  },
  searchIcon: {
    color: theme.palette.primary.main,
    marginLeft: 15,
  },
  iconRoot: {
    color: theme.palette.primary.main,
    fontSize: 18,
  },
  inputUnderline: {
    '&:hover': {
      '&:before': {
        borderBottomColor: '#e9effb !important',
      },
    },
    '&.Mui-focused&:after': {
      borderBottomColor: '#e9effb !important',
    },
    '&:before': {
      borderBottomColor: '#e9effb',
    },
  },
  dialogActionsRoot: {
    backgroundColor: '#e9effb',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 0,
    margin: 2,
    borderRadius: 6,
    background: '#e9effb',
  },
  searchTerm: {
    fontWeight: '600 !important',
    marginRight: '10px !important',
  },
  listRoot: {
    width: '100%',
  },
  searchTermLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    display: 'flex',
    width: '100%',
  },
  searchResult: {
    boxShadow: '0 0 18px rgba(0, 0, 0, 0.68)',
    maxHeight: 200,
    overflowY: 'auto',
  },
  dataNotFound: {
    color: 'black',
    paddingLeft: 15,
  },
  loader: {
    margin: '5px auto',
    display: 'block',
  },
  inputRoot: {
    outline: 'none',
    border: 0,
    // padding: '5px 0',
    paddingLeft: 25,
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    color: '#1c315f',
    fontSize: 16,
    background: '#ffffff',
    borderRadius: '3px',
    width: '100%',
  },
}));
