import { orderBy } from 'lodash';
import moment from 'moment';
import NormalizeGame from '../models/NormalizeGame';
import Team from '../models/Team';

const RankingFactorValues = {
  WIN_PERCENTAGE: 1,
  HEAD_TO_HEAD: 2,
  GOAL_DIFFERENCE: 3,
  GOAL_SCORED: 4,
  GOAL_ALLOWED: 5,
};

export async function processTeamRankings(data: any) {
  if (data && data.length) {
    let teams = [];
    for await (const item of data) {
      try {
        let copyItem = Object.assign({}, item);

        let parsedArr: any = `[${copyItem.json_team_stats}]`;
        parsedArr = JSON.parse(parsedArr); // Parse stats from String to Array

        delete copyItem.json_team_stats;

        let finalTeamData = {
          ...copyItem,
          ...getStats(parsedArr),
        };

        finalTeamData.win_percent = getWinPercentage(finalTeamData);

        finalTeamData.mRPI = calculate_mRPI(finalTeamData);

        teams.push(finalTeamData);
      } catch (e: any) {
        console.log(e.message);
      }
    }

    return decideRankings(teams);
  } else {
    return [];
  }
}

function decideRankings(teams: Array<any>) {
  let sortedList = teams.slice().sort((a, b) => {
    return b.mRPI - a.mRPI;
  });
  return sortedList.slice().map((v) => {
    return {
      ...v,
      rank: sortedList.findIndex((item) => item.mRPI === v.mRPI) + 1,
    };
  });
}

function getWinPercentage(team: any) {
  return ((team.wins / (team.wins + team.losses)) * 100).toFixed();
}

function calculate_mRPI(team: any) {
  const win_25 = (team.wp * 25) / 100;
  const sos_50 = (team.sos * 50) / 100;
  const osos_25 = (team.opp_sos * 25) / 100;
  const agd = team.agd / 100;

  return (win_25 + sos_50 + osos_25 + agd).toFixed(3);
}

function getStateKeyValue(stat: any) {
  switch (stat.statistic_name) {
    case 'SOS':
      return { key: 'sos', value: stat.value };
    // return { 'sos': stat.value };
    case 'oSOS':
      return { key: 'opp_sos', value: stat.value };
    case 'Rank':
      return { key: 'rank', value: stat.value };
    case 'Goals For':
      return { key: 'goals_for', value: stat.value };
    case 'Goals Against':
      return { key: 'goals_against', value: stat.value };
    case 'Wins':
      return { key: 'wins', value: stat.value };
    case 'Losses':
      return { key: 'losses', value: stat.value };
    case 'WP':
      return { key: 'wp', value: stat.value };
    case 'AVG Pts/Game':
      return { key: 'avg_points', value: stat.value };
    case 'AVG Goal Diff':
      return { key: 'agd', value: stat.value };
    case 'FO %':
      return { key: 'fo_percent', value: stat.value };
    case 'Save %':
      return { key: 'save_percent', value: stat.value };
  }
}

function getStats(stats: Array<any>) {
  let statsObj: any = {};
  for (let i = stats.length - 1; i >= 0; i--) {
    const stat: any = getStateKeyValue(stats[i]);
    statsObj[stat.key] = stat.value;
  }
  return statsObj;
}

const dates = {
  today: {
    start: () => moment().format('YYYY-MM-DD 00:00:01'),
    end: () => moment().format('YYYY-MM-DD 23:59:59'),
  },
  yesterday: {
    start: () => moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:01'),
    end: () => moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59'),
  },
  currentweek: {
    start: () => moment().startOf('isoWeek').format('YYYY-MM-DD 00:00:01'),
    end: () => moment().endOf('isoWeek').format('YYYY-MM-DD 23:59:59'),
  },
  lastweek: {
    start: () => moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD 00:00:01'),
    end: () => moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD 23:59:59'),
  },
  lastyear: {
    value: () => moment().subtract(1, 'year').year(),
  },
};

export function filterByTime(time: string, teams: Array<any>) {
  switch (time) {
    case 'today':
      return teams.filter((team) => {
        return moment(team.measure_date).isBetween(dates.today.start(), dates.today.end());
      });
    case 'yesterday':
      return teams.filter((team) => {
        return moment(team.measure_date).isBetween(dates.yesterday.start(), dates.yesterday.end());
      });
    case 'thisweek':
      return teams.filter((team) => {
        return moment(team.measure_date).isBetween(dates.currentweek.start(), dates.currentweek.end());
      });
    case 'lastweek':
      return teams.filter((team) => {
        return moment(team.measure_date).isBetween(dates.lastweek.start(), dates.lastweek.end());
      });
    case 'lastyear':
      return teams.filter((team) => {
        const lastyear = dates.lastyear.value();
        const measureDateYear = moment(team.measure_date).year();
        return lastyear === measureDateYear;
      });
    default:
      return teams;
  }
}

const sortTeamsByBestRecord = (a: Team, b: Team, _: any) => (b.wins + 0.5 * b.ties) / (b.wins + b.ties + b.losses) - (a.wins + 0.5 * a.ties) / (a.wins + a.ties + a.losses);

const sortTeamsByHeadToHead = (a: Team, b: Team, games: NormalizeGame[]) => {
  const teamScores = games.reduce(
    (acc: any, game: NormalizeGame) => {
      if ((game.team_id === a.team_id && game.opponent_team_id === b.team_id) || (game.team_id === b.team_id && game.opponent_team_id === a.team_id)) {
        const gameTeamAScore = a.team_id === game.team_id ? game.team_score : game.opponent_team_score;

        const gameTeamBScore = b.team_id === game.team_id ? game.team_score : game.opponent_team_score;

        return {
          teamAScore: (acc.teamAScore = acc.teamAScore + Number(gameTeamAScore)),
          teamBScore: (acc.teamBScore = acc.teamBScore + Number(gameTeamBScore)),
        };
      }

      return acc;
    },
    { teamAScore: 0, teamBScore: 0 },
  );

  return teamScores.teamBScore - teamScores.teamAScore;
};

const sortTeamsByGoalAllowed = (a: Team, b: Team, _: any) => a.goalsAllowed - b.goalsAllowed;

const sortTeamsByDifference = (a: Team, b: Team, _: any) => b.goalsScored - b.goalsAllowed - (a.goalsScored - a.goalsAllowed);

const sortTeamsByGoalScored = (a: Team, b: Team, _: any) => b.goalsScored - a.goalsScored;

const getFiltredTeamByResults = (teams: Team[]) => {
  return teams.reduce((acc: any, team: Team) => (team.wins > 0 || team.losses > 0 || team.ties > 0 ? { ...acc, teamWithResults: [...acc.teamWithResults, team] } : { ...acc, teamWithoutResults: [...acc.teamWithoutResults, team] }), {
    teamWithResults: [],
    teamWithoutResults: [],
  });
};

const SortTeamsBy = {
  [RankingFactorValues.WIN_PERCENTAGE]: sortTeamsByBestRecord,
  [RankingFactorValues.HEAD_TO_HEAD]: sortTeamsByHeadToHead,
  [RankingFactorValues.GOAL_ALLOWED]: sortTeamsByGoalAllowed,
  [RankingFactorValues.GOAL_DIFFERENCE]: sortTeamsByDifference,
  [RankingFactorValues.GOAL_SCORED]: sortTeamsByGoalScored,
};

export const sortTeamByScored = (teams: Team[], games: NormalizeGame[], rankings: any) => {
  const parsedRankings = JSON.parse(rankings);

  if (parsedRankings.length !== Object.keys(RankingFactorValues).length) {
    return teams;
  }

  const filtredTeamsByResults = getFiltredTeamByResults(teams);

  const sortedTeams = filtredTeamsByResults.teamWithResults.sort((a: Team, b: Team) => SortTeamsBy[parsedRankings[0]](a, b, games) || SortTeamsBy[parsedRankings[1]](a, b, games) || SortTeamsBy[parsedRankings[2]](a, b, games) || SortTeamsBy[parsedRankings[3]](a, b, games) || SortTeamsBy[parsedRankings[4]](a, b, games));

  return [...sortedTeams, ...filtredTeamsByResults.teamWithoutResults];
};

export const getOptionsSelectTeams = (teams: Team[]) => {
  const optionsSelectTeams = teams.map((team: Team) => ({
    value: team.team_id,
    label: team.team_name,
  }));

  const sortedOptionsSelectTeams = orderBy(optionsSelectTeams, ({ label }) => [label]);

  return [
    {
      value: 'All',
      label: 'All',
    },
    ...sortedOptionsSelectTeams,
  ];
};
