import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Dialog, CircularProgress, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import Alert from '@mui/lab/Alert';
import _ from 'lodash';

import { useStyles } from './styles';
import ModalTitle from './ModalTitle';
import SearchInput from './SearchInput';
import { fetchTeamsv2 } from '../../apis';
import { yearRange, geographies } from '../../constants';
import { MemberContext } from '../CognitoAuth';
import MaterialuiSelect from '../Select/MaterialuiSelect';

import * as memberOnboardingActions from '../../redux/actions/memberOnboardingActions';
import { Organization } from '../../models/Organization';
import getVarcharEight from '../../helpers/get-random-id';
import InputSearch from '../Common/InputSearch/InputSearch';
import { Option } from '../../models/lib';

const teamDivisions = yearRange.map((x) => {
  return { label: x, value: x };
});

type Props = {
  team: any;
  showAddTeam: boolean;
  selectedClub: Organization;
  isLoadingAddTeam: boolean;
  addingClubError: any;
  onClose: () => void;
  dispatchAddTeam?: any;
  dispatchTeamSelection?: any;
  dispatchClubSelection?: any;
  dispatchGetClub: (clubId: string) => void;
};

const AddTeamModal: React.FC<Props> = (props) => {
  const { showAddTeam, selectedClub, onClose, isLoadingAddTeam, addingClubError, dispatchAddTeam, dispatchGetClub } = props;

  const classes = useStyles();

  const { member } = useContext<any>(MemberContext);
  const sportId = member ? member.sport_id_default : 1;

  const [clubId, setClubId] = useState('');
  const [clubName, setClubName] = useState('');
  const [teamDivision, setTeamDivision] = useState<string>('');
  const [uniqueName, setUniqueName] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [warning, setWarning] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const [filteredData, setFilteredData] = useState<Option[]>([]);
  const Reg = new RegExp(searchKey, 'i');

  const statesFilter: Option[] = geographies.map((data: any) => ({
    label: data.state_name,
    value: data.state_abbr,
  }));

  useEffect(() => {
    setCity(selectedClub?.org_city || '');
    setSearchKey(selectedClub?.org_state || '');
  }, [selectedClub]);

  useEffect(() => {
    const data = statesFilter.filter((data: any) => Reg.test(data.label || ''));
    setFilteredData(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  const onSubmit = () => {
    const publicRequest: any = {
      request_id: getVarcharEight(),
      request_type: 'team',
      item_name: uniqueName,
      item_city: city,
      item_state: searchKey,
      item_json: JSON.stringify({ parent_id: clubId, division: teamDivision }),
    };

    const responseHandler = (success: boolean) => {
      if (success) {
        setSuccess(true);
      }
    };

    dispatchAddTeam(publicRequest, responseHandler);
  };

  const isSubmitDisabled = () => clubId === '' || clubName === '' || uniqueName === '' || teamDivision === ''  || searchKey === '' || isLoadingAddTeam;

  const existingTeams = async (teams: any, division: number) => {
    let teamsDivision: any = _.filter(teams, (t) => t.team_year === division);
    const teamsList: any[] = [];

    if (teamsDivision !== undefined && teamsDivision !== null && teamsDivision.length > 0) {
      _.each(teamsDivision, (t: any) => {
        let tName = t.team_name;
        if (t.team_division) tName += '/' + t.team_division;
        if (t.team_level) tName += '/' + t.team_level;
        teamsList.push(tName);
      });
    }
    return teamsList;
  };

  const getTeams = (orgId: string, division: number) => {
    const body: any = {
      sport_id: sportId,
      parent_team_id: orgId,
    };
    fetchTeamsv2(body)
      .then(async (teams: any) => {
        if (teams) {
          let teamList: any[] = await existingTeams(teams, division);

          let warning = '';
          if (teamList.length > 0) {
            warning = (teamList.length > 1 ? 'These ' : 'This ') + division + ' team' + (teamList.length > 1 ? 's' : '') + ' already exist' + (teamList.length > 1 ? '' : 's') + ': ' + teamList.join(', ') + '.';
          }
          setWarning(warning);
        }
      })
      .catch((e: any) => {
        // console.log('No existing teams match that name and division.');
      });
  };

  const onSelectClub = (data: any) => {
    setClubId(data.fk_id);
    dispatchGetClub(data.fk_id);
    setClubName(data.search_term);
  };

  const onSelectDivision = (division: any) => {
    setTeamDivision(division);
    getTeams(clubId, division);
  };

  const resetForm = () => {
    setClubName('');
    setClubId('');
    setTeamDivision('');
    setUniqueName('');
    setCity('');
    setSearchKey('');
    setSuccess(false);
    onClose();
  };
  useEffect(() => {
    setSearchKey('');
  },[onClose])

  const getFullName = () => {
    return `${member ? member.first_name : ''} ${member ? member.last_name : ''}`;
  };

  const onChangeSearchValue = (e: any) => {
    setSearchKey(e.target.value);
  };

  const onSelectState = (data: Option) => {
    setSearchKey(data.label!);
  };

  const onReset = () => {
    setSearchKey('');
  };

  return (
    <Dialog open={showAddTeam}>
      <ModalTitle title={'Request to Add a Team'} memberName={getFullName()} onClose={onClose} />
      <DialogContent className={classes.dialogContent}>
        <div className={classes.popupForm}>
          <SearchInput keyType="org_id" placeholder="Search for a Club" label="Parent Club" styles={{ width: '100%' }} onSelect={(data: any) => onSelectClub(data)} />
          <div className={classes.twoFormInputs}>
            <div>
              <TextField
                label="City"
                style={{ margin: 0 }}
                placeholder="Enter City Name"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                autoComplete="false"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className={classes.popupFormInput}
                variant='standard'
              />
            </div>
            <div className={`${classes.popupFormSelect} ${classes.popupFormSelectLast}`}>
              <label>State*</label>
              <InputSearch searchKey={searchKey} placeholder="Select State/Province" filteredData={filteredData} fieldToDisplay="label" onReset={onReset} onChangeSearch={onChangeSearchValue} onSelect={onSelectState} />
            </div>
          </div>
          <div className={classes.popupFormSelect}>
            <label>Division</label>
            <MaterialuiSelect id="team-division" value={teamDivision} onValueChange={(e: any) => onSelectDivision(e.target.value)} data={teamDivisions} />
          </div>
          {(warning !== '' || addingClubError) && (
            <Alert severity="warning" className={classes.alertsSmall}>
              {warning} {addingClubError}
            </Alert>
          )}
          <div>
            <TextField
              label="Unique Name"
              placeholder="Formet = Club Name + YY (division year) + Unique Name (optional)"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={uniqueName}
              onChange={(e) => setUniqueName(e.target.value)}
              className={classes.popupFormInput}
              variant='standard'
            />
          </div>
          <DialogActions className={classes.playerDialogActions}>
            <Button variant="contained" color="primary" onClick={onSubmit} className={classes.playerCSVSubmit} disabled={isSubmitDisabled()}>
              {!isLoadingAddTeam ? 'Submit' : <CircularProgress size={22} className={classes.buttonProgress} />}
            </Button>
          </DialogActions>
        </div>
        <Dialog open={success} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">We have received your request!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">We will review this request and add it to our database very shortly. Thanks!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  team: state.teamReducer,
  selectedClub: state.memberOnboardingReducer.selectedClub,
  isLoadingAddTeam: state.memberOnboardingReducer.isLoadingAddingTeam,
  addingClubError: state.memberOnboardingReducer.addingClubError,
});

const mapDispatchToProps = {
  dispatchAddTeam: (data: any, callback: any) => memberOnboardingActions.addTeam(data, callback),
  dispatchTeamSelection: (data: any) => memberOnboardingActions.updateTeam(data),
  dispatchClubSelection: (data: any) => memberOnboardingActions.updateClub(data),
  dispatchGetClub: (clubId: string) => memberOnboardingActions.getClub(clubId),
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTeamModal);
