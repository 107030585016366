import _ from 'lodash';

const geographies = [
  {
    row_num: 1,
    mapping_id: 0,
    state_name: 'Alaska',
    state_abbr: 'AK',
    region: 'West',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 52,
    mapping_id: 0,
    state_name: 'Alberta',
    state_abbr: 'ALB',
    region: 'North',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 2,
    mapping_id: 0,
    state_name: 'Arizona',
    state_abbr: 'AZ',
    region: 'West',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 3,
    mapping_id: 0,
    state_name: 'Arkansas',
    state_abbr: 'AR',
    region: 'South',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 53,
    mapping_id: 0,
    state_name: 'British Columbia',
    state_abbr: 'BRC',
    region: 'North',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 4,
    mapping_id: 0,
    state_name: 'California',
    state_abbr: 'CA',
    region: 'West',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 5,
    mapping_id: 0,
    state_name: 'Colorado',
    state_abbr: 'CO',
    region: 'West',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 6,
    mapping_id: 0,
    state_name: 'Connecticut',
    state_abbr: 'CT',
    region: 'Northeast',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 7,
    mapping_id: 0,
    state_name: 'Delaware',
    state_abbr: 'DE',
    region: 'Mid Atlantic',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 8,
    mapping_id: 0,
    state_name: 'District of Columbia',
    state_abbr: 'DC',
    region: 'Mid Atlantic',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 9,
    mapping_id: 0,
    state_name: 'Florida',
    state_abbr: 'FL',
    region: 'South',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 10,
    mapping_id: 0,
    state_name: 'Georgia',
    state_abbr: 'GA',
    region: 'South',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 11,
    mapping_id: 0,
    state_name: 'Hawaii',
    state_abbr: 'HI',
    region: 'West',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 12,
    mapping_id: 0,
    state_name: 'Idaho',
    state_abbr: 'ID',
    region: 'West',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 13,
    mapping_id: 0,
    state_name: 'Illinois',
    state_abbr: 'IL',
    region: 'Midwest',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 14,
    mapping_id: 0,
    state_name: 'Indiana',
    state_abbr: 'IN',
    region: 'Midwest',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 15,
    mapping_id: 0,
    state_name: 'Iowa',
    state_abbr: 'IA',
    region: 'Midwest',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 16,
    mapping_id: 0,
    state_name: 'Kansas',
    state_abbr: 'KS',
    region: 'Midwest',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 17,
    mapping_id: 0,
    state_name: 'Kentucky',
    state_abbr: 'KY',
    region: 'Midwest',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 18,
    mapping_id: 0,
    state_name: 'Louisiana',
    state_abbr: 'LA',
    region: 'South',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 19,
    mapping_id: 0,
    state_name: 'Maine',
    state_abbr: 'ME',
    region: 'Northeast',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 32,
    mapping_id: 0,
    state_name: 'Maryland',
    state_abbr: 'MD',
    region: 'Mid Atlantic',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 33,
    mapping_id: 0,
    state_name: 'Massachusetts',
    state_abbr: 'MA',
    region: 'Northeast',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 34,
    mapping_id: 0,
    state_name: 'Michigan',
    state_abbr: 'MI',
    region: 'Midwest',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 35,
    mapping_id: 0,
    state_name: 'Minnesota',
    state_abbr: 'MN',
    region: 'Midwest',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 36,
    mapping_id: 0,
    state_name: 'Mississippi',
    state_abbr: 'MS',
    region: 'South',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 37,
    mapping_id: 0,
    state_name: 'Missouri',
    state_abbr: 'MO',
    region: 'Midwest',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 20,
    mapping_id: 0,
    state_name: 'Montana',
    state_abbr: 'MT',
    region: 'West',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 21,
    mapping_id: 0,
    state_name: 'Nebraska',
    state_abbr: 'NE',
    region: 'Midwest',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 22,
    mapping_id: 0,
    state_name: 'Nevada',
    state_abbr: 'NV',
    region: 'West',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 23,
    mapping_id: 0,
    state_name: 'New Hampshire',
    state_abbr: 'NH',
    region: 'Northeast',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 24,
    mapping_id: 0,
    state_name: 'New Jersey',
    state_abbr: 'NJ',
    region: 'Mid Atlantic',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 25,
    mapping_id: 0,
    state_name: 'New Mexico',
    state_abbr: 'NM',
    region: 'West',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 26,
    mapping_id: 0,
    state_name: 'New York',
    state_abbr: 'NY',
    region: 'Northeast',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 27,
    mapping_id: 0,
    state_name: 'North Carolina',
    state_abbr: 'NC',
    region: 'South',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 28,
    mapping_id: 0,
    state_name: 'North Dakota',
    state_abbr: 'ND',
    region: 'Midwest',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 29,
    mapping_id: 0,
    state_name: 'Ohio',
    state_abbr: 'OH',
    region: 'Midwest',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 30,
    mapping_id: 0,
    state_name: 'Oklahoma',
    state_abbr: 'OK',
    region: 'Midwest',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 54,
    mapping_id: 0,
    state_name: 'Ontario',
    state_abbr: 'ONT',
    region: 'North',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 31,
    mapping_id: 0,
    state_name: 'Oregon',
    state_abbr: 'OR',
    region: 'West',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 38,
    mapping_id: 0,
    state_name: 'Pennsylvania',
    state_abbr: 'PA',
    region: 'Mid Atlantic',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 39,
    mapping_id: 0,
    state_name: 'Rhode Island',
    state_abbr: 'RI',
    region: 'Northeast',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 55,
    mapping_id: 0,
    state_name: 'Quebec',
    state_abbr: 'QUE',
    region: 'North',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 40,
    mapping_id: 0,
    state_name: 'South Carolina',
    state_abbr: 'SC',
    region: 'South',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 41,
    mapping_id: 0,
    state_name: 'South Dakota',
    state_abbr: 'SD',
    region: 'Midwest',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 42,
    mapping_id: 0,
    state_name: 'Tennessee',
    state_abbr: 'TN',
    region: 'Midwest',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 43,
    mapping_id: 0,
    state_name: 'Texas',
    state_abbr: 'TX',
    region: 'South',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 44,
    mapping_id: 0,
    state_name: 'Utah',
    state_abbr: 'UT',
    region: 'West',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 45,
    mapping_id: 0,
    state_name: 'Vermont',
    state_abbr: 'VT',
    region: 'Northeast',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 46,
    mapping_id: 0,
    state_name: 'Virginia',
    state_abbr: 'VA',
    region: 'Mid Atlantic',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 47,
    mapping_id: 0,
    state_name: 'Washington',
    state_abbr: 'WA',
    region: 'West',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 48,
    mapping_id: 0,
    state_name: 'West Virginia',
    state_abbr: 'WV',
    region: 'Mid Atlantic',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 49,
    mapping_id: 0,
    state_name: 'Wisconsin',
    state_abbr: 'WI',
    region: 'Midwest',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
  {
    row_num: 50,
    mapping_id: 0,
    state_name: 'Wyoming',
    state_abbr: 'WY',
    region: 'West',
    is_active_YN: 1,
    created_by: '',
    created_datetime: '2019-12-20 17:00:39',
    updated_by: null,
    updated_datetime: null,
  },
];

const tempRegions = _.uniqBy(geographies, (e: any) => {
  return e.region;
});

const tempState_abbr = _.uniqBy(geographies, (e: any) => {
  return e.state_abbr;
});

export const regions = tempRegions.map((x: any) => {
  return {
    label: x.region,
    value: x.region,
  };
});

export const regionsState = tempState_abbr.map((x: any) => {
  return {
    label: x.state_abbr,
    value: x.state_abbr,
  };
});

export default geographies;
