import { GET_CLUBS_REQ_START, GET_CLUBS_REQ_SUCCESS, GET_CLUBS_REQ_FAILED, GET_CLUB_DETAIL_REQ_START, GET_CLUB_DETAIL_REQ_SUCCESS, GET_CLUB_DETAIL_REQ_FAILED, GET_CLUB_TEAM_SUMMARY_REQ_START, GET_CLUB_TEAM_SUMMARY_REQ_SUCCESS, GET_CLUB_TEAM_SUMMARY_REQ_FAILED, GET_CLUB_LOGO_REQ_START, GET_CLUB_LOGO_REQ_SUCCESS, GET_CLUB_LOGO_REQ_FAILED, ADD_CLUB_REQ_START, ADD_CLUB_REQ_SUCCESS, ADD_CLUB_REQ_FAILED, GET_CLUBS_STATISTICS_REQ_SUCCESS, UPDATE_CLUB_FOLLEWED } from '../actions/clubActions';

let initialState = {
  clubs: [],
  clubDetail: [],
  clubTeamSummary: [],
  clubLogoUrls: [],
  isLoadingClubs: false,
  isLoadingClubDetail: false,
  isLoadingClubTeamSummary: false,
  isLoadingAddClub: false,
  error: null,
  clubDetailError: null,
  clubTeamSummaryError: null,
  addError: null,
  clubsStatistics: [],
};

const clubReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CLUBS_REQ_START:
      return { ...state, isLoadingClubs: true, error: null, clubs: [] };
    case GET_CLUBS_REQ_SUCCESS:
      return { ...state, isLoadingClubs: false, clubs: action.data };
    case GET_CLUBS_REQ_FAILED:
      return {
        ...state,
        isLoadingClubs: false,
        error: action.error,
      };
    case GET_CLUB_DETAIL_REQ_START:
      return {
        ...state,
        isLoadingClubDetail: true,
        clubDetailError: null,
        clubDetail: [],
      };
    case GET_CLUB_DETAIL_REQ_SUCCESS:
      return { ...state, isLoadingClubDetail: false, clubDetail: action.data };
    case GET_CLUB_DETAIL_REQ_FAILED:
      return {
        ...state,
        isLoadingClubDetail: false,
        clubDetailError: action.error,
      };
    case GET_CLUB_TEAM_SUMMARY_REQ_START:
      return {
        ...state,
        isLoadingClubTeamSummary: true,
        clubTeamSummaryError: null,
        clubTeamSummary: [],
      };
    case GET_CLUB_TEAM_SUMMARY_REQ_SUCCESS:
      return {
        ...state,
        isLoadingClubTeamSummary: false,
        clubTeamSummary: action.data,
      };
    case GET_CLUB_TEAM_SUMMARY_REQ_FAILED:
      return {
        ...state,
        isLoadingClubTeamSummary: false,
        clubTeamSummaryError: action.error,
      };
    case GET_CLUB_LOGO_REQ_START:
      return { ...state, isLoadingClubs: true, error: null, clubs: [] };
    case GET_CLUB_LOGO_REQ_SUCCESS:
      let clubsArr = action.data;
      let logoUrl = null;
      let arr: any = state.clubLogoUrls;
      if (clubsArr.length > 0) {
        logoUrl = clubsArr[0].org_logo_url;
        // console.log('CLUB logoUrl: ', logoUrl);
        arr.push(logoUrl);
        // console.log('CLUB logoUrl arr: ', arr);
      }
      return {
        ...state,
        isLoadingClubs: false,
        clubLogoUrls: arr,
      };
    case GET_CLUB_LOGO_REQ_FAILED:
      return {
        ...state,
        isLoadingClubs: false,
        error: action.error,
      };
    case ADD_CLUB_REQ_START:
      return { ...state, isLoadingAddClub: true, addError: null };
    case ADD_CLUB_REQ_SUCCESS:
      return { ...state, isLoadingAddClub: false, addError: null };
    case ADD_CLUB_REQ_FAILED:
      return { ...state, isLoadingAddClub: false, addError: action.error };
    case GET_CLUBS_STATISTICS_REQ_SUCCESS:
      return { ...state, clubsStatistics: action.data };
      case UPDATE_CLUB_FOLLEWED:
        let newClubs: any = [...state.clubs];
        newClubs = newClubs.map((club: any) => {
          if (club.org_id === action.data.org_id) {
            return { ...club, num_followers: action.data.num_followers };
            // if(action.data.is_following === 1){
            // return { ...club, is_following: club.is_following + 1 };
            // }
            // if(action.data.is_following === -1){
            //   return { ...club, is_following: club.is_following - 1 };
            // }
          }
          return club
        });
        return { ...state,  clubs: newClubs };
    default:
      return state;
  }
};

export default clubReducer;
