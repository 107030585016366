import { API_URL } from '../constants';
import {Highlight} from '../models/Highlight';
import fetchAuthorization from './fetchAuthorization';

export async function fetchEventHighlights(
    eventId: string,
): Promise<Highlight[]> {
  
  try {
    const data = await fetchAuthorization(
      `${API_URL}member_media?event_id=${eventId}`,
    );

    return await data.json();
  } catch (e: any) {
    throw new Error(`Something went wrong. Error message: ${e.message}`);
  }
}

export default fetchEventHighlights;
