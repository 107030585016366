const routes = {
  home: () => '/',
  clubs: () => '/clubs',
  search: () => '/search',
  events: () => '/events',
  eventDetail: ({ eventId }: { eventId: string }) => `/event/${eventId}`,
  gameStats: ({ gameId }: { gameId: string }) => `/game/${gameId}`,
  pastEvents: () => `${routes.events()}/past-events`,
  requestEvents: () => `${routes.events()}/request-events`,
  prospectDays: () => `${routes.events()}/prospect-days`,
  clubTryouts: () => `${routes.events()}/club-tryouts`,
  alerts: () => '/alerts',
  dataLab: () => '/data-lab',
  account: () => '/account',
  faceOff: () => `${routes.dataLab()}/face-off`,
  profile: () => '/my-profile',
  rankings: () => '/rankings',
  utilities: () => '/utilities',
  dashboard: () => '/dashboard',
  producers: () => '/producers',
  videos: () => '/videos',
  playerLab: () => `${routes.dataLab()}/player-lab`,
  sourceData: () => '/source-data',
  onBoarding: () => '/on-boarding',
  clubDetail: ({ clubId }: { clubId: string | null }) => `${routes.clubs()}/${clubId}`,
  playerDetail: ({ playerId }: { playerId: string }) => `/player/${playerId}`,
  subscription: () => `${routes.account()}/subscription`,
  playerMapping: () => `${routes.profile()}/player-mapping`,
  accountOptions: () => `${routes.profile()}/account-options`,
  playerRankings: () => `${routes.rankings()}/player-rankings`,
  collegeCoaches: () => '/college-coaches',
  teamDetail: ({ teamId }: { teamId: string }) =>`${routes.clubs()}/team/${teamId}`,
  teamGames: ({ teamId }: { teamId: string }) =>`${routes.clubs()}/team/${teamId}/team-games`,
  teamPlayers: ({ teamId }: { teamId: string }) =>`${routes.clubs()}/team/${teamId}/team-players`,
  tscoAdmin: () => `tscoadmin`,
  teams: () => '/teams',
  clubcommitments: ({org_id} : {org_id: string}) => `/club_commitments_dashboard/${org_id}`,
  notifications: () => '/notifications',
};

export default routes;
