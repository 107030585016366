import React, { ReactNode } from 'react';

import { Dialog, DialogContent, DialogTitle, Typography, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getIcon, Icons } from '../../../helpers/icons';

interface Props {
  label: string;
  isOpen: boolean;
  maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined;
  children: ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles({
  dialogContent: {
    paddingTop: 0,
    paddingBottom: '24px',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const DialogBox = ({ label, isOpen, maxWidth, children, onClose }: Props) => {
  const classes = useStyles();
  return (
    <Dialog open={isOpen} maxWidth={maxWidth} fullWidth>
      <DialogTitle className={`add-picture__header ${classes.dialogTitle}`}>
        <Typography variant="h2">{label}</Typography>
        <IconButton onClick={onClose}>{getIcon(Icons.CLEAR)}</IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>{children}</DialogContent>
    </Dialog>
  );
};

export default DialogBox;
