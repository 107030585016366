const initialState = {
  error: [],
};

const sourceDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default sourceDataReducer;
