import React, { useState, useEffect } from 'react';
import ActionCard from '../../ActionCard';
import '../styles.css';
import { MenuItem, Select, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { GamePublic } from '../interfaces';
import geographies from '../../../constants/geographies';
import { connect } from 'react-redux';
import * as eventsActions from '../../../redux/actions/eventsActions';
import InputSearch from '../../Common/InputSearch/InputSearch';
import { orderBy } from 'lodash';
import { PastEvent } from '../../../apis/fetchPastEvents';
import { timeZoneOptions } from '../consts';
import { Option } from '../../../models/lib';

export const useStyles = makeStyles(() =>
  createStyles({
    searchRoot: {
      width: '100%',
      '& > label': {
        fontSize: '20px',
        fontWeight: 'bold',
      },
      '& > div': {
        borderRadius: 0,
        boxShadow: 'none',
      },
      '& > div > input': {
        borderRadius: 0,
        padding: '6px 0 7px !important',
        fontSize: '14px',
        fontWeight: '500',
        height: '1.1876em',
      },
      '& > div:before': {
        display: 'inline',
      },
    },
  }),
);

interface EventComponents {
  isCancel: boolean;
  isChecking: boolean;
  pastEvents: any;
  onEventChange: (item: Partial<GamePublic>) => void;
  dispatchSearch: (data: any) => void;
}

const EventComponent = ({ isCancel, isChecking, pastEvents, onEventChange, dispatchSearch }: EventComponents) => {
  const classes = useStyles();

  const [name, setName] = useState<string>();
  const [eventProducer, setEventProducer] = useState<string>();
  const [city, setCity] = useState<string>();
  const [state, setState] = useState<string>('');
  const [tZone, setTZone] = useState<string>();
  const [eventId, setEventId] = useState<string>();
  const [filteredData, setFilteredData] = useState<Option[]>([]);
  const Reg = new RegExp(state, 'i');


  useEffect(() => {
    onEventChange({
      event_name: name || null,
      event_producer: eventProducer || null,
      game_city: city || null,
      game_state: state || null,
      time_zone: tZone || null,
      event_id: eventId!,
    });
    // eslint-disable-next-line
  }, [name, eventProducer, city, state, tZone]);

  useEffect(() => {
    if (isCancel) {
      onReset();
    }
  }, [isCancel]);

  useEffect(() => {
    dispatchSearch({});
  }, [dispatchSearch]);

  const onChange = ({ target: { value, name } }: any) => {
    switch (name) {
      case 'event_producer': {
        setEventProducer(value);
        break;
      }
      case 'event_city': {
        setCity(value);
        break;
      }
      case 'event_state': {
        setState(value);
        break;
      }
      case 'event_zone': {
        setTZone(value);
        break;
      }
    }
  };

  const onChangeEvent = ({ target: { value } }: any) => setName(value);

  const onSelectEvent = (data: PastEvent) => {
    setEventId(data.event_id);
    setName(data.event_desc);
    setCity(data.event_city);
    setState(data.event_state);
  };

  const onReset = () => {
    setName('');
    setEventProducer('');
    setCity('');
    setState('');
    setTZone('');
  };

  const onChangeSearchValue = (e: any) => {
    setState(e.target.value);
  };

  const onSelectState = (data: Option) => {
    setState(data.label!);
  };

  useEffect(() => {
    const data = statesFilter.filter((data: any) => Reg.test(data.label || ''));
    setFilteredData(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const statesFilter = geographies.map((data: any) => ({
    label: data.state_name,
    value: data.state_name,
  }));

  const filteredEvents = name === '' || !name ? orderBy(pastEvents, 'event_desc', 'asc') : orderBy(pastEvents, 'event_desc', 'asc').filter((pastEvent: any) => pastEvent.event_desc.toLowerCase().replaceAll(name?.toLowerCase(), '') !== pastEvent.event_desc.toLowerCase());

  return (
    <ActionCard title="Event Search Engine" styles={{ width: '49%' }}>
      <div className="comp-container">
        <div className="event-comp-item" style={{ width: '49%' }}>
          <label>Name</label>
          {/* <TextField
            name="event_name"
            value={name}
            fullWidth
            onChange={onChange}
            error={isChecking && !name}
          /> */}
          <InputSearch searchKey={name || ''} placeholder="Enter Event" filteredData={filteredEvents} fieldToDisplay="event_desc" customStyles={classes.searchRoot} onReset={onReset} onChangeSearch={onChangeEvent} onSelect={onSelectEvent} error={isChecking && !name} />
        </div>
        <div className="event-comp-item" style={{ width: '49%' }}>
          <label>Event Producer</label>
          <TextField name="event_producer" value={eventProducer} fullWidth onChange={onChange} error={isChecking && !eventProducer} variant="standard" />
        </div>
      </div>
      <div className="comp-container">
        <div className="event-comp-item" style={{ width: '30%' }}>
          <label>City</label>
          <TextField value={city} name="event_city" fullWidth error={isChecking && !city} onChange={onChange} variant="standard" />
        </div>
        <div className="event-comp-select" style={{ width: '30%' }}>
          <label>State</label>
          <InputSearch searchKey={state} customStyles={classes.searchRoot} placeholder="Select State/Province" filteredData={filteredData} fieldToDisplay="label" onReset={onReset} onChangeSearch={onChangeSearchValue} onSelect={onSelectState} />
        </div>
        <div className="event-comp-select" style={{ width: '30%' }}>
          <label>Time Zone</label>
          <Select name="event_zone" value={tZone} onChange={onChange} error={isChecking && !tZone} variant="standard">
            {timeZoneOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </ActionCard>
  );
};

const mapStateToProps = (state: any) => ({
  pastEvents: state.eventsReducer.pastEvents,
});

const mapDispatchToProps = {
  dispatchSearch: (data: any) => eventsActions.getPastEvents(data),
};

export default connect(mapStateToProps, mapDispatchToProps)(EventComponent);
