interface Team {
  event_id: string;
  division_id: string;
  division_name: string;
  pool_id: string;
  pool_name: string;
  team_id: string;
  team_type: string;
  team_name: string;
  team_logo: string | null;
  team_year: string | null;
  wins: number;
  losses: number;
  ties: number;
  goalsScored: number;
  goalsAllowed: number;
  goalsDifferentialMax?: number;
  highlightField?: HighlighField;
}

export enum HighlighField {
  GS = 'gs',
  GA = 'ga',
  GD = 'gd',
  WP = 'wp',
  GDwM = 'gdwm',
}

export default Team;
