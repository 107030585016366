import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';

export interface TeamsPlayerStats {
  team_id: string;
  team_name: string;
  division: string;
  wins: number;
  Losses: number;
  Ties: number;
  num_games: number;
  WP: number;
  SOS: number;
  oSOS: number;
  Rank: number;
  APG: number;
  AGD: number;
  APA: number;
  RPI: number;
  AVGRANK: number;
  is_active_YN: 1 | 0;
}

export async function fetchTeamsPlayerStats(): Promise<TeamsPlayerStats[]> {
  try {
    const data = await fetchAuthorization(`${API_URL}team_records`);

    const teamsPlayerStats = await data.json();

    return teamsPlayerStats;
  } catch (e: any) {
    throw new Error(`Sorry, something went wrong. Error message: ${e.message}`);
  }
}

export default fetchTeamsPlayerStats;
