import { Auth } from 'aws-amplify';

const fetchAuthorization: typeof fetch = async (input: RequestInfo | URL, init?: RequestInit | undefined): Promise<Response> => {
  const user = await Auth.currentUserPoolUser();
  const jwtToken = user.signInUserSession.idToken.jwtToken;

  const initWithAuthorization = {
    ...init,
    headers: {
      ...init?.headers,
      authorization: `Bearer ${jwtToken}`,
    },
  };
  return fetch(input, initWithAuthorization);
};

export default fetchAuthorization;
