import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      padding: '20px !important',
      '& h2': {
        fontSize: '18 !important',
        fontWeight: 'normal !important',
      },
    },
    dialogTitleNoDesc: {
      padding: '20px 20px 0px 20px !important',
      '& h2': {
        fontSize: '18 !important',
        fontWeight: 'normal !important',
      },
    },
    formFilter: {
      marginLeft: `${theme.spacing(1)} !important`,
      marginRight: `${theme.spacing(1)} !important`,
    },
    select: {
      fontSize: '12px !important',
      color: `${theme.palette.primary.main} !important`,
      fontWeight: 'bold !important',
      width: 120,
      lineHeight: '1.1876em !important',
    },
    MuiSelectOutlined: {
      '& .MuiSelect-outlined': {
        padding: '10px 32px 10px 15px !important',
      },
      '& fieldset': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
      '& .MuiOutlinedInput-input': {
        minHeight: '1.1876em !important',

        '&:focus': {
          backgroundColor: 'rgba(0, 0, 0, 0.05) !important',
        },
      },
    },
    icon: {
      fill: `${theme.palette.primary.main} !important`,
    },
    dialogContent: {
      // height: '450px !important',
      padding: '0px 20px 20px 20px !important',
    },
    feedbackDialogContent: {
      padding: '0px 20px 0px 20px !important',
    },
    popupMain: {
      minWidth: '420px',
      padding: '15px 12px',
    },
    popupHead: {
      marginLeft: '5px',
      marginTop: '10px',
      marginBottom: '10px',
      '& h2': {
        fontSize: '20px',
      },
    },
    popupContainer: {},

    warningIcon: {
      color: '#ed3237 !important',
      fontSize: '30px !important',
      marginRight: '12px !important',
    },
    popupSubTitle: {
      marginBottom: '15px',
      paddingBottom: '15px',
      borderBottom: '1px solid #ccc',
    },
    popupForm: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      height: '100%',
    },
    popupFormInput: {
      '& > label': {
        fontSize: '20px',
        fontWeight: 'bold',
      },
      '& > div > input': {
        borderRadius: 5,
        boxShadow: '0 0 5px #ccc',
        padding: '8px 10px !important',
        marginTop: '8px',
        fontSize: '18px',
        fontWeight: '500',
      },
      '& > div:before': {
        display: 'none',
      },
    },
    popupFormInputMultiline: {
      '& > label': {
        fontSize: '20px',
        fontWeight: 'bold',
      },
      '& > label > div': {
        padding: '0 !important',
      },
      '& > div > textarea': {
        borderRadius: 5,
        boxShadow: '0 0 5px #ccc',
        padding: '8px 10px !important',
        marginTop: '8px',
        fontSize: '18px',
        fontWeight: '500',
      },
      '& > div:before': {
        display: 'none',
      },
    },
    numberInput: {
      '& input': {
        textAlign: 'center',
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        margin: 0,
        '-webkit-appearance': 'none',
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        margin: 0,
        '-webkit-appearance': 'none',
      },
    },
    popupFormSearchInputWrapper: {
      // padding: '20px 0px'
    },
    eventpopupFormSearchInputWrapper: {
      padding: '20px 0px',
      color: "#ed3237",
    },
    eventpopupFormAddPlayer: {
      padding: '10px 0px',
    },
    addMeToThisTeamTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '24px',
      '& > div': {
        width: '35%',
      },
      '& > label': {
        fontSize: '18px !important',
        fontWeight: 'bold !important',
        fontFamily: 'Roboto, sansSerif',
        color: '#1c315f !important',
        lineHeight: '1',
        marginBottom: '8px !important',
        display: 'block !important',
      },
      '& > div > div': {
        width: '100%',
        margin: '0px !important',
      },
      '& > div > div > div': {
        width: '100%',
        boxShadow: '0 0 5px #ccc !important',
        border: 'none !important',
        fontSize: '18px !important',
        fontWeight: '500 !important',
        color: '#373737 !important',
      },
      '& > div > div > div > input': {
        fontSize: '14px !important',
        padding: '10px !important',
      },
      '& > div > div > div > fieldset': {
        border: 'none !important',
      },
      '& .MuiFormControl-root.MuiTextField-root': {
        borderRadius: '5px !important',
        // padding: "5px 10px",
        '& .MuiInput-underline:before': {
          borderBottom: '0px !important',
        },
      },
      '& .MuiOutlinedInput-input': {
        padding: '4px 0px 5px',
      },
    },

    addMeToThisTeam: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '24px',
      '& > div': {
        width: '35%',
      },
      '& > label': {
        fontSize: '15px !important',
        fontWeight: 'bold !important',
        fontFamily: 'Roboto, sansSerif',
        color: '#757575 !important',
        lineHeight: '1',
        marginBottom: '8px !important',
        display: 'block !important',
      },
      '& > div > div': {
        width: '100%',
        margin: '0px !important',
      },
      '& > div > div > div': {
        width: '100%',
        boxShadow: '0 0 5px #ccc !important',
        border: 'none !important',
        fontSize: '18px !important',
        fontWeight: '500 !important',
        color: '#373737 !important',
      },
      '& > div > div > div > input': {
        fontSize: '14px !important',
        padding: '10px !important',
      },
      '& > div > div > div > fieldset': {
        border: 'none !important',
      },
      '& .MuiFormControl-root.MuiTextField-root': {
        borderRadius: '5px !important',
        // padding: "5px 10px",
        '& .MuiInput-underline:before': {
          borderBottom: '0px !important',
        },
      },
      '& .MuiOutlinedInput-input': {
        padding: '4px 0px 5px',
      },
    },
    addJerseyNumber: {
      width: '30%',
      marginTop: '2px !important',
      '& > label': {
        fontSize: '15px !important',
        fontWeight: 'bold !important',
        fontFamily: 'Roboto, sansSerif',
        color: '#757575 !important',
        lineHeight: '1',
        marginBottom: '8px !important',
        display: 'block !important',
      },
      '& > div': {
        width: '100%',
        boxShadow: '0 0 5px #ccc !important',
        border: 'none !important',
        fontSize: '18px !important',
        fontWeight: '500 !important',
        color: '#373737 !important',
      },
      '& > div > input': {
        fontSize: '14px !important',
        padding: '10px !important',
      },
      '& > div > fieldset': {
        border: 'none !important',
      },
      '& .MuiFormControl-root.MuiTextField-root': {
        borderRadius: '5px !important',
        // padding: "5px 10px",
        '& .MuiInput-underline:before': {
          borderBottom: '0px !important',
        },
      },
      '& .MuiOutlinedInput-input': {
        padding: '4px 0px 5px',
      },
    },
    popupFormSelect: {
      '& > label': {
        fontSize: '15px !important',
        fontWeight: 'bold !important',
        fontFamily: 'Roboto, sansSerif',
        color: '#757575 !important',
        lineHeight: '1',
        marginBottom: '8px !important',
        display: 'block !important',
      },
      '& > div': {
        width: '100%',
        margin: '0px !important',
      },
      '& > div > div': {
        width: '100%',
        boxShadow: '0 0 5px #ccc !important',
        border: 'none !important',
        fontSize: '18px !important',
        fontWeight: '500 !important',
        color: '#373737 !important',
        borderRadius: '4px',
      },
      '& > div > div > input': {
        fontSize: '14px !important',
        padding: '10px !important',
      },
      '& > div > div > fieldset': {
        border: 'none !important',
      },
      '& .MuiFormControl-root.MuiTextField-root': {
        borderRadius: '5px !important',
        // padding: "5px 10px",
        '& .MuiInput-underline:before': {
          borderBottom: '0px !important',
        },
      },
      '& .MuiOutlinedInput-input': {
        padding: '4px 0px 5px',
      },
    },
    popupFormSelectTwo: {
      paddingTop: 0,
    },
    popupFormSelectThree: {
      '& > div > fieldset': {
        border: 'none !important',
      },
    },
    popupFormSelectLast: {
      paddingBottom: '0px !important',
    },
    cancelBtn: {
      backgroundColor: '#ed3237',
      color: '#fff',
      marginRight: '4%',
    },
    popupBtn: {
      marginTop: 20,
      '& > button': {
        fontSize: '16px',
        width: '48%',
        padding: '8px 16px',
      },
    },
    dialogActions: {
      display: 'block',
      flex: 'unset',
      alignItems: 'unset',
      justifyContent: 'unset',
    },
    overrideMargin: {
      margin: 0,
    },
    downloadButton: {
      width: '100%',
      margin: '0px auto',
      marginTop: 10,
      padding: '16px',
    },
    csvSelectSection: {},
    csvButton: {
      width: '100%',
      margin: '10px auto',
      padding: '16px',
      marginTop: 20,
      '& input': {
        display: 'none',
      },
    },
    fileSelected: {
      margin: '5px 5px 10px',
    },
    fileName: {
      fontSize: 14,
      fontWeight: 'normal',
    },
    buttonProgress: {
      color: 'white',
    },
    nameWithNumGrid: {
      '& > div:first-child': {
        paddingRight: '5px',
      },
      '& > div:last-child': {
        paddingLeft: '5px',
      },
    },
    addPlayerTitle: {
      display: 'inline-block',
      color: "#1C315F",
    },
    closePlayerModalIcon: {
      float: 'right',
      cursor: 'pointer',
    },
    alerts: {
      margin: '20px 0px 0px 0px',
      width: '100%',
    },
    alertsSmall: {
      margin: '20px 0px 0px 0px',
      width: '395px',
    },
    playerDialogActions: {
      // bottom: 10,
      position: 'relative',
      width: '100%',
      margin: '0px auto !important',
      marginTop: ' 5px !important',
      padding: '0px !important',
    },
    playerCSVSubmit: {
      width: '100%',
      // margin: '0px auto !important',
      padding: '16px !important',
      borderRadius: '9px !important',
      // height: 45,
    },
    playerCSVFormControl: {
      marginTop: 15,
      padding: 0,
    },
    smallButton: {
      marginTop: '10px',
      marginBottom: theme.spacing(3),
      padding: '8px 20px',
      fontSize: '13px',
      lineHeight: '14px',
      right: '40px',
      position: 'absolute',
    },

    //Review Player hosters style
    playersDialogContent: {
      height: 560,
    },
    loadingPlayerRoster: {
      top: '45%',
      position: 'absolute',
      left: '50%',
      margin: '10px 0',
    },
    clubExistError: {
      marginTop: 10,
    },
    popupFormSection: {
      padding: '20px 0px 0px 0px',
      display: 'flex',
      alignItems: 'center',
    },
    scoreFiled: {
      flex: 0.15,
      marginLeft: 15,
      '& > label': {
        fontSize: '20px',
        fontWeight: 'bold',
      },
      '& .MuiInputBase-root': {
        boxShadow: '0 0 5px #ccc',
        marginTop: '24px !important',
        borderRadius: 5,
        '&:before': {
          borderBottom: '0px !important',
        },
        '& .MuiInputBase-input': {
          // padding: "8px 5px !important" ,
          padding: '10px 32px 10px 15px !important',
          fontSize: 18,
          fontWeight: 500,
          borderRadius: 5,
        },
        '& .MuiOutlinedInput-input': {
          padding: '10px 32px 10px 15px !important',
        },
      },
    },
    searchInputLeft: {
      flex: 1,
      width: '100%',
    },
    cancelButton: {
      backgroundColor: theme.palette.error.main,
      color: 'white',
      width: '100%',
      margin: '0px auto',
      padding: 16,
      marginTop: 20,
    },
    twoFormInputs: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
      '&>div': {
        width: '48%',
      },
      paddingTop: '10px ',
    },
    selectedToggleButton: {
      color: 'rgba(28, 49, 95) !important',
    },
    toggle: {
      height: 35,
      border: '1px solid #1c315f',
    },
    toggleButton: {
      maxWidth: '107px',
      marginLeft: 'auto',
    },
    clubsPopupFormSearchInputWrapper: {
      // paddingTop: '20px',
      paddingBottom: '20px',
    },
    MultiTextLineFiled: {
      '& .MuiInputBase-root': {
        padding: '0px !important',
      },
    },
    add_to_team_modal: {
      width: '100%',
      '& div.MuiPaper-root': {
        width: '100%',
      },
      '& div.MuiPaper-root h2': {
        fontSize: '20px',
        color: '#373737',
        fontWeight: 'bold',
      },
      '& div.MuiDialogContent-root': {
        textAlign: 'center',
        paddingBottom: '10px !important',
      },
      '& div.MuiDialogContent-root > p': {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#757575',
      },
      '& div > div > svg.MuiSvgIcon-root': {
        fontSize: '22px',
        color: 'rgba(0, 0, 0, 0.537)',
      },
      '& div.MuiDialogContent-root div.custom-text-field': {
        width: '30% !important',
        margin: '0 auto',
      },
      '& div.MuiDialogContent-root input::-webkit-outer-spin-button,input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      "& div.MuiDialogContent-root input[type='input']": {
        '-moz-appearance': 'textfield',
      },
      '& div.MuiPaper-root button': {
        padding: '8px 17px !important',
      },
    },
    label: {
      minHeight: 40,
      marginBottom: 10,
      display: 'block',
    },
    radioWrapper: {
      maxWidth: '400px',
    },
    addMeToThisTeamSelect: {
      '& div': {
        fontSize: '14px !important',
      },
    },
    removeBoxShadow: {
      '& > div > input': {
        boxShadow: 'none !important',
      },
    },
    playerHighlightSubmit: {
      width: '100% !important',
      padding: '12px !important',
      borderRadius: '9px !important',
      marginLeft: '24px !important',
    },
    playerHighlightCancel: {
      width: '100% !important',
      padding: '12px !important',
      borderRadius: '9px !important',
      marginRight: '24px !important',
    },
    inputFont: {
      '& div > input': {
        fontSize: '18px !important',
      },
    },
    addHighlight: {
      display: 'flex',
      gap: '20px',
      '& > span': {
        paddingTop: '8px !important',
      },
    },
    playerDialogActionsRight: {
      position: 'relative',
      width: '46.8%',
      marginLeft: 'auto',
      padding: '0px !important',
      '& > div': {
        width: '170px',
        minWidth: 'unset',
      },
      '& > div > div': {
        width: '170px',
      },
    },
    media_checkbox: {
      '& > span': {
        padding: '6px !important',
      },
    },
    multiSelect: {
      '& > div': {
        margin: '0 !important',
        paddingTop: '10px',
      },
    },
    customSelect: {
      '& > label': {
        fontSize: '15px',
        fontWeight: 'bold',
        position: 'absolute',
        top: '-5px',
        left: 0,
      },
      '& > div': {
        borderRadius: 5,
        marginTop: '30px !important',
        boxShadow: '0 0 5px #ccc',
        padding: '8px !important',
      },
      '& > div > div:focus': {
        backgroundColor: 'white',
      },
      '& > div > input': {
        padding: '8px 10px !important',
        fontSize: '18px',
        fontWeight: '500',
      },
    },
    customDialogHight: {
      paddingTop: '10vh',
      height: '90vh',
    },
    videoModalRightPanel: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      '& > div': {
        width: '100%',
      },
      '& > div:nth-child(1)': {
        flexGrow: 1,
      },
      '& > div:nth-child(2) > div': {
        margin: '0 !important',
        fontSize: '14px !important',
      },
      '& > div:nth-child(2) > div input': {
        fontSize: '14px !important',
        padding: '5px !important',
      },
      '& > div:nth-child(2) > div > div > div': {
        margin: '0 !important',
        borderBottom: '1px solid #999 !important',
        boxShadow: 'none !important',
        borderRadius: '0 !important',
      },
      '& > div:nth-child(3)': {},
    },
    popupFormlabel: {
      '& > label': {
        fontSize: '15px !important',
        fontWeight: 'bold !important',
        fontFamily: 'Roboto, sansSerif',
        color: '#757575 !important',
        lineHeight: '1',
        display: 'block !important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '8px 10px !important',
        fontSAize: '18px',
        boxShadow: '0 0 5px #ccc',
        marginTop: '8px',
        fontWeight: 500,
        borderRadius: '5px',
      },
    },
    clubsPopupFormFacilityField: {
      paddingTop: '20px',
    },
    clubsPopupFormFacilityPro: {
      paddingBottom: '0 !important',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    clubsPopupDateWidth: {
      '&>div': {
        width: '100%',
      },
    },
    PopupFormDateInput: {
      marginBottom: '0px !important',
    },
    feedbackDialogButton: {
      padding: '0px !important',
    },
    customMargin: {
      marginTop: '20px !important',
    },
    popupFormAutoComplete: {
      '& > label': {
        fontSize: '15px !important',
        fontWeight: 'bold !important',
        fontFamily: 'Roboto, sansSerif',
        color: '#757575 !important',
        lineHeight: '1',
        display: 'block !important',
        marginBottom: '8px !important',
      },
      '& .MuiOutlinedInput-root': {
        padding: '2px 10px 2px 10px !important',
        boxShadow: '0 0 5px #ccc',
        fontWeight: 500,
        borderRadius: '5px',
        fontSize: '18px',
      },
    },
  }),
);
