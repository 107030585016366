import moment from 'moment';
import _ from 'lodash';

import * as stats from './stats';

export async function processTeamSummaryStats(data: any) {
  if (data && data.length) {
    let teams = [];
    for await (const item of data) {
      try {
        let copyItem = Object.assign({}, item);

        // Parse stats from String to Array
        let parsedArr: any;
        if (copyItem.json_team_stats !== null) {
          let jsonStr = copyItem.json_team_stats;
          // console.log('json_team_stats: ', jsonStr);

          // DIRTY FIX FOR TRUNCATED "JSON" string
          let jsonStrTrim = jsonStr.substring(0, jsonStr.lastIndexOf('}') + 1);
          // console.log('jsonStrTrim', jsonStrTrim);

          parsedArr = JSON.parse(`[${jsonStrTrim}]`);
          // console.log('parsedArr', parsedArr);
        }
      
        let clubStats = await stats.getSummaryStats(parsedArr); // Map detailed stats key-values
        // console.log('processClubStats - clubStats', clubStats);

        delete copyItem.json_team_stats;

        let teamStatsData = {
          ...copyItem,
          stats: clubStats,
        };

        teams.push(teamStatsData);
      } catch (e: any) {
        console.log(e.stack);
      }
    }
    return teams;
  } else {
    return [];
  }
}

// Club Sumary stats for processedTeamSummaryStats
export async function processClubSummaryStats(teams: any) {
  
  const wins = _.sumBy(teams, 'stats.wins') || 0;
  const losses = _.sumBy(teams, 'stats.losses') || 0;
  const ties = _.sumBy(teams, 'stats.ties') || 0;
  const games = wins + losses + ties;

  // const games = Math.round(ave_wp ? wins / ave_wp : wins + losses); // fallback calc may be inaccurate since ties are missing
  // const ties = games - wins - losses;

  // Face-offs won / lost
  const face_offs = _.sumBy(teams, 'stats.fos') || 0;
  const ave_fo_win_percent = _.sumBy(teams, 'stats.fo_percent') || 0 / _.filter(teams, (t: any) => t.stats.fo_percent !== null).length;

  const fo_wins = face_offs * ave_fo_win_percent;
  const fo_losses = face_offs - fo_wins;

  let clubStats = {
    // rank: teams.stats.rank,
    // wp: ave_wp,
    games: games,
    wins: wins,
    losses: losses,
    ties: ties,
    goals: _.sumBy(teams, 'stats.goals'),
    goals_against: _.sumBy(teams, 'stats.goals_against'),
    assists: _.sumBy(teams, 'stats.assists'),
    // saves: _.sumBy(teams, 'stats.saves'),
    save_percent: _.sumBy(teams, 'stats.save_percent'),
    fto: _.sumBy(teams, 'stats.fto'),
    // ground_balls: _.sumBy(teams, 'stats.gb'),
    ground_balls: _.sumBy(teams, 'stats.ground_balls'),
    face_offs: face_offs,
    fo_wins: fo_wins,
    fo_losses: fo_losses,
    wp: _.meanBy(teams, 'stats.wp'),
    sos: _.meanBy(teams, 'stats.sos'),
    osos: _.meanBy(teams, 'stats.osos'),
    pts_scored: _.meanBy(teams, 'stats.pts_scored'),
    pts_allowed: _.meanBy(teams, 'stats.pts_allowed'),
    agd: _.meanBy(teams, 'stats.agd'),
    rpi: _.meanBy(teams, 'stats.rpi')
  };

  // console.log('clubStats', clubStats);

  return clubStats;
}

const dates = {
  today: {
    start: () => moment().format('YYYY-MM-DD 00:00:01'),
    end: () => moment().format('YYYY-MM-DD 23:59:59'),
  },
  yesterday: {
    start: () => moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:01'),
    end: () => moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59'),
  },
  currentweek: {
    start: () => moment().startOf('isoWeek').format('YYYY-MM-DD 00:00:01'),
    end: () => moment().endOf('isoWeek').format('YYYY-MM-DD 23:59:59'),
  },
  lastweek: {
    start: () => moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD 00:00:01'),
    end: () => moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD 23:59:59'),
  },
  lastyear: {
    value: () => moment().subtract(1, 'year').year(),
  },
};

export function filterClubByTime(time: string, clubs: Array<any>) {
  switch (time) {
    case 'today':
      return clubs.filter((club) => {
        return moment(club.created_datetime).isBetween(dates.today.start(), dates.today.end());
      });
    case 'yesterday':
      return clubs.filter((club) => {
        return moment(club.created_datetime).isBetween(dates.yesterday.start(), dates.yesterday.end());
      });
    case 'thisweek':
      return clubs.filter((club) => {
        return moment(club.created_datetime).isBetween(dates.currentweek.start(), dates.currentweek.end());
      });
    case 'lastweek':
      return clubs.filter((club) => {
        return moment(club.created_datetime).isBetween(dates.lastweek.start(), dates.lastweek.end());
      });
    case 'lastyear':
      return clubs.filter((club) => {
        const lastyear = dates.lastyear.value();
        const createdYear = moment(club.created_datetime).year();
        return lastyear === createdYear;
      });
    default:
      return clubs;
  }
}
