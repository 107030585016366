export enum TEAM_RESULT {
  WIN = 'W',
  LOSS = 'L',
  TIE = 'T',
}

interface NormalizeGame {
  division_id: string;
  division_name: string;
  event_desc: string | null;
  event_id: string;
  event_type: string;
  game_date: string;
  game_id: string;
  is_active_YN: 1 | 0 | null;
  is_bracket_YN: 1 | 0 | null;
  opponent_logo_url: string | null;
  opponent_team_id: string;
  opponent_team_name: string;
  opponent_team_score: number;
  opponent_team_year: string | null;
  pool_id: string;
  pool_name: string;
  sport_id: number | null;
  team1_outcome: TEAM_RESULT;
  team_id: string;
  team_logo_url: string | null;
  team_name: string;
  team_score: number;
  team_year: string | null;
}

export default NormalizeGame;
