import React, { ReactNode } from 'react';
import makeStyles from '@mui/styles/makeStyles';
interface IProps {
  children: ReactNode;
}
const useStyles = makeStyles({
  root: {
    margin: '0 auto',
    maxWidth: '1280px',
    height: '100%',
    padding: '0 17px',
  },
});

const ConstrainedWidth = (props: IProps) => {
  const classes = useStyles(props);

  return <div className={classes.root}>{props.children}</div>;
};

export default ConstrainedWidth;
