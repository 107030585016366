import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import { getErrorMessage } from '../helpers';

export interface Feedback {
    feedback_id?: string;
    member_id?: string;
    subject?: string;
    message_body?: string;
    rating_value?: number;
    allow_followup_YN?: boolean;
    is_resolved_YN?: number;
    is_active_YN?: number;
    created_by?: string;
    created_datetime?: string;
    updated_by?: string;
    updated_datetime?: string;
}

export async function addFeedback(feedback: Feedback): Promise<any> {
    try {
      const response = await fetchAuthorization(`${API_URL}feedback`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(feedback),
      });
  
      const result = await response.json();
  
      if (response.status !== 200) {
        const message = getErrorMessage(result);
        throw new Error(message ? message : `Status Code: ${response.status}. Something went wrong. Please try again later.`);
      }
  
      return result;
    } catch (e: any) {
      throw new Error(`We were unable to post the feeback. ${e.message}`);
    }
  }
  
  export default addFeedback;