import qs from 'qs';
import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';

export type FetchGamesOptions = {
  gameId?: string;
  eventId?: string;
  sportId?: string;
  teamId?: string;
  teamYear?: number;
};

export async function fetchMutchupGames(opts: FetchGamesOptions): Promise<any> {
  try {
    const query = qs.stringify({
      game_id: opts.gameId,
      tournament_id: opts.eventId,
      sport_id: opts.sportId,
      team_id: opts.teamId,
      team_year: opts.teamYear,
    });

    const data = await fetchAuthorization(`${API_URL}games?${query}`);

    return await data.json();
  } catch (e: any) {
    throw new Error(`Something went wrong. Error message: ${e.message}`);
  }
}

export default fetchMutchupGames;
