import Box from '@mui/material/Box';
import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';

type Props = {
  index: string | number;
  tabGroupName: string;
  value: number;
  className?: string;
  children: ReactNode;
};

const TabPanel: React.FC<Props> = (props) => {
  const { children, value, index, tabGroupName, className, ...other } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`${tabGroupName}-tabpanel-${index}`} aria-labelledby={`${tabGroupName}-tab-${index}`} className={className} {...other}>
      {value === index && (
        <Box p={3} style={{ padding: 0 }}>
          {children}
        </Box>
      )}
    </Typography>
  );
};

export default TabPanel;
