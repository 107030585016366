// type MemberType = 'Player' | 'Coach' | 'Other' | 'Parent';

// const memberTypes: Record<MemberType, MemberType> = {
//   Coach: 'Coach',
//   Other: 'Other',
//   Parent: 'Parent',
//   Player: 'Player',
// };

const memberTypes = [
  {
    label: 'Player',
    value: 'Player',
  },
  {
    label: 'Parent/Public',
    value: 'Parent/Public',
  },
  {
    label: 'Club/Team Admin',
    value: 'Club Coach / Team Admin',
  },
  {
    label: 'Event Producer',
    value: 'Event Producer',
  },
];

export default memberTypes;
