import React from 'react';
import { DialogTitle, Grid, Typography } from '@mui/material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Close as CloseIcon } from '@mui/icons-material';

import { useStyles } from './styles';

type Props = {
  title: string;
  onClose: any;
  memberName?: string;
  hideDescription?: boolean;
};

const ModalTitle: React.FC<Props> = (props) => {
  const { title, onClose, memberName, hideDescription } = props;
  const classes = useStyles();


  return (
    <>
      {hideDescription ?
        <DialogTitle className={classes.dialogTitleNoDesc}>
          <div className={classes.popupHead}>
            <Typography variant="h2" className={classes.addPlayerTitle}>
              {title}
            </Typography>
            <CloseIcon fontSize="medium" className={classes.closePlayerModalIcon} onClick={onClose} />
          </div>
          <hr></hr>
        </DialogTitle>
        :
        <DialogTitle className={classes.dialogTitle}>
          <div className={classes.popupHead}>
            <Typography variant="h2" className={classes.addPlayerTitle}>
              {title}
            </Typography>
            <CloseIcon fontSize="medium" className={classes.closePlayerModalIcon} onClick={onClose} />
          </div>

          <Grid container alignItems="center" direction="row" className={`${classes.popupSubTitle} ${classes.overrideMargin}`} >
            <ReportProblemOutlinedIcon className={classes.warningIcon} />
            <div>
              <Typography variant="h6">You are requesting this addition as {' ' + memberName}.</Typography>
              <Typography variant="body2">These requests are audited and misrepresentations will be penalized by revoking membership access.</Typography>
            </div>
          </Grid>
        </DialogTitle>
      }
    </>
  );
};

export default ModalTitle;
