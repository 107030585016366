import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import { getErrorMessage } from '../helpers';


export async function fetchTscoAdmin(event_id?: string): Promise<any> {
  try {
    let url;
    if(event_id){
      url = `${API_URL}v_tmachine_division_team_map?event_id=${event_id}`
    }
    else{
      url = `${API_URL}v_tmachine_division_team_map`
    }
    const response = await fetchAuthorization(url);

    const result = await response.json();

    if (response.status !== 200) {
      const message = getErrorMessage(result);
      throw new Error(message ? message : `Status Code: ${response.status}. Something went wrong. Please try again later.`);
    }

    return result;
  } catch (e: any) {
    throw new Error(`${e.message}`);
  }
}

export default fetchTscoAdmin;
