import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import qs from 'qs';

export async function _fetchRequest<T>(type: 'GET' | 'PUT' | 'POST' | 'DELETE', url: string, query?: object, body?: object) {
  try {
    const queryString = qs.stringify(query);
    const headers = {
      method: type,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await fetchAuthorization(
      query ? `${API_URL}${url}?${queryString}` : `${API_URL}${url}`,
      body
        ? {
            ...headers,
            body: JSON.stringify(body),
          }
        : type === 'DELETE'
        ? headers
        : undefined,
    );

    const result = await response.json();

    return result as T;
  } catch (e: any) {
    throw new Error(`Something went wrong. Error message: ${e.message}`);
  }
}

export default _fetchRequest;
