import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      padding: theme.spacing(2),
      paddingBottom: '10px',
      justifyContent: 'space-between',
    },
    title: {
      fontSize: '14px !important',
      marginTop: '6px !important',
      fontWeight: 'bold !important',
    },
    content: {
      padding: theme.spacing(2),
    },
    emptyState: {
      color: theme.palette.text.secondary,
      fontSize: '13px !important',
      textAlign: 'center',
      fontWeight: 'normal !important',
      padding: theme.spacing(2),
    },
  }),
);
