import moment from 'moment';
import _, { groupBy } from 'lodash';

export async function processPlayerSummaryStatsJSON(data: any, jsonField?: string) {
  if (data && data.length) {
    let players = [];
    for await (const item of data) {
      try {
        let copyItem = Object.assign({}, item);

        let parsedArr: any = `[${copyItem[!jsonField ? 'json_player_stats' : jsonField]}]`;
        parsedArr = JSON.parse(parsedArr); // Parse stats from String to Array
        let stats = await getStats(parsedArr); // Map detailed stats key-values

        delete copyItem.json_player_stats;

        let finalPlayerData = {
          ...copyItem,
          stats: stats,
        };

        if (finalPlayerData.jersey_number !== -1) players.push(finalPlayerData);
      } catch (e: any) {
        console.log(e.stack);
      }
    }
    return players;
  } else {
    return [];
  }
}

export async function processPlayerGameStatsCollection(data: any) {
  if (data && data.length) {
    let players = [];
    for await (const item of data) {
      try {
        let copyItem = Object.assign({}, item);

        let parsedArr: any = `[${copyItem.json_player_stats}]`;
        parsedArr = JSON.parse(parsedArr); // Parse stats from String to Array
        let stats = await getStats(parsedArr); // Map detailed stats key-values

        delete copyItem.json_player_stats;

        if (parsedArr.length) {
          let finalGameData = {
            ...copyItem,
            stats: stats,
          };

          // console.log('parsedArr', parsedArr);
          // console.log('finalGameData', finalGameData);

          players.push(finalGameData);
        }
      } catch (e: any) {
        // 74D4F393
        // console.log(e.stack);
      }
    }

    return players;
    // const getTotalCount = await calculatePlayerGamesStatsData(players);
    // return getTotalCount;
  } else {
    return [];
  }
}

// Pre March 24th
export async function processPlayerGameStatsJSON(data: any) {
  if (data && data.length) {
    let players = [];
    for await (const item of data) {
      try {
        let copyItem = Object.assign({}, item);

        let parsedArr: any = `[${copyItem.json_player_stats}]`;
        parsedArr = JSON.parse(parsedArr); // Parse stats from String to Array

        delete copyItem.json_player_stats;

        let finalPlayerData = {
          ...copyItem,
          ...getStats(parsedArr),
        };

        players.push(finalPlayerData);
      } catch (e: any) {
        // 74D4F393
        // console.log(e.stack);
      }
    }

    const getTotalCount = await calculatePlayerGamesStatsData(players);
    return getTotalCount;
  } else {
    return [];
  }
}

export async function processPlayerGameStatsJSONParse(data: any) {
  if (data && data.length) {
    let players = [];
    for await (const item of data) {
      try {
        let copyItem = Object.assign({}, item);

        let parsedArr: any = `[${copyItem.json_player_stats}]`;
        parsedArr = JSON.parse(parsedArr); // Parse stats from String to Array

        delete copyItem.json_player_stats;

        let finalPlayerData = {
          ...copyItem,
          ...getPlayerProfileStats(parsedArr),
        };
        // const data: any = { ...getPlayerProfileStats(parsedArr) }
        // console.log("data", data)
        players.push(finalPlayerData);
      } catch (e: any) {
        // 74D4F393
        // console.log(e.stack);
      }
    }
    return players;
  } else {
    return [];
  }
}

function getPlayerProfileStatKeyValue(stat: any) {
  switch (stat.statistic_name) {
    case 'Ground Ball':
      return { key: 'GB', value: stat.value };
    case 'Goal':
      return { key: 'goals', value: stat.value };
    case 'Forced Turnover':
      return { key: 'fto', value: stat.value };
    case 'Goal Even Strength':
      return { key: 'ges', value: stat.value };
    case 'Goal Allowed Even Strength':
      return { key: 'goal_allowed_strength', value: stat.value };
    case 'Save Made Even Strength':
      return { key: 'save_made_strength', value: stat.value };
    case 'Miss Even Strength':
      return { key: 'miss_strength', value: stat.value };
    case 'Save Even Strength':
      return { key: 'save_strength', value: stat.value };
    case 'Player Power Ranking':
      return { key: 'ppr', value: stat.value };
    case 'Save':
      return { key: 'save', value: stat.value };
    case 'Miss':
      return { key: 'miss', value: stat.value };
    case 'Assist':
      return { key: 'assists', value: stat.value };
    case '30 Sec Releasable':
      return { key: 'sec_releasable', value: stat.value };
    case 'Faceoff Lost':
      return { key: 'faceoff_lost', value: stat.value };
    case 'Faceoff Won':
      return { key: 'faceoff_won', value: stat.value };
    case 'Save Man Up':
      return { key: 'save_man_up', value: stat.value };
    case '1 Min Releasable':
      return { key: 'min_releasable', value: stat.value };
  }
}

function getPlayerProfileStats(stats: Array<any>) {
  let statsObj: any = {};
  for (let i = stats.length - 1; i >= 0; i--) {
    try {
      if (stats[i] !== null) {
        const stat: any = getPlayerProfileStatKeyValue(stats[i]);
        statsObj[stat.key] = stat.value;
      }
    } catch (e: any) {
      console.log(stats[i]);
      console.log(e.stack);
    }
  }
  return statsObj;
}

function getStatKeyValue(stat: any) {
  switch (stat.statistic_name) {
    case 'Ground Ball':
      return { gb: stat.statistic_value ? +stat.statistic_value : stat.value };
    case 'Goal':
      return {
        goals: stat.statistic_value ? +stat.statistic_value : stat.value,
      };
    case 'Forced Turnover':
      return { fto: stat.statistic_value ? +stat.statistic_value : stat.value };
    case 'Goal Even Strength':
      return { ges: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'Goal Allowed Even Strength':
      return {
        goal_allowed_strength: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Save Made Even Strength':
      return {
        save_made_strength: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Miss Even Strength':
      return {
        miss_strength: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Save Even Strength':
      return {
        save_strength: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Player Power Ranking':
      return { ppr: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'Save':
      return { save: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'Miss':
      return { miss: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'Assist':
      return {
        assists: stat.statistic_value ? +stat.statistic_value : stat.value,
      };
    case '30 Sec Releasable':
      return {
        sec_releasable: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Faceoffs':
      return {
        faceoffs: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Faceoff Lost':
      return {
        faceoff_lost: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Faceoff Won':
      return {
        faceoff_won: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Save Man Up':
      return {
        save_man_up: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case '1 Min Releasable':
      return {
        min_releasable: stat.statistic_value ? stat.statistic_value : stat.value,
      };
  }
}

async function getStats(stats: Array<any>) {
  let statsObj: any = _.map(stats, (stat) => {
    return stat ? getStatKeyValue(stat) : {};
  });
  return _.assign.apply(_, statsObj); // combine stats into a single object
}

async function calculatePlayerGamesStatsData(games: Array<any>) {
  let copyItem: any = {};
  let goalsScored = 0;
  let gamesPlayed = 0;
  let winPercent = 0;
  let ppr = 0;
  let assists = 0;
  let fto = 0;
  let GB = 0;
  let totalPoints = 0;

  for await (const item of games) {
    try {
      copyItem = Object.assign({}, item);

      if (copyItem != null && copyItem.ppr) {
        ppr += copyItem.ppr;
      }

      if (games !== undefined && games.length) {
        gamesPlayed = games.length;
      }

      if (copyItem != null && copyItem.team1_outcome) {
        let total_games = games.length;
        let no_of_wins = games.filter((obj: any) => obj.team1_outcome === 'W').length;
        winPercent = Number(((no_of_wins * 100) / total_games).toFixed(1));
      }

      if (copyItem != null && copyItem.goals) {
        goalsScored += copyItem.goals;
      }

      if (copyItem != null && copyItem.assists) {
        assists += copyItem.assists;
      }

      if (copyItem != null && copyItem.fto) {
        fto += copyItem.fto;
      }

      if (copyItem != null && copyItem.GB) {
        GB += copyItem.GB;
      }
    } catch (e: any) {
      // console.log('E', e);
    }
  }

  totalPoints = goalsScored + assists;

  return {
    ...copyItem,
    goals_scored: goalsScored,
    games_played: gamesPlayed,
    win_percent: winPercent,
    ppr: ppr,
    assists: assists,
    fto: fto,
    GB: GB,
    total_points: totalPoints,
    date_window_points: Number(goalsScored + assists + fto + GB),
  };
}

const dates = {
  today: {
    start: () => moment().format('YYYY-MM-DD 00:00:01'),
    end: () => moment().format('YYYY-MM-DD 23:59:59'),
  },
  currentweek: {
    start: () => moment().startOf('isoWeek').format('YYYY-MM-DD 00:00:01'),
    end: () => moment().endOf('isoWeek').format('YYYY-MM-DD 23:59:59'),
  },
  currentmonth: {
    start: () => moment().startOf('month').format('YYYY-MM-DD 00:00:01'),
    end: () => moment().endOf('month').format('YYYY-MM-DD 23:59:59'),
  },
  currentyear: {
    start: () => moment().startOf('year').format('YYYY-MM-DD 00:00:01'),
    end: () => moment().endOf('year').format('YYYY-MM-DD 23:59:59'),
  },
  lastyear: {
    start: () => moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD 00:00:01'),
    end: () => moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD 23:59:59'),
  },
};

export function FilterByGameDate(time: string, games: Array<any>) {
  switch (time) {
    case 'today':
      return games.filter((game) => {
        return moment(game.game_date).isBetween(dates.today.start(), dates.today.end());
      });
    case 'thisweek':
      return games.filter((game) => {
        return moment(game.game_date).isBetween(dates.currentweek.start(), dates.currentweek.end());
      });
    case 'thismonth':
      return games.filter((game) => {
        return moment(game.game_date).isBetween(dates.currentmonth.start(), dates.currentmonth.end());
      });
    case 'thisyear':
      return games.filter((game) => {
        return moment(game.game_date).isBetween(dates.currentyear.start(), dates.currentyear.end());
      });
    case 'lastyear':
      return games.filter((game) => {
        return moment(game.game_date).isBetween(dates.lastyear.start(), dates.lastyear.end());
      });
    default:
      return games;
  }
}

export async function processPlayerEventStatsJSON(data: any) {
  if (data && data.length) {
    let players = [];
    for await (const item of data) {
      try {
        let copyItem = Object.assign({}, item);

        let parsedArr: any = copyItem['json_player_games_stats'];
        parsedArr = JSON.parse(parsedArr); // Parse stats from String to Array
        const sortedByDivision = groupBy(parsedArr, 'division_id');
        for (let key in sortedByDivision) {
          let stats = await getStats(sortedByDivision[key]); // Map detailed stats key-values
          delete copyItem.json_player_games_stats;

          let finalPlayerData = {
            ...copyItem,
            division_id: key,
            team_name: sortedByDivision[key][0]?.team_name,
            team_id: sortedByDivision[key][0]?.team_id,
            stats: stats,
          };

          if (finalPlayerData.jersey_number !== -1) players.push(finalPlayerData);
        }
      } catch (e: any) {
        console.log(e.stack);
      }
    }
    return players;
  } else {
    return [];
  }
}
