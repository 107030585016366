import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import qs from 'qs';
// import _ from 'lodash';
import { PlayerTeamMap } from '../models/PlayerTeamMap';

export async function fetchPlayerTeamMap(memberId: string | undefined): Promise<PlayerTeamMap[]> {
  try {
    const query = qs.stringify({
      primary_key: memberId,
    });

    // const response = await fetchAuthorization(`${API_URL}player_team_map?${query}`);
    const response = await fetchAuthorization(`${API_URL}player_team_map?${query}`);

    const result = await response.json();

    // const data = _.uniqBy(result, function (e: MemberAssociation) {
    //   return [e.association_fk_id, e.association_id].join();
    //   // return e.association_fk_id;
    // });
    return result;
  } catch (e: any) {
    throw new Error(`Something went wrong. Error message: ${e.message}`);
  }
}



export default fetchPlayerTeamMap;
