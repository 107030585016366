import { fetchMemberAssociations, updateMemberAssociation } from '../../apis';
import _fetchRequest from '../../apis/_fetchRequest';
import { updateClubFollowed } from './clubActions';
import * as commonActions from './commonActions';
import { getPlayerFollowers } from './playerActions';

export const FETCH_MEMBER_ASSOCIATIONS_REQ_START = 'FETCH_MEMBER_ASSOCIATIONS_REQ_START';
export const FETCH_MEMBER_ASSOCIATIONS_REQ_SUCCESS = 'FETCH_MEMBER_ASSOCIATIONS_REQ_SUCCESS';
export const FETCH_MEMBER_ASSOCIATIONS_REQ_FAILED = 'FETCH_MEMBER_ASSOCIATIONS_REQ_FAILED';

export const SET_MEMBER_ASSOCIATION_START = 'SET_MEMBER_ASSOCIATION_START';
export const SET_MEMBER_ASSOCIATION_SUCCESS = 'SET_MEMBER_ASSOCIATION_SUCCESS';
export const SET_MEMBER_ASSOCIATION_FAILED = 'SET_MEMBER_ASSOCIATION_FAILED';

export const SET_TEAMS_FOLLOWED = 'SET_TEAMS_FOLLOWED';
export const SET_PLAYERS_FOLLOWED = 'SET_PLAYERS_FOLLOWED';
export const SET_PLAYERS_WHO_FOLLOW_US = 'SET_PLAYERS_WHO_FOLLOW_US';

export const SET_ORG_FOLLOWED_START = 'SET_ORG_FOLLOWED';
export const SET_ORG_FOLLOWED_SUCCESS = 'SET_ORG_FOLLOWED_SUCCESS';
export const SET_ORG_FOLLOWED_FAILED = 'SET_ORG_FOLLOWED_FAILED';


export const getMemberAssociationsStart = () => ({
  type: FETCH_MEMBER_ASSOCIATIONS_REQ_START,
});

export const getMemberAssociationsSuccess = (data: any) => ({
  type: FETCH_MEMBER_ASSOCIATIONS_REQ_SUCCESS,
  data,
});

export const getMemberAssociationsFailed = (error: any) => ({
  type: FETCH_MEMBER_ASSOCIATIONS_REQ_FAILED,
  error,
});

export const setMemberAssociationStart = (association_fk_id: string) => ({
  type: SET_MEMBER_ASSOCIATION_START,
  selected: association_fk_id,
});

export const setMemberAssociationSuccess = (data: any) => ({
  type: SET_MEMBER_ASSOCIATION_SUCCESS,
  data,
});

export const setMemberAssociationFailed = (error: any) => ({
  type: SET_MEMBER_ASSOCIATION_FAILED,
  error,
});

export const setTeamsFollowed = (data: any) => ({
  type: SET_TEAMS_FOLLOWED,
  data,
});

export const setPlayersFollowed = (data: any) => ({
  type: SET_PLAYERS_FOLLOWED,
  data,
});


export const setOrgFollowedStart = () => ({
  type: SET_ORG_FOLLOWED_START,
});

export const setOrgFollowedSuccess = (data: any) => ({
  type: SET_ORG_FOLLOWED_SUCCESS,
  data,
});

export const setOrgFollowedFailed = (error: any) => ({
  type: SET_ORG_FOLLOWED_FAILED,
  error,
});

function getTeamsIds(data: any, dispatch: any) {
  const filtered = data.filter((x: any) => x.association_id === 3 && x.is_active_YN === 1);

  if (filtered.length) {
    const teamIds = filtered.map((x: any) => x.association_fk_id);
    
    dispatch(setTeamsFollowed(teamIds));
  }
} 

 function getPlayersIds(data: any, dispatch: any) {
  
  const filtered = data.filter((x: any) => x.association_id === 2 && x.is_active_YN === 1);
  
  if (filtered.length) {
    const playerIds = filtered.map((x: any) => x.association_fk_id);
    
    dispatch(setPlayersFollowed(playerIds));
  }
} 

export function getMemberAssociations(data: any) {
  return (dispatch: any) => {
    dispatch(getMemberAssociationsStart());
    fetchMemberAssociations(data)
      .then((res: any) => {
        dispatch(getMemberAssociationsSuccess(res));
        if (res && res.length) {
          
          const clonedData = Object.assign([], res);
          getTeamsIds(clonedData, dispatch);
          getPlayersIds(clonedData, dispatch);
        }
      })
      .catch((error: any) => {
        console.log('error in getMemberAssociations', error);
        dispatch(getMemberAssociationsFailed(error));
      });
  };
}


export function getUniverMemberAssociations(univerId: string) {
  return (dispatch: any) => {
    dispatch(getMemberAssociationsStart());
    _fetchRequest('GET', 'member_associations', { association_fk_id: univerId })
      .then((res: any) => {
        dispatch({
          type: SET_PLAYERS_WHO_FOLLOW_US,
          payload: res.map((it: any) => it.member_id),
        });
      })
      .catch((error: any) => {
        console.log('error in getMemberAssociations', error);
        dispatch(getMemberAssociationsFailed(error));
      });
  };
}

export function setMemberAssociation(data: any, reqType: string) {
  return (dispatch: any) => {
    const clickedOn = data.association_fk_id + data.association_id;
    dispatch(setMemberAssociationStart(clickedOn));
    updateMemberAssociation(data, reqType)
      // updateMemberAssociation(data, 'POST')
      .then((res: any) => {
        dispatch(setMemberAssociationSuccess(res));
        // TO DO: REMOVE TEST DATA
        const body: any = {
          app_id: 1,
          sport_id: data.sport_id, // ToDo, get from member record
          member_id: data.member_id,
          association_fk_id: data.association_fk_id,
          is_active_YN: data.is_active_YN,
          created_by: data.member_id,
        };
        dispatch(getMemberAssociations(body)); // Referesh member associations list
        dispatch(getPlayerFollowers(data.association_fk_id));
      })
      .catch((error: any) => {
        console.log('error in setMemberAssociation', error);
        dispatch(setMemberAssociationFailed('Sorry, something went wrong, Please try later.'));
        dispatch(commonActions.handleError(error));
      });
  };
}

  export function setOrgFollowed(data:any) {
  return async (dispatch: any) => {
    dispatch(setOrgFollowedStart());
    const payloadData = {
      num_followers: data.num_followers,
    };
    _fetchRequest("PUT", `orgs?org_type=${data.org_type}&org_id=${data.org_id}`,undefined, payloadData)

    .then((res: any) => {
      dispatch(setOrgFollowedSuccess(res));
      dispatch(updateClubFollowed({org_id: data.org_id, num_followers: data?.num_followers}));
    })
    .catch((error: any) => {
      console.log('error in getMemberAssociations', error);
      dispatch(setOrgFollowedFailed(error));
    });
  };
}