import { API_URL } from '../../constants';
import fetchAuthorization from '../fetchAuthorization';

export async function fetchFollow(memberId: string): Promise<any> {
  try {
    const respClubs = await fetchAuthorization(`${API_URL}follows_clubs_universities?member_id=${memberId}`);
    const playerClubs = await respClubs.json();

    const data: any[] = playerClubs.filter((club: any) => club.association_id === 5) || [];
    const playerUniversities = playerClubs.filter((club: any) => club.association_id === 8) || [];
    return { playerClubs: data, playerUniversities: playerUniversities };
  } catch (e: any) {
    throw new Error(`Something went wrong. Error message: ${e.message}`);
  }
}

export default fetchFollow;
