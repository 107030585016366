import { API_URL } from '../constants';
import { HistoricalGame } from '../models/HistoricalGame';
import fetchAuthorization from './fetchAuthorization';

export async function fetchHistoricalGames(teamsIds: string[]): Promise<HistoricalGame[]> {
  try {
    const data = await fetchAuthorization(`${API_URL}games_normalized?team_id=${teamsIds.join(',')}`);

    return await data.json();
  } catch (e: any) {
    throw new Error(`Sorry, something went wrong. Error message: ${e.message}`);
  }
}

export default fetchHistoricalGames;
