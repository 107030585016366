import React, { useState } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Paper, Select, MenuItem } from '@mui/material';

import TShirt from '../TShirt';
import { makeid } from '../../helpers';
import { useStyles } from './styles';
import { positionStates } from '../../constants';
import { updatePlayer, deletePlayer, addPlayers } from '../../apis';

const SelectPosition = (props: any) => {
  const value = props.value === null ? 'goalie' : props.value;
  return (
    <Select id="position" value={value} onChange={(e) => props.onChange(e.target.value)}>
      {positionStates.map((item, i) => (
        <MenuItem value={item.value} key={i}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};

// interface Data {
//   player_id: string;
//   jersey_number: number;
//   first_name: string;
//   last_name: string;
//   position_description: string;
// }

const getPositionLabel = (value: string) => {
  let position = value ? value.toLowerCase() : 'attack'; // default failsafe
  let index = 1;
  index = positionStates.findIndex((x) => x.value === position);
  if (index !== undefined && index > -1) {
    return positionStates[index].label;
  }
};

const columns: Array<any> = [
  {
    title: 'No',
    field: 'jersey_number',
    type: 'numeric',
    headerStyle: {
      textAlign: 'left',
    },
    initialEditValue: '00',
    render: (row: any) => <TShirt textLink={row.jersey_number !== -1 ? row.jersey_number.toString() : 'NA'} />,
  },
  { title: 'First name', field: 'first_name', defaultSort: 'asc' },
  { title: 'Last name', field: 'last_name' },
  {
    title: 'Position',
    field: 'position_description',
    initialEditValue: 'goalie',
    render: (row: any) => getPositionLabel(row.position_description),
    editComponent: (props: any) => <SelectPosition {...props} />,
  },
  { editable: 'never' },
];

const tableOptions: any = {
  actionsColumnIndex: -1,
  maxBodyHeight: 400,
  paging: false,
  icons: {
    Search: null,
    // Add: forwardRef((props) => <AddCircleOutlineIcon {...props} />),
  },
  addRowPosition: 'first',
};

type Props = {
  teamId: string;
  players: Array<any>;
};

const PlayerTableEditable: React.FC<Props> = (props) => {
  const { teamId, players } = props;
  const classes = useStyles();
  const [data, setPlayers] = useState<any>(players);

  const updatePlayerReq = (newData: any) => {
    return new Promise((resolve: any) => {
      updatePlayer(newData)
        .then((res: any) => {
          resolve(true);
        })
        .catch((e: any) => {
          resolve(false);
        });
    });
  };

  const deletePlayerReq = (data: any) => {
    return new Promise((resolve: any) => {
      deletePlayer({ player_id: data.player_id })
        .then((res: any) => {
          resolve(true);
        })
        .catch((e: any) => {
          resolve(false);
        });
    });
  };

  const addPlayerReq = (data: any) => {
    const player: any = {
      team_id: teamId,
      player_id: makeid(8),
      first_name: data.first_name,
      last_name: data.last_name,
      jersey_number: data.jersey_number ? data.jersey_number : '00',
      position_description: data.position_description,
    };

    return new Promise((resolve: any) => {
      addPlayers(player)
        .then((res: any) => {
          resolve(player);
        })
        .catch((e: any) => {
          resolve(false);
        });
    });
  };

  return (
    <div className={classes.root}>
      <MaterialTable
        title=""
        components={{
          Container: (cprops) => <Paper {...cprops} elevation={0} />,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} searchFieldAlignment={'left'} className={classes.tableToolbar} />
            </div>
          ),
        }}
        columns={columns}
        data={data}
        options={tableOptions}
        editable={{
          onRowUpdate: (newData: any, oldData: any) =>
            new Promise(async (resolve: any, reject: any) => {
              const update = await updatePlayerReq(newData);
              if (update) {
                const index: number = data.indexOf(oldData);
                let clonedData = Object.assign([], data);
                clonedData[index] = newData;
                setPlayers(clonedData);
                resolve();
              } else {
                reject();
              }
            }),
          onRowDelete: (oldData) =>
            new Promise(async (resolve: any, reject: any) => {
              const deleteRes = await deletePlayerReq(oldData);
              if (deleteRes) {
                const index: number = data.indexOf(oldData);
                let clonedData = Object.assign([], data);
                clonedData.splice(index, 1);
                setPlayers(clonedData);
                resolve();
              } else {
                reject();
              }
            }),
          onRowAdd: (newData) =>
            new Promise(async (resolve: any, reject: any) => {
              const addedPlayer = await addPlayerReq(newData);
              if (addedPlayer) {
                let clonedData = Object.assign([], data);
                clonedData.push(addedPlayer);
                setPlayers(clonedData);
                resolve();
              } else {
                reject();
              }
            }),
        }}
      />
    </div>
  );
};

export default PlayerTableEditable;
