import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    MuiToolbar: {
      '& root': {
        padding: '0px 0px 0px 0px !important',
        margin: '0px',
      },
      '& MuiToolbar-root': {
        padding: '0px 0px 0px 0px !important',
        margin: '0px',
      },
      '& gutters': {
        paddingLeft: '0px',
      },
    },
    MTableToolbar: {
      '& root': {
        paddingLeft: '0px',
      },
      '& searchField': {
        paddingLeft: '0px',
      },
    },
    // MuiToolbar-root MuiToolbar-regular MTableToolbar-root-893 MuiToolbar-gutters
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    followButton: {
      color: 'transparent',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      padding: 0,
      position: 'relative',
    },
    headCell: {
      lineHeight: 'normal',
      padding: 4,
      verticalAlign: 'bottom',
      '&:first-child': {
        width: 'auto',
        textAlign: 'left',
      },
    },
    activeLabelCell: {
      color: `${theme.palette.primary.main} !important`,
    },
    tableSortLabelRoot: {
      fontSize: 11,
      fontWeight: 'bold',
      alignItems: 'flex-end !important',
      '&:hover': {
        color: `${theme.palette.primary.main} !important`,
        '&& $icon': {
          opacity: 1,
          color: `${theme.palette.primary.main} !important`,
        },
      },
    },
    tableSortLabelIcon: {
      color: `${theme.palette.primary.main} !important`,
      fontSize: '14px !important',
      opacity: '1 !important',
    },
    tableRow: {
      '&:hover': {
        backgroundColor: `#EAEFFB !important`,
      },
    },
    bodyTableCell: {
      lineHeight: 'normal',
      padding: '6px 12px 6px 4px',
      color: theme.palette.primary.main,
      fontSize: 13,
      fontWeight: 'bold',
    },
    mediumWidth: {
      width: 250,
      maxWidth: 250,
    },
    autoWidth: {
      width: 'auto',
      textAlign: 'left',
    },
    bodyTableCellRegular: {
      lineHeight: 'normal',
      padding: '6px 12px 6px 4px',
      fontSize: 13,
    },
    score: {
      backgroundColor: '#EAEFFB',
      borderRadius: '50%',
      height: 30,
      width: 30,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    firstRankScrore: {
      backgroundColor: '#FBDD8C',
    },
    secondRankScrore: {
      backgroundColor: '#E8E8E8',
    },
    thirdRankScrore: {
      backgroundColor: '#F9BA71',
    },
    paginationSpacer: {
      flex: '0 !important',
    },
    paginationRoot: {
      marginTop: 10,
    },
    paginationToolbar: {
      justifyContent: 'space-between !important',
      paddingLeft: '0px !important',
    },
    paginationDisplayRows: {
      flex: "1 !important",
      textAlign: "center !important" as any,
      fontSize:"15px !important"
    },
    paginationSelectLabel: {
      fontSize:"15px !important"
    },
    paginationSelect: {
      border: `1px solid ${theme.palette.primary.main} !important`,
      borderRadius: '9px !important',
      paddingRight: '44px !important',
      fontWeight: 'bold !important',
      '&:focus': {
        borderRadius: '9px !important',
      },
    },
    paginationSelectIcon: {
      right: 5,
      top: 3,
      color: theme.palette.primary.main,
    },
    teamSection: {
      display: 'flex',
      alignItems: 'center',
    },
    teamImage: {
      border: '2px solid #E6E6E6',
      width: 40,
      marginRight: 15,
      borderRadius: 7,
      padding: 2,
    },
    tablePaginationRoot: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    prevIcon: {
      transform: 'rotate(180deg)',
    },
    prevIconButton: {
      padding: 4,
    },
    disablePrevIconButton: {
      opacity: 0.5,
    },
    pageNumbers: {
      color: theme.palette.primary.main,
      fontSize: 14,
      margin: '0 5px',
    },
    bodyTableCellClaim: {
      whiteSpace: 'pre',
    },
    tableToolbar: {
      marginLeft: '-40px',
      paddingLeft: '0px;',
    },
    shirtIcons: {
      backgroundSize: 'contain',
      width: '40px !important',
      height: '40px !important',
      display: 'inline-flex !important',
      marginLeft: '10px',
    },
    claimedText: {
      fontSize: 12,
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
    },
    claimedMe: {
      fontSize: 13,
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
    },
    claimIcon: {
      marginRight: 5,
      fontSize: 20,
    },
    buttonProgress: {
      position: 'absolute',
      backgroundColor: 'rgba(233, 239, 251, 0.7)',
      width: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    disabledImage: {
      opacity: 0.4,
    },
    tableSearchSelect: {
      padding: '12px 0 10px',
    },
    endAlign: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    clubLabel: {
      fontSize: '12px !important',
    },
    optionSelect: {
      color: `${theme.palette.primary.main}`,
      '&:before': {
        display: 'none',
      },
      '& > div': {
        fontSize: 12,
        color: `${theme.palette.primary.main}`,
      },
      '& > svg': {
        color: `${theme.palette.primary.main}`,
        fill: `${theme.palette.primary.main}`,
      },
    },
    sectionHeaderBtn: {
      margin: '0 10px',
    },
    editPlayerIcon: {
      cursor: 'pointer',
    },
  }),
);
