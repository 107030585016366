import { API_URL } from '../../constants';
import Accolade from '../../models/Accolade';
import fetchAuthorization from '../fetchAuthorization';

export async function fetchPlayerAccolades(memberId: string): Promise<Accolade[]> {
  try {
    const data = await fetchAuthorization(`${API_URL}player_accolades?member_id=${memberId}`);

    return await data.json();
  } catch (e: any) {
    throw new Error(`${e.message || 'Sorry, something went wrong!'}`);
  }
}

export default fetchPlayerAccolades;
