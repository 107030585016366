import { OPEN_ERROR_DIALOG, CLOSE_ERROR_DIALOG, OPEN_SUCCESS_DIALOG, CLOSE_SUCCESS_DIALOG } from '../actions/commonActions';

let initialState = {
  errorDialog: false,
  error: null,
  successMsg: null,
  successsDialog: false
};

const commonReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case OPEN_ERROR_DIALOG:
      return { ...state, errorDialog: true, error: action.error };
    case CLOSE_ERROR_DIALOG:
      return { ...state, errorDialog: false };
      case OPEN_SUCCESS_DIALOG:
      return { ...state, successsDialog: true, successMsg: action.message };
    case CLOSE_SUCCESS_DIALOG:
      return { ...state, successsDialog: false };
    default:
      return state;
  }
};

export default commonReducer;
