import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import { getErrorMessage } from '../helpers';

export interface PlayerData {
  row_num?: number;
  team_id: string;
  player_id: string;
  first_name: string;
  last_name: string;
  jersey_number: number;
  position_description: string;
  created_by: string;
  created_datetime: string;
  is_active_yn: number;
}

export async function updatePlayer(data: PlayerData): Promise<any> {
  try {
    const response = await fetchAuthorization(`${API_URL}players?player_id=${data.player_id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (response.status !== 200) {
      const message = getErrorMessage(result);
      throw new Error(message ? message : `Status Code: ${response.status}. Sorry, something went wrong. Please try again later.`);
    }

    return result;
  } catch (e: any) {
    throw new Error(`Unable to update player. ${e.message}`);
  }
}

export default updatePlayer;
