import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import qs from 'qs';
import _ from 'lodash';
import { ClubStatistics } from '../models/ClubStatistics';

type FetchClubsOptions = {
  sport_id: 1;
};

export async function fetchClubsStatistics(opts: FetchClubsOptions): Promise<ClubStatistics[]> {
  try {
    const query = qs.stringify({
      sport_id: opts.sport_id,
    });

    const data = await fetchAuthorization(`${API_URL}club_records?${query}`);

    const clubsStatistics = await data.json();
    const sortedClubsStatistics = _.orderBy(clubsStatistics, [(club) => club.ranking]);

    return sortedClubsStatistics;
  } catch (e: any) {
    throw new Error(`${e.message || 'Something went wrong!'}`);
  }
}

export default fetchClubsStatistics;
