import { FETCH_VIDEOS_REQ_START, FETCH_ALL_VIDEOS_RATING, FETCH_HIGHEST_RATING, FETCH_MOSTVOTES_RATING, FETCH_RECENT_RATING, FETCH_VIDEOS_RATING } from '../actions/videosActions';

let initialState = {
  isLoadingVideos: false,
  videos: [],
  allVideosRating: [],
  highestRating: [],
  mostVotes: [],
  mostRecent: [],
};

const videosReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_VIDEOS_REQ_START:
      return { ...state, isLoadingVideos: true };
    case FETCH_ALL_VIDEOS_RATING:
      return { ...state, allVideosRating: action.data, isLoadingVideos: false };
    case FETCH_VIDEOS_RATING:
      return { ...state, videosRating: action.data, isLoadingVideos: false };
    case FETCH_HIGHEST_RATING:
      const array = state.highestRating.concat(action.data);
      return { ...state, highestRating: array, isLoadingVideos: false };
    case FETCH_MOSTVOTES_RATING:
      const data = state.mostVotes.concat(action.data);
      return { ...state, mostVotes: data, isLoadingVideos: false };
    case FETCH_RECENT_RATING:
      const existing = state.mostRecent.concat(action.data);
      return { ...state, mostRecent: existing, isLoadingVideos: false };
    default:
      return state;
  }
};

export default videosReducer;
