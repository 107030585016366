import { UPDATE_CLUB, UPDATE_TEAM, UPDATE_PLAYER, FETCH_GET_CLUB, ADD_TEAM_REQ_START, ADD_TEAM_REQ_SUCCESS, ADD_TEAM_REQ_FAILED } from '../actions/memberOnboardingActions';

let initialState = {
  org_id: null,
  team_id: null,
  player_id: null,
  clubs: [],
  teams: [],
  players: [],
  selectedClub: null,
  isLoadingAddingTeam: false,
  addingClubError: null,
};

const memberOnboardingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_CLUB:
      return { ...state, org_id: action.data };
    case UPDATE_TEAM:
      return { ...state, team_id: action.data };
    case UPDATE_PLAYER:
      return { ...state, player_id: action.data };
    case FETCH_GET_CLUB:
      return { ...state, selectedClub: action.payload };
    case ADD_TEAM_REQ_START:
      return { ...state, isLoadingAddingTeam: true };
    case ADD_TEAM_REQ_SUCCESS:
      return { ...state, isLoadingAddingTeam: false };
    case ADD_TEAM_REQ_FAILED:
      return {
        ...state,
        isLoadingAddingTeam: false,
        addingClubError: action.error,
      };
    default:
      return state;
  }
};

export default memberOnboardingReducer;
