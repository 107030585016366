import React, { useState } from 'react';
import { useInputValue } from '../../hooks';
import { Auth } from 'aws-amplify';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import ErrorBlock from '../ErrorBlock';
import ConstrainedWidth from '../ConstrainedWidth';
import { Button, TextField } from '@mui/material';
import { validateEmail } from '../../helpers';

interface Props {
  endLoading: () => void;
  startLoading: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    marginBottom: theme.spacing(4),
  },
  input: {
    marginBottom: `${theme.spacing(4)} !important`,
    marginRight: `${theme.spacing(4)} !important`,
    width: 375,
  },
  root: {
    textAlign: 'center',
  },
  button: {
    paddingTop: '14px !important',
    paddingBottom: '14px !important',
    paddingRight: '25px !important',
    paddingLeft: '25px !important',
    borderRadius: '9px !important'
  },
}));

const LoginForm = ({ endLoading, startLoading }: Props) => {
  const classes = useStyles();

  const [error, setError] = useState<Error | undefined>(undefined);
  const { inputValue: email, changeInput: setEmail } = useInputValue('');
  const { inputValue: password, changeInput: setPassword } = useInputValue('');

  const onSignIn = () => {
    if (!email) {
      setError(new Error('An email address is required.'));
      return;
    }
    if (!password) {
      setError(new Error('A password is required.'));
      return;
    }
    if (!validateEmail(email)) {
      setError(
        new Error(
          'That is not a valid email. Please enter your correct email.',
        ),
      );
      return;
    }

    // Clear out the previous error
    setError(undefined);
    startLoading();

    Auth.signIn(email, password)
      .then(() => endLoading())
      .catch((err) => {
        setError(err);
        endLoading();
      });
  };

  return (
    <ConstrainedWidth>
      <form className={classes.root}>
        {error && (
          <ErrorBlock
            className={classes.error}
            error={
              error.message === 'User is not confirmed.'
                ? 'This user has not confirmed their this email address yet. Check your inbox for verification.'
                : error
            }
          />
        )}

        <TextField
          className={classes.input}
          error={!!error}
          id="email"
          InputLabelProps={{
            shrink: true,
          }}
          label="Email Address"
          onChange={setEmail}
          required
          value={email}
          variant='standard'
        />

        <br />
        <TextField
          className={classes.input}
          error={!!error}
          id="password"
          InputLabelProps={{
            shrink: true,
          }}
          label="Password"
          onChange={setPassword}
          required
          type="password"
          value={password}
          variant='standard'
        />
        <br />
        <Button
          color="primary"
          className={classes.button}
          onClick={onSignIn}
          variant="contained"
        >
          Sign In
        </Button>
      </form>
    </ConstrainedWidth>
  );
};

export default LoginForm;
