import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import qs from 'qs';

export interface Player {
  row_num: number;
  player_id: string;
  team_id: string;
  org_id: string;
  first_name: string;
  last_name: string;
  jersey_number: number;
  position_description: string;
  sport_id?: number;
  json_player_stats?: any;
  is_active_YN: number;
}

type PlayerSummaryStats = {
  team_id?: string | undefined;
  player_id?: string | undefined;
  org_id?: string | undefined;
  sport_id?: number | undefined;
};

export async function fetchPlayerSummaryStats(opts: PlayerSummaryStats): Promise<Player[]> {
  try {
    const query = qs.stringify({
      team_id: opts.team_id,
      player_id: opts.player_id,
      org_id: opts.org_id,
      sport_id: opts.sport_id,
    });

    const response = await fetchAuthorization(`${API_URL}player_summary_stats?${query}`);

    const result = await response.json();

    return result;
  } catch (e: any) {
    throw new Error(`Something went wrong. Error message: ${e.message}`);
  }
}

export default fetchPlayerSummaryStats;
