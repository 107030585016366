import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import 'date-fns';
import { ThemeProvider, TextField } from '@mui/material';
import createTheme from '@mui/material/styles/createTheme';

interface Props {
  name?: string;
  value: string | Date;
  format?: string;
  styles?: {};
  variant?: 'dialog' | 'inline' | 'static';
  autoFocus?: boolean;
  placeholder?: string;
  disableToolbar?: boolean;
  fontSize?: number;
  onChange: (date: any) => void;
  disabled?: boolean;
  fullWidth?: boolean;
}

const DateInput: React.FC<Props> = ({ name, value, fullWidth, format, autoFocus, fontSize, placeholder, disableToolbar, onChange, disabled }) => {
  const theme = createTheme({
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: 0,
            padding: 0,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            padding: 0,
            paddingTop: 2,
            fontFamily: 'Open Sans, sans-serif',

            fontSize: fontSize ? fontSize : '18px',
            fontWeight: 400,
            letterSpacing: '-0.45px',
            lineHeight: '28.62px',
          },
          multiline: {
            padding: 0,
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            padding: 0,
            paddingTop: 3,
            fontFamily: 'Open Sans, sans-serif',
            fontSize: '20px',
            fontWeight: 400,
            letterSpacing: '-0.45px',
            lineHeight: '28.62px',

            inputMarginDense: {
              padding: '6px 5px 7px !important',
            },
          },
          input: {
            padding: 0,
            boxSizing: 'inherit',
          },
        },
      },
      MuiInputAdornment:{
        styleOverrides: {
          root:{
            // padding: "12px 16px",
            padding: "12px 12px 12px 1px"
          }
        }
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker value={value} onChange={onChange} inputFormat={format} autoFocus={autoFocus} disabled={disabled} label={name} renderInput={(params) => <TextField variant="standard" {...params} fullWidth={fullWidth} placeholder={placeholder} />} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default DateInput;
