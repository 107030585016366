import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Alert from '@mui/lab/Alert';
import { TextField, Button, Grid, DialogActions, CircularProgress } from '@mui/material';

import { useStyles } from './styles';
import SearchInput from './SearchInput';
import { positionStates } from '../../constants';
import MaterialuiSelect from '../Select/MaterialuiSelect';
import * as teamActions from '../../redux/actions/teamActions';
import * as playerActions from '../../redux/actions/playerActions';
import { makeid } from '../../helpers';

type Props = {
  player: any;
  dispatchAddPlayers?: any;
  dispatchGetTeams?: any;
  onClose: () => void;
  onSubmitPlayerSuccess?: any;
};

const PlayerForm: React.FC<Props> = (props) => {
  const { player, dispatchAddPlayers, dispatchGetTeams, onClose, onSubmitPlayerSuccess } = props;
  const { isLoaddingAddPlayer } = player;
  const classes: any = useStyles();
  const [jerseyNo, setJerseyNo] = useState<any>('00');
  const [fname, setFName] = useState<string>('');
  const [lname, setLName] = useState<string>('');
  // const [gender, setSportId] = useState<any>(sportId);
  const [position, setPosition] = useState<any>('position');
  const [clubId, setClubId] = useState('');
  const [teams, setTeams] = useState<Array<any>>([]);
  const [selectTeams, setSelectTeams] = useState<Array<any>>([]);
  const [teamId, setTeamId] = useState('');
  const [success, setSuccess] = useState<string>('');
  
  useEffect(() => {
    const data: any = {};
    if (clubId !== '') {
      data.parent_team_id = clubId;

      const responseHandler = (res: Array<any>) => {
        if (res && res.length) {
          setTeams(res);
        }
      };
      dispatchGetTeams(data, responseHandler);
    }
  }, [clubId, dispatchGetTeams]);

  useEffect(() => {
    if (teams.length !== 0) {
      let selectTeamsArray: Array<any> = [];
      for (const team of teams) {
        selectTeamsArray.push({
          label: `${team.team_name} ${team.team_year}`,
          value: team.team_id,
        });
      }
      setSelectTeams(selectTeamsArray);
    }
  }, [teams]);

  const resetPlayer = () => {
    setJerseyNo('00');
    setFName('');
    setLName('');
    // setSportId(sportId);
    setPosition('position');
  };

  const resetState = () => {
    setClubId('');
    setTeamId('');
    resetPlayer();
  };

  const onSelectClub = (item: any) => {
    setClubId(item.fk_id);
  };

  const onSelectTeam = (event: any) => {
    setTeamId(event.target.value);
  };

  const capitalize = (str: string) => {
    return str.substr(0, 1).toUpperCase() + str.substr(1, str.length);
  };

  const onSubmitPlayer = () => {
    const playerId = makeid(8);

    const player: any = {
      team_id: teamId,
      player_id: playerId,
      first_name: fname,
      last_name: lname,
      jersey_number: jerseyNo,
      position_description: capitalize(position),
      active_YN: 1,
      createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
    };

    const responseHandler = (success: boolean) => {
      if (success) {
        onSubmitPlayerSuccess(playerId);
        // resetPlayer();
        // resetState();
        setSuccess('Player has been added successfully.');
      }
    };
    dispatchAddPlayers(player, responseHandler);
  };

  const handleClose = () => {
    onClose();
  };

  /* Check state */
  const isTeamSelectionVisible = () => {
    return (clubId !== '' && clubId !== undefined && clubId !== null) || success !== '' || isLoaddingAddPlayer;
  };

  const isPlayerInputVisible = () => {
    return (clubId !== '' && teamId !== '' && teamId !== undefined && teamId !== null) || success !== '' || isLoaddingAddPlayer;
  };

  const isSubmitDisabled = () => {
    if (fname === '' || lname === '' || teamId === '' || isLoaddingAddPlayer) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className={classes.popupForm}>
        <div className={classes.popupFormSearchInputWrapper}>
          <SearchInput keyType="org_id" placeholder="Search for a club" label="Club" onSelect={onSelectClub} onReset={resetState} />
        </div>
        {isTeamSelectionVisible() && (
          <div className={`${classes.popupFormSelect}`}>
            <label>Team</label>
            <MaterialuiSelect id="team" value={teamId} placeholder="Select a division/year" onValueChange={onSelectTeam} data={selectTeams} />
          </div>
        )}
        {isPlayerInputVisible() && (
          <div className={classes.popupFormSearchInputWrapper}>
            <Grid container className={classes.nameWithNumGrid}>
              <Grid item xs={2}>
                <TextField
                  label="No."
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="number"
                  className={`${classes.popupFormInput} ${classes.numberInput} ${classes.overrideMargin} ${classes.removeBoxShadow}`}
                  value={jerseyNo}
                  onChange={(e) => setJerseyNo(e.target.value)}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="First Name"
                  placeholder="Enter first name"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={fname}
                  className={`${classes.popupFormInput} ${classes.overrideMargin} ${classes.removeBoxShadow}`}
                  onChange={(e) => setFName(e.target.value)}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Last Name"
                  placeholder="Enter last name"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={lname}
                  className={`${classes.popupFormInput} ${classes.overrideMargin} ${classes.removeBoxShadow}`}
                  onChange={(e) => setLName(e.target.value)}
                />
              </Grid>
            </Grid>
            <div className={`${classes.popupFormSelect} ${classes.popupFormSelectTwo}`} style={{ margin: '0 0 5px 0' }}>
              <label>Position</label>
              <MaterialuiSelect id="position" value={position} onValueChange={(e) => setPosition(e.target.value)} data={positionStates} />
            </div>
          </div>
        )}
        {/* {isPlayerInputVisible() && (
          <div className={classes.popupFormSearchInputWrapper}>
            <Grid container className={classes.nameWithNumGrid}>
              <Grid item xs={2}>
                <TextField
                  label="No."
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="number"
                  className={`${classes.popupFormInput} ${classes.numberInput} ${classes.overrideMargin}`}
                  value={jerseyNo}
                  onChange={(e) => setJerseyNo(e.target.value)}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="First Name"
                  placeholder="Enter first name"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={fname}
                  className={`${classes.popupFormInput} ${classes.overrideMargin}`}
                  onChange={(e) => setFName(e.target.value)}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Last Name"
                  placeholder="Enter last name"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={lname}
                  className={`${classes.popupFormInput} ${classes.overrideMargin}`}
                  onChange={(e) => setLName(e.target.value)}
                />
              </Grid>
            </Grid>
            <div className={`${classes.popupFormSelect} ${classes.popupFormSelectTwo}`} style={{ margin: '20px 0 0' }}>
              <label>Position</label>
              <MaterialuiSelect id="position" value={position} onValueChange={(e) => setPosition(e.target.value)} data={positionStates} />
            </div>
          </div>
        )} */}

        <DialogActions className={classes.playerDialogActions}>
          {success === '' && (
            <Button variant="contained" color="primary" onClick={onSubmitPlayer} className={classes.playerCSVSubmit} disabled={isSubmitDisabled()}>
              {!isLoaddingAddPlayer ? 'Submit' : <CircularProgress size={22} className={classes.buttonProgress} />}
            </Button>
          )}
          {success !== '' && (
            <Alert className={classes.alerts} onClose={() => setSuccess('')} style={{ width: '100%' }}>
              {success}
            </Alert>
          )}
        </DialogActions>
        {success !== '' && (
          <Button variant="contained" color="primary" onClick={handleClose} className={classes.smallButton}>
            Done
          </Button>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  player: state.playerReducer,
});

const mapDispatchToProps = {
  dispatchAddPlayers: (data: any, callback: any) => playerActions.addPlayersRequest(data, callback),
  dispatchGetTeams: (data: any, callback: any) => teamActions.getTeamsForModal(data, callback),
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerForm);
