import React, { useState } from 'react';
import { useInputValue } from '../../hooks';
import { Auth } from 'aws-amplify';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import ErrorBlock from '../ErrorBlock';
import ConstrainedWidth from '../ConstrainedWidth';
import { Grid, Button, Link, TextField, IconButton, InputAdornment } from '@mui/material';
import { InfoDialog } from '../Common';
import { validateEmail, validatePassword } from '../../helpers';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    marginBottom: theme.spacing(4),
  },
  input: {
    marginBottom: `${theme.spacing(4)} !important`,
    marginRight: `${theme.spacing(4)} !important`,
    width: 375,
    color: "#1C315F",
  },
  inputConfirmPassword: {
    marginBottom: `${theme.spacing(4)} !important`,
    marginRight: `${theme.spacing(4)} !important`,
    width: 170,
  },
  root: {
    textAlign: 'center',
  },
  button: {
    paddingTop: '14px !important',
    paddingBottom: '14px !important',
    paddingRight: '25px !important',
    paddingLeft: ' 25px !important',
    borderRadius: '9px !important',
  },
  buttonEnabled: {
    backgroundColor: '#1C315F',
  },
  buttonDisabled: {
    backgroundColor: '#CCCBCB',
  },
}));

const SignupForm: React.FC<{ success: () => void }> = (props) => {
  const classes = useStyles();

  const [pageState] = useState<string>('request');
  const [error, setError] = useState<Error | undefined>(undefined);
  const [isOpenVerifyPopup, setIsOpenVerifyPopup] = useState<boolean>(false);
  const { inputValue: code, changeInput: setCode } = useInputValue('');
  const { inputValue: email, changeInput: setEmail } = useInputValue('');
  const { inputValue: password, changeInput: setPassword } = useInputValue('');
  const { inputValue: confirmPassword, changeInput: setConfirmPassword } = useInputValue('');
  const { inputValue: first_name, changeInput: setFirstName } = useInputValue('');
  const { inputValue: last_name, changeInput: setLastName } = useInputValue('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const signupRequest = () => {
    Auth.signUp({
      username: email.trim(),
      password,
      attributes: {
        given_name: first_name.trim(),
        family_name: last_name.trim(),
      },
    })
      .then((data) => {
        console.log(data);
        setIsOpenVerifyPopup(true);
        // props.success();
      })
      .catch((err) => {
        setError(new Error(err.message));
      });
  };

  const confirmCode = () => {
    Auth.confirmSignUp(email, code, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true,
    })
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };

  const resendCode = () => {
    Auth.resendSignUp(email)
      .then(() => {
        console.log('code resent successfully');
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSignUp = () => {
    if (!first_name || !first_name.trim()) {
      setError(new Error('First Name is required.'));
      return;
    }
    if (!last_name || !last_name.trim()) {
      setError(new Error('Last Name is required.'));
      return;
    }
    if (!email) {
      setError(new Error('Email Address is required.'));
      return;
    }
    if (!validateEmail(email)) {
      setError(new Error('That is not a valid email. Please enter your correct email.'));
      return;
    }
    if (!password) {
      setError(new Error('Password is required.'));
      return;
    }
    if (!validatePassword(password)) {
      setError(new Error('Your password must have a length >= to 8 characters.'));
      return;
    }
    if (password !== confirmPassword) {
      setError(new Error('Passwords do not match.'));
      return;
    }
    // Clear out the previous error
    setError(undefined);
    signupRequest()
    // setIsOpenVerifyPopup(true);
  };

  const onCloseVerifyPopup = () => setIsOpenVerifyPopup(false);
  const onConfirmVerifyPopup = () => {
    // signupRequest();
    setIsOpenVerifyPopup(false);
    props.success();
  };

  const isFormValid = () => {
    return first_name.trim() && last_name.trim() && validateEmail(email) && validatePassword(password) && password === confirmPassword;
  };

  return (
    <ConstrainedWidth>
      {pageState === 'request' ? (
        <form className={classes.root}>
          {error && <ErrorBlock className={classes.error} error={error} />}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                className={classes.input}
                error={!!error}
                id="first_name"
                InputLabelProps={{
                  shrink: true,
                }}
                label="First Name"
                placeholder="Type your first name here"
                onChange={setFirstName}
                required
                type="text"
                value={first_name}
                variant="standard"
              />
              <br />

              <TextField
                className={classes.input}
                error={!!error}
                id="last_name"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Last Name *"
                placeholder="Type your last name here"
                onChange={setLastName}
                type="text"
                value={last_name}
                variant="standard"
              />

              <br />
            </Grid>

            <Grid item xs={6}>
              <TextField
                className={classes.input}
                error={!!error}
                id="email"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Email Address"
                placeholder="Type your email here"
                onChange={setEmail}
                required
                value={email}
                variant="standard"
              />
              <br />

              <TextField
                className={classes.inputConfirmPassword}
                error={!!error}
                id="password"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Password (8 char min)"
                onChange={setPassword}
                required
                type={showPassword ? 'text' : 'password'}
                value={password}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                className={classes.inputConfirmPassword}
                error={!!error}
                id="confirmPassword"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Confirm Password"
                onChange={setConfirmPassword}
                required
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword} onMouseDown={handleMouseDownPassword}>
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <br />
            </Grid>
          </Grid>
          <div>
            <Typography variant="h2" color="primary" gutterBottom>
              Players should use THEIR email address and not their parents!
            </Typography>
            <Typography variant="h3" color="textSecondary" gutterBottom>
              Why? Because players game records and statistics are linked through time using the player's unique email.
            </Typography>
            <br></br>
          </div>
          <Button color="primary" className={`${classes.button} ${isFormValid() ? classes.buttonEnabled : classes.buttonDisabled}`} onClick={onSignUp} variant="contained" disabled={!isFormValid()}>
            Register Me!
          </Button>
          <br></br>
          <br></br>
          <Typography variant="h3" color="#1C315F" gutterBottom>
            All fields are required to enable the "Register Me!" functionality.
          </Typography>
          <br></br>
        </form>
      ) : (
        <form className={classes.root}>
          {error && <ErrorBlock className={classes.error} error={error} />}
          <TextField
            className={classes.input}
            error={!!error}
            id="code"
            InputLabelProps={{
              shrink: true,
            }}
            label="Confirmation Code"
            onChange={setCode}
            required
            value={code}
            variant="standard"
          />

          <br />
          <br />

          <Button
            color="primary"
            onClick={() => {
              if (!email) {
                setError(new Error('Confirmation Code is required.'));
                return;
              }

              // Clear out the previous error
              setError(undefined);

              confirmCode();
            }}
            variant="contained"
          >
            Confirm Code
          </Button>

          <br />
          <br />

          <Link onClick={() => resendCode()}>Resend Code</Link>

          <br />
          <br />
        </form>
      )}
      <InfoDialog
        title="Next Step: Check your inbox for a confirmation email"
        isOpen={isOpenVerifyPopup}
        textMessage="You should have just received an email with a Verify My Email link . This makes it so that no one uses someone else's identity.
                After verifying the link, you will login on the next page."
        onClose={onCloseVerifyPopup}
        onConfirm={onConfirmVerifyPopup}
      />
    </ConstrainedWidth>
  );
};

export default SignupForm;
