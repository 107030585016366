import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// @ts-ignore
import CSVReader from 'react-csv-reader';
import Alert from '@mui/lab/Alert';
import GetAppIcon from '@mui/icons-material/GetApp';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Publish from '@mui/icons-material/Publish';
import {
  Button,
  Box,
  // DialogActions,
  CircularProgress,
} from '@mui/material';

import { useStyles } from './styles';
import ErrorBlock from '../ErrorBlock';
import SearchInput from './SearchInput';
import { fetchPlayers } from '../../apis';
import MaterialuiSelect from '../Select/MaterialuiSelect';
import * as teamActions from '../../redux/actions/teamActions';
import * as playerActions from '../../redux/actions/playerActions';
import { validateCSVData, convertToValidPlayerJSON } from '../../helpers/player';

const parseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: string) => header.toLowerCase().replace(/\W/g, '_'),
};

type Props = {
  player: any;
  onClose: () => void;
  onClickPlayerRoster: (teamId: string) => void;
  dispatchAddPlayers?: any;
  dispatchGetTeams?: any;
};

const PlayerByCSV: React.FC<Props> = (props) => {
  const { player, dispatchAddPlayers, dispatchGetTeams, onClickPlayerRoster } = props;
  const { isLoaddingAddPlayer } = player;
  const classes = useStyles();
  const [players, setPlayers] = useState([]);
  const [clubId, setClubId] = useState('');
  const [selectTeams, setSelectTeams] = useState<Array<any>>([]);
  const [teams, setTeams] = useState<Array<any>>([]);
  const [teamId, setTeamId] = useState('');
  const [file, setFile] = useState<any>(null);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [theInputKey, setInputKey] = useState<any>('');
  const [showReviewButton, setShowReviewButton] = useState<boolean>(false);
  const [playersCount, setPlayersCount] = useState<number>(0);

  useEffect(() => {
    const data: any = {};
    if (clubId !== '') {
      data.parent_team_id = clubId;

      const responseHandler = (res: Array<any>) => {
        if (res && res.length) {
          setTeams(res);
        }
      };
      dispatchGetTeams(data, responseHandler);
    }
  }, [clubId, dispatchGetTeams]);

  useEffect(() => {
    if (teams.length !== 0) {
      let selectTeamsArray: Array<any> = [];
      for (const team of teams) {
        selectTeamsArray.push({
          label: `${team.team_name} ${team.team_year}`,
          value: team.team_id,
        });
      }
      setSelectTeams(selectTeamsArray);
    }
  }, [teams]);

  /* Handle the csv select and load players from csv */
  const handleForce = (data: any, fileInfo: any) => {
    const totalItems = data.length; // Total Records
    const firstItem = data.length ? [data[0]] : []; //Check only first record for validate.
    if (data && data.length && totalItems <= 1000 && validateCSVData(firstItem)) {
      // console.dir(data);
      setPlayers(data);
      setFile(fileInfo);
      setError('');
    } else {
      resetFileInput();
      setError(`Invalid CSV file. Max 1000 rows, and must be valid entries.  Please use the Player Roster CSV.`);
    }
  };

  /* Reset file state and rerender file input for select files again. */
  const resetFileInput = () => {
    setFile(null);
    const randomString = Math.random().toString(36);
    setInputKey(randomString);
  };

  const resetState = () => {
    resetFileInput();
    setPlayers([]); // Empty the players records
    setError(''); // Hide error
  };

  const saveHandler = () => {
    const responseHandler = (success: boolean) => {
      resetState();
      if (success) {
        setShowReviewButton(true);
        setSuccess('Players have been added successfully.');
      }
    };
    const records: Array<any> = convertToValidPlayerJSON(players, teamId);
    dispatchAddPlayers(records, responseHandler);
  };

  const onSelectClub = (item: any) => {
    setClubId(item.fk_id);
  };

  const onSelectTeam = (event: any) => {
    const selectedTeamId = event.target.value;
    setTeamId(selectedTeamId);
    getPlayers(selectedTeamId);
    /* below is temp code to check without adding players */
    // setShowReviewButton(true);
    // resetState();
    // setSuccess('Players have been added successfully.');
  };

  const getPlayers = (teamId: string) => {
    const body: any = {
      team_id: teamId,
    };
    fetchPlayers(body)
      .then((res: any) => {
        if (res && res.length) {
          setPlayersCount(res.length);
        } else {
          setPlayersCount(res.length);
        }
      })
      .catch((e: any) => {
        setPlayersCount(0);
      });
  };

  const openReview = () => {
    onClickPlayerRoster(teamId);
  };

  /* Check state */
  const isDownloadCsvPrimaryState = () => {
    return isSubmitDisabled() && !showReviewButton && !isLoaddingAddPlayer;
  };

  const isSelectCsvPrimaryState = () => {
    return isSubmitDisabled() && !showReviewButton && !isLoaddingAddPlayer && teamId !== '';
  };

  const isTeamSelectionVisible = () => {
    return clubId !== '' && clubId !== undefined && clubId !== null;
  };

  const isSubmitDisabled = () => {
    return error !== '' || isLoaddingAddPlayer || players.length === 0 || teamId === '';
  };

  return (
    <>
      <Box className={classes.csvSelectSection}>
        <Button variant="contained" className={isDownloadCsvPrimaryState() ? classes.downloadButton : classes.csvButton} color={isDownloadCsvPrimaryState() ? 'primary' : 'inherit'} disableElevation={isDownloadCsvPrimaryState() ? false : true} startIcon={<GetAppIcon />} href="/sample-players.csv" download>
          Download Player Roster CSV
        </Button>

        <div className={classes.playerCSVFormControl}>
          <SearchInput keyType="org_id" placeholder="Search for a club" label="Club" onSelect={onSelectClub} />
        </div>
        {isTeamSelectionVisible() === true && (
          <div className={`${classes.popupFormSelect} ${classes.playerCSVFormControl}`}>
            <label>Team</label>
            <MaterialuiSelect id="team" value={teamId} placeholder="Select division / year" onValueChange={onSelectTeam} data={selectTeams} />
          </div>
        )}
        <div key={theInputKey || ''} style={{ margin: '10px 0' }}>
          <Button variant="contained" component="label" className={classes.csvButton} disabled={teamId === '' ? true : false} disableElevation={isSelectCsvPrimaryState() ? false : true} startIcon={<InsertDriveFileOutlinedIcon />}>
            <span>Select Player Roster CSV File</span>
            <CSVReader onFileLoaded={handleForce} parserOptions={parseOptions} />
          </Button>
        </div>
        {file !== null && (
          <div>
            <h4 className={classes.fileSelected}>
              File Selected: <span className={classes.fileName}>{file !== null ? file.name : ''}</span>
            </h4>
          </div>
        )}
        {error !== '' && <ErrorBlock className={classes.alerts} error={error} />}
      </Box>
      {playersCount > 0 && success === '' && (
        <Alert severity="warning" className={classes.alerts}>
          There are {playersCount} players already associated with this team. Do you want to continue adding players?
        </Alert>
      )}
      {success !== '' && <Alert className={classes.alerts}>{success}</Alert>}
      {/* <DialogActions className={classes.playerCSVDialogActions}> */}
      {!showReviewButton && (
        <Button variant="contained" color="primary" onClick={saveHandler} disabled={isSubmitDisabled()} className={classes.playerCSVSubmit} startIcon={<Publish />}>
          {!isLoaddingAddPlayer ? 'Submit' : <CircularProgress size={22} className={classes.buttonProgress} />}
        </Button>
      )}
      {showReviewButton && (
        <Button variant="contained" color="primary" onClick={openReview} className={classes.playerCSVSubmit}>
          Review Player Roster
        </Button>
      )}
      {/* </DialogActions> */}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  player: state.playerReducer,
});

const mapDispatchToProps = {
  dispatchAddPlayers: (data: any, callback: any) => playerActions.addPlayersRequest(data, callback),
  dispatchGetTeams: (data: any, callback: any) => teamActions.getTeamsForModal(data, callback),
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerByCSV);
