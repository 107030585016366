import { fetchTeamRankings, fetchTeamStats, fetchTeamsv2, fetchTeamsFollowed, addTeam as addTeamReq, fetchTeamSummaryStats } from '../../apis';
import * as commonActions from './commonActions';
import { processTeamRankings, processTeamGameStats } from '../../helpers';
import { fetchHistoricalGames } from '../../apis/fetchHistoricalGames';
import { HistoricalGame } from '../../models/HistoricalGame';
import { ADD_TEAM_REQ_FAILED, ADD_TEAM_REQ_START, ADD_TEAM_REQ_SUCCESS, FETCH_DELETE_MEMBER_TEAM_ASSOCIATIONS_SUCCESS, FETCH_FOLLOWED_TEAMS_REQ_FAILED, FETCH_FOLLOWED_TEAMS_REQ_START, FETCH_FOLLOWED_TEAMS_REQ_SUCCESS, FETCH_GET_ORGS_FAILED, FETCH_GET_ORGS_STARTED, FETCH_GET_ORGS_SUCCESS, FETCH_GET_ORG_SUCCESS, FETCH_HISTORICAL_GAMES_FAILED, FETCH_HISTORICAL_GAMES_STARTED, FETCH_HISTORICAL_GAMES_SUCCESS, FETCH_MEMBER_TEAM_ASSOCIATIONS_FAILED, FETCH_MEMBER_TEAM_ASSOCIATIONS_STARTED, FETCH_MEMBER_TEAM_ASSOCIATIONS_SUCCESS, FETCH_POST_MEMBER_TEAM_ASSOCIATIONS_SUCCESS, FETCH_PUT_MEMBER_TEAM_ASSOCIATIONS_SUCCESS, FETCH_PUT_TEAM_SUCCESS, FETCH_TEAMS_PLAYERS_STATS_REQ_FAILED, FETCH_TEAMS_PLAYERS_STATS_REQ_START, FETCH_TEAMS_PLAYERS_STATS_REQ_SUCCESS, FETCH_TEAMS_REQ_FAILED, FETCH_TEAMS_REQ_START, FETCH_TEAMS_REQ_SUCCESS, FETCH_TEAM_GAMES_REQ_FAILED, FETCH_TEAM_GAMES_REQ_START, FETCH_TEAM_GAMES_REQ_SUCCESS, FETCH_TEAM_RANKINGS_REQ_FAILED, FETCH_TEAM_RANKINGS_REQ_START, FETCH_TEAM_RANKINGS_REQ_SUCCESS, FETCH_TEAM_STATS_REQ_FAILED, FETCH_TEAM_STATS_REQ_START, FETCH_TEAM_STATS_REQ_SUCCESS, FETCH_TEAM_SUMMARY_STATS_REQ_FAILED, FETCH_TEAM_SUMMARY_STATS_REQ_START, FETCH_TEAM_SUMMARY_STATS_REQ_SUCCESS } from '../action-types/teamActionTypes';
import { _fetchRequest } from '../../apis/_fetchRequest';
import { MemberTeamAssociation } from '../../models/MemberTeamAssociation';
import { Organization } from '../../models/Organization';
import fetchTeamsPlayerStats, { TeamsPlayerStats } from '../../apis/fetchTeamPlayerStats';

// Teams
export const getTeamsStart = () => ({
  type: FETCH_TEAMS_REQ_START,
});


export const getTeamsSuccess = (data: Array<any>) => ({
  type: FETCH_TEAMS_REQ_SUCCESS,
  data,
});

export const getTeamsFailed = (error: any) => ({
  type: FETCH_TEAMS_REQ_FAILED,
  error,
});

//Add Team
export const addTeamStart = () => ({
  type: ADD_TEAM_REQ_START,
});

export const addTeamFailed = (error: any) => ({
  type: ADD_TEAM_REQ_FAILED,
  error,
});

export const addTeamSuccess = () => ({
  type: ADD_TEAM_REQ_SUCCESS,
});

// Team Rankings
export const getTeamRankingsStart = () => ({
  type: FETCH_TEAM_RANKINGS_REQ_START,
});

export const updateTeamRankings = (data: any) => ({
  type: FETCH_TEAM_RANKINGS_REQ_SUCCESS,
  data,
});

export const getTeamRankingsFailed = (error: any) => ({
  type: FETCH_TEAM_RANKINGS_REQ_FAILED,
  error,
});

// Team Stats (Data Lab)
export const getTeamStatsStart = () => ({
  type: FETCH_TEAM_STATS_REQ_START,
});

export const updateTeamStats = (data: any) => ({
  type: FETCH_TEAM_STATS_REQ_SUCCESS,
  data,
});

export const getTeamStatsFailed = (error: any) => ({
  type: FETCH_TEAM_STATS_REQ_FAILED,
  error,
});

// Team Game-Level Stats (Team Scores)
export const getTeamGamesStart = () => ({
  type: FETCH_TEAM_GAMES_REQ_START,
});

export const updateTeamGames = (data: any) => ({
  type: FETCH_TEAM_GAMES_REQ_SUCCESS,
  data,
});

export const getTeamGamesFailed = (error: any) => ({
  type: FETCH_TEAM_GAMES_REQ_FAILED,
  error,
});

export const getFollowedTeamsStart = () => ({
  type: FETCH_FOLLOWED_TEAMS_REQ_START,
});

export const getFollowedTeamsSuccess = (data: any) => ({
  type: FETCH_FOLLOWED_TEAMS_REQ_SUCCESS,
  data,
});

export const getFollowedTeamsFailed = (error: any) => ({
  type: FETCH_FOLLOWED_TEAMS_REQ_FAILED,
  error,
});

//getTeamSummaryStatsStart
export const getTeamSummaryStatsStart = () => ({
  type: FETCH_TEAM_SUMMARY_STATS_REQ_START,
});

export const updateTeamSummaryStats = (data: any) => ({
  type: FETCH_TEAM_SUMMARY_STATS_REQ_SUCCESS,
  data,
});

export const getTeamSummaryStatsFailed = (error: any) => ({
  type: FETCH_TEAM_SUMMARY_STATS_REQ_FAILED,
  error,
});

export const getTeamsPlayerStatsStart = () => ({
  type: FETCH_TEAMS_PLAYERS_STATS_REQ_START,
});

export const getTeamsPlayerStatsSuccess = (data: Array<TeamsPlayerStats>) => ({
  type: FETCH_TEAMS_PLAYERS_STATS_REQ_SUCCESS,
  data,
});

export const getTeamsPlayerStatsFailed = (error: any) => ({
  type: FETCH_TEAMS_PLAYERS_STATS_REQ_FAILED,
  error,
});

export function getTeams(data: any) {
  return (dispatch: any) => {
    dispatch(getTeamsStart());
    fetchTeamsv2(data)
      .then(async (res: any) => {
        dispatch(getTeamsSuccess(res));
      })
      .catch((error: any) => {
        // console.log('error in team rankings', error);
        dispatch(getTeamsFailed('Sorry, something went wrong. Please try again later.'));
      });
  };
}

export function putTeam(data: any) {
  return (dispatch: any) => {
    _fetchRequest('PUT', 'teams', undefined, data)
      .then(async () => {
        dispatch({
          type: FETCH_PUT_TEAM_SUCCESS,
          payload: data,
        });
      })
      .catch((error: any) => {
        console.error(error);
      });
  };
}

//Workaround for club selection in add modals
export function getTeamsForModal(data: any, callback: any) {
  return (dispatch: any) => {
    fetchTeamsv2(data)
      .then(async (res: any) => {
        callback(res);
      })
      .catch((error: any) => {
        callback([]);
      });
  };
}

export function getTeamsCallback(data: any, callback: any) {
  return (dispatch: any) => {
    dispatch(getTeamsStart());
    fetchTeamsv2(data)
      .then(async (res: any) => {
        dispatch(getTeamsSuccess(res));
        if (callback) callback(res);
      })
      .catch((error: any) => {
        dispatch(getTeamsFailed('Sorry, something went wrong. Please try again later.'));
      });
  };
}

export function getTeamRankings(data: any) {
  return (dispatch: any) => {
    dispatch(getTeamRankingsStart());
    fetchTeamRankings(data)
      .then(async (res: any) => {
        // console.log('getTeamRankings res', res);
        const processedTeams = await processTeamRankings(res);
        dispatch(updateTeamRankings(processedTeams));
      })
      .catch((error: any) => {
        // console.log('error in team rankings', error);
        dispatch(getTeamRankingsFailed(error));
      });
  };
}

export function getFollowedTeams(data: any, callback?: any) {
  return (dispatch: any) => {
    dispatch(getFollowedTeamsStart());
    fetchTeamsFollowed(data)
      .then((res: any) => {
        // console.log("getFollowedTeams res", res);
        dispatch(getFollowedTeamsSuccess(res));
        if (callback) callback(res);
      })
      .catch((error: any) => {
        dispatch(getFollowedTeamsFailed(error));
      });
  };
}

export function getTeamStats(data: any) {
  return (dispatch: any) => {
    dispatch(getTeamStatsStart());
    fetchTeamStats(data)
      .then(async (res: any) => {
        const processedTeamGameStats = await processTeamGameStats(res);

        dispatch(updateTeamStats(processedTeamGameStats));
      })
      .catch((error: any) => {
        console.log('error in getTeamStats', error);
        dispatch(getTeamStatsFailed('Sorry, something went wrong. Please try again later.'));
      });
  };
}

export function getTeamGames(data: any) {
  return (dispatch: any) => {
    dispatch(getTeamGamesStart());
    _fetchRequest('GET', 'games_normalized', { team_id: data.team_id })
      .then(async (res: any) => {
        dispatch(updateTeamGames(res));
      })
      .catch((error: any) => {
        console.log('error in getTeamStats', error);
        dispatch(getTeamGamesFailed('Sorry, something went wrong. Please try again later.'));
      });
  };
}

export function addTeam(data: any, callback: any) {
  return (dispatch: any) => {
    dispatch(addTeamStart());
    addTeamReq(data)
      .then(async (res: any) => {
        dispatch(addTeamSuccess());
        callback(true);
      })
      .catch((error: any) => {
        dispatch(addTeamFailed(error));
        dispatch(commonActions.handleError(error));
        callback(false);
      });
  };
}

export function resetTeams() {
  return (dispatch: any) => {
    dispatch(getTeamsSuccess([]));
  };
}

// Team Summary Stats
// GET '/team_summmary_stats'
export function getTeamSummaryStats(data: any) {
  return (dispatch: any) => {
    dispatch(getTeamSummaryStatsStart());
    fetchTeamSummaryStats(data)
      .then(async (res: any) => {
        const processedTeams = await processTeamRankings(res);
        dispatch(updateTeamSummaryStats(processedTeams));
      })
      .catch((error: any) => {
        console.log('error in getTeamSummaryStats', error);
        dispatch(getTeamSummaryStatsFailed(error));
      });
  };
}

export function getHistoricalGames(teamsIds: string[]) {
  return (dispatch: any) => {
    dispatch({
      type: FETCH_HISTORICAL_GAMES_STARTED,
    });
    fetchHistoricalGames(teamsIds)
      .then(async (res: HistoricalGame[]) => {
        dispatch({
          type: FETCH_HISTORICAL_GAMES_SUCCESS,
          payload: res,
        });
      })
      .catch((error: any) => {
        console.log('error in getTeamSummaryStats', error);
        dispatch({
          type: FETCH_HISTORICAL_GAMES_FAILED,
        });
      });
  };
}

export function getMemberTeamAssociations(memberId: string) {
  return (dispatch: any) => {
    dispatch({
      type: FETCH_MEMBER_TEAM_ASSOCIATIONS_STARTED,
    });
    _fetchRequest<MemberTeamAssociation[]>('GET', 'v_player_team_map', { member_id: memberId })
      .then(async (res: MemberTeamAssociation[]) => {
        dispatch({
          type: FETCH_MEMBER_TEAM_ASSOCIATIONS_SUCCESS,
          payload: res,
        });
      })
      .catch((error: any) => {
        console.log('error in getTeamSummaryStats', error);
        dispatch({
          type: FETCH_MEMBER_TEAM_ASSOCIATIONS_FAILED,
        });
      });
  };
}

export function deleteMemberTeamAssoc(rowNum: number) {
  
  return (dispatch: any) => {
    dispatch({
      type: FETCH_MEMBER_TEAM_ASSOCIATIONS_STARTED,
    });
    // _fetchRequest<MemberTeamAssociation[]>('DELETE', 'player_team_map', {}, { player_team_map_id: rowNum })
    _fetchRequest<MemberTeamAssociation[]>('DELETE', 'player_team_map', { player_team_map_id: rowNum })
      .then(async () => {
        dispatch({
          type: FETCH_DELETE_MEMBER_TEAM_ASSOCIATIONS_SUCCESS,
          payload: rowNum,
        });
      })
      .catch((error: any) => {
        console.log('error in getTeamSummaryStats', error);
        dispatch({
          type: FETCH_MEMBER_TEAM_ASSOCIATIONS_FAILED,
        });
      });
  };
}

export function putMemberTeamAssoc(teamAss: Partial<MemberTeamAssociation>, rowNum: number) {
  
  return (dispatch: any) => {
    dispatch({
      type: FETCH_MEMBER_TEAM_ASSOCIATIONS_STARTED,
    });
    _fetchRequest<MemberTeamAssociation[]>('PUT', 'player_team_map', { primary_key: rowNum }, teamAss )
      .then(async () => {
        dispatch({
          type: FETCH_PUT_MEMBER_TEAM_ASSOCIATIONS_SUCCESS,
          payload: teamAss,
        });
      })
      .catch((error: any) => {
        console.log('error in getTeamSummaryStats', error);
        dispatch({
          type: FETCH_MEMBER_TEAM_ASSOCIATIONS_FAILED,
        });
      });
  };
}

export function getOrgSummary(sport_id_default?: number) {
  return (dispatch: any) => {
    dispatch({
      type: FETCH_GET_ORGS_STARTED,
    });
    const queryParams = sport_id_default ? `?sport_id=${sport_id_default}` : '';

    _fetchRequest<Organization[]>('GET', `org_summary${queryParams}`)
      .then(async (orgs: Organization[]) => {
        dispatch({
          type: FETCH_GET_ORGS_SUCCESS,
          payload: orgs,
        });
      })
      .catch((error: any) => {
        console.log('error in getTeamSummaryStats', error);
        dispatch({
          type: FETCH_GET_ORGS_FAILED,
        });
      });
  };
}

export function getOrg(orgId: string) {
  return (dispatch: any) => {
    _fetchRequest<Organization[]>('GET', 'orgs', { org_id: orgId })
      .then(async (org: Organization[]) => {
        dispatch({
          type: FETCH_GET_ORG_SUCCESS,
          payload: org[0],
        });
      })
      .catch((error: any) => {
        console.log('error in getTeam', error);
      });
  };
}

export function postMemberTeamAssoc(teamAss: Partial<MemberTeamAssociation>, division: string, teamName: string) {
  return (dispatch: any) => {
    _fetchRequest<MemberTeamAssociation[]>('POST', 'player_team_map', {}, teamAss)
      .then(async () => {
        dispatch({
          type: FETCH_POST_MEMBER_TEAM_ASSOCIATIONS_SUCCESS,
          payload: {
            ...teamAss,
            team_division: division,
            team_name: teamName,
          },
        });
      })
      .catch((error: any) => {
        console.log('error in getTeamSummaryStats', error);
        dispatch({
          type: FETCH_MEMBER_TEAM_ASSOCIATIONS_FAILED,
        });
      });
  };
}

export function getTeamsPlayerStats() {
  return (dispatch: any) => {
    dispatch(getTeamsPlayerStatsStart());
    fetchTeamsPlayerStats()
      .then(async (res: TeamsPlayerStats[]) => {
        dispatch(getTeamsPlayerStatsSuccess(res));
       
      })
      .catch((error: any) => {
        // console.log('error in team rankings', error);
        dispatch(getTeamsPlayerStatsFailed('Sorry, something went wrong. Please try again later.'));
      });
  };
}