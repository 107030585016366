import {
  fetchPlayerRankings,
  fetchFollowedPlayers,
  fetchPlayerGameStats,
  fetchPlayerSummaryStats,
  fetchPlayers,
  addPlayers,
  fetchTeamsv2,
  fetchSearch,
  fetchPlayerUniversity,
} from '../../apis';
import * as commonActions from './commonActions';
import {
  processPlayers,
  processPlayerJSON,
  processPlayerSummaryStatsJSON,
  processPlayerGameStatsJSON,
  processPlayerGameStatsCollection,
  processPlayerGameStatsJSONParse,
  processPlayerEventStatsJSON,
} from '../../helpers';
import _, { orderBy } from 'lodash';
import fetchPlayerProfile from '../../apis/fetchPlayerProfile';
import { IPlayerProfile } from '../../models/PlayerProfile';
import PlayerUniversity from '../../models/PlayerUniversity';
import fetchPlayerAccolades from '../../apis/Accolades/fetchPlayerAccolades';
import fetchPlayerHighlights from '../../apis/fetchPlayerHighlights';
import Accolade from '../../models/Accolade';
import { Highlight, HighlightsRatings, AllHighlightsRatings } from '../../models/Highlight';
import { addHighlights } from '../../apis/addHighlights';
import {
  errorToast,
  successToast,
} from '../../components/Common/Toasts/showToasts';
import { deleteHighlights } from '../../apis/deleteHighlight';
import addAccolade from '../../apis/Accolades/addAccolade';
import editAccolade from '../../apis/Accolades/editAccolade';
import { fetchGetPlayerStats } from '../../apis/getPlayerStats';
import { PlayerStatistic } from '../../models/PlayerStatictic';
import fetchGearList from '../../apis/fetchGearList';
import GearOption from '../../models/GearOption';
import savePlayerProfile from '../../apis/savePlayerProfile';
import { deleteUniversity } from '../../apis/PlayerUniversities/deleteUniversity';
import addUniversity from '../../apis/PlayerUniversities/addUniversity';
import { deleteAccolade } from '../../apis/Accolades/deleteAccolade';
import _fetchRequest from '../../apis/_fetchRequest';
import { Follow } from '../../models/Follow';
import { PlayerStatConfirm } from '../../models/PlayerStatConfirm';
import fetchPlayerHighlightsRating from '../../apis/fetchplayerHighlightsRating';
import fetchAllPlayerHighlightsRating from '../../apis/fetchAllplayerHighlightsRating';
import { PlayerGamesStats } from '../../apis/fetchPlayerGameStats';
import { addClub } from '../../apis/PlayerClubs/addClub';
import { deleteClub } from '../../apis/PlayerClubs/deleteClub';
import fetchFollow from '../../apis/PlayerClubs/fetchFollow';

export const FETCH_PLAYER_RANKINGS_REQ_START =
  'FETCH_PLAYER_RANKINGS_REQ_START';
export const FETCH_PLAYER_RANKINGS_REQ_SUCCESS =
  'FETCH_PLAYER_RANKINGS_REQ_SUCCESS';
export const FETCH_PLAYER_RANKINGS_REQ_FAILED =
  'FETCH_PLAYER_RANKINGS_REQ_FAILED';

export const FETCH_PLAYER_REQ_START = 'FETCH_PLAYER_REQ_START';
export const FETCH_PLAYER_REQ_SUCCESS = 'FETCH_PLAYER_REQ_SUCCESS';
export const FETCH_PLAYER_WHO_FOLLOW_US_REQ_SUCCESS =
  'FETCH_PLAYER_WHO_FOLLOW_US_REQ_SUCCESS';
export const FETCH_PLAYER_REQ_FAILED = 'FETCH_PLAYER_REQ_FAILED';

export const UPDATE_PLAYER_UNIVERSITY = 'UPDATE_PLAYER_UNIVERSITY';
export const FETCH_PLAYER_UNIVERSITY = 'FETCH_PLAYER_UNIVERSITY';
export const FETCH_ALL_UNIVERSITY = 'FETCH_ALL_UNIVERSITY';
export const FETCH_PLAYER_ACCOLADES = 'FETCH_PLAYER_ACCOLADES';
export const ADD_PLAYER_ACCOLADE = 'ADD_PLAYER_ACCOLADE';
export const EDIT_PLAYER_ACCOLADES = 'EDIT_PLAYER_ACCOLADES';
export const FETCH_PLAYER_HIGHLIGHTS = 'FETCH_PLAYER_HIGHLIGHTS';
export const FETCH_PLAYER_HIGHLIGHTS_RATING = 'FETCH_PLAYER_HIGHLIGHTS_RATING';
export const FETCH_ALL_PLAYER_HIGHLIGHTS_RATING = 'FETCH_ALL_PLAYER_HIGHLIGHTS_RATING'
export const ADD_PLAYER_HIGHLIGHTS = 'ADD_PLAYER_HIGHLIGHTS';
export const DELETE_PLAYER_HIGHLIGHTS = 'DELETE_PLAYER_HIGHLIGHTS';
export const FETCH_GEAR_LIST = 'FETCH_GEAR_LIST';

export const FETCH_FOLLOWED_PLAYER_REQ_START =
  'FETCH_FOLLOWED_PLAYER_REQ_START';
export const FETCH_FOLLOWED_PLAYER_SUCCESS = 'FETCH_FOLLOWED_PLAYER_SUCCESS';

export const FETCH_PLAYER_SUMMARY_STATS_REQ_START =
  'FETCH_PLAYER_SUMMARY_STATS_REQ_START';
export const FETCH_PLAYER_SUMMARY_STATS_REQ_SUCCESS =
  'FETCH_PLAYER_SUMMARY_STATS_REQ_SUCCESS';
export const FETCH_PLAYER_SUMMARY_STATS_REQ_FAILED =
  'FETCH_PLAYER_SUMMARY_STATS_REQ_FAILED';

export const FETCH_PLAYER_EVENT_STATS_REQ_SUCCESS =
  'FETCH_PLAYER_EVENT_STATS_REQ_SUCCESS';

export const FETCH_PLAYER_GAME_STATS_REQ_START =
  'FETCH_PLAYER_GAME_STATS_REQ_START';
export const FETCH_PLAYER_GAME_STATS_REQ_SUCCESS =
  'FETCH_PLAYER_GAME_STATS_REQ_SUCCESS';
export const FETCH_PLAYER_GAME_STATS_REQ_FAILED =
  'FETCH_PLAYER_GAME_STATS_REQ_FAILED';

export const FETCH_PLAYER_PROFILE_STATS_REQ_START =
  'FETCH_PLAYER_PROFILE_STATS_REQ_START';
export const FETCH_PLAYER_PROFILE_STATS_REQ_SUCCESS =
  'FETCH_PLAYER_PROFILE_STATS_REQ_SUCCESS';
export const FETCH_PLAYER_PROFILE_STATS_SUCCESS =
  'FETCH_PLAYER_PROFILE_STATS_SUCCESS';
export const FETCH_PLAYER_PROFILE_STATS_CONFIRM_SUCCESS =
  'FETCH_PLAYER_PROFILE_STATS_CONFIRM_SUCCESS';
export const FETCH_PLAYER_PROFILE_STATS_REQ_FAILED =
  'FETCH_PLAYER_PROFILE_STATS_REQ_FAILED';

export const FETCH_PLAYER_PROFILE_REQ_SUCCESS =
  'FETCH_PLAYER_PROFILE_REQ_SUCCESS';
export const FETCH_PLAYER_PROFILE_REQ_START = 'FETCH_PLAYER_PROFILE_REQ_START';

export const ADD_PLAYER_REQ_START = 'ADD_PLAYER_REQ_START';
export const ADD_PLAYER_REQ_SUCCESS = 'ADD_PLAYER_REQ_SUCCESS';
export const ADD_PLAYER_REQ_FAILED = 'ADD_PLAYER_REQ_FAILED';

export const FETCH_TEAMS_REQ_START = 'FETCH_TEAMS_REQ_START';
export const FETCH_TEAMS_REQ_SUCCESS = 'FETCH_TEAMS_REQ_SUCCESS';
export const FETCH_TEAMS_REQ_FAILED = 'FETCH_TEAMS_REQ_FAILED';

export const FETCH_PLAYER_FOLLOWERS_SUCCESS = 'FETCH_PLAYER_FOLLOWERS_SUCCESS';
export const FETCH_CHECK_FOLLOW_SUCCESS = 'FETCH_CHECK_FOLLOW_SUCCESS';
export const FETCH_SET_FOLLOW_NUM_SUCCESS = 'FETCH_SET_FOLLOW_NUM_SUCCESS';

export const FETCH_PLAYER_CLUBS_SUCCESS = 'FETCH_PLAYER_CLUBS_SUCCESS';
export const UPDATE_PLAYER_CLUBS = 'UPDATE_PLAYER_CLUBS';
export const FETCH_PLAYER_UNIVERSITY_NOTIFICATION = 'FETCH_PLAYER_UNIVERSITY_NOTIFICATION';

export const getPlayerRankingsStart = () => ({
  type: FETCH_PLAYER_RANKINGS_REQ_START,
});

export const updatePlayerRankings = (data: any) => ({
  type: FETCH_PLAYER_RANKINGS_REQ_SUCCESS,
  data,
});

export const getPlayerRankingsFailed = (error: any) => ({
  type: FETCH_PLAYER_RANKINGS_REQ_FAILED,
  error,
});

export const getPlayersStart = () => ({
  type: FETCH_PLAYER_REQ_START,
});

export const getPlayersSuccess = (data: any) => ({
  type: FETCH_PLAYER_REQ_SUCCESS,
  data,
});

export const getPlayersWhoFollowUsSuccess = (data: any) => ({
  type: FETCH_PLAYER_WHO_FOLLOW_US_REQ_SUCCESS,
  data,
});

export const getPlayersFailed = (error: any) => ({
  type: FETCH_PLAYER_REQ_FAILED,
  error,
});

export const getFollowedPlayersStart = () => ({
  type: FETCH_FOLLOWED_PLAYER_REQ_START,
});

export const updateFollowedPlayers = (data: any) => ({
  type: FETCH_FOLLOWED_PLAYER_SUCCESS,
  data,
});

//getPlayerSummaryStatsStart
export const getPlayerSummaryStatsStart = () => ({
  type: FETCH_PLAYER_SUMMARY_STATS_REQ_START,
});

export const updatePlayerSummaryStats = (data: any) => ({
  type: FETCH_PLAYER_SUMMARY_STATS_REQ_SUCCESS,
  data,
});

export const updatePlayerEventStats = (data: any) => ({
  type: FETCH_PLAYER_EVENT_STATS_REQ_SUCCESS,
  data,
});


export const getPlayerSummaryStatsFailed = (error: any) => ({
  type: FETCH_PLAYER_SUMMARY_STATS_REQ_FAILED,
  error,
});

export const getPlayerGamesStatsStart = () => ({
  type: FETCH_PLAYER_GAME_STATS_REQ_START,
});

export const updatePlayerGamesStats = (data: any) => ({
  type: FETCH_PLAYER_GAME_STATS_REQ_SUCCESS,
  data,
});

export const getPlayerGamesStatsFailed = (error: any) => ({
  type: FETCH_PLAYER_GAME_STATS_REQ_FAILED,
  error,
});

export const addPlayerStart = () => ({
  type: ADD_PLAYER_REQ_START,
});

export const addPlayerSuccess = () => ({
  type: ADD_PLAYER_REQ_SUCCESS,
});

export const addPlayerFailed = (error: any) => ({
  type: ADD_PLAYER_REQ_FAILED,
  error,
});

// PLAYER PROFILE ACTIONS
export const getPlayerProfileStatsStart = () => ({
  type: FETCH_PLAYER_PROFILE_STATS_REQ_START,
});

export const getPlayerProfileStart = () => ({
  type: FETCH_PLAYER_PROFILE_REQ_START,
});

export const updatePlayerProfile = (data: IPlayerProfile | null) => ({
  type: FETCH_PLAYER_PROFILE_REQ_SUCCESS,
  data,
});

export const updatePlayerProfileStats = (data: any) => ({
  type: FETCH_PLAYER_PROFILE_STATS_REQ_SUCCESS,
  data,
});

export const getPlayerProfileStatsFailed = (error: any) => ({
  type: FETCH_PLAYER_PROFILE_STATS_REQ_FAILED,
  error,
});

export const getPlayerUniversity = (payload: PlayerUniversity[]) => ({
  type: FETCH_PLAYER_UNIVERSITY,
  payload,
});

export const getPlayerUniversityNotification = (payload: PlayerUniversity[]) => ({
  type: FETCH_PLAYER_UNIVERSITY_NOTIFICATION,
  payload,
});

export const updatePlayerUniversities = (payload: PlayerUniversity) => ({
  type: UPDATE_PLAYER_UNIVERSITY,
  payload,
});

export const updatePlayerClubs = (payload: any) => ({
  type: UPDATE_PLAYER_CLUBS,
  payload,
});

export const getAllUniversities = (payload: PlayerUniversity[]) => ({
  type: FETCH_ALL_UNIVERSITY,
  payload,
});

export const getPlayerAccolades = (payload: Accolade[]) => ({
  type: FETCH_PLAYER_ACCOLADES,
  payload,
});

export const addPlayerAccolade = (payload: Accolade) => ({
  type: ADD_PLAYER_ACCOLADE,
  payload,
});

export const editPlayerAccolade = (payload: Accolade[]) => ({
  type: EDIT_PLAYER_ACCOLADES,
  payload,
});

export const getPlayerHighlights = (payload: Highlight[]) => ({
  type: FETCH_PLAYER_HIGHLIGHTS,
  payload,
});

export const getPlayerHighlightsRating = (payload: HighlightsRatings[]) =>
({
  type: FETCH_PLAYER_HIGHLIGHTS_RATING,
  payload,
}
);
export const getAllPlayerHighlightsRating = (payload: AllHighlightsRatings[]) =>
({
  type: FETCH_ALL_PLAYER_HIGHLIGHTS_RATING,
  payload,
}
);

export const addPlayerHighlight = (payload: Highlight) => ({
  type: ADD_PLAYER_HIGHLIGHTS,
  payload,
});

export const deletePlayerHighlights = (payload: Highlight[]) => ({
  type: DELETE_PLAYER_HIGHLIGHTS,
  payload,
});

export const getGearList = (payload: GearOption[]) => ({
  type: FETCH_GEAR_LIST,
  payload,
});

// Teams
export const getTeamsStart = () => ({
  type: FETCH_TEAMS_REQ_START,
});

export const getTeamsSuccess = (data: Array<any>) => ({
  type: FETCH_TEAMS_REQ_SUCCESS,
  data,
});

export const getTeamsFailed = (error: any) => ({
  type: FETCH_TEAMS_REQ_FAILED,
  error,
});

export const getPlayerclubs = (payload: any) => ({
  type: FETCH_PLAYER_CLUBS_SUCCESS,
  payload,
})

// GET '/players'

export function getPlayers(data: any, callback?: any) {
  return (dispatch: any) => {
    dispatch(getPlayersStart());
    fetchPlayers(data)
      .then(async (res: any) => {
        const processedPlayers = await processPlayers(res);
        // console.log('processedPlayers', processedPlayers);
        dispatch(getPlayersSuccess(processedPlayers));
        if (callback) callback(res);
      })
      .catch((error: any) => {
        dispatch(
          getPlayersFailed(
            'Sorry, something went wrong. Please try again later.',
          ),
        );
      });
  };
}

export function getTeams(data: any) {
  return (dispatch: any) => {
    dispatch(getTeamsStart());
    fetchTeamsv2(data)
      .then((res: any) => {
        dispatch(getTeamsSuccess(res));
      })
      .catch((error: any) => {
        dispatch(
          getTeamsFailed(
            'Sorry, something went wrong. Please try again later.',
          ),
        );
      });
  };
}

export function getPlayersCallback(data: any, callback: any) {
  return (dispatch: any) => {
    dispatch(getPlayersStart());
    fetchPlayers(data)
      .then((res: any) => {
        dispatch(getPlayersSuccess(res));
        callback(res);
      })
      .catch((error: any) => {
        dispatch(
          getPlayersFailed(
            'Sorry, something went wrong. Please try again later.',
          ),
        );
      });
  };
}

export function resetPlayers() {
  return (dispatch: any) => {
    dispatch(getPlayersSuccess([]));
  };
}

// POST players
export function addPlayersRequest(data: any, callback: any) {
  return (dispatch: any) => {
    dispatch(addPlayerStart());
    addPlayers(data)
      .then((res: any) => {
        dispatch(addPlayerSuccess());
        callback(true);
      })
      .catch((error: any) => {
        dispatch(addPlayerFailed(error));
        dispatch(commonActions.handleError(error));
        callback(false);
      });
  };
}

// Followed Players
// ToDo: check if this is needed (currently used in Player Rankings)
export function getFollowedPlayers(member_id: any) {
  return (dispatch: any) => {
    dispatch(getFollowedPlayersStart());
    fetchFollowedPlayers(member_id)
      .then((res: any) => {
        // console.log("getFollowedTeams res", res);
        dispatch(updateFollowedPlayers(res));
      })
      .catch((error: any) => {
        console.log('error in getFollowedTeams', error);
        dispatch(commonActions.handleError(error));
      });
  };
}

// Player Ratings

export function getPlayerRatings() {
  return (dispatch: any) => {
    fetchPlayerHighlightsRating().then((res: HighlightsRatings[]) => {
      dispatch(getPlayerHighlightsRating(res));
    })
      .catch((error: any) => {
        dispatch(commonActions.handleError(error));
      });
  };

}

export function getAllPlayerRatings() {
  return (dispatch: any) => {
    fetchAllPlayerHighlightsRating().then((res: AllHighlightsRatings[]) => {
      dispatch(getAllPlayerHighlightsRating(res));
    })
      .catch((error: any) => {
        dispatch(commonActions.handleError(error));
      });
  };

}

// Player Rankings

export default function getPlayerRankings(data: any) {
  return (dispatch: any) => {
    dispatch(getPlayerRankingsStart());
    fetchPlayerRankings(data)
      .then(async (res: any) => {
        const processedPlayers = await processPlayerJSON(res);
        // console.log('processedPlayers', processedPlayers);
        dispatch(updatePlayerRankings(processedPlayers));
      })
      .catch((error: any) => {
        console.log('error in player rankings', error);
        dispatch(getPlayerRankingsFailed(error));
      });
  };
}

// Player Summary Stats
// GET '/player_summmary_stats'
export function getPlayerSummaryStats(data: any) {
  return (dispatch: any) => {
    dispatch(getPlayerSummaryStatsStart());
    fetchPlayerSummaryStats(data)
      .then(async (res: any) => {
        const processedPlayers = await processPlayerSummaryStatsJSON(res);
        dispatch(updatePlayerSummaryStats(processedPlayers));
      })
      .catch((error: any) => {
        console.log('error in getPlayerSummaryStats', error);
        dispatch(getPlayerSummaryStatsFailed(error));
      });
  };
}

export function getPlayerEventsStats(data: any) {
  return (dispatch: any) => {
    dispatch(getPlayerSummaryStatsStart());
    const event_id = data.eventId;
    _fetchRequest('GET', 'player_events_stats', { event_id })
      .then(async (res: any) => {
        const processedPlayers = await processPlayerEventStatsJSON(res);
        // dispatch(updatePlayerSummaryStats(processedPlayers));
        dispatch(updatePlayerEventStats(processedPlayers));

      })
      .catch((error: any) => {
        console.log('error in getPlayerSummaryStats', error);
        dispatch(getPlayerSummaryStatsFailed(error));
      });
  };
}

// Player Game Stats (Player Lab, My Dasboard - Player Stats) - games, W/L/T
// GET '/player_game_stats'
// calculate ALL summary stats based on player game-level data (includes total games, W/L/T, and detailed stats)
// getPlayerGamesSummaryStats
export function getPlayerGameSummaryStats(data: any) {
  return (dispatch: any) => {
    dispatch(getPlayerGamesStatsStart());
    fetchPlayerGameStats(data)
      .then(async (res: any) => {
        // Sum stats across all games  playersStats

        // parsedGameStats
        let gameParsedStats = await processPlayerGameStatsCollection(res);

        let playersStats = _(gameParsedStats)
          .groupBy('player_id')
          .map((objs, key) => {
            const wins = _.sumBy(objs, 'Win') || 0;
            const losses = _.sumBy(objs, 'Loss') || 0;
            const ties = _.sumBy(objs, 'Tie') || 0;
            const games = wins + losses + ties;
            const goals = _.sumBy(objs, 'stats.goals') || 0;
            const assists = _.sumBy(objs, 'stats.assists') || 0;
            const points = goals + assists;
            const ppr = _.sumBy(objs, 'stats.ppr')
              ? _.sumBy(objs, 'stats.ppr') / objs.length
              : undefined;

            return {
              ...objs[0],
              games_played: games,
              total_wins: wins,
              total_losses: losses,
              total_ties: ties,
              goals: goals,
              assists: assists,
              fto: _.sumBy(objs, 'stats.fto'),
              gb: _.sumBy(objs, 'stats.gb'),
              total_points: points,
              ppr: ppr,
            };
          })
          .value();

        dispatch(updatePlayerGamesStats(playersStats));
      })
      .catch((error: any) => {
        console.log('error in getPlayerGamesStats', error);
        dispatch(getPlayerGamesStatsFailed(error));
      });
  };
}

function playerGamesStatsByOffset(data: any) {
  return new Promise(async (resolve: any, reject) => {
    try {
      let options: any = Object.assign({}, data);
      let allRecords: Array<any> = [];
      const result = await fetchPlayerGameStats(options);
      if (result && typeof result === 'object' && result.length) {
        allRecords = allRecords.concat(result);
        options.offset = allRecords.length + 1;
        resolve(allRecords);
      } else {
        console.log("resolve due to no more records");
        resolve(allRecords);
      }
    } catch (e: any) {
      reject(new Error(e.message));
    }
  });
}

export function getPlayerGamesStats(data: any) {
  return (dispatch: any) => {
    dispatch(getPlayerGamesStatsStart());
    playerGamesStatsByOffset(data)
      .then(async (res: any) => {
        let groupedParsedPlayers: any = _.groupBy(res, function (o) {
          return o.player_id;
        });

        var playersStats: number[] = await Promise.all(
          Object.keys(groupedParsedPlayers).map(
            async (key, index): Promise<any> => {
              const data: Array<any> = await processPlayerGameStatsJSON(
                groupedParsedPlayers[key],
              );
              return data;
            },
          ),
        );

        const sortedData = _.orderBy(
          playersStats,
          (a: any) => {
            return a.first_name !== '' && a.first_name !== null
              ? a.first_name
              : undefined;
          },
          ['asc'],
        );

        // const sortedData = _.orderBy(playersStats, [(a: any) => { return a.first_name || ''}], ['asc']);
        // const sortedData = _.orderBy(playersStats, ['first_name'],['asc']);
        // console.log("sortedData", sortedData);
        dispatch(updatePlayerGamesStats(sortedData));
      })
      .catch((error: any) => {
        console.log('error in getPlayerGamesStats', error);
        dispatch(getPlayerGamesStatsFailed(error));
      });
  };
}

export function searchPlayerStats(data: any, statsReq: any) {
  return (dispatch: any) => {
    fetchSearch(data)
      .then(async (res: any) => {
        if (res && res.length) {
          const playerIds = res.map((x: any) => x['fk_id']).join(',');
          try {
            let options: any = Object.assign({}, statsReq);
            options.player_id = playerIds;
            const result = await fetchPlayerGameStats(options);
            console.log('result', result);
          } catch (e: any) {
            dispatch(getPlayerGamesStatsFailed(e.message));
          }
        } else {
          dispatch(updatePlayerGamesStats([]));
        }
      })
      .catch((error: any) => {
        dispatch(updatePlayerGamesStats([]));
      });
  };
}

// Player Game Stats
// GET '/player_game_stats'
export function getPlayerProfileStats(data: PlayerGamesStats) {
  return (dispatch: any) => {
    dispatch(getPlayerProfileStatsStart());
    fetchPlayerGameStats(data)
      .then(async (res: any) => {
        let groupedParsedTeams: any = _.groupBy(res, function (o) {
          return o.player_id;
        });

        var playersStats: number[] = await Promise.all(
          Object.keys(groupedParsedTeams).map(
            async (key, index): Promise<any> => {
              const data: Array<any> = await processPlayerGameStatsJSONParse(
                groupedParsedTeams[key],
              );
              return data;
            },
          ),
        );
        // console.log("playersStats", playersStats)
        dispatch(updatePlayerProfileStats(playersStats));
      })
      .catch((error: any) => {
        console.log('error in getPlayerGamesStats', error);
        dispatch(getPlayerProfileStatsFailed(error));
      });
  };
}

export function getPlayerProfile(data: { player_id: string }) {
  return (dispatch: any) => {
    dispatch(getPlayerProfileStart());
    fetchPlayerProfile(data.player_id)
      .then(async (res: IPlayerProfile | null) => {
        dispatch(updatePlayerProfile(res));
      })
      .catch((error: any) => {
        console.log('error in getPlayerGamesStats', error);
        dispatch(getPlayerProfileStatsFailed(error));
      });
    fetchGearList()
      .then(async (res: any) => {
        dispatch(getGearList(res));
      })
      .catch((error: any) => {
        console.log('error in fetchGearList', error);
      });
    fetchPlayerUniversity(data.player_id)
      .then(async (res: any) => {
        dispatch(getPlayerUniversity(res.playerUniversities));
        dispatch(getAllUniversities(res.allUniversities));
      })
      .catch((error: any) => {
        console.log('error in fetchPlayerUniversity', error);
      });
    fetchPlayerAccolades(data.player_id)
      .then(async (res: Accolade[]) => {
        dispatch(getPlayerAccolades(res));
      })
      .catch((error: any) => {
        console.log('error in fetchPlayerAccolades', error);
      });
    fetchPlayerHighlights(data.player_id)
      .then(async (res: Highlight[]) => {
        dispatch(getPlayerHighlights(res));
      })
      .catch((error: any) => {
        console.log('error in fetchPlayerHighlights', error);
      });
  };
}

export const getUniversities = (email_domain?: string) => async (dispatch: any) => {
  _fetchRequest('GET', email_domain ? `universities?email_domain=${email_domain}` : 'universities')
    .then(async (res: any) => {
      dispatch(getAllUniversities(res));
    })
    .catch((error: any) => {
      console.error('error in fetchUniversity', error);
    });
};

export const getPlayersByMemberId =
  (memberIds: any, isWhoFollowUs?: boolean) => (dispatch: any) => {
    dispatch(getPlayersStart());
    _fetchRequest('GET', 'player_profile', { member_id: memberIds })
      .then((res: any) => {
        const players = orderBy(res, 'first_name');
        !isWhoFollowUs
          ? dispatch(getPlayersSuccess(players))
          : dispatch(getPlayersWhoFollowUsSuccess(players));
      })
      .catch((_error: any) => {
        errorToast("Couldn't save Player Profile.");
      });
  };

export const changePlayerProfile =
  (newProfile: IPlayerProfile) => (dispatch: any) => {
    savePlayerProfile(newProfile)
      .then((_res: any) => {
        dispatch(updatePlayerProfile(newProfile));
        successToast('Player Profile saved successfully.');
      })
      .catch((_error: any) => {
        errorToast("Couldn't save Player Profile.");
      });
  };

export const addNewAccolade =
  (newAccolade: Accolade, playerId: string) => (dispatch: any) => {
    addAccolade(newAccolade, playerId)
      .then((_res: any) => {
        dispatch(addPlayerAccolade(newAccolade));
        successToast('Accolade added successfully.');
      })
      .catch((_error: any) => {
        errorToast("Couldn't add accolade.");
      });
  };

export const editSelectedAccolade =
  (editedAccolade: Accolade, allAccolades: Accolade[], playerId: string) =>
    (dispatch: any) => {
      editAccolade(editedAccolade, playerId)
        .then((_res: any) => {
          const editedAccolades = allAccolades.map((accolade: Accolade) =>
            editedAccolade.accolade_id === accolade.accolade_id
              ? editedAccolade
              : accolade,
          );
          dispatch(editPlayerAccolade(editedAccolades));
          successToast('Accolade added successfully.');
        })
        .catch((_error: any) => {
          errorToast("Couldn't add accolade.");
        });
    };

export const deleteSelectedAccolade =
  (accoladeId: string | number, allAccolades: Accolade[]) =>
    (dispatch: any) => {
      deleteAccolade(accoladeId)
        .then((_res: any) => {
          dispatch(
            getPlayerAccolades(
              allAccolades.filter(
                (accolade: Accolade) => accolade.accolade_id !== accoladeId,
              ),
            ),
          );
        })
        .catch((_error: any) => {
          errorToast("Couldn't delete accolade.");
        });
    };

export const addNewHighlights =
  (newHighlight: Highlight, playerId: string) => (dispatch: any) => {
    addHighlights(newHighlight, playerId)
      .then((_res: any) => {
        newHighlight = { ...newHighlight, member_media_id: _res.insertId }
        dispatch(addPlayerHighlight(newHighlight));
        successToast('Highlight added successfully.');
      })
      .catch((_error: any) => {
        errorToast("Couldn't add highlight.");
      });
  };

export const deleteSelectedHighlights =
  (deletedHighlights: (string | number)[], allHighlights: Highlight[] | null) =>
    async (dispatch: any) => {
      deleteHighlights(deletedHighlights)
        .then((_res: any) => {
          const newHighlights: Highlight[] = [];
          allHighlights?.forEach((item: Highlight) => {
            const match = deletedHighlights.find(
              (videoId) => videoId === item.member_media_id,
            );
            if (!match) {
              newHighlights.push(item);
            }
          });
          dispatch(deletePlayerHighlights(newHighlights));
          successToast('Highlights added successfully.');
        })
        .catch((_error: any) => {
          errorToast("Couldn't delete highlights.");
        });
    };

export const getPlayerStats = (playerId: string) => async (dispatch: any) => {
  dispatch({
    type: FETCH_PLAYER_PROFILE_STATS_REQ_START,
  });
  fetchGetPlayerStats(playerId)
    .then((res: PlayerStatistic[]) => {
      dispatch({
        type: FETCH_PLAYER_PROFILE_STATS_SUCCESS,
        payload: res,
      });
    })
    .catch((_error: any) => {
      dispatch({
        type: FETCH_PLAYER_PROFILE_STATS_REQ_FAILED,
      });
      errorToast("Couldn't delete highlights.");
    });
};

export const getTotalPlayerStats =
  (playerId: string) => async (dispatch: any) => {
    dispatch({
      type: FETCH_PLAYER_PROFILE_STATS_REQ_START,
    });
    _fetchRequest<PlayerStatConfirm[]>('GET', 'player_stat_confirms', {
      member_id: playerId,
    })
      .then((res: PlayerStatConfirm[]) => {
        dispatch({
          type: FETCH_PLAYER_PROFILE_STATS_CONFIRM_SUCCESS,
          payload: res[0],
        });
      })
      .catch((_error: any) => {
        errorToast("Couldn't get Player Stats Confirm.");
      });
  };

export const deletePlayerUniversity =
  (universityRowNum: number | null, updatedUniversities: PlayerUniversity[]) =>
    async (dispatch: any) => {
      deleteUniversity(universityRowNum)
        .then((_res: any) => {
          dispatch(getPlayerUniversity(updatedUniversities));
        })
        .catch((_error: any) => {
          errorToast("Couldn't delete University.");
        });
    };

export const addNewPlayerUniversity =
  (newUniversity: PlayerUniversity) => async (dispatch: any) => {
    addUniversity(newUniversity)
      .then((_res: any) => {
        dispatch(updatePlayerUniversities(newUniversity));
      })
      .catch((_error: any) => {
        errorToast("Couldn't add University.");
      });
  };

export const deleteFollows =
  (universityRowNum: number | null) =>
    async (dispatch: any) => {
      deleteClub(universityRowNum)
        .then((_res: any) => { })
        .catch((_error: any) => {
          errorToast("Couldn't delete University.");
        });
    };

export const addNewPlayerClub =
  (newClub: any) => async (dispatch: any) => {
    addClub(newClub)
      .then((_res: any) => {
        fetchFollow(newClub.member_id).then((res: any) => {
          dispatch(getPlayerclubs(res.playerClubs));
        })
      })
      .catch((_error: any) => {
        errorToast("Couldn't add Clubs.");
      });
  };

export const getPlayerFollowers =
  (memberId: string) => async (dispatch: any) => {
    _fetchRequest<Follow[]>('GET', `followers`, { primary_key: memberId })
      .then((res: Follow[]) => {
        const numFollowers = res?.length === 0 ? 0 : res[0].num_followers;
        dispatch({
          type: FETCH_PLAYER_FOLLOWERS_SUCCESS,
          payload: numFollowers,
        });
      })
      .catch((_error: any) => {
        errorToast("Couldn't GET followers.");
      });
  };

export const postFollow =
  (playerId: string, playerForFollow: string) => async (dispatch: any) => {
    _fetchRequest<Follow[]>('POST', `member_follows`, undefined, {
      app_id: 1,
      sport_id: null,
      association_id: 2,
      member_id: playerId,
      association_fk_id: playerForFollow,
      is_active_YN: 1,
      created_by: playerId,
      created_datetime: new Date().toISOString(),
    })
      .then((res: any) => {
        dispatch({
          type: FETCH_SET_FOLLOW_NUM_SUCCESS,
          payload: res.insertId,
        });
        dispatch(getPlayerFollowers(playerForFollow));
        successToast('Great!');
      })
      .catch((_error: any) => {
        errorToast("Couldn't post followers.");
      });
  };

export const getFollow =
  (playerId: string, playerForFollow: string) => async (dispatch: any) => {
    _fetchRequest<Follow[]>('GET', `member_follows`, {
      member_id: playerId,
      association_fk_id: playerForFollow,
    })
      .then((res: any) => {
        const followNum = res?.length !== 0 ? res[0].row_num : undefined;
        dispatch({
          type: FETCH_SET_FOLLOW_NUM_SUCCESS,
          payload: followNum,
        });
        dispatch({
          type: FETCH_CHECK_FOLLOW_SUCCESS,
          payload: !!followNum,
        });
        successToast('Great!');
      })
      .catch((_error: any) => {
        errorToast("Couldn't post followers.");
      });
  };

export const deleteFollow =
  (followNumId: number, playerForFollow: string) => async (dispatch: any) => {
    _fetchRequest<Follow[]>('DELETE', `member_follows`, {
      row_num: followNumId,
    })
      .then((res: any) => {
        dispatch(getPlayerFollowers(playerForFollow));
        successToast('Great!');
      })
      .catch((_error: any) => {
        errorToast("Couldn't post followers.");
      });
  };
