export { default as memberTypes } from './memberTypes';
export { default as routes } from './routes';
export { default as stateCodes } from './stateCodes';
export { default as yearRange } from './yearRange';
export { default as geographies, regions } from './geographies';
export { default as positionStates } from './positionStates';
export { default as genderStates } from './genderStates';
export { default as levelStates } from './levelStates';

export const apiOrigin = 'https://apps.clublacrosse.org';
export const logoPrefix = 'https://clublacrosse.s3.amazonaws.com/Images/Team+Logos';
export const API_URL = 'https://api.clublacrosse.org/v2/';
export const AUTH_PROD_API_URL = 'https://api.usalacrosse.com/api';
export const AUTH_API_URL =  'https://api-d.usalacrosse.com/api';


