import { useEffect, useState } from 'react';
import crossfilter from 'crossfilter2';

const useCrossfilter = <T>(data: T[] | undefined) => {
  let [ndx, setNdx] = useState<crossfilter.Crossfilter<T> | undefined>(undefined);

  useEffect(() => {
    if (data) {
      setNdx(crossfilter(data));
    }
  }, [data]);

  return ndx;
};

export default useCrossfilter;
