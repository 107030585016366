import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Menu, MenuProps, MenuItem, ListItemText, ListItemIcon, Link } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SubjectIcon from '@mui/icons-material/Subject';
import NotificationIcon from '@mui/icons-material/Notifications';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import GroupsIcon from '@mui/icons-material/Groups';
import { routes } from '../../constants';
import { connect } from 'react-redux';
import FeedbackModal from '../AddRecords/FeedbackModal';
import RadarIcon from '@mui/icons-material/Radar';

const NAV_HEIGHT = 120;

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
    justifyContent: 'space-around',
    textAlign: 'right',
    alignItems: 'center',
    height: NAV_HEIGHT / 2,
    marginRight: theme.spacing(3),
    minWidth: 75,
    color: '#ffffff80 !important',
    borderBottom: '5px solid transparent',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
      textDecoration: 'underline',
    },
  },
  green: {
    color: '#00FF47',
  },
  red: {
    color: '#FF2839',
  },
  subscriber: {
    textDecoration: 'none',
    fontSize: '11px',
    textTransform: 'uppercase',
  },
  member: {
    height: '100%',
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  menuItem: {
    padding: '2px 16px 2px 8px !important',
    '& a': {
      display: 'flex !important',
      alignItems: 'center !important',
    },
  },
  listIcon: {
    minWidth: '25px !important',
    color: 'white !important',
  },
  listItemText: {
    color: 'white !important',
    '& span': {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  logo: {
    width: 37,
    height: 37,
    borderRadius: 37,
    marginRight: '5px !important',
  },
}));

const StyledMenu = withStyles((theme) => ({
  paper: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: '0px !important',
  },
}))((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const MyProfileMenu: React.FC = (props: any) => {
  const { member, memberProfile } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClose = () => {
    // onClose();
    setAnchorEl(null);
  };

  const onProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onLogout = () => {
    Auth.signOut({ global: true }).then(() => {
      setTimeout(() => {
        navigate('/');
      });
    });
  };

  const handleFeedback = () => {
    setAnchorEl(null);
    setIsOpenModal(true);
  };
  
  const handleData = () => {
    setAnchorEl(null);
    setIsOpenModal(true);
  };


  return (
    <>
      <div className={classes.link} onClick={(e: any) => onProfileClick(e)}>
        {member.member_type === 'Player' && memberProfile?.player_picture_URL ? <img src={`https://clubsportsmedia.s3.amazonaws.com/public/${memberProfile?.player_picture_URL}`} alt="logo" className={classes.logo} /> : <AccountCircleOutlinedIcon />}
        <div className={classes.member}>
          &nbsp;Hello, {member.first_name} {member.last_name}!<span className={`${classes.subscriber} ${member.is_subscriber_YN ? classes.green : classes.red}`}>{member.is_subscriber_YN ? 'Premium Data Subscriber' : 'Community Data (Limited Access)'}</span>
        </div>
      </div>
      <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem className={classes.menuItem} onClick={handleClose}>
          <NavLink end to={routes.playerDetail({ playerId: props.member.member_id })}>
            <ListItemIcon className={classes.listIcon}>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="My Profile" className={classes.listItemText} />
          </NavLink>
        </MenuItem>
        {/* <MenuItem className={classes.menuItem} onClick={handleClose}>
          <NavLink exact to={routes.playerMapping}>
            <ListItemIcon className={classes.listIcon}>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary="Player Mapping"
              className={classes.listItemText}
            />
          </NavLink>
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleClose}>
          <NavLink exact to={routes.accountOptions}>
            <ListItemIcon className={classes.listIcon}>
              <SubjectIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary="My Account Details"
              className={classes.listItemText}
            />
          </NavLink>
        </MenuItem> */}
        {member.member_type === 'Player' && (
          <MenuItem className={classes.menuItem} onClick={handleClose}>
            <Link
              onClick={() => {
                navigate(routes.playerDetail({ playerId: props.member.member_id }), { state: { isMyTeam: 'team' } });
              }}
            >
              <ListItemIcon className={classes.listIcon}>
                <GroupsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="My Teams" className={classes.listItemText} />
            </Link>
          </MenuItem>
        )}
        <MenuItem className={classes.menuItem} onClick={handleClose}>
          <NavLink end to={routes.account()}>
            <ListItemIcon className={classes.listIcon}>
              <SubjectIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Account/Subscription Details" className={classes.listItemText} />
          </NavLink>
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleClose}>
          <NavLink end to={routes.notifications()}>
            <ListItemIcon className={classes.listIcon}>
              <NotificationIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Manage Notifications" className={classes.listItemText} />
          </NavLink>
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleFeedback}>
          <ListItemIcon className={classes.listIcon}>
            <FeedbackOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Feedback" className={classes.listItemText} />
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleData}>
          <ListItemIcon className={classes.listIcon}>
            <RadarIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Data Corrections" className={classes.listItemText} />
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={onLogout}>
          <ListItemIcon className={classes.listIcon}>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Logout" className={classes.listItemText} />
        </MenuItem>
      </StyledMenu>
      <FeedbackModal showFeedback={isOpenModal} onClose={() => setIsOpenModal(false)} />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  member: state.memberReducer.member,
  memberProfile: state.memberReducer.memberProfile,
});

export default connect(mapStateToProps)(MyProfileMenu);
