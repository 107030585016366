import { orderBy } from 'lodash';
import { fetchSearch } from '../../apis';

export const FETCH_SEARCH_REQ_START = 'FETCH_SEARCH_REQ_START';
export const FETCH_SEARCH_REQ_SUCCESS = 'FETCH_SEARCH_REQ_SUCCESS';
export const FETCH_SEARCH_REQ_FAILED = 'FETCH_SEARCH_REQ_FAILED';
export const SET_SEARCH_SELECTION = 'SET_SEARCH_SELECTION'; // user selects an option from search results

export const startSearching = () => ({
  type: FETCH_SEARCH_REQ_START,
});

export const getSearchResult = (data: any) => ({
  type: FETCH_SEARCH_REQ_SUCCESS,
  data,
});

export const setSearchSelection = (data: any) => ({
  type: SET_SEARCH_SELECTION,
  data,
});

export const searchingFailed = (error: any) => ({
  type: FETCH_SEARCH_REQ_FAILED,
  error,
});

export function handleSearch(data: any) {
  return (dispatch: any) => {
    dispatch(startSearching());
    fetchSearch(data)
      .then((res: any) => {
        const sortedResult = orderBy(res, ['search_term', 'details']);
        dispatch(getSearchResult(sortedResult));
      })
      .catch((error: any) => {
        console.log('error in handleSearch', error);
        dispatch(searchingFailed(error));
      });
  };
}
