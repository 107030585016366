import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import qs from 'qs';

interface Club {
  org_id: string;
  org_name: string;
  org_city: string | null;
  org_state: string | null;
  sport_id: number;
  org_logo_url: string | null;
  num_teams: number | null;
  num_players: number | null;
}

type FetchClubsOptions = {
  org_id?: string | undefined;
  team_id?: string | undefined;
  team_year?: string | undefined;
  region?: string | undefined;
  sport_id?: number | 1;
};

export async function fetchClubTeamSummary(opts: FetchClubsOptions): Promise<Club[]> {
  try {
    const query = qs.stringify({
      // org_id: opts.org_id,
      parent_team_id: opts.org_id,
      team_id: opts.team_id,
      team_year: opts.team_year,
      region: opts.region,
      sport_id: opts.sport_id,
    });

    const data = await fetchAuthorization(
      // `${API_URL}org_team_summary?${query}`,
      `${API_URL}team_summary_stats?${query}`,
    );

    const teams = await data.json();

    return teams;
  } catch (e: any) {
    throw new Error(`Something went wrong. Error message: ${e.message}`);
  }
}

export default fetchClubTeamSummary;
