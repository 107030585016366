import { combineReducers } from 'redux';
import testReducer from './testReducer';
import teamReducer from './teamReducer';
import geographiesReducer from './geographiesReducer';
import eventsReducer from './eventsReducer';
import commonReducer from './commonReducer';
import playerReducer from './playerReducer';
import searchReducer from './searchReducer';
import memberAssociationsReducer from './memberAssociationsReducer';
import memberOnboardingReducer from './memberOnboardingReducer';
import faceToFaceReducer from './faceToFaceReducer';
import memberReducer from './memberReducer';
import clubReducer from './clubReducer';
import gameReducer from './gameReducer';
import accountReducer from './accountReducer';
import sourceDataReducer from './sourceDataReducer';
import playerTeamMapReducer from './playerTeamMapreducer';
import videosReducer from './videoReducer';
import tscoAdminReducer from './tscoadminReducer';
import commitmentReducer from './commitmentReducer';

export default combineReducers({
  testReducer,
  teamReducer,
  geographiesReducer,
  eventsReducer,
  commonReducer,
  playerReducer,
  searchReducer,
  memberAssociationsReducer,
  memberOnboardingReducer,
  faceToFaceReducer,
  memberReducer,
  clubReducer,
  gameReducer,
  accountReducer,
  sourceDataReducer,
  playerTeamMapReducer,
  videosReducer,
  tscoAdminReducer,
  commitmentReducer,
});

