import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@mui/material';
import 'date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { useStyles } from './styles';
import ModalTitle from './ModalTitle';
import SearchInput from './SearchInput';
import MaterialuiSelect from '../Select/MaterialuiSelect';
import { yearRange } from '../../constants';
import { makeid } from '../../helpers';
import { MemberContext } from '../CognitoAuth';
import * as teamActions from '../../redux/actions/teamActions';
import * as gameActions from '../../redux/actions/gameActions';

type Props = {
  showAddGame: boolean;
  onClose: () => void;
  dispatchGetTeams: any;
  gameReduxState: any;
  dispatchAddGame: any;
};

const teamDivisions = yearRange.map((x) => {
  return { label: x, value: x };
});

const AddGameModal: React.FC<Props> = (props) => {
  const { showAddGame, onClose, dispatchGetTeams, gameReduxState, dispatchAddGame } = props;

  const { isLoadingAddGame } = gameReduxState;
  const classes = useStyles();
  const { member } = useContext<any>(MemberContext);
  const sportId = member ? member.sport_id_default : 1;
  // Home clubs
  const [homeClubId, setHomeClubId] = useState('');
  const [homeTeams, setHomeTeams] = useState<Array<any>>([]);
  const [selectHomeTeams, setSelectHomeTeams] = useState<Array<any>>([]);
  const [homeTeamId, setHomeTeamId] = useState('');

  // Away clubs
  const [awayClubId, setAwayClubId] = useState('');
  const [awayTeams, setAwayTeams] = useState<Array<any>>([]);
  const [selectAwayTeams, setSelectAwayTeams] = useState<Array<any>>([]);
  const [awayTeamId, setAwayTeamId] = useState('');

  const [success, setSuccess] = useState<boolean>(false);
  const [homeScore, setHomeScore] = useState<any>('');
  const [awayScore, setAwayScore] = useState<any>('');
  const [teamDivision, setTeamDivision] = useState(new Date().getFullYear());

  const [selectedDate, setSelectedDate] = React.useState<any>(new Date());

  const handleDateChange = (date: unknown) => {
    setSelectedDate(date);
  };

  const handleClose = () => {
    onClose();
  };

  const resetForm = () => {
    setSuccess(false);
    handleClose();
  };

  const onSubmit = () => {
    const gameId = makeid(8);

    const game: any = {
      game_id: gameId,
      game_date: moment(selectedDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      tournament_id: 'POKJO8R8',
      sport_id: sportId,
      tournament_name: 'Crabfeast',
      bracket_year: teamDivision,
      away_team_id: awayTeamId,
      home_team_id: homeTeamId,
      away_team_score: Number(awayScore),
      home_team_score: Number(homeScore),
    };

    const responseHandler = (success: boolean) => {
      if (success) {
        setSuccess(true);
      }
    };
    dispatchAddGame(game, responseHandler);
  };

  const isSubmitDisabled = () => {
    if (isLoadingAddGame || homeClubId === '' || awayClubId === '' || homeTeamId === '' || awayTeamId === '' || homeScore === '' || awayScore === '' || selectedDate == null || teamDivision == null) {
      return true;
    }
    return false;
  };
  const onSelectHomeClub = (data: any) => {
    setHomeClubId(data.fk_id);
  };

  const onSelectHomeTeam = (event: any) => {
    setHomeTeamId(event.target.value);
  };

  const onSelectAwayClub = (data: any) => {
    setAwayClubId(data.fk_id);
  };

  const onSelectAwayTeam = (event: any) => {
    setAwayTeamId(event.target.value);
  };

  const getFullName = () => {
    return `${member ? member.first_name : ''} ${member ? member.last_name : ''}`;
  };

  useEffect(() => {
    const data: any = {};
    if (homeClubId !== '') {
      data.parent_team_id = homeClubId;

      const responseHandler = (res: Array<any>) => {
        if (res && res.length) {
          setHomeTeams(res);
        }
      };
      dispatchGetTeams(data, responseHandler);
    }
  }, [homeClubId, dispatchGetTeams]);

  useEffect(() => {
    if (homeTeams.length !== 0) {
      let selectTeamsArray: Array<any> = [];
      for (const team of homeTeams) {
        selectTeamsArray.push({
          label: `${team.team_name} ${team.team_year}`,
          value: team.team_id,
        });
      }
      setSelectHomeTeams(selectTeamsArray);
    }
  }, [homeTeams]);

  useEffect(() => {
    const data: any = {};
    if (awayClubId !== '') {
      data.parent_team_id = awayClubId;

      const responseHandler = (res: Array<any>) => {
        if (res && res.length) {
          setAwayTeams(res);
        }
      };
      dispatchGetTeams(data, responseHandler);
    }
  }, [awayClubId, dispatchGetTeams]);

  useEffect(() => {
    if (awayTeams.length !== 0) {
      let selectTeamsArray: Array<any> = [];
      for (const team of awayTeams) {
        selectTeamsArray.push({
          label: `${team.team_name} ${team.team_year}`,
          value: team.team_id,
        });
      }
      setSelectAwayTeams(selectTeamsArray);
    }
  }, [awayTeams]);

  const onSelectDivision = (division: any) => {
    setTeamDivision(division);
    // getTeams(clubId, division);
  };

  /* Check Home Club state */
  const isHomeTeamSelectionVisible = () => {
    return (homeClubId !== '' && homeClubId !== undefined && homeClubId !== null) || isLoadingAddGame;
  };

  /* Check Away Team state */
  const isAwayTeamSelectionVisible = () => {
    return (awayClubId !== '' && awayClubId !== undefined && awayClubId !== null) || isLoadingAddGame;
  };

  return (
    <Dialog maxWidth="xs" fullWidth={true} open={showAddGame}>
      <ModalTitle title={'Add a Game'} memberName={getFullName()} onClose={onClose} />
      <DialogContent className={classes.dialogContent}>
        <div className={classes.popupForm}>
          <div className={classes.popupFormSelect} style={{ paddingBottom: 20 }}>
            <label>Date</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={selectedDate}
                onChange={handleDateChange}
                className={classes.scoreFiled}
                renderInput={(params) => <TextField {...params} />}
                inputFormat="MM/dd/yyyy"
                label=""
              />
            </LocalizationProvider>
          </div>
          <div style={{ width: '100%', marginBottom: '20px' }}>
          <SearchInput keyType="events" placeholder="Search for an Event" label="Event" onSelect={(data: any) => onSelectHomeClub(data)} id="events" />
          </div>
          <div className={classes.popupFormSelect} style={{ paddingBottom: 20 }}>
            <label>Division</label>
            <MaterialuiSelect id="team-division" value={teamDivision} onValueChange={(e: any) => onSelectDivision(e.target.value)} data={teamDivisions} />
          </div>
          <div style={{ width: '100%'}}>
          <SearchInput keyType="org_id" placeholder="Search for a Club" label="Home Club" onSelect={(data: any) => onSelectHomeClub(data)} id="homeClub" />
          </div>
          <div className={classes.popupFormSection} style={{ paddingBottom: isHomeTeamSelectionVisible() ? 20 : 0 }}>
            {isHomeTeamSelectionVisible() && (
              <>
                <div className={classes.popupFormSelect} style={{ paddingTop: 0, flex: 1 }}>
                  <label>Home Team</label>
                  <MaterialuiSelect id="homeTeam" value={homeTeamId} placeholder="First, select a club" onValueChange={onSelectHomeTeam} data={selectHomeTeams} />
                </div>
                <TextField
                  id="homeGoals"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Goals'}
                  placeholder={'00'}
                  onChange={(e: any) => setHomeScore(e.target.value)}
                  value={homeScore}
                  type="number"
                  className={classes.scoreFiled}
                />
              </>
            )}
          </div>
          <div style={{ width: '100%'}}>
          <SearchInput keyType="org_id" placeholder="Search for a Club" label="Away Club" onSelect={(data: any) => onSelectAwayClub(data)} id="awayClub" />
          </div>
          <div className={classes.popupFormSection} style={{ paddingBottom: isAwayTeamSelectionVisible() ? 20 : 0 }}>
            {isAwayTeamSelectionVisible() && (
              <>
                <div className={classes.popupFormSelect} style={{ paddingTop: 0, flex: 1 }}>
                  <label>Away Team</label>
                  <MaterialuiSelect id="awayTeam" value={awayTeamId} placeholder="First, select a club" onValueChange={onSelectAwayTeam} data={selectAwayTeams} />
                </div>
                <TextField
                  id="awayGoals"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Goals'}
                  placeholder={'00'}
                  onChange={(e: any) => setAwayScore(e.target.value)}
                  value={awayScore}
                  type="number"
                  className={classes.scoreFiled}
                />
              </>
            )}
          </div>
          <DialogActions className={classes.playerDialogActions}>
            <Button variant="contained" color="primary" onClick={onSubmit} sx={{ mt: 2 }} className={classes.playerCSVSubmit} disabled={isSubmitDisabled()}>
              {!isLoadingAddGame ? 'Save' : <CircularProgress size={22} className={classes.buttonProgress} />}
            </Button>
          </DialogActions>
        </div>

        <Dialog open={success} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Success</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Game has been added successfully.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  gameReduxState: state.gameReducer,
});

const mapDispatchToProps = {
  dispatchAddGame: (data: any, callback: any) => gameActions.addGame(data, callback),
  dispatchGetTeams: (data: any, callback: any) => teamActions.getTeamsForModal(data, callback),
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGameModal);
