export const timeZoneOptions = [
  {
    label: 'Eastern',
    value: 'Eastern',
  },
  {
    label: 'Central',
    value: 'Central',
  },
  {
    label: 'Mountain',
    value: 'Mountain',
  },
  {
    label: 'Pacific',
    value: 'Pacific',
  },
];
