import { CognitoUser } from '@aws-amplify/auth';

const getUserAttribute =
  (attributeName: string) =>
  (user: CognitoUser): Promise<string> => {
    return new Promise((resolve, reject) => {
      user.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err);
          return;
        }

        if (!attributes) {
          reject();
          return;
        }

        const attribute = attributes.find((attribute) => attribute.getName() === attributeName);
        if (!attribute) {
          reject();
          return;
        }

        resolve(attribute.getValue());
      });
    });
  };

export const getEmailFromCognitoUser = getUserAttribute('email');
export const getFirstNameFromCognitoUser = getUserAttribute('given_name');
export const getLastNameFromCognitoUser = getUserAttribute('family_name');
export const getSubFromCognitoUser = getUserAttribute('sub');
