import { SET_MEMBER_DATA, UPDATE_MEMBER_START, UPDATE_MEMBER_SUCCESS, UPDATE_MEMBER_FAILED, SET_MEMBER_PROFILE, MEMBER_PING_SUCCESS, GET_AUTH_TOKEN_SUCCESS, US_MEMBER_AGE_VERIFY_SUCCESS, US_MEMBER_AGE_VERIFY_DIVISION_SUCCESS } from '../actions/memberActions';

let initialState = {
  isLoadingUpdateMember: false,
  updateError: null,
  member: {},
  memberProfile: {},
  memAgeVerify: {},
  memberPing: {},
  authToken: {},
  division: {},
};

const memberReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_MEMBER_DATA:
      return { ...state, member: action.data ? action.data : {} };
    case UPDATE_MEMBER_START:
      return { ...state, isLoadingUpdateMember: true, updateError: null };
    case UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        isLoadingUpdateMember: false,
        member: { ...state.member, ...action.data },
      };
    case UPDATE_MEMBER_FAILED:
      return {
        ...state,
        isLoadingUpdateMember: false,
        updateError: action.error,
      };
    case SET_MEMBER_PROFILE:
      return {
        ...state,
        memberProfile: action.data,
      };
    case MEMBER_PING_SUCCESS: {
      return {
        ...state,
        memberPing: action.data,
      };
    }
    case GET_AUTH_TOKEN_SUCCESS: {
      return {
        ...state,
        authToken: action.data,
      };
    }
    case US_MEMBER_AGE_VERIFY_SUCCESS: {
      return {
        ...state,
        memAgeVerify: action.data,
      };
    }
    case US_MEMBER_AGE_VERIFY_DIVISION_SUCCESS: {
      return {
        ...state,
        division: action.data,
      };
    }
    default:
      return state;
  }
};

export default memberReducer;
