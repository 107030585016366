import {  API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import { getErrorMessage } from '../helpers';

export async function fetchOrganizationInfo(org_id: string): Promise<any> {
    try {
      const response = await fetchAuthorization(`${API_URL}orgs?org_id=${org_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const result = await response.json();
  
      if (response.status !== 200) {
        const message = getErrorMessage(result);
        throw new Error(message ? message : `Status Code: ${response.status}. Something went wrong. Please try again later.`);
      }
  
      return result;
    } catch (e: any) {
      throw new Error(`Something went wrong. Error message: ${e.message}`);
    }
  }
  
  export default fetchOrganizationInfo;