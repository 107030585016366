import React, { useState } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField, Typography } from '@mui/material';
import { useStyles } from './styles';
import * as feedbackAction from '../../redux/actions/feedbackAction';
import { connect } from 'react-redux';
import { Feedback } from '../../apis/addFeedback';

type Props = {
  showFeedback: boolean;
  onClose: () => void;
  dispatchAddFeedbackApi: (feedback: Partial<Feedback>) => void;
  memberID: any;
};

const FeedbackModal: React.FC<Props> = (props) => {
  const { showFeedback, onClose, dispatchAddFeedbackApi, memberID } = props;
  const classes = useStyles();
  const [subject, setSubject] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  const [isCheck, setIsCheck] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState<any>({
    subject: '',
    comments: '',
    isCheck: '',
  });

  const handleChange = (e: any) => {
    let isChecked = e.target.checked;
    setIsCheck(isChecked);
  };

  const onSubmit = () => {
    setIsSubmit(true);
    if (subject === '' || comments === '' || isCheck === false) {
      setSuccess(false);
      const error: any = {};

      if (!subject) error.subject = 'subject is required !';

      if (!comments) error.comments = 'comments is required !';
      if (!isCheck) error.isCheck = '';
      return setError({ ...error });
    } else {
      const feedback: Partial<Feedback> = {
        subject: subject,
        message_body: comments,
        allow_followup_YN: isCheck,
        member_id: memberID.member_id,
      };
      dispatchAddFeedbackApi(feedback);
      setSuccess(true);
    }
  };

  const handleClose = () => {
    onClose();
  };
  const resetForm = () => {
    setSubject('');
    setComments('');
    setIsCheck(false);
    setSuccess(false);
    onClose();
  };

  const OnkeyDown = (e: any) => {
    if (e.key === 'Escape') {
      handleClose();
    }
  };

  return (
    <Dialog maxWidth="xs" fullWidth={true} open={showFeedback} onKeyDown={OnkeyDown} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" autoFocus={true}>
      <DialogTitle>
        <Typography variant="h2"  className={classes.addPlayerTitle}>
          User Feedback
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1">Please tell us what you do/do not like about the site. What is working? What should we add? If you tell us, we will get right on it!!</Typography>
        <div className={classes.eventpopupFormSearchInputWrapper}>
          <TextField
            label="Subject"
            style={{ margin: 0 }}
            placeholder="Enter Subject"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="false"
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
              setIsSubmit(false);
              if (!e.target.value) {
                return setError({
                  ...error,
                  subject: 'A subject is required!',
                });
              } else {
                return setError({
                  ...error,
                  subject: '',
                });
              }
            }}
            className={classes.popupFormInput}
            helperText={isSubmit && error.subject}
            error={error.subject && isSubmit ? true : false}
            variant="standard"
          />
        </div>
        <div className={classes.MultiTextLineFiled}>
          <TextField
            id="outlined-multiline-static"
            label="Comments:"
            multiline
            rows={4}
            placeholder="Enter you comments here..."
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.popupFormInputMultiline}
            value={comments}
            onChange={(e) => {
              setComments(e.target.value);
              setIsSubmit(false);
              if (!e.target.value) {
                return setError({
                  ...error,
                  comments: 'Please add a comment (they are required)!',
                });
              } else {
                return setError({
                  ...error,
                  comments: '',
                });
              }
            }}
            variant="standard"
            inputProps={{ maxLength: 1000 }}
            autoComplete="false"
            margin="normal"
            style={{ margin: 0 }}
            error={error.comments && isSubmit ? true : false}
            helperText={isSubmit && error.comments}
          />
        </div>
        <div>
          <FormControlLabel control={<Checkbox name="allow_feedback" color="primary" checked={isCheck} onChange={(e) => handleChange(e)} required={true} />} label="Allow Follow-up" />
        </div>
        <Dialog open={success} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Thanks for your feedback!</DialogTitle>
          <DialogActions>
            <Button onClick={resetForm} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <DialogActions className={classes.feedbackDialogButton}>
        <Button color="primary" variant="contained" onClick={onSubmit}>
          Submit
        </Button>
        <Button color="primary" variant="outlined" onClick={resetForm}>
          Cancel
        </Button>
      </DialogActions>
      </DialogContent>
      
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  memberID: state.memberReducer.member,
});

const mapDispatchToProps = {
  dispatchAddFeedbackApi: (feedback: Partial<Feedback>) => feedbackAction.addFeedbackApi(feedback),
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackModal);
