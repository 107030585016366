const genderStates = [
  {
    label: 'Boys',
    value: 'boys',
  },
  {
    label: 'Girls',
    value: 'girls',
  },
];

export default genderStates;
