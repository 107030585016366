import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import MyProfileMenu from './MyProfileMenu';
import ConstrainedWidth from '../ConstrainedWidth';
import ClubLacrosseNavLink from './ClubLacrosseNavLink';
import Logo from '../../images/logo.png';
import { routes } from '../../constants';
import SearchBar from '../SearchBar';
import CustomButton from '../Common/CustomButton';
import { Member } from '../../models/Member';
import { connect } from 'react-redux';
import { getIcon, Icons } from '../../helpers/icons';
import { IconButton } from '@mui/material';

import DialogBox from '../Common/DialogBox';
import SourceDataProps from '../SourceData';
import ReactPlayer from 'react-player/lazy';
import { InfoDialog } from '../Common';

interface AppHeaderProps {
  member: Member;
}

// const StyledMenu = withStyles((theme) => ({
//   paper: {
//     backgroundColor: theme.palette.primary.main,
//     borderRadius: 3,
//     width: '120px',
//     color: theme.palette.common.white,
//     top: '113px !important',
//     left: 'calc(100vw/2 + 320px) !important',
//   },
// }))((props: MenuProps) => <Menu elevation={0} {...props} />);

const NAV_HEIGHT = 120;
const useStyles = makeStyles((theme: Theme) => ({
  errorCircle: {
    alignItems: 'center',
    backgroundColor: theme.palette.error.main,
    borderRadius: '50%',
    display: 'flex',
    fontWeight: 'bold',
    height: '24px',
    justifyContent: 'center',
    width: '24px',
  },

  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    background: `url(${Logo}) no-repeat left/${NAV_HEIGHT}px`,
    height: NAV_HEIGHT,
    paddingLeft: NAV_HEIGHT + 32 /* + theme.spacing(4) */,
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: NAV_HEIGHT / 2,
    marginRight: theme.spacing(2),
    minWidth: 75,
    fontSize: '.85rem',
    textAlign: 'center',
  },
  subscrLink: {
    textDecorationLine: 'none',
    marginTop: 25,
    },
  root: {
    background: `linear-gradient(to bottom, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.secondary.main} 50%,${theme.palette.secondary.main} 100%)`,
  },
  section: {
    display: 'flex',
  },
  searchBar: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    marginRight: 24,
    flexDirection: 'column',
    justifyContent: 'center',
    color: 'white',
    borderBottom: '5px solid transparent !important',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  subscribe: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 90,
    right: 'calc((100% - 1280px) / 2 + 25px)',
  },
  add: {
    alignItems: 'center',
    position: 'absolute',
    top: 75,
    right: 'calc((100% - 1280px) / 2 + 230px)',
  },
  addContainer: {
    position: 'relative',
    top: '-120px',
  },
}));

const AppHeader = ({ member }: AppHeaderProps) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const [open, setOpen] = useState<boolean>(false);
  const [isAddGame, setIsAddGame] = useState<boolean>(false);
  // eslint-disable-next-line
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [statsWarning, setStatsWarning] = useState<boolean>(false);
  const [subscribeInfo, setSubscribeInfo] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const closeDropdown = () => setDropdown(false);

  // eslint-disable-next-line
  const openDropdown = () => setDropdown(true);

  const toggleStatsWarning = () => setStatsWarning(!statsWarning);

  const handleAddGame = () => {
    closeDropdown();
    setIsAddGame(!isAddGame);
  };

  // eslint-disable-next-line
  const handleAddStats = () => {
    if (member.member_type !== 'Player') {
      toggleStatsWarning();
    }
    closeDropdown();
  };

  const handleSubscribeInfo = () => {
    setSubscribeInfo(!subscribeInfo);
  };

  return (
    <header data-testid="app-header" className={classes.root}>
      <ConstrainedWidth>
        <nav className={classes.nav}>
          <div className={classes.section}>
            <ClubLacrosseNavLink end className={classes.link} to={routes.dashboard()}>
              {getIcon(Icons.DASHBOARD)}
              My Dashboard
            </ClubLacrosseNavLink>
            <ClubLacrosseNavLink className={classes.link} to={routes.clubs()}>
              {getIcon(Icons.FLAG)}
              Club Stats & Rankings
            </ClubLacrosseNavLink>
            <ClubLacrosseNavLink className={classes.link} to={routes.teams()}>
              {getIcon(Icons.LEADERDASHBOARD)}
              Divisions & Teams Stats/Rankings
            </ClubLacrosseNavLink>
            <ClubLacrosseNavLink className={classes.link} to={routes.events()}>
              {getIcon(Icons.CALENDAR)}
              Events Database
            </ClubLacrosseNavLink>
            <ClubLacrosseNavLink className={classes.link} to={routes.dataLab()}>
              {getIcon(Icons.PLAYERS)}
              Team & Player Data Lab
            </ClubLacrosseNavLink>
            {/* <ClubLacrosseNavLink className={classes.link} to={routes.collegeCoaches()}>
              {getIcon(Icons.SPORT)}
              College Coaches
            </ClubLacrosseNavLink> */}
            <ClubLacrosseNavLink className={classes.link} to={'https://public.clublacrosse.org/commitments'}>
              {getIcon(Icons.SPORT)}
              Commitments Database
             </ClubLacrosseNavLink>
{/*}            <ClubLacrosseNavLink className={classes.link} to={routes.producers()}>            //  COMMENTED OUT Aug 1, 2024 by DBC as per David Earl's suggestion
              {getIcon(Icons.STATS)}
              Event Management
            </ClubLacrosseNavLink> */}
          {/*             <ClubLacrosseNavLink className={classes.link} to={routes.videos()}>
              {getIcon(Icons.VIDEO)}
              Community Videos
            </ClubLacrosseNavLink> */}
          </div>

          <div className={classes.section}>
            <MyProfileMenu />
            {/* <ClubLacrosseNavLink
              active
              noActiveBorder
              className={classes.link}
              to={routes.alerts()}
            >
              <div className={classes.errorCircle}>8</div>
              Alerts
            </ClubLacrosseNavLink> */}
          </div>
        </nav>
      </ConstrainedWidth>
      {/* <div className={classes.addContainer}>
        <IconButton
          color="primary"
          style={{ padding: 0 }}
          component="span"
          onClick={openDropdown}
          className={classes.add}
        >
          {getIcon(Icons.ADD, { fontSize: '39px' })}
        </IconButton>
        <StyledMenu open={dropdown} onClose={closeDropdown}>
          <MenuItem onClick={handleAddGame}>Add Game</MenuItem>
          <MenuItem onClick={handleAddStats}>Add My Stats</MenuItem>
        </StyledMenu>
      </div> */}
      {member.is_subscriber_YN || pathname === routes.subscription() ? null : (
        <div className={classes.subscribe}>
          <NavLink className={classes.subscrLink} to={routes.subscription()}>
            <CustomButton label={'Upgrade Now!'} width={'154px'} btnStyles={{ background: '#FF2839' }} />
          </NavLink>
          <IconButton color="primary" style={{ padding: 0, marginLeft: '10px' }} component="span" onClick={handleSubscribeInfo}>
            {getIcon(Icons.QUESTION, { color: 'silver', padding: 0 })}
          </IconButton>
        </div>
      )}
      <SearchBar open={open} onClose={handleClose} sportId={member.sport_id_default} />
      <DialogBox label="What is Free and What is Premium?" isOpen={subscribeInfo} maxWidth="sm" onClose={handleSubscribeInfo}>
        <ReactPlayer url="https://youtu.be/yBf0pMz1iDk" width="100%" light />
      </DialogBox>
      <DialogBox label="" isOpen={isAddGame} maxWidth="lg" onClose={handleAddGame}>
        <SourceDataProps headerLabel="Create Game Wizard" />
      </DialogBox>
      <InfoDialog title={``} isOpen={statsWarning} textMessage={`Statistics are only to be added only for members that are Players.`} onClose={toggleStatsWarning} onConfirm={toggleStatsWarning} />
    </header>
  );
};

const mapStateToProps = (state: any) => ({
  member: state.memberReducer.member,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
