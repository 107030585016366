import { PlayerTeamMap } from '../../models/PlayerTeamMap';
import { _fetchRequest } from '../../apis/_fetchRequest';
import { FETCH_MEMBER_TEAM_ASSOCIATIONS_FAILED, FETCH_POST_MEMBER_TEAM_ASSOCIATIONS_SUCCESS } from '../action-types/teamActionTypes';

export function postPlayerTeamMap(teamAss: Partial<PlayerTeamMap>, teamName?: string,callback?: any) {
  return (dispatch: any) => {
    _fetchRequest<PlayerTeamMap[]>('POST', 'player_team_map', {}, teamAss)
      .then(async () => {
        dispatch({
          type: FETCH_POST_MEMBER_TEAM_ASSOCIATIONS_SUCCESS,
          payload: {
            ...teamAss,
            team_name: teamName,
          },
        });
        callback(true);
      })
      .catch((error: any) => {
        console.log('error in getTeamSummaryStats', error);
        dispatch({
          type: FETCH_MEMBER_TEAM_ASSOCIATIONS_FAILED,
        });
        callback(false);
      });
  };
}
