import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import qs from 'qs';
import { MemberAssociation } from '../models/MemberAssociation';

/*
  Example JSON request body
  {
    "app_id": 1,
    "sport_id": 1,
    "association_id": 2,
    "member_id": "j4j90z4w",
    "association_fk_id": "test",
    "is_active_YN": 1,
    "created_by": "j4j90z4w",
    "created_datetime": "2020-03-16"
  }
*/

export async function updateMemberAssociation(data: MemberAssociation, reqType: string): Promise<any> {
  try {
    const query = qs.stringify({
      row_num: data.row_num,
    });

    const response = await fetchAuthorization(`${API_URL}member_follows?${query}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: reqType, // 'POST' or 'DELETE'
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (response.status !== 200) {
      throw new Error(result.message ? result.message : `Status Code: ${response.status}. Sorry, something went wrong. Please try again later.`);
    }

    return result;
  } catch (e: any) {
    throw new Error(`Unable to update memeber. ${e.message}`);
  }
}

export default updateMemberAssociation;
