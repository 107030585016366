export const FETCH_TEAMS_REQ_START = 'FETCH_TEAMS_REQ_START';
export const FETCH_TEAMS_REQ_SUCCESS = 'FETCH_TEAMS_REQ_SUCCESS';
export const FETCH_TEAMS_REQ_FAILED = 'FETCH_TEAMS_REQ_FAILED';

export const ADD_TEAM_REQ_START = 'ADD_TEAM_REQ_START';
export const ADD_TEAM_REQ_SUCCESS = 'ADD_TEAM_REQ_SUCCESS';
export const ADD_TEAM_REQ_FAILED = 'ADD_TEAM_REQ_FAILED';

export const FETCH_TEAM_RANKINGS_REQ_START = 'FETCH_TEAM_RANKINGS_REQ_START';
export const FETCH_TEAM_RANKINGS_REQ_SUCCESS = 'FETCH_TEAM_RANKINGS_REQ_SUCCESS';
export const FETCH_TEAM_RANKINGS_REQ_FAILED = 'FETCH_TEAM_RANKINGS_REQ_FAILED';

export const FETCH_TEAM_STATS_REQ_START = 'FETCH_TEAM_STATS_REQ_START';
export const FETCH_TEAM_STATS_REQ_SUCCESS = 'FETCH_TEAM_STATS_REQ_SUCCESS';
export const FETCH_TEAM_STATS_REQ_FAILED = 'FETCH_TEAM_STATS_REQ_FAILED';

export const FETCH_TEAM_GAMES_REQ_START = 'FETCH_TEAM_GAMES_REQ_START';
export const FETCH_TEAM_GAMES_REQ_SUCCESS = 'FETCH_TEAM_GAMES_REQ_SUCCESS';
export const FETCH_TEAM_GAMES_REQ_FAILED = 'FETCH_TEAM_GAMES_REQ_FAILED';

export const FETCH_FOLLOWED_TEAMS_REQ_START = 'FETCH_FOLLOWED_TEAMS_REQ_START';
export const FETCH_FOLLOWED_TEAMS_REQ_SUCCESS = 'FETCH_FOLLOWED_TEAMS_REQ_SUCCESS';
export const FETCH_FOLLOWED_TEAMS_REQ_FAILED = 'FETCH_FOLLOWED_TEAMS_REQ_FAILED';

//getTeamSummaryStatsStart
export const FETCH_TEAM_SUMMARY_STATS_REQ_START = 'FETCH_TEAM_SUMMARY_STATS_REQ_START';
export const FETCH_TEAM_SUMMARY_STATS_REQ_SUCCESS = 'FETCH_TEAM_SUMMARY_STATS_REQ_SUCCESS';
export const FETCH_TEAM_SUMMARY_STATS_REQ_FAILED = 'FETCH_TEAM_SUMMARY_STATS_REQ_FAILED';

//getHistoricalGames
export const FETCH_HISTORICAL_GAMES_STARTED = 'FETCH_HISTORICAL_GAMES_STARTED';
export const FETCH_HISTORICAL_GAMES_FAILED = 'FETCH_HISTORICAL_GAMES_FAILED';
export const FETCH_HISTORICAL_GAMES_SUCCESS = 'FETCH_HISTORICAL_GAMES_SUCCESS';

export const FETCH_MEMBER_TEAM_ASSOCIATIONS_STARTED = 'FETCH_MEMBER_TEAM_ASSOCIATIONS_STARTED';
export const FETCH_MEMBER_TEAM_ASSOCIATIONS_SUCCESS = 'FETCH_MEMBER_TEAM_ASSOCIATIONS_SUCCESS';
export const FETCH_MEMBER_TEAM_ASSOCIATIONS_FAILED = 'FETCH_MEMBER_TEAM_ASSOCIATIONS_FAILED';
export const FETCH_DELETE_MEMBER_TEAM_ASSOCIATIONS_SUCCESS = 'FETCH_DELETE_MEMBER_TEAM_ASSOCIATIONS_SUCCESS';
export const FETCH_PUT_MEMBER_TEAM_ASSOCIATIONS_SUCCESS = 'FETCH_PUT_MEMBER_TEAM_ASSOCIATIONS_SUCCESS';
export const FETCH_GET_ORGS_FAILED = 'FETCH_GET_ORGS_FAILED';
export const FETCH_GET_ORGS_SUCCESS = 'FETCH_GET_ORGS_SUCCESS';
export const FETCH_GET_ORGS_STARTED = 'FETCH_GET_ORGS_STARTED';
export const FETCH_POST_MEMBER_TEAM_ASSOCIATIONS_SUCCESS = 'FETCH_POST_MEMBER_TEAM_ASSOCIATIONS_SUCCESS';

export const FETCH_PUT_TEAM_SUCCESS = 'FETCH_PUT_TEAM_SUCCESS';
export const FETCH_GET_ORG_SUCCESS = 'FETCH_GET_ORG_SUCCESS';

export const FETCH_TEAMS_PLAYERS_STATS_REQ_SUCCESS = 'FETCH_TEAMS_PLAYERS_STATS_REQ_SUCCESS';
export const FETCH_TEAMS_PLAYERS_STATS_REQ_START = 'FETCH_TEAMS_PLAYERS_STATS_REQ_START';
export const FETCH_TEAMS_PLAYERS_STATS_REQ_FAILED = 'FETCH_TEAMS_PLAYERS_STATS_REQ_FAILED';


