import addFeedback from '../../apis/addFeedback';
import * as commonActions from './commonActions';
import { Feedback } from '../../apis/addFeedback';
export const ADD_FEEDBACK_SUCCESS = 'ADD_FEEDBACK_SUCCESS';
export const ADD_FEEDBACK_FAILED = 'ADD_FEEDBACK_FAILED';

export const addFeedbackSuccess = () => ({
    type: ADD_FEEDBACK_SUCCESS,
  });

  export const addGameFailed = (error: string) => ({
    type: ADD_FEEDBACK_FAILED,
    error,
  });  

export function addFeedbackApi(feedback: Partial<Feedback>) {
    return (dispatch: any) => {
        addFeedback(feedback)
        .then((res: any) => {
          dispatch(addFeedbackSuccess());
        })
        .catch((error: any) => {
          console.log('error in set ', error);
          dispatch(addGameFailed(error));
          dispatch(commonActions.handleError(error));
        });
    };
  }