import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Dialog, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, ToggleButtonGroup, ToggleButton, Autocomplete } from '@mui/material';
import { useStyles } from './styles';
import ModalTitle from './ModalTitle';
import { MemberContext } from '../CognitoAuth';
import * as commitmentActions from '../../redux/actions/commitmentActions';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { orderBy } from 'lodash';
import { PlayerPosition } from '../../enums';
import { Universities } from '../../models/Universities';
import { useNavigate } from 'react-router-dom';
import { AddCommitment } from '../../apis/addCommitment';
import MaterialuiSelect from '../Select/MaterialuiSelect';
import SearchIcon from '@mui/icons-material/Search';
type Props = {
  organization: any;
  isLoadingAddEvent: boolean;
  showAddCommitment: boolean;
  onClose: () => void;
  dispatchfetchUniversities: (sport_id: number) => void;
  universities: Universities[];
  dispatchfetchHighSchools: (sport_id: number) => void;
  highSchools: HighSchool[];
  dispatchAddCommitment: (data: AddCommitment) => void;
  commitmentIsAdded: boolean;
  dispatchAddedCommitmentSuccess: (value: boolean) => void;
};

const getLabelForHighSchool = (item: any) => {
  if (item) return `${item.high_school_orig} (${item.hs_state})`;
};

const AddCommitmentModal: React.FC<Props> = ({ organization, showAddCommitment, isLoadingAddEvent, universities, highSchools, commitmentIsAdded, dispatchAddCommitment, dispatchfetchHighSchools, dispatchfetchUniversities, onClose, dispatchAddedCommitmentSuccess }: Props) => {
  const classes = useStyles();
  const { member } = useContext<any>(MemberContext);
  const sport_id = organization?.sport_id;
  const navigate = useNavigate();

  const [selectedSportId, setSportId] = React.useState(sport_id);
  const [openHighSchool, setOpenHighSchool] = useState(false);
  const [openUniversity, setOpenUniversity] = useState(false);

  const [CommitmentDate, setCommitmentDate] = useState<string | null>(null);
  const [position, setPosition] = useState<string>('');
  const [playerName, setPlayerName] = useState<string>('');
  const [highSchoolClass, setHighSchoolClass] = useState<string>('');
  const [university, setUniversity] = useState<string>('');
  const [highSchool, setHighSchool] = useState<string>('');
  const [supportURL, setsupportURL] = useState<string>('');
  const [openDailouge, setOpenDailouge] = useState<boolean>(false);

  const options = ['2020', '2021', '2022', '2023', '2024', '2025', '2026'];
  const UniversitiesOptions =
    universities?.map((item: Universities) => {
      return {
        label: item.short_name,
        id: item.university_id,
      };
    }) || [];

  const HighSchoolOptions =
    highSchools?.map((item: HighSchool) => {
      return {
        label: getLabelForHighSchool(item) || '',
        id: item.hs_state,
      };
    }) || [];

  const highSchoolClassOptions = options.map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  const playerPositionOptions = Object.values(PlayerPosition).map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  useEffect(() => {
    dispatchfetchUniversities(sport_id);
    dispatchfetchHighSchools(sport_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (commitmentIsAdded) {
      setOpenDailouge(true);
    }
  }, [commitmentIsAdded]);

  const handleClose = () => {
    onClose();
    dispatchAddedCommitmentSuccess(false);
  };

  const isSubmitDisabled = () => {
    if (isLoadingAddEvent || CommitmentDate === '' || position === '' || playerName === '' || highSchoolClass.length === 0 || university === '' || highSchool === '' || supportURL === '') {
      return true;
    }
    return false;
  };

  const resetForm = () => {
    setCommitmentDate(null);
    setPosition('');
    setHighSchoolClass('');
    setHighSchool('');
    setPlayerName('');
    setUniversity('');
    setsupportURL('');
    setOpenDailouge(false);
    dispatchAddedCommitmentSuccess(false);
    setOpenHighSchool(false);
    setOpenUniversity(false);
  };

  const onSubmit = () => {
    if (isSubmitDisabled()) {
      return;
    }
    const data = {
      player_name: playerName,
      gender_id: sport_id === 1 ? 'boys' : sport_id === 2 ? 'girls' : '',
      class_id: highSchoolClass,
      position_name: position,
      school_name: university,
      division_id: universities?.find((item: Universities) => item.short_name === university)?.division || '',
      high_school: highSchools?.find((item: HighSchool) => getLabelForHighSchool(item) === highSchool)?.high_school_orig || '',
      hs_state: highSchools?.find((item: HighSchool) => getLabelForHighSchool(item) === highSchool)?.hs_state || '',
      club_team: organization.org_short_name,
      commitment_date: CommitmentDate || '',
      supportURL: supportURL,
      is_active_YN: 1,
      created_datetime: new Date(),
    };
    dispatchAddCommitment(data);
  };

  const getFullName = () => {
    return `${member ? member.first_name : ''} ${member ? member.last_name : ''}`;
  };

  const onChangeSportId = (event: React.MouseEvent<HTMLElement>, newAlignment: number | null) => {
    if (newAlignment) {
      setSportId(newAlignment);
    }
  };

  const OnkeyDown = (e: any) => {
    if (e.key === 'Escape') {
      handleClose();
    }
  };

  return (
    <Dialog maxWidth="xs" fullWidth={true} open={showAddCommitment} onKeyDown={OnkeyDown}>
      <ModalTitle title={'Request A Commitment To Be Added'} memberName={getFullName()} onClose={onClose} />
      <DialogContent className={classes.dialogContent}>
        <div className={classes.popupForm}>
          <ToggleButtonGroup
            value={selectedSportId}
            exclusive
            onChange={onChangeSportId}
            sx={{
              '& .MuiToggleButton-root.Mui-selected': {
                backgroundColor: '#1c315f',
                color: '#fff !important',
                '&:hover': {
                  backgroundColor: '#1c315f',
                },
              },
            }}
            classes={{
              root: classes.toggle,
            }}
            className={classes.toggleButton}
          >
            <ToggleButton value={1} className={classes.selectedToggleButton}>
              Boys
            </ToggleButton>
            <ToggleButton value={2} className={classes.selectedToggleButton}>
              Girls
            </ToggleButton>
          </ToggleButtonGroup>

          <div className={`${classes.popupFormlabel}`}>
            <label>Player Name *</label>
            <TextField
              style={{ margin: 0 }}
              placeholder="Enter Player First and Last Name"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus={true}
              autoComplete="false"
              value={playerName}
              className={classes.popupFormInput}
              variant="standard"
              onChange={(e) => {
                setPlayerName(e.target.value);
              }}
              tabIndex={1}
              inputProps={{
                style: { fontSize: 14 }, // Set the font size here
              }}
            />
          </div>
          <div className={`${classes.popupFormAutoComplete} ${classes.customMargin}`}>
            <label>University Attending*</label>
            <Autocomplete
              freeSolo
              sx={{ padding: 0 }}
              options={orderBy(UniversitiesOptions)}
              autoHighlight
              getOptionLabel={(option: any) => option.label}
              className={classes.popupFormInput}
              fullWidth
              open={openUniversity}
              onOpen={() => {
                if (university.length > 0) {
                  setOpenUniversity(true);
                }
              }}
              onClose={() => setOpenUniversity(false)}
              filterOptions={(options, params) => {
                const filtered = orderBy(options, ['label'], ['asc']).filter((option) => {
                  return option.label.toLowerCase().includes(params.inputValue.toLowerCase());
                });
                return filtered;
              }}
              inputValue={university}
              onInputChange={(_event: React.ChangeEvent<{}>, value: string, reason: any) => {
                if (reason === 'reset' && university === '') {
                  return;
                }
                if (value.length >= 0) {
                  setUniversity(value);
                  if (value.length === 0) {
                    setOpenUniversity(false);
                  }
                  else {
                    setOpenUniversity(true);
                  }
                }
                else {
                  setOpenUniversity(false);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder="Search for a University"
                  inputProps={{
                    ...params.inputProps,
                    style: { fontSize: 14 }, // Set the font size here
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        <SearchIcon style={{ cursor: 'pointer' }} /> {/* Use the SearchIcon component here */}
                      </React.Fragment>
                    ),
                  }}
                  className={classes.popupFormSelectThree}
                />
              )}
            />
          </div>
          <div className={classes.twoFormInputs}>
            <div className={`${classes.popupFormSelect} ${classes.customMargin}`}>
              <label>High School Class *</label>
              <MaterialuiSelect id="commitment-highschoolclass" placeholder="Select..." value={highSchoolClass} onValueChange={(e: any) => setHighSchoolClass(e.target.value)} data={highSchoolClassOptions} />
            </div>

            <div className={`${classes.popupFormSelect} ${classes.customMargin}`}>
              <label>Position *</label>
              <MaterialuiSelect id="commitment-position" placeholder="Select..." value={position} onValueChange={(e: any) => setPosition(e.target.value)} data={orderBy(playerPositionOptions)} />
            </div>
          </div>
          <div className={`${classes.popupFormSelect} ${classes.eventpopupFormSearchInputWrapper}`}>
            <label>Approximate Commitment Date *</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={CommitmentDate}
                onChange={(date) => {
                  date ? setCommitmentDate(date) : setCommitmentDate('');
                }}
                className={classes.scoreFiled}
                renderInput={(params) => <TextField {...params} />}
                inputFormat="MM/dd/yyyy"
                label=""
              />
            </LocalizationProvider>
          </div>

          <div className={`${classes.popupFormAutoComplete}`}>
            <label>High School *</label>
            <Autocomplete
              freeSolo
              sx={{ padding: 0 }}
              options={HighSchoolOptions}
              autoHighlight
              getOptionLabel={(option: any) => (option ? String(option.label) : '')}
              className={classes.popupFormInput}
              fullWidth
              clearOnEscape
              open={openHighSchool}
              onOpen={() => {
                if (highSchool.length > 0) {
                  setOpenHighSchool(true);
                }
              }}
              onClose={() => setOpenHighSchool(false)}
              inputValue={highSchool}
              filterOptions={(options, params) => {
                const filtered = orderBy(options, ['label'], ['asc']).filter((option) => {
                  return option.label.toLowerCase().includes(params.inputValue.toLowerCase());
                });
                return filtered;
              }}
              clearOnBlur
              onInputChange={(_event: React.ChangeEvent<{}>, value: string, reason: any) => {
                if (reason === 'reset' && highSchool === '') {
                  return;
                }
                if (value.length >= 0) {
                  setHighSchool(value);
                  if (value.length === 0) {
                    setOpenHighSchool(false);
                  }
                  else {
                    setOpenHighSchool(true);
                  }
                } else {
                  setOpenHighSchool(false);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder="Search for the player's High School"
                  inputProps={{
                    ...params.inputProps,
                    style: { fontSize: 14 }, // Set the font size here

                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        <SearchIcon style={{ cursor: 'pointer' }} /> {/* Use the SearchIcon component here */}
                      </React.Fragment>
                    ),
                  }}
                  className={classes.popupFormSelectThree}
                />
              )}
            />
          </div>
          <div className={`${classes.popupFormlabel} ${classes.customMargin}`}>
            <label>Supporting Webpage or Instagram Post URL </label>
            <TextField
              style={{ margin: 0 }}
              placeholder="www.instagram.com/p/..."
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="false"
              value={supportURL}
              className={classes.popupFormInput}
              variant="standard"
              onChange={(e) => {
                setsupportURL(e.target.value);
              }}
              inputProps={{
                style: { fontSize: 14 }, // Set the font size here
              }}
            />
          </div>

          <DialogActions className={classes.playerDialogActions}>
            <Button variant="contained" color="primary" disabled={isSubmitDisabled()} onClick={onSubmit} sx={{ mt: 2 }} className={classes.playerCSVSubmit}>
              {!isLoadingAddEvent ? 'Submit This Request' : <CircularProgress size={22} className={classes.buttonProgress} />}
            </Button>
          </DialogActions>
        </div>

        <Dialog open={openDailouge} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Success</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">The Commitment was successfully added. Whould you like to add another one or return to the homePage?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                resetForm();
              }}
              color="primary"
            >
              Add Another
            </Button>
            <Button
              onClick={() => {
                navigate('https://members.clublacrosse.org/');
              }}
              color="primary"
            >
              Go to HomePage
            </Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  organization: state.commitmentReducer.organizationData[0],
  isLoadingAddEvent: state.commitmentReducer.isLoadingAddEvent,
  universities: state.commitmentReducer.universities,
  highSchools: state.commitmentReducer.highSchools,
  commitmentIsAdded: state.commitmentReducer.commitmentIsAdded,
});

const mapDispatchToProps = {
  dispatchAddedCommitmentSuccess: (value: boolean) => commitmentActions.addedCommitmentSuccess(value),
  dispatchAddCommitment: (data: AddCommitment) => commitmentActions.addCommitment(data),
  dispatchfetchUniversities: (sport_id: number) => commitmentActions.fetchUniversities(sport_id),
  dispatchfetchHighSchools: (sport_id: number) => commitmentActions.fetchHighSchools(sport_id),
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCommitmentModal);
