import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import qs from 'qs';
import _ from 'lodash';

interface Club {
  org_id: string;
  org_name: string;
  org_city: string | null;
  org_state: string | null;
  sport_id: number;
  org_logo_url: string | null;
  num_teams: number | null;
  num_players: number | null;
}

type FetchClubsOptions = {
  org_id?: string | undefined;
  sport_id?: number | 1;
};

export async function fetchClubsv2(opts: FetchClubsOptions): Promise<Club[]> {
  try {
    const query = qs.stringify({
      org_id: opts.org_id,
      sport_id: opts.sport_id,
      org_type: 'club',
    });

    const data = await fetchAuthorization(`${API_URL}orgs?${query}`);

    const clubs = await data.json();

    // let clubsFiltered = _.filter(clubs, function(c) {
    //   if (c.org_logo_url !== null) {
    //     return c;
    //   }
    //   // return c.sport_id === 1;
    // });
    const sortedClubs = _.orderBy(clubs, [(club) => club.org_name.toLowerCase()]);

    return sortedClubs;
  } catch (e: any) {
    throw new Error(`${e.message || 'Something went wrong!'}`);
  }
}

export default fetchClubsv2;
