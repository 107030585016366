// import _ from 'lodash';
import qs from 'qs';
import fetchAuthorization from './fetchAuthorization';
// import teamStatsMockData from './__mocks__/teamStats';
import { API_URL } from '../constants';

// export interface ApiTeam {
//   team_id?: string;
//   team_name?: string;
//   region?: string;
//   team_year?: number;
//   statistic_id?: number;
//   statistic_name?: string;
//   statistic_value?: number;
//   team_level?: string;
//   games_played?: number;
//   win_percent?: number;
//   wins?: number;
//   losses?: number;
//   goals_scored?: number;
//   goals_against?: number;
//   forced_turnovers?: number;
//   ground_balls?: number;
//   total_points?: number;
//   // is_following: number;
// }

// // ToDo: Parse data to include stats
// export type ParsedTeam = Omit<
//   ApiTeam,
//   'statistic_name' | 'statistic_id' | 'statistic_value'
// >;

// const mapTeamStats = (teams: ApiTeam[]): ParsedTeam[] => {
//   // map statistic to appropriate field
//   let parsedStats = _.map(teams, (team) => {
//     if (team.statistic_name === 'Wins') team.wins = team.statistic_value;
//     if (team.statistic_name === 'Losses') team.losses = team.statistic_value;
//     if (team.statistic_name === 'Goals For')
//       team.goals_scored = team.statistic_value;
//     if (team.statistic_name === 'Goals Against')
//       team.goals_against = team.statistic_value;
//     if (team.statistic_name === 'Forced Turnover')
//       team.forced_turnovers = team.statistic_value;
//     if (team.statistic_name === 'Ground Ball')
//       team.ground_balls = team.statistic_value;
//     if (team.statistic_name === 'WP') team.win_percent = team.statistic_value;
//     if (team.statistic_name === 'Total Points')
//       team.total_points = team.statistic_value;

//     return team;
//   });

//   let groupedParsedTeams = _.groupBy(parsedStats, function(o) {
//     return o.team_id;
//   });

//   let flatParsedTeams = _.map(groupedParsedTeams, function(obj) {
//     let merged = _.merge({}, ...obj);
//     return merged;
//   });

//   return flatParsedTeams;
// };

// type FetchTeamStatsOpts = {
//   onlyWithStats?: boolean;
//   clubId?: string;
//   event_id?: string | undefined;
//   team_id?: string | undefined;
//   team_year?: string | undefined;
// };

// export async function fetchTeamStats(
//   year: number,
//   opts: FetchTeamStatsOpts = {},
// ): Promise<ParsedTeam[]> {
//   const query = qs.stringify({
//     event_id: opts.event_id,
//     team_id: opts.team_id,
//     team_year: opts.team_year,
//   });
//   try {
//     // const response = await fetchAuthorization(`${API_URL}team_game_results`);
//     const response = await fetchAuthorization(
//       `${API_URL}team_game_results?${query}&limit=1000`,
//     );

//     // Temp: mock data
//     const data = await response.json();
//     // const data = teamStatsMockData;

//     let parsedData = mapTeamStats(data);

//     console.log('parsedData', parsedData);

//     // if (response.status !== 200) {
//     //   throw new Error(
//     //     data.error
//     //       ? data.error
//     //       : `Status Code: ${response.status}. Something went wrong. Please try again later.`,
//     //   );
//     // }
//     return parsedData;
//   } catch (e: any) {
//     throw new Error(`Unable to fetch teams for year: ${year}.`);
//   }
// }

export interface TeamStats {
  team_id: string;
  event_desc: string;
  event_type: string;
  event_id: string;
  org_logo_url: string;
  created_by: string;
  created_datetime: string;
  is_active_YN: number;
  json_game_results: string;
  region: string;
  games_played?: number;
  win_percent?: number;
  goals_scored?: number;
  goals_against?: number;
  assists?: number;
  forced_turnovers?: number;
  ground_balls?: number;
  total_points?: number;
  date_window_points?: number;
  date?: string;
  sat_date?: string;
}

// ToDo: Parse data to include stats
export type ParsedTeam = Omit<TeamStats, 'statistic_name' | 'statistic_id' | 'statistic_value'>;

type TeamStatsOptions = {
  event_id?: string | undefined;
  team_id?: string | undefined;
  team_year?: string | undefined;
  sport_id?: number;
};

export async function fetchTeamStats(opts: TeamStatsOptions): Promise<TeamStats[]> {
  const query = qs.stringify({
    event_id: opts.event_id,
    team_id: opts.team_id,
    team_year: opts.team_year,
    sport_id: opts.sport_id,
  });

  const response = await fetchAuthorization(`${API_URL}team_game_results?${query}`);

  const result = await response.json();
  if (response.status !== 200) {
    throw new Error(result.error ? result.error : `Status Code: ${response.status}. Sorry, something went wrong. Please try again later.`);
  }
  return result;
}

export default fetchTeamStats;
