import { API_URL } from '../constants';
import fetchWithAuthorization from './fetchAuthorization';

async function fetchCostAppParam(): Promise<number> {
  const response = await fetchWithAuthorization(`${API_URL}app_params?param_id=327`);
  const param = await response.json();

  if (!param) {
    throw new Error(`Sorry, no promo codes were found.`);
  }

  return Number(param[0].param_value);
}

export default fetchCostAppParam;
