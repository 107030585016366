import { FETCH_MEMBER_ASSOCIATIONS_REQ_START, FETCH_MEMBER_ASSOCIATIONS_REQ_SUCCESS, FETCH_MEMBER_ASSOCIATIONS_REQ_FAILED, SET_MEMBER_ASSOCIATION_START, SET_MEMBER_ASSOCIATION_SUCCESS, SET_MEMBER_ASSOCIATION_FAILED, SET_TEAMS_FOLLOWED, SET_PLAYERS_FOLLOWED, SET_PLAYERS_WHO_FOLLOW_US, SET_ORG_FOLLOWED_START, SET_ORG_FOLLOWED_SUCCESS, SET_ORG_FOLLOWED_FAILED } from '../actions/memberAssociationsActions';

let initialState = {
  isLoadingMemberAssociations: false,
  memberAssociationsList: [],
  memberAssociationsError: null,
  updatingMemberAssociation: '',
  updatingMemberAssociationError: null,
  teamIdsFollowed: [], // My Dashboard only
  playerIdFollowed: [], // My Dashboard only
  eventIdsFollowed: [],
  playerIdWhoFollowedUs: [],
  orgsFollowed: [],
  orgsFollowedError: null,
  isLoadingOrgsFollowed: false,
};

const memberAssociationsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_MEMBER_ASSOCIATIONS_REQ_START:
      return { ...state, isLoadingMemberAssociations: true };
    case FETCH_MEMBER_ASSOCIATIONS_REQ_SUCCESS:
      const eventIdsFollowed = action.data.filter((it: any) => it.association_id === 4).map((it: any) => it.association_fk_id);
      return {
        ...state,
        isLoadingMemberAssociations: false,
        memberAssociationsList: action.data,
        memberAssociationsError: null,
        eventIdsFollowed,
      };
    case FETCH_MEMBER_ASSOCIATIONS_REQ_FAILED:
      return {
        ...state,
        isLoadingMemberAssociations: false,
        memberAssociationsError: action.error,
        memberAssociationsList: [],
      };
    case SET_MEMBER_ASSOCIATION_START:
      return {
        ...state,
        updatingMemberAssociation: action.selected,
        updatingMemberAssociationError: null,
      };
    case SET_MEMBER_ASSOCIATION_SUCCESS:
      return { ...state, updatingMemberAssociation: '' };
    case SET_MEMBER_ASSOCIATION_FAILED:
      return {
        ...state,
        updatingMemberAssociation: '',
        updatingMemberAssociationError: action.error,
      };
    case SET_TEAMS_FOLLOWED:
      return { ...state, teamIdsFollowed: action.data };
    case SET_PLAYERS_FOLLOWED:
      return { ...state, playerIdFollowed: action.data };
    case SET_PLAYERS_WHO_FOLLOW_US:
      return { ...state, playerIdWhoFollowedUs: action.payload };

    case SET_ORG_FOLLOWED_START:
      return { ...state, isLoadingOrgsFollowed: true };
    case SET_ORG_FOLLOWED_SUCCESS:
      return { ...state, isLoadingOrgsFollowed: false, orgsFollowed: action.data, orgsFollowedError: null };
    case SET_ORG_FOLLOWED_FAILED:
      return { ...state, isLoadingOrgsFollowed: false, orgsFollowedError: action.error };
    default:
      return state;
  }
};

export default memberAssociationsReducer;
