import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';

export async function fetchGearList(): Promise<any[]> {
  try {
    const respData = await fetchAuthorization(`${API_URL}gear_list`);

    const data = await respData.json();

    const mappedData = data.map((k: any) => {
      return {
        ...k,
        Name_exp_5: JSON.parse(k.Name_exp_5),
      };
    });

    return mappedData;
  } catch (e: any) {
    throw new Error(`${e.message || 'Sorry, something went wrong!'}`);
  }
}

export default fetchGearList;
