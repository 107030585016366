import CircularProgress from '@mui/material/CircularProgress';
import React, { ReactNode, Suspense, lazy, useContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import PageWrapper from './PageWrapper';
import { routes } from '../../constants';
import { MemberContext } from '../CognitoAuth';

const TscoAdmin = lazy(() => import('../TscoAdmin'));
const ClubDashboard = lazy(() => import('../ClubDashboard'));
const Clubs = lazy(() => import('../Clubs'));
const DataLab = lazy(() => import('../DataLab'));
const CollegeCoaches = lazy(() => import(`../CollegeCoaches`));
const Producers = lazy(() => import('../Producers'));
const RosteringWaivers = lazy(() => import('../Rostering'));
const Events = lazy(() => import('../Events'));
const EventDashboard = lazy(() => import('../EventDashboard'));
const MyDashboard = lazy(() => import('../MyDashboard'));
const MyProfile = lazy(() => import('../MyProfile'));
const Account = lazy(() => import('../Account'));
const Rankings = lazy(() => import('../Rankings'));
const Utilities = lazy(() => import('../Utilities'));
const Onboarding = lazy(() => import('../Onboarding'));
const PlayerProfile = lazy(() => import('../PlayerProfile'));
const TeamDashboard = lazy(() => import('../TeamDashboard'));
const GameStats = lazy(() => import('../GameStats'));
const PlayerLab = lazy(() => import('../PlayerLab'));
const FacetoFace = lazy(() => import('../FacetoFace'));
const PastEvents = lazy(() => import('../Events/PastEvents'));
const RequestEvents = lazy(() => import('../Events/RequestedEvents'));
const ClubTryouts = lazy(() => import('../Events/ClubTryouts'));
const AccountTab = lazy(() => import('../Account/components/AccountTab'));
const PlayerMapping = lazy(() => import('../MyProfile/PlayerMapping'));
const AccountOptions = lazy(() => import('../MyProfile/AccountOptions'));
const PlayerRankingsYear = lazy(() => import('../Rankings/PlayerRankingsYear'));
const TeamGamesScores = lazy(() => import('../TeamDashboard/TeamGamesScores'));
const PlayersTab = lazy(() => import('../TeamDashboard/Players'));
const PageNotFound = lazy(() => import('../PageNotFound'));
const Videos = lazy(() => import('../Videos'));
const Teams = lazy(() => import('../Teams'));
const ClubCommitments = lazy(() => import('../ClubCommitments'));
const Notifications = lazy(() => import('../Notifications'));

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    margin: '0 auto',
    display: 'block',
    position: 'absolute',
    left: 0,
    right: 0,
    top: '50%',
  },
}));

function isOnboardingRemain(member: any) {
  if (member) {
    if (member.member_type === '' || member.member_type === null || member.sport_id_default === 0 || member.sport_id_default === null) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

const LoggedInApplication: React.FC = () => {
  const classes = useStyles();
  const { member } = useContext(MemberContext);
  const isOnboarding = isOnboardingRemain(member);

  const MyComponent = ({ element }: { element: ReactNode }) => {
    return (
      <PageWrapper>
        <Suspense fallback={<CircularProgress classes={{ root: classes.loader }} />}>{element}</Suspense>
      </PageWrapper>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.home()} element={isOnboarding ? <Navigate to={routes.onBoarding()} /> : <Navigate to={routes.dashboard()} />} />
        <Route path={routes.onBoarding()} element={<MyComponent element={<Onboarding />} />} />
        <Route path={routes.tscoAdmin()} element={<MyComponent element={<TscoAdmin />} />} />
        <Route path={routes.dashboard()} element={<MyComponent element={<MyDashboard />} />} />
        <Route path={routes.rankings()} element={<MyComponent element={<Rankings />} />}>
          <Route path={routes.playerRankings()} element={<MyComponent element={<PlayerRankingsYear team_year={0} />} />} />
        </Route>
        <Route path={routes.clubs()} element={<MyComponent element={<Clubs />} />} />
        <Route path={routes.clubDetail({ clubId: ':clubId' })} element={<MyComponent element={<ClubDashboard />} />} />
        <Route path={routes.teamDetail({ teamId: ':teamId' })} element={<MyComponent element={<TeamDashboard />} />}>
          <Route path={routes.teamGames({ teamId: ':teamId' })} element={<MyComponent element={<TeamGamesScores />} />} />
          <Route path={routes.teamPlayers({ teamId: ':teamId' })} element={<MyComponent element={<PlayersTab />} />} />
        </Route>

        <Route path={routes.dataLab()} element={<MyComponent element={<DataLab />} />}>
          <Route path={routes.playerLab()} element={<MyComponent element={<PlayerLab sportId={0} />} />} />
          <Route path={routes.faceOff()} element={<MyComponent element={<FacetoFace />} />} />
        </Route>
        <Route path={routes.collegeCoaches()} element={<MyComponent element={<CollegeCoaches />} />} />
        <Route path={routes.producers()} element={<MyComponent element={<Producers />} />} />
        <Route path={routes.videos()} element={<MyComponent element={<Videos />} />} />
        <Route path={routes.sourceData()} element={<MyComponent element={<RosteringWaivers />} />} />
        <Route path={routes.events()} element={<MyComponent element={<Events />} />}>
          <Route path={routes.pastEvents()} element={<MyComponent element={<PastEvents />} />} />
          <Route path={routes.requestEvents()} element={<MyComponent element={<RequestEvents />} />} />
          <Route path={routes.prospectDays()} element={<MyComponent element={<RequestEvents />} />} />
          <Route path={routes.clubTryouts()} element={<MyComponent element={<ClubTryouts />} />} />
        </Route>
        <Route path={routes.eventDetail({ eventId: ':eventId' })} element={<MyComponent element={<EventDashboard />} />} />
        <Route path={routes.profile()} element={<MyComponent element={<MyProfile />} />}>
          <Route path={routes.playerMapping()} element={<MyComponent element={<PlayerMapping />} />} />
          <Route path={routes.accountOptions()} element={<MyComponent element={<AccountOptions />} />} />
        </Route>
        <Route path={routes.account()} element={<MyComponent element={<Account />} />}>
          <Route path={routes.subscription()} element={<MyComponent element={<AccountTab />} />} />
        </Route>
        <Route path={routes.utilities()} element={<MyComponent element={<Utilities />} />} />
        <Route path={routes.playerDetail({ playerId: ':playerId' })} element={<MyComponent element={<PlayerProfile />} />} />
        <Route path={routes.gameStats({ gameId: ':gameId' })} element={<MyComponent element={<GameStats />} />} />
        <Route path={routes.teams()} element={<MyComponent element={<Teams />} />} />
        <Route path={routes.clubcommitments({ org_id: ':org_id' })} element={<MyComponent element={<ClubCommitments />} />} />
        <Route path={routes.notifications()} element={<MyComponent element={<Notifications />} />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default LoggedInApplication;
