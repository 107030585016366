import createTheme from '@mui/material/styles/createTheme';
import { CLThemeExtension } from './type';

const themeExtentions: CLThemeExtension = {
  palette: {
    accuracy: {
      main: '#f0ac2e',
    },
    event: {
      main: '#73d068',
    },
    player: {
      main: '#c777f2',
    },
    team: {
      main: '#31c2fd',
    },

    // Alert colors
    info: {
      main: '#2196f3',
    },
    success: {
      main: '#4caf50',
    },
    warning: {
      main: '#ff9800',
    },
  },
};

const theme = createTheme(
  {
    palette: {
      background:{
        default: '#fafafa'
      },
      error: {
        main: '#ed3237',
      },
      primary: {
        main: '#1c315f',
      },
      secondary: {
        main: '#e9effb',
      },
      text: {
        primary: '#373737',
        secondary: '#757575',
      },
    },
    shape: {
      borderRadius: 9,
    },
    typography: {
      fontFamily: 'Roboto, sansSerif !important',
      fontSize: 14,
      body1: {
        fontSize: 14,
        fontWeight: 500,
      },
      body2: {
        fontSize: 12,
        fontWeight: 500,
      },

      button: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1.3,
        textTransform: 'none',
      },

      h1: {
        fontSize: 24,
        fontWeight: 'bold',
      },
      h2: {
        fontSize: 24,
        fontWeight: 500,
      },
      h3: {
        fontSize: 18,
        fontWeight: 'bold',
      },
      h4: {
        fontSize: 16,
        fontWeight: 900,
      },
      h5: {
        fontSize: 14,
      },
      h6: {
        fontSize: 12,
        fontWeight: 'bold',
      },
    },
    components: {
      MuiCardContent: {
        styleOverrides: {
          root: {
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingBottom: '10px',
            paddingTop: 'unset',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '5px',
            width: '100px',
            // textAlign: 'left',
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            border: 'solid 1px #d4d4ce',
          },
          img: {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
  themeExtentions,
);

export default theme;
