import { fetchGeographies } from '../../apis';

export const FETCH_GEOGRAPHIES_REQ_SUCCESS = 'FETCH_GEOGRAPHIES_REQ_SUCCESS';

export const geographiesSuccess = (data: any) => ({
  type: FETCH_GEOGRAPHIES_REQ_SUCCESS,
  data,
});

export function getGeographies() {
  console.log('getGeographies');
  return (dispatch: any) => {
    fetchGeographies()
      .then(async (res: any) => {
        console.log('geographies', res);
        const processedData = await res.data;
        console.log('processedData', processedData);
        // dispatch(geographiesSuccess(res.data));
      })
      .catch((error: any) => {
        console.log('error in geographies', error);
      });
  };
}
