import React from 'react';
import ActionCard from '../../ActionCard';
import '../styles.css';
import { TextField } from '@mui/material';
import { Team } from '../interfaces';
import DateInput from '../../Common/DateInput';

interface MatchUpMakers {
  isChecking: boolean;
  homeTeam: Team | undefined;
  awayTeam: Team | undefined;
  date: Date | null;
  awayTeamScore: number | null;
  homeTeamScore: number | null;
  onChangeDate: (date: Date | null) => void;
  onChangeAwayScore: ({ target: { value } }: any) => void;
  onChangeHomeScore: ({ target: { value } }: any) => void;
}

const MatchUpMaker = ({ awayTeam, homeTeam, date, awayTeamScore, homeTeamScore, isChecking, onChangeDate, onChangeAwayScore, onChangeHomeScore }: MatchUpMakers) => {
  return (
    <ActionCard title="Match Up Maker" styles={{ width: '85%' }}>
      <div className="comp-container">
        <div className="matchup-comp-item">
          <label>Game Date</label>
          <DateInput
            disableToolbar
            variant="inline"
            format="M/dd/yyyy"
            styles={{
              display: 'block',
              width: '100%',
            }}
            fontSize={14}
            value={date || ''}
            onChange={onChangeDate}
            // variant="standard"
          />
        </div>
        <div className="matchup-comp-item">
          <label>Away Name</label>
          <TextField
            value={awayTeam?.team_name || ''}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            disabled={!awayTeam}
            error={isChecking && !awayTeam?.team_name}
            variant="standard"
          />
        </div>
        <div className="matchup-comp-item">
          <label>Away ID</label>
          <TextField
            value={awayTeam?.team_id || ''}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            error={isChecking && (!awayTeam?.team_id || awayTeam?.team_id === homeTeam?.team_id)}
            disabled={!awayTeam}
            variant="standard"
          />
        </div>
        <div className="matchup-score">
          <label>Score</label>
          <div className="score">
            <TextField value={awayTeamScore} style={{ width: '40%' }} inputProps={{ min: 0, style: { textAlign: 'right' } }} type="number" error={isChecking && awayTeamScore === 0 && homeTeamScore === 0} onChange={onChangeAwayScore} variant="standard" />
            <TextField value={homeTeamScore} style={{ width: '40%' }} inputProps={{ min: 0, style: { textAlign: 'right' } }} type="number" error={isChecking && awayTeamScore === 0 && homeTeamScore === 0} onChange={onChangeHomeScore} variant="standard" />
          </div>
        </div>
        <div className="matchup-comp-item">
          <label>Home Name</label>
          <TextField
            value={homeTeam?.team_name || ''}
            error={isChecking && !homeTeam?.team_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            disabled={!homeTeam}
            variant="standard"
          />
        </div>
        <div className="matchup-comp-item">
          <label>Home ID</label>
          <TextField
            value={homeTeam?.team_id || ''}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            error={isChecking && (!homeTeam?.team_id || awayTeam?.team_id === homeTeam?.team_id)}
            disabled={!homeTeam}
            variant="standard"
          />
        </div>
      </div>
    </ActionCard>
  );
};

export default MatchUpMaker;
