import { fetchMutchupGames, addGames as addGameAPI} from '../../apis';
import _fetchRequest from '../../apis/_fetchRequest';
import * as commonActions from './commonActions';
import GameStat from '../../components/GameStats/models/GameStat';
import Statistics from '../../models/CommonStatistics';
import ClaimedStatistics from '../../models/ClaimedStatistics';
import ClaimedStatisticsConfirm, { ClaimedStatisticsConfirmMember, ClaimedStatisticsNewConfirm } from '../../models/ClaimedStatisticsConfirm';
import { Player } from '../../apis/fetchPlayers';
import { SaveClaimStats } from '../../components/GameStats/helper';
import { GameStatSummary } from '../../models/GameStatSummary';

export const FETCH_GAME_REQ_START = 'FETCH_GAME_REQ_START';
export const FETCH_GAME_REQ_SUCCESS = 'FETCH_GAME_REQ_SUCCESS';
export const FETCH_GAME_REQ_FAILED = 'FETCH_GAME_REQ_FAILED';

export const ADD_GAME_REQ_START = 'ADD_GAME_REQ_START';
export const ADD_GAME_REQ_SUCCESS = 'ADD_GAME_REQ_SUCCESS';
export const ADD_GAME_REQ_FAILED = 'ADD_GAME_REQ_FAILED';
export const FETCH_GAME_STATS_SUCCESS = 'FETCH_GAME_STATS_SUCCESS';
export const FETCH_GAME_STATS_START = 'FETCH_GAME_STATS_START';
export const FETCH_GAME_STATS_FAILED = 'FETCH_GAME_STATS_FAILED';

export const FETCH_GET_GAME_STAT_SUMMARY = 'FETCH_GET_GAME_STAT_SUMMARY';

export const FETCH_POST_CLAIMED_STATS_CONFIRM = 'FETCH_POST_CLAIMED_STATS_CONFIRM';

export const FETCH_PUT_CLAIMED_STATS_CONFIRM = 'FETCH_PUT_CLAIMED_STATS_CONFIRM';
export const FETCH_DELETE_CLAIMED_STATS_CONFIRM = 'FETCH_DELETE_CLAIMED_STATS_CONFIRM';
export const FETCH_GET_CLAIMED_STATS_MEMBERLIST = 'FETCH_GET_CLAIMED_STATS_MEMBERLIST';

export const getGameStart = () => ({
  type: FETCH_GAME_REQ_START,
});

export const getGameSuccess = (data: any) => ({
  type: FETCH_GAME_REQ_SUCCESS,
  data,
});

export const getGameFailed = (error: string) => ({
  type: FETCH_GAME_REQ_FAILED,
  error,
});

// Add game
export const addGameStart = () => ({
  type: ADD_GAME_REQ_START,
});

export const addGameSuccess = () => ({
  type: ADD_GAME_REQ_SUCCESS,
});

export const addGameFailed = (error: string) => ({
  type: ADD_GAME_REQ_FAILED,
  error,
});

export function getGameDetails(data: any) {
  return (dispatch: any) => {
    dispatch(getGameStart());
    fetchMutchupGames(data)
      .then((res: any) => {
        if (res && res.length) {
          dispatch(getGameSuccess(res[0]));
        } else {
          dispatch(getGameFailed("Game doesn't found!"));
        }
      })
      .catch((error: any) => {
        // console.log('error in get game details', error);
        dispatch(getGameFailed(error));
      });
  };
}


export const getSummaryGameStats = (gameId: string) => async (dispatch: any) => {
  const stats = await _fetchRequest<GameStatSummary[]>('GET', 'v_game_summary_stats', { game_id: gameId });

  if (!stats) {
    return;
  }

  dispatch({
    type: FETCH_GET_GAME_STAT_SUMMARY,
    payload: stats,
  });
};

const GAMESTATNAME = ['Goal', 'Assist', 'Ground Ball', 'Forced Turnover', 'Goal Allowed', 'Save Made', 'Faceoff Won', 'Faceoff Lost'];

export const getGameStats = (gameId: string, homeTeamId: string, awayTeamId: string) => async (dispatch: any) => {
  dispatch({
    type: FETCH_GAME_STATS_START,
  });

  try {
    const claimedStatistics = await _fetchRequest<ClaimedStatistics[]>('GET', `claimed_statistics`, { game_id: gameId });
    
    const statisticId = claimedStatistics.map((item: any) => item.statistic_id).join(',');
    const claimId = claimedStatistics.map((item: any) => item.claim_id).join(',');

    const players = await _fetchRequest<Player[]>('GET', `players`, {
      team_id: homeTeamId + ',' + awayTeamId,
    });
    if (statisticId) {
    }
    const statistics = statisticId
      ? await _fetchRequest<Statistics[]>('GET', `statistics`, {
          statistic_id: statisticId,
        })
      : [];
    const claimedStatisticsConfirm = claimId
      ? await _fetchRequest<ClaimedStatisticsConfirm[]>('GET', 'claimed_statistics_confirms', {
          claim_id: claimId,
          source_id: 2,
        })
      : [];

    const fullStatistic: GameStat[] = [];

    players.forEach((player: Player) => {
      const playerClaimedStats = claimedStatistics.filter((stat: ClaimedStatistics) => player.player_id === stat.player_id);
      
      const claimedStat = playerClaimedStats.map((playerStat: ClaimedStatistics) => {
        const statistic = statistics.find((stat: Statistics) => stat.statistic_id === playerStat?.statistic_id);

        return {
          ...playerStat,
          statistic_name: statistic?.statistic_name,
        };
      });

      GAMESTATNAME.forEach((name) => {
        const statistic = claimedStat.find((stat) => stat.statistic_name === name);
        
        if (statistic) {
          const numAgree = claimedStatisticsConfirm.filter((confirm) => confirm.claim_id === statistic.claim_id && confirm.confirm_value === 1).length;
          const numDisagree = claimedStatisticsConfirm.filter((confirm) => confirm.claim_id === statistic.claim_id && confirm.confirm_value === -1).length;

          fullStatistic.push({
            claim_id: statistic.claim_id || null,
            game_id: statistic.game_id || null,
            player_id: player?.player_id,
            num_claimed: statistic.num_claimed || 0,
            statistic_name: statistic.statistic_name || null,
            first_name: player?.first_name,
            last_name: player?.last_name,
            team_id: player?.team_id,
            jersey_number: player?.jersey_number,
            position_description: player?.position_description,
            member_id: player?.member_id,
            num_agree: numAgree,
            num_disagree: numDisagree,
            num_agree_actual: statistic.num_agree,
            num_disagree_actual: statistic.num_disagree,
            num_votes: statistic.num_votes,
            percent_agree: statistic.percent_agree,
          } as GameStat);
        } else {
          fullStatistic.push({
            claim_id: null,
            game_id: null,
            player_id: player?.player_id,
            num_claimed: 0,
            statistic_name: name,
            first_name: player?.first_name,
            last_name: player?.last_name,
            team_id: player?.team_id,
            jersey_number: player?.jersey_number,
            position_description: player?.position_description,
            member_id: player?.member_id,
            num_agree: 0,
            num_disagree: 0,
          } as GameStat);
        }
      });
    });

    dispatch({
      type: FETCH_GAME_STATS_SUCCESS,
      payload: { fullStatistic, claimedStatisticsConfirm },
    });
  } catch {
    dispatch({
      type: FETCH_GAME_STATS_FAILED,
    });

    console.error("Can't load the game stats");
  }
};

export function addGame(data: any, callback: any) {
  return (dispatch: any) => {
    dispatch(addGameStart());
    addGameAPI(data)
      .then((res: any) => {
        dispatch(addGameSuccess());
        callback(true);
      })
      .catch((error: any) => {
        console.log('error in set ', error);
        dispatch(addGameFailed(error));
        dispatch(commonActions.handleError(error));
        callback(true);
      });
  };
}

export const getAgree = (claimId: number, memberId: string) => async (dispatch: any) => {
  try {
    const conf = {
      claim_id: claimId,
      created_by: memberId,
      confirm_value: 1,
      source_id: 2,
    };

    _fetchRequest('POST', 'claimed_statistics_confirms', undefined, conf).then((res: any) => {
      dispatch({
        type: FETCH_POST_CLAIMED_STATS_CONFIRM,
        payload: {
          ...conf,
          confirm_id: res.insertId,
        },
      });
    });
  } catch {
    console.error("Can't post agree.");
  }
};


export const getDisagree = (claimId: number, memberId: string) => async (dispatch: any) => {
  
  const conf = {
    claim_id: claimId,
    created_by: memberId,
    confirm_value: -1,
    source_id: 2,
  };

  _fetchRequest('POST', 'claimed_statistics_confirms', undefined, conf)
    .then((res: any) => {
      dispatch({
        type: FETCH_POST_CLAIMED_STATS_CONFIRM,
        payload: {
          ...conf,
          confirm_id: res.insertId,
        },
      });
    })
    .catch((error: any) => console.error(error));
};

export const saveNumClaimed = (saveInfo: SaveClaimStats[]) => async (dispatch: any, getState: any) => {
  
  const { gameStats, statsConfirm } = getState().gameReducer;
  const fetchGameStat = saveInfo.map((stat: SaveClaimStats) => {
    if (!stat.claimId) {
      return {
        game_id: stat.gameId,
        team_id: stat.teamId,
        source_id: 2,
        player_id: stat.playerId,
        num_claimed: stat.numClaimed,
        statistic_id: stat.statisticId,
      };
    } else {
      return {
        claim_id: stat.claimId,
        num_claimed: stat.numClaimed,
        source_id: 2,
      };
    }
  });
  try {
    const newGameStats = fetchGameStat.filter((stat) => !stat.claim_id);
    const changedGameStats = fetchGameStat.filter((stat) => stat.claim_id);

    if (newGameStats.length > 0) {
      _fetchRequest('POST', 'claimed_statistics', undefined, newGameStats).then(() => dispatch(getSummaryGameStats(newGameStats[0].game_id!)));
    }
    if (changedGameStats.length > 0) {
      _fetchRequest('PUT', 'claimed_statistics', undefined, changedGameStats).then(() => dispatch(getSummaryGameStats(changedGameStats[0].game_id!)));
    }

    const fullStatistic = gameStats.map((stat: any) => {
      const existStats = saveInfo.find((info) => (info.statisticName === stat.statistic_name && info.playerId === stat.player_id) || stat.claim_id === info.claimId);

      if (existStats && !existStats.claimId) {
        return {
          ...stat,
          claim_id: 2000000000000000000,
          game_id: existStats.gameId,
          team_id: existStats.teamId,
          player_id: existStats.playerId,
          num_claimed: existStats.numClaimed,
          statistic_name: existStats.statisticName,
        };
      }
      if (existStats && existStats.claimId) {
        return {
          ...stat,
          num_claimed: existStats.numClaimed,
        };
      }

      return stat;
    });

    dispatch({
      type: FETCH_GAME_STATS_SUCCESS,
      payload: { fullStatistic, claimedStatisticsConfirm: statsConfirm },
    });
  } catch {
    console.error("Can't post num claimed.");
  }
};

export const putConfirm = (item: ClaimedStatisticsConfirm) => async (dispatch: any) => {
  try {
    _fetchRequest('PUT', 'claimed_statistics_confirms', undefined, item);
   

    dispatch({
      type: FETCH_PUT_CLAIMED_STATS_CONFIRM,
      payload: item,
    });
  } catch {
    console.error("Can't put confirm claimed.");
  }
};

export const putNumConfirm = ( item: ClaimedStatisticsNewConfirm, request: "PUT" | "POST") => async () =>{
  try {
    _fetchRequest(request, 'claimed_statistics', undefined, item);
 
  }catch{
    console.error("Can't put confirm claimed.");
  }
}

export const deleteConfirm = (confirmId: number) => async (dispatch: any) => {
  try {
    _fetchRequest('DELETE', 'claimed_statistics_confirms', { confirm_id: confirmId });

    dispatch({
      type: FETCH_DELETE_CLAIMED_STATS_CONFIRM,
      payload: confirmId,
    });
  } catch {
    console.error("Can't delete confirm claimed");
  }
};

export const getClaimedStatsMemberList = (claim_ids:string) => async (dispatch: any) => {

  const stats = await _fetchRequest<ClaimedStatisticsConfirmMember[]>('GET', 'claimed_statistics_memberlist',{claim_id: claim_ids});

  if (!stats) {
    return;
  }

  dispatch({
    type: FETCH_GET_CLAIMED_STATS_MEMBERLIST,
    payload: stats,
  });
};
