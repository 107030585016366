import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import qs from 'qs';
import _ from 'lodash';

export interface Events {
  event_id: string;
  sport_id: number;
  avg_goal_diff: number | null;
  org_id: string;
  org_name: string;
  event_type: string;
  event_desc: string;
  event_comments: null;
  num_games: null;
  num_teams: null;
  event_desc_short: string;
  event_address: string;
  event_city: string;
  event_state: string;
  state_abbr: string;
  region: string;
  event_startdate: string;
  event_enddate: string;
  days_difference: number;
  days_filter: string;
  ranking_factor_divisions: null;
  ranking_factor_pools: null;
  max_goal_differential: null;
  format_id: number;
  period_type: number;
  scoring_rules: string;
  central_timer: string;
  playoff_determination: string;
  OT_rules: string;
  multilocation: null;
  is_live_YN: number;
  event_location_lat: null;
  event_location_long: null;
  event_map_desc: null;
  event_map_url: null;
  event_website_URL: string;
  show_goals_scored: null;
  show_goals_allowed: null;
  show_goals_diff: null;
  event_rules_URL: null;
  is_active_YN: 1;
  created_by: string;
  created_datetime: string;
  agd: string;
}

export type FetchEventsOptions = {
  event_id?: string;
  sport_id?: number | 1;
  org_id?: string;
};

export async function fetchVEvents(opts: FetchEventsOptions): Promise<Events[]> {
  try {
    const query = qs.stringify({
      event_id: opts.event_id,
      sport_id: opts.sport_id,
      org_id: opts.org_id,
    });

    const data = await fetchAuthorization(`${API_URL}v_events?${query}`);

    const events = await data.json();

    const sortedEvents = _.orderBy(events, [(event) => (event.event_desc ? event.event_desc.toLowerCase() : event.event_desc)]);

    return sortedEvents;
  } catch (e: any) {
    throw new Error(`Sorry, something went wrong. Error message: ${e.message}`);
  }
}

export default fetchVEvents;
