export enum ProfileLogo {
  BIO = 'BIO',
  CLUBS = 'CLUBS',
  MY_GEAR = 'THIS PLAYERS GEAR',
  CONTACT = 'CONTACT/SOCIAL',
  ABOUT_ME = 'ABOUT THIS PLAYER',
  SHARE_DATA = 'SHARE RECRUITING DATA',
  RECRUITING = 'COMMITTED STATUS',
  HIGHLIGHTS = 'PLAYER HIGHLIGHT VIDEOS',
  STATISTICS = 'CONTACT',
  TEST_SCORES = 'TEST SCORES',
  ACHIEVEMENTS = 'NOTABLE ACHIEVEMENTS/ACCOLADES',
  ADD_HIGHLIGHT = 'ADD PLAYER HIGHLIGHT VIDEOS',
  UPCOMING_GAMES = 'UPCOMING GAMES',
  HISTORICAL_GAMES = 'HISTORICAL GAMES',
  CURRENT_SCHOOL = 'CURRENT SCHOOL',
  TOTAL_GAMES_PLAYED = 'TOTAL GAMES PLAYED',
  UNIVERSITIES_FOLLOWING = 'UNIVERSITIES FOLLOWING:',
  AGE_Verification = 'USA LACROSSE AGE VERIFICATION',
  CLUBS_FOLLOWING = 'CLUBS FOLLOWING:',
}
