import { apiOrigin } from '../constants';
import { compose, sortBy } from 'ramda';
import qs from 'qs';
import fetchWithAuthorization from './fetchWithAuthorization';
const R = require('ramda');
export interface Game {
  away_name_short: string;
  away_team_id: string;
  away_team_logo: string;
  away_team_loss: number;
  away_team_name: string;
  away_team_score: number;
  away_team_tie: number;
  away_team_win: number;
  bracket_year: number;
  days_ago: number;
  division: string;
  event_desc: string;
  field_description: string;
  game_date: string;
  game_id: string;
  game_location_city: string;
  game_location_lat: number;
  game_location_long: number;
  game_location_state: string;
  game_time: string;
  home_name_short: string;
  home_team_id: string;
  home_team_logo: string;
  home_team_loss: number;
  home_team_name: string;
  home_team_score: number;
  home_team_tie: number;
  home_team_win: number;
  is_active_YN: number;
  is_live_YN: number;
  period_type: number;
  sport_id: number;
  tournament_id: string;
}

const sortByDate = sortBy(compose((dateStr: string) => new Date(dateStr).getTime() * -1, R.prop('game_date')));

const sortByMap = {
  date: sortByDate,
};
type SortByMethods = keyof typeof sortByMap;

export interface FetchGamesForMemberOptions {
  sortBy?: SortByMethods;
}

async function fetchGamesForMember(memberId: string, opts: FetchGamesForMemberOptions = {}): Promise<Game[]> {
  const queryString = qs.stringify({ created_by: memberId });
  const response = await fetchWithAuthorization(`${apiOrigin}/api/v1/games?${queryString}`);
  let { games } = await response.json();

  const sortByMethod = sortByMap[opts.sortBy || 'date'];

  return sortByMethod(games);
}

export default fetchGamesForMember;
