import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import qs from 'qs';

export interface Player {
  player_id: string;
  team_id: string;
  first_name?: string;
  last_name?: string;
  position_description?: string;
  jersey_number?: string;
  member_id?: string;
}

type FetchPlayersOptions = {
  player_id?: string | null;
  team_id?: string | null;
};

export async function fetchPlayers(opts: FetchPlayersOptions): Promise<Player[]> {
  try {
    let q = opts.team_id ? { team_id: opts.team_id } : { player_id: opts.player_id };
    const query = qs.stringify(q);

    const data = await fetchAuthorization(`${API_URL}players?${query}`);

    const players = await data.json();

    return players;
  } catch (e: any) {
    throw new Error(`Something went wrong. Error message: ${e.message}`);
  }
}

export default fetchPlayers;
