import React, { ReactNode } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { useStyles } from './styles';

export { default as EmptyState } from './EmptyState';

type Props = {
  title: string;
  className?: string;
  action?: ReactNode;
  button?: ReactNode;
  styles?: object;
  children: ReactNode;
};

const ActionCard: React.FC<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <div style={{ ...props.styles }} className={props.className} data-testid="action-card">
      <Card>
        <div className={classes.header}>
          <Typography variant="h4" gutterBottom className={classes.title}>
            {props.title}
          </Typography>
          {props.action && <Typography variant="h6">{props.action}</Typography>}
          {props.button && <>{props.button}</>}
        </div>
        <Divider variant="middle" />
        <div className={classes.content}>{props.children}</div>
      </Card>
    </div>
  );
};

export default ActionCard;
