import React from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';

import { useStyles } from './styles';

interface Props {
  title: string;
  isOpen: boolean;
  showNo?: boolean;
  customNo?: string;
  customYes?: string;
  textMessage: string;
  onClose: () => void;
  onCancel?: () => void;
  onConfirm: () => void;
}

const InfoDialog = ({ title, isOpen, showNo, customNo, customYes, textMessage, onClose, onCancel, onConfirm }: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        style: {
          minWidth: '600px',
        },
      }}
      onClose={onClose}
    >
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogDescription}>{textMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {showNo && (
          <Button autoFocus color="primary" variant="text" className={classes.btnCofirm} onClick={onCancel}>
            {customNo || 'No'}
          </Button>
        )}
        <Button autoFocus color="primary" variant="contained" className={classes.btnCofirm} onClick={onConfirm}>
          {customYes || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
