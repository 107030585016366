import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import { getErrorMessage } from '../helpers';
import {Highlight} from '../models/Highlight';
import { Video } from '../models/Videos';


export async function addHighlights(
  newHighlight: Highlight,
  memberId: string,
): Promise<Highlight> {
  try {
    const response = await fetchAuthorization(
      `${API_URL}member_media?member_id=${memberId}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(newHighlight),
      },
    );

    const result = await response.json();

    if (response.status !== 200) {
      const message = getErrorMessage(result);
      throw new Error(
        message
          ? message
          : `Status Code: ${response.status}. Sorry, something went wrong. Please try again later.`,
      );
    }

    return result;
  } catch (e: any) {
    throw new Error(`Unable to add highlight. Error Message: ${e.message}`);
  }
}

export async function addEventHighlights(
  newHighlight: Highlight,
  eventId: string,
): Promise<Highlight> {
  try {
    const response = await fetchAuthorization(
      `${API_URL}member_media?event_id=${eventId}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(newHighlight),
      },
    );

    const result = await response.json();

    if (response.status !== 200) {
      const message = getErrorMessage(result);
      throw new Error(
        message
          ? message
          : `Status Code: ${response.status}. Sorry, something went wrong. Please try again later.`,
      );
    }

    return result;
  } catch (e: any) {
    throw new Error(`Unable to add highlight. Error Message: ${e.message}`);
  }
}

export async function addVideos(
  newVideo: Video,
): Promise<Video> {
  try {
    const response = await fetchAuthorization(
      `${API_URL}member_media?`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(newVideo),
      },
    );

    const result = await response.json();

    if (response.status !== 200) {
      const message = getErrorMessage(result);
      throw new Error(
        message
          ? message
          : `Status Code: ${response.status}. Sorry, something went wrong. Please try again later.`,
      );
    }

    return result;
  } catch (e: any) {
    throw new Error(`Unable to add highlight. Error Message: ${e.message}`);
  }
}
