import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import _ from 'lodash';
import qs from 'qs';

export interface Tryouts {
  address : string | null;
  city: string;
  comments: string | null;
  created_by: string;
  created_datetime: string;
  updated_by: string | null;
  updated_datetime: string | null;
  end_date: string;
  facility: string;
  facility_lat: number | null;
  facility_long: number | null;
  has_been_reviewed: number;
  invited_divisions: string;
  is_active_YN: number;
  org_id: string | null;
  org_name: string;
  org_url: string | null;
  registration_URL: string;
  sport_id: number;
  start_date: string;
  state: string;
  region: string;
  tryout_id: number;
  zip: string | null;
  event_dates: string;
}

export type FetchEventsOptions = {
  event_id?: string;
  sport_id?: number | 1;
  org_id?: string;
};
export async function fetchClubTryouts(opts: FetchEventsOptions): Promise<Tryouts[]> {
  try {
    const query = qs.stringify({
      event_id: opts.event_id,
      sport_id: opts.sport_id,
      org_id: opts.org_id,
    });
    const data = await fetchAuthorization(`${API_URL}club_tryouts?${query}`);

    const events = await data.json();

    const sortedEvents = _.orderBy(events, [(event) => (event.event_desc ? event.event_desc.toLowerCase() : event.event_desc)]);

    return sortedEvents;
  } catch (e: any) {
    throw new Error(`Sorry, something went wrong. Error message: ${e.message}`);
  }
}

export default fetchClubTryouts;
