import React, { useEffect, useState } from 'react';
import ActionCard from '../../ActionCard';
import '../styles.css';
import CustomButton from '../../Common/CustomButton';
import { MenuItem, Select, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Organization } from '../../../models/Organization';
import InputSearch from '../../Common/InputSearch/InputSearch';
import { orderBy } from 'lodash';
import { Team } from '../interfaces';

export const  useStyles = makeStyles(() =>
  createStyles({
    searchRoot: {
      width: '100%',
      '& > label': {
        fontSize: '20px',
        fontWeight: 'bold',
      },
      '& > div': {
        borderRadius: '0px',
        boxShadow: 'none',
      },
      '& > div > input': {
        borderRadius: 0,
        padding: '6px 0 7px !important',
        fontSize: '14px',
        fontWeight: '500',
        height: '1.1876em',
      },
      '& > div:before': {
        display: 'inline',
      },
    },
  }),
);

interface SearchComponentProps {
  orgs: Organization[];
  isCancel: boolean;
  onPasteAsAway: (team: Team | undefined) => void;
  onPasteAsHome: (team: Team | undefined) => void;
}

const SearchComponent = ({ orgs, isCancel, onPasteAsAway, onPasteAsHome }: SearchComponentProps) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedClub, setSelectedClub] = useState<Organization>();
  const [divisionOptions, setDivisionOptions] = useState<{ label: string; value: Team }[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Team>();

  useEffect(() => {
    if (isCancel) {
      onClear();
      onPasteAsAway(undefined);
      onPasteAsHome(undefined);
    }
    // eslint-disable-next-line
  }, [isCancel]);

  const onChangeSearchValue = ({ target: { value } }: any) => setSearchValue(value);

  const onReset = () => {
    setSearchValue('')
    setSelectedClub(undefined);
    setDivisionOptions([]);
    setSelectedTeam(undefined);
  };

  const onSelectClub = (data: Organization) => {
    setSelectedClub(data as Organization);
    setSearchValue(data.org_name);
    try {
      const teams: Team[] = JSON.parse(data.json_org_info);
      const options = orderBy(teams, 'team_year').map((it: Team) => {
        return {
          label: it.team_year,
          value: it,
        };
      });
      setDivisionOptions(options);
    } catch {
      console.log(`Couldn't parse ${data}`);
    }
  };

  const onChangeTeam = ({ target: { value } }: any) => {
    setSelectedTeam(value);
  };

  const onHandlePasteAsAway = () => {
    onPasteAsAway(selectedTeam!);
    onClear();
  };

  const onHandlePasteAsHome = () => {
    onPasteAsHome(selectedTeam!);
    onClear();
  };

  const onClear = () => {
    setSelectedTeam(undefined);
    setSearchValue('');
    setSelectedClub(undefined);
    setDivisionOptions([]);
  };

  const filteredClubs = searchValue === '' ? orderBy(orgs, 'org_name', 'asc') : orderBy(orgs, 'org_name', 'asc').filter((org: Organization) => org.org_name.toLowerCase().replaceAll(searchValue.toLowerCase(), '') !== org.org_name.toLowerCase());

  return (
    <ActionCard title="Team Search & Insert Manager" styles={{ width: '49%', overflow: 'visible' }} button={<CustomButton label={'Clear'} width={'77px'} variant="text" onClick={onClear} />}>
      <div className="comp-container">
        <div className="search-comp-item">
          <label>Organization *</label>
          <InputSearch searchKey={searchValue} placeholder="Enter Club Name" filteredData={filteredClubs} fieldToDisplay="org_name" customStyles={classes.searchRoot} onReset={onReset} onChangeSearch={onChangeSearchValue} onSelect={onSelectClub} />
        </div>
        <div className="search-comp-item">
          <label>Division *</label>
          <Select fullWidth onChange={onChangeTeam} disabled={!selectedClub} variant="standard">
            {divisionOptions.map((item: any, idx) => (
              <MenuItem value={item?.value} key={idx}>
                {item?.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="search-comp-item">
          <label>Team Name</label>
          <TextField
            value={selectedTeam?.team_name || ''}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            disabled={!selectedTeam}
            variant="standard"
          />
        </div>
      </div>
      <div className="search-comp-btns">
        <CustomButton label={'Paste as Away Team'} width={'170px'} disabled={!selectedTeam} onClick={onHandlePasteAsAway}/>
        <CustomButton label={'Paste as Home Team'} width={'170px'} disabled={!selectedTeam} onClick={onHandlePasteAsHome}/>
      </div>
    </ActionCard>
  );
};

export default SearchComponent;
