import Team from '../models/Team';
import NormalizeGame, { TEAM_RESULT } from '../models/NormalizeGame';
import { Event } from '../models/Event';

export const getTeamsFromGames = (games: NormalizeGame[], eventInfo: Event) => {
  const mappedTeams: Team[] = [];
  games.forEach((game: NormalizeGame) => {
    const teamIdx = mappedTeams.findIndex((team: Team) => team.team_id === game.team_id);

    if (teamIdx < 0) {
      mappedTeams.push({
        event_id: game.event_id,
        division_id: game.division_id,
        division_name: game.division_name,
        pool_id: game.pool_id,
        pool_name: game.pool_name,
        team_id: game.team_id,
        team_name: game.team_name,
        team_logo: game.team_logo_url,
        team_year: game.team_year,
        wins: game.team1_outcome === TEAM_RESULT.WIN ? 1 : 0,
        losses: game.team1_outcome === TEAM_RESULT.LOSS ? 1 : 0,
        ties: game.team1_outcome === TEAM_RESULT.TIE ? 1 : 0,
        goalsScored: game.team_score ? game.team_score : 0,
        goalsAllowed: game.opponent_team_score ? game.opponent_team_score : 0,
        goalsDifferentialMax: countGoalDifferentialMax(eventInfo?.max_goal_differential, 0, game.team_score, game.opponent_team_score),
      } as Team);
    } else {
      const team = mappedTeams[teamIdx];
      mappedTeams[teamIdx] = {
        ...team,
        wins: game.team1_outcome === TEAM_RESULT.WIN ? ++team.wins : team.wins,
        losses: game.team1_outcome === TEAM_RESULT.LOSS ? ++team.losses : team.losses,
        ties: game.team1_outcome === TEAM_RESULT.TIE ? ++team.ties : team.ties,
        goalsScored: team.goalsScored + game.team_score,
        goalsAllowed: team.goalsAllowed + game.opponent_team_score,
        goalsDifferentialMax: countGoalDifferentialMax(eventInfo?.max_goal_differential, team.goalsDifferentialMax, game.team_score, game.opponent_team_score),
      } as Team;
    }
  });

  return mappedTeams;
};

export const countGoalDifferentialMax = (maxGoalDifferential: null | number, prevValue: undefined | number, teamOneScore: null | string | number, teamTwoScore: null | string | number) => {
  let scoreValue = prevValue || 0;

  const differentialScore = Number(teamOneScore) - Number(teamTwoScore);

  if (maxGoalDifferential === null) {
    scoreValue = scoreValue + differentialScore;

    return scoreValue;
  }

  let increment = differentialScore;

  if (increment < 0 && Math.abs(increment) > maxGoalDifferential) {
    increment = -maxGoalDifferential;
  } else if (increment >= 0 && increment > maxGoalDifferential) {
    increment = maxGoalDifferential;
  }

  scoreValue = scoreValue + increment;

  return scoreValue;
};
