import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import { getErrorMessage } from '../helpers';

export interface PlayerData {
  player_id: string;
  team_id: string;
  event_id: string;
  member_id: string;    
  first_name: string;
  last_name: string;
  jersey_number: number;
  position_description: string;
  is_active_yn: number;
}

export async function addTeamPlayers(data: PlayerData): Promise<any> {
  try {
    const response = await fetchAuthorization(`${API_URL}players`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (response.status !== 200) {
      const message = getErrorMessage(result);
      throw new Error(message ? message : `Status Code: ${response.status}. Something went wrong. Please try again later.`);
    }

    return result;
  } catch (e: any) {
    throw new Error(`Unable to add players. Error message: ${e.message}`);
  }
}

export default addTeamPlayers;
