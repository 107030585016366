import { useState } from 'react';

const useInputValue = (initialValue: string = '') => {
  const [inputValue, setInputValue] = useState(initialValue);

  return {
    inputValue,
    changeInput: (event: React.ChangeEvent<{ value: any }>) => {
      setInputValue(event.target.value);
    },
  };
};

export default useInputValue;
