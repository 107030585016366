import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import { getErrorMessage } from '../helpers';
import { PlayerStatistic } from '../models/PlayerStatictic';

export async function fetchGetPlayerStats(playerId: string): Promise<PlayerStatistic[]> {
  try {
    const response = await fetchAuthorization(`${API_URL}json_player_games_stats?member_id=${playerId}`);

    const result = await response.json();

    if (response.status !== 200) {
      const message = getErrorMessage(result);
      throw new Error(message ? message : `Status Code: ${response.status}. Sorry, something went wrong. Please try again later.`);
    }

    return result;
  } catch (e: any) {
    throw new Error(`Unable to get player stats. Error Message: ${e.message}`);
  }
}

export default fetchGetPlayerStats;
