import { FETCH_COST_SUCCESS, FETCH_PROMOCODE_SUCCESS, FETCH_PROMOCODE_FAILED, FETCH_PROMOCODE_START, FETCH_TRANSACTIONS_SUCCESS } from '../actions/accountActions';

const initialState = {
  promocode: null,
  cost: null,
  isNotFoundPromocode: false,
  isLoadingPromocode: false,
  transactions: null,
};

const accountReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_PROMOCODE_START:
      return { ...state, isNotFoundPromocode: false, isLoadingPromocode: true };
    case FETCH_PROMOCODE_SUCCESS:
      return {
        ...state,
        isNotFoundPromocode: false,
        promocode: action.payload,
        isLoadingPromocode: false,
      };
    case FETCH_PROMOCODE_FAILED:
      return { ...state, isNotFoundPromocode: true, isLoadingPromocode: false };
    case FETCH_COST_SUCCESS:
      return { ...state, cost: action.payload };
    case FETCH_TRANSACTIONS_SUCCESS:
      return { ...state, transactions: action.payload };
    default:
      return state;
  }
};

export default accountReducer;
