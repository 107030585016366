import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import { getErrorMessage } from '../helpers';

export interface Member {
  cognito_sub?: string | null;
  email_address?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  member_id: string;
  sport_id_default: number | 1;
  email_validated?: number | 0;
}

export async function createMember(data: Member): Promise<any> {
  try {
    const response = await fetchAuthorization(`${API_URL}members`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (response.status !== 200) {
      const message = getErrorMessage(result);
      throw new Error(message ? message : `Status Code: ${response.status}. Something went wrong. Please try again later.`);
    }

    return result;
  } catch (e: any) {
    throw new Error(`Unable to add players. Error message: ${e.message}`);
  }
}

export default createMember;
