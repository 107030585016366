import React, { useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import AddPlayerModal from '../AddRecords/AddPlayerModal';
import AddTeamModal from '../AddRecords/AddTeamModal';
import AddClubModal from '../AddRecords/AddClubModal';
import AddGameModal from '../AddRecords/AddGameModal';
import AddTeamPlayer from '../AddRecords/AddTeamPlayerModal';
import AddTeamPlayerEvent from '../AddRecords/AddPlayerTeamEventModal';
import AddCommitmentModal from '../AddRecords/AddCommitmentModal';
import Division from '../../models/Division';
import { PowerRankings } from '../../models/PowerRankings';

type Props = {
  buttonText: string;
  onClick?: any;
  recordType?: string;
  className?: string;
  smallButton?: boolean;
  disabled?: boolean;
  teamIds?: string | undefined;
  divisions?: Division;
  powerRanking?: PowerRankings;
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonWrapper: {
    width: '100%',
    textAlign: 'center',
  },
  largeButton: {
    marginTop: `${theme.spacing(3)} !important`,
    marginBottom: `${theme.spacing(3)} !important`,
    padding: '8px 20px !important',
    fontSize: '14px !important',
    lineHeight: '14px !important',
    fontWeight: '500px !important',
    borderRadius: '3px !important',
  },
  smallButton: { fontSize: '12px !important' },
}));

const AddRecordsButton: React.FC<Props> = (props) => {
  const classes = useStyles(props);

  const { onClick, buttonText, recordType, className, smallButton, teamIds, disabled = false, divisions, powerRanking } = props;

  const [openClubModal, setClubModal] = useState<boolean>(false);
  const [openTeamModal, setTeamModal] = useState<boolean>(false);
  const [openPlayerModal, setPlayerModal] = useState<boolean>(false);
  const [openGameModal, setGameModal] = useState<boolean>(false);
  const [openAddMeTeamModal, setOpenAddMeTeamModal] = useState<boolean>(false);
  const [openAddCommitmentModal, setOpenAddCommitmentModal] = useState<boolean>(false);

  const getOnClick = () => {
    if (onClick) onClick();

    if (recordType === 'Player') {
      setPlayerModal(true);
    }

    if (recordType === 'team') {
      setTeamModal(true);
    }

    if (recordType === 'club') {
      setClubModal(true);
    }

    if (recordType === 'game') {
      setGameModal(true);
    }

    if (recordType === 'addMeToThisTeam') {
      setOpenAddMeTeamModal(true);
    }

    if (recordType === 'addMeAsPlayer') {
      setOpenAddMeTeamModal(true);
    }

    if (recordType === 'commitment') {
      setOpenAddCommitmentModal(true);
    }

    if (!recordType) {
      setPlayerModal(false);
      setTeamModal(false);
    }
  };

  const displayLargeButton = () => {
    return (
      <Tooltip title={disabled && 'You are already on this Team'} placement="top">
        <div className={className ? className : classes.buttonWrapper}>
          <Button className={classes.largeButton} variant="contained" color="primary" startIcon={<AddCircleOutlineIcon />} onClick={getOnClick} disabled={Object.hasOwnProperty(disabled as any)}>
            {buttonText}
          </Button>
        </div>
      </Tooltip>
    );
  };

  const displaySmallButton = () => {
    return (
      <Tooltip title={disabled && 'You are already on this Team'} placement="top">
        <div className={className ? className : classes.buttonWrapper}>
          <Button className={classes.smallButton} variant="outlined" color="primary" startIcon={<AddCircleOutlineIcon />} onClick={getOnClick} disabled={Object.hasOwnProperty(disabled as any)}>
            {buttonText}
          </Button>
        </div>
      </Tooltip>
    );
  };

  return (
    <>
      {smallButton ? displaySmallButton() : displayLargeButton()}
      <AddTeamModal showAddTeam={openTeamModal} onClose={() => setTeamModal(false)} />
      <AddPlayerModal showAddPlayer={openPlayerModal} onClose={() => setPlayerModal(false)} />
      <AddClubModal showAddClub={openClubModal} onClose={() => setClubModal(false)} />
      <AddGameModal showAddGame={openGameModal} onClose={() => setGameModal(false)} />
      <AddCommitmentModal showAddCommitment={openAddCommitmentModal} onClose={() => setOpenAddCommitmentModal(false)} />
      {recordType === 'addMeAsPlayer' || (!disabled && <AddTeamPlayerEvent title={'Adding You as a Player on this Team'} openModel={openAddMeTeamModal} close={() => setOpenAddMeTeamModal(false)} divisions={divisions} powerRanking={powerRanking} />)}
      {recordType === 'addMeToThisTeam' || (!disabled && <AddTeamPlayer title={'Adding You as a Player on this Team'} openModel={openAddMeTeamModal} close={() => setOpenAddMeTeamModal(false)} teamIds={teamIds} />)}
    </>
  );
};

export default AddRecordsButton;
