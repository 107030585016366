import { API_URL } from '../../constants';
import fetchAuthorization from '../fetchAuthorization';

export async function deleteUniversity(deletedUniversity: number | null): Promise<any> {
  try {
    return await fetchAuthorization(`${API_URL}member_universities?row_num=${deletedUniversity!}`, {
      method: 'DELETE',
    });
  } catch (e: any) {
    throw new Error(`Unable to delete university. Error message: ${e.message}`);
  }
}
