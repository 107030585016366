import { apiOrigin } from '../constants';
import fetchWithAuthorization from './fetchWithAuthorization';
import qs from 'qs';

interface Club {
  club_id: string;
  org_name: string;
  org_state: string;
  sport_id: number;
  org_logo_url: string | null;
  num_teams: number | null;
  num_players: number | null;
  num_commits: number | null;
  num_pending_commits: number | null;
}

type FetchClubsOptions = {
  clubName?: string | undefined;
  limit?: number;
  offset?: number;
  sportId?: number | undefined;
  state?: string | undefined;
};

async function fetchClubs(opts: FetchClubsOptions): Promise<Club[]> {
  const query = qs.stringify({
    club_name: opts?.clubName?.trim() === '' ? undefined : opts?.clubName?.trim(),
    limit: opts.limit,
    offset: opts.offset,
    sport_id: opts.sportId,
    state: opts.state,
  });

  const response = await fetchWithAuthorization(`${apiOrigin}/api/v1/clubs?${query}`);

  const data = await response.json();
  const clubs = data.clubs;

  return clubs;
}

export default fetchClubs;
