import React from 'react';
import MuiTab, { TabProps } from '@mui/material/Tab';

function a11yProps(tabGroupName: string, index: string | number) {
  return {
    id: `${tabGroupName}-tab-${index}`,
    'aria-controls': `${tabGroupName}-tabpanel-${index}`,
  };
}

type Props = {
  tabGroupName: string;
  index: string | number;
};

const Tab: React.FC<Props & TabProps> = (props) => {
  const { index, tabGroupName, ...muiTabProps } = props;
  return <MuiTab {...a11yProps(tabGroupName, index)} {...muiTabProps} />;
};

export default Tab;
