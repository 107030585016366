import { API_URL } from '../constants';
import { Transaction } from '../models/Transaction';
import fetchWithAuthorization from './fetchAuthorization';

async function fetchMemberTransactions(memberId: string): Promise<Transaction[]> {
  const response = await fetchWithAuthorization(`${API_URL}member_transaction?member_id=${memberId}`);
  const transactions = await response.json();

  if (!transactions) {
    throw new Error(`No promo codes were found.`);
  }

  return transactions;
}

export default fetchMemberTransactions;
