import React from 'react';
import { Theme } from '@mui/material/styles';
import { List, ListItem, ListItemText, Divider, Typography, Box } from '@mui/material/';
import ConstrainedWidth from '../ConstrainedWidth';
import Logo from '../../images/logo.png';
import { LocationOn as LocationOnIcon, Phone as PhoneIcon, Mail as MailIcon } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: `${theme.palette.primary.main}`,
    background: `${theme.palette.primary.main}`,
    marginTop: 75,
  },
  footerContainer: {
    width: '100%',
    flexShrink: 0,
    padding: '15px 10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  menuItemLeft: {
    flexDirection: 'column',
    alignItems: 'flex-start !important',
  },
  menuItemRight: {
    flexDirection: 'column',
    alignItems: 'flex-end !important',
  },
  logo: {
    width: 121,
    height: 108,
  },
  menuText: {
    color: 'white',
    textDecoration: 'none',
    '& .MuiListItemText-root': {
      margin: 0,
    },
  },
  menuList: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footerInfo: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infoText: {
    color: 'white',
  },
  MuiBoxRoot: {
    width: '100%',
  },
  divider: {
    backgroundColor: 'white !important',
    '&.MuiDivider-root':{
      borderColor: 'white !important'
    }
  },
  icon: {
    color: 'white',
    marginRight: 8,
  },
  copyRightText: {
    color: 'white',
    textAlign: 'center',
  },
}));

const AppFooter: React.FC = () => {
  const classes = useStyles();

  return (
    <footer data-testid="app-footer" className={classes.root}>
      <ConstrainedWidth>
        <div className={classes.footerContainer}>
          <div className={classes.MuiBoxRoot}>
            <List className={classes.menuList}>
              <ListItem className={classes.menuItemLeft}>
                <a href="https://www.tourneymaster.org/privacy-policy/" target="_blank" rel="noopener noreferrer" className={classes.menuText}>
                  <ListItemText primary="Privacy Policy" />
                </a>
                <a href="https://www.tourneymaster.org/terms-of-service/" target="_blank" rel="noopener noreferrer" className={classes.menuText}>
                  <ListItemText primary="Terms of Service" />
                </a>
              </ListItem>
              <img src={Logo} alt="logo" className={classes.logo} />
              <ListItem className={classes.menuItemRight}>
                <a href="mailto:support@clublacrosse.org" className={classes.menuText}>
                  <ListItemText primary="Support" />
                </a>
                <a href="https://www.tourneymaster.org/about/" target="_blank" rel="noopener noreferrer" className={classes.menuText}>
                  <ListItemText primary="About" />
                </a>
              </ListItem>
            </List>
            <Divider classes={{ root: classes.divider }} />
            <div className={classes.footerInfo}>
              <Box display="flex" alignItems="center">
                <LocationOnIcon classes={{ root: classes.icon }} />
                <Typography className={classes.infoText} variant="body1">
                  One World Trade Center, Suite 8500, New York NY 10007
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <PhoneIcon classes={{ root: classes.icon }} />
                <Typography className={classes.infoText} variant="body1">
                  +1.212.377.7020
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <MailIcon classes={{ root: classes.icon }} />
                <Typography className={classes.infoText} variant="body1">
                  <a href="mailto:support@clublacrosse.org" className={classes.menuText}>
                    support@clublacrosse.org
                  </a>
                </Typography>
              </Box>
            </div>
            <Typography className={classes.copyRightText} variant="body1">
              The Club Sports Organization, LLC &copy; 2024
            </Typography>
          </div>
        </div>
      </ConstrainedWidth>
    </footer>
  );
};

export default AppFooter;
