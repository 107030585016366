import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogContent } from '@mui/material';

import { useStyles } from './styles';
import ModalTitle from './ModalTitle';
import PlayerForm from './PlayerForm';
import PlayerByCSV from './PlayerByCSV';
import { MemberContext } from '../CognitoAuth';
import ReviewPlayerRoster from './ReviewPlayers';
import { AppBar, Tab, TabPanel, Tabs } from '../Tabs';
import * as playerActions from '../../redux/actions/playerActions';
import * as memberOnboardingActions from '../../redux/actions/memberOnboardingActions';

type Props = {
  showAddPlayer: boolean;
  onClose: () => void;
  dispatchGetPlayers?: any;
  dispatchPlayerSelection?: any;
};

const AddPlayerModal: React.FC<Props> = (props) => {
  const { showAddPlayer, onClose, dispatchGetPlayers, dispatchPlayerSelection } = props;
  const classes = useStyles();
  const { member } = useContext<any>(MemberContext);
  const [selectedTab, setTab] = useState(0);
  const [teamId, setTeamId] = useState('');
  const [showReviewPlayers, setShowReviewPlayers] = useState<boolean>(false);

  const handleClose = () => {
    setShowReviewPlayers(false);
    if (teamId) dispatchGetPlayers({ team_id: teamId });
    onClose();
  };

  const onSubmitPlayerSuccess = (playerId: string) => {
    if (playerId) {
      dispatchPlayerSelection(playerId);
      handleClose();
    }
  };

  const openPlayerRoster = (teamId: string) => {
    setTeamId(teamId);
    setShowReviewPlayers(true);
  };

  const getFullName = () => {
    return `${member ? member.first_name : ''} ${member ? member.last_name : ''}`;
  };

  return (
    <>
      {!showReviewPlayers && (
        <Dialog open={showAddPlayer} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth={true}>
          <ModalTitle title={'Add Players'} memberName={getFullName()} onClose={handleClose} />
          <DialogContent className={classes.dialogContent}>
            <AppBar>
              <Tabs value={selectedTab} onChange={(e, value) => setTab(value)}>
                <Tab index={0} label="Add a Player" tabGroupName="AddPlayerTab" />
                <Tab index={1} label="Import from CSV" tabGroupName="AddPlayerTab" />
              </Tabs>
            </AppBar>
            <TabPanel value={selectedTab} index={0} tabGroupName="AddPlayerTab">
              <PlayerForm onClose={handleClose} onSubmitPlayerSuccess={onSubmitPlayerSuccess} />
            </TabPanel>
            <TabPanel value={selectedTab} index={1} tabGroupName="AddPlayerTab">
              <PlayerByCSV onClose={handleClose} onClickPlayerRoster={openPlayerRoster} />
            </TabPanel>
          </DialogContent>
        </Dialog>
      )}
      {showReviewPlayers && <ReviewPlayerRoster teamId={teamId} onClose={() => handleClose()} />}
    </>
  );
};

const mapDispatchToProps = {
  dispatchGetPlayers: (data: any) => playerActions.getPlayers(data),
  dispatchPlayerSelection: (data: any) => memberOnboardingActions.updatePlayer(data),
};

export default connect(null, mapDispatchToProps)(AddPlayerModal);
