import { ADD_TEAM_PLAYER_REQ_FAILED, ADD_TEAM_PLAYER_REQ_START, ADD_TEAM_PLAYER_REQ_SUCCESS, FETCH_PLAYER_TEAM_MAP_REQ_FAILED, FETCH_PLAYER_TEAM_MAP_REQ_SSUCCESS, FETCH_PLAYER_TEAM_MAP_REQ_START } from '../actions/playerTeamMapActions';

let initialState = {
  isLoadingPlayerMap: false,
  playerTeamMapError: {},
  playerTeam: [],
  isLoaddingAddTeamPlayer: false,
  addError: null,
  players: [],
};

const playerTeamMapReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_PLAYER_TEAM_MAP_REQ_START:
      return { ...state, isLoadingPlayerMap: true };
    case FETCH_PLAYER_TEAM_MAP_REQ_SSUCCESS:
      return {
        ...state,
        isLoadingPlayerMap: false,
        playerTeam: action.data,
      };
    case FETCH_PLAYER_TEAM_MAP_REQ_FAILED:
      return {
        ...state,
        isLoadingPlayerMap: false,
        playerTeamMapError: action.error,
        playerTeam: [],
      };
    case ADD_TEAM_PLAYER_REQ_START:
      return { ...state, isLoaddingAddTeamPlayer: true, addError: null };
    case ADD_TEAM_PLAYER_REQ_SUCCESS:
      return { ...state, isLoaddingAddTeamPlayer: false };
    case ADD_TEAM_PLAYER_REQ_FAILED:
      return { ...state, isLoaddingAddTeamPlayer: false, addError: action.error };
    default:
      return state;
  }
};

export default playerTeamMapReducer;
