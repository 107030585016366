import axios from 'axios';
import { API_URL } from '../constants';
import { RankingsParams } from '../models/Videos';
import { Auth } from 'aws-amplify';

export async function fetchVideos(payload: RankingsParams) {
  const user = await Auth.currentUserPoolUser();
  const jwtToken = user.signInUserSession.idToken.jwtToken;
  const instance = axios.create({
    baseURL: API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${jwtToken}`,
    },
  });

  try {
    const data = await instance.post(`${API_URL}ranked_media`, payload);
    return data.data[0];
  } catch (e: any) {
    throw new Error(`Something went wrong. Error message: ${e.message}`);
  }
}

export default fetchVideos;
