import { FETCH_SEARCH_REQ_START, FETCH_SEARCH_REQ_SUCCESS, FETCH_SEARCH_REQ_FAILED, SET_SEARCH_SELECTION } from '../actions/searchActions';

let initialState = {
  isLoadingSearch: false,
  searchResults: [],
  searchSelection: null,
  searchError: null,
};

const searchReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_SEARCH_REQ_START:
      return { ...state, isLoadingSearch: true };
    case FETCH_SEARCH_REQ_SUCCESS:
      return {
        ...state,
        isLoadingSearch: false,
        searchResults: action.data,
        searchError: null,
      };
    case FETCH_SEARCH_REQ_FAILED:
      return {
        ...state,
        isLoadingSearch: false,
        searchError: action.error,
        searchResults: [],
      };
    case SET_SEARCH_SELECTION:
      return {
        ...state,
        isLoadingSearch: false,
        searchSelection: action.data,
        searchError: null,
      };
    default:
      return state;
  }
};

export default searchReducer;
