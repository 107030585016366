import { FETCH_PLAYER_RANKINGS_REQ_START, FETCH_PLAYER_RANKINGS_REQ_SUCCESS, FETCH_PLAYER_RANKINGS_REQ_FAILED, FETCH_FOLLOWED_PLAYER_REQ_START, FETCH_FOLLOWED_PLAYER_SUCCESS, FETCH_PLAYER_SUMMARY_STATS_REQ_START, FETCH_PLAYER_SUMMARY_STATS_REQ_SUCCESS, FETCH_PLAYER_SUMMARY_STATS_REQ_FAILED, FETCH_PLAYER_GAME_STATS_REQ_START, FETCH_PLAYER_GAME_STATS_REQ_SUCCESS, FETCH_PLAYER_GAME_STATS_REQ_FAILED, FETCH_PLAYER_REQ_START, FETCH_PLAYER_REQ_SUCCESS, FETCH_PLAYER_REQ_FAILED, ADD_PLAYER_REQ_START, ADD_PLAYER_REQ_SUCCESS, ADD_PLAYER_REQ_FAILED, FETCH_PLAYER_PROFILE_STATS_REQ_START, FETCH_PLAYER_PROFILE_STATS_REQ_SUCCESS, FETCH_PLAYER_PROFILE_STATS_REQ_FAILED, FETCH_TEAMS_REQ_START, FETCH_TEAMS_REQ_SUCCESS, FETCH_TEAMS_REQ_FAILED, FETCH_PLAYER_PROFILE_REQ_START, FETCH_PLAYER_PROFILE_REQ_SUCCESS, FETCH_PLAYER_UNIVERSITY, FETCH_PLAYER_ACCOLADES, FETCH_PLAYER_HIGHLIGHTS,FETCH_PLAYER_HIGHLIGHTS_RATING, ADD_PLAYER_HIGHLIGHTS, DELETE_PLAYER_HIGHLIGHTS, ADD_PLAYER_ACCOLADE, EDIT_PLAYER_ACCOLADES, FETCH_GEAR_LIST, UPDATE_PLAYER_UNIVERSITY, FETCH_PLAYER_PROFILE_STATS_SUCCESS, FETCH_PLAYER_FOLLOWERS_SUCCESS, FETCH_CHECK_FOLLOW_SUCCESS, FETCH_SET_FOLLOW_NUM_SUCCESS, FETCH_PLAYER_PROFILE_STATS_CONFIRM_SUCCESS, FETCH_PLAYER_WHO_FOLLOW_US_REQ_SUCCESS, FETCH_ALL_PLAYER_HIGHLIGHTS_RATING, FETCH_PLAYER_EVENT_STATS_REQ_SUCCESS, FETCH_PLAYER_CLUBS_SUCCESS, UPDATE_PLAYER_CLUBS, FETCH_PLAYER_UNIVERSITY_NOTIFICATION } from '../actions/playerActions';

let initialState = {
  isLoadingPlayerRankings: false,
  playerRankings: [],
  playerRankingsError: null,
  isLoadingFollowedPlayers: false,
  followedPlayers: [],
  isLoadingPlayerSummaryStats: false,
  playerSummaryStatsResult: [],
  playerEventStatsResult: [],
  playerSummaryStatsError: null,
  isLoadingPlayerGamesStats: false,
  playerGamesStatsResult: [],
  playerGamesStatsError: null,
  isLoadingPlayers: false,
  players: [],
  playersError: null,
  isLoaddingAddPlayer: false,
  addError: null,
  isLoadingPlayerProfileStats: false,
  isLoadingPlayerProfile: false,
  playerProfileStatsResult: [],
  playerProfileStats: [],
  playerProfileStatsError: null,
  playerProfile: null,
  isLoadingTeams: false,
  teams: [],
  teamsError: null,
  playerUniversity: [],
  playerAccolades: [],
  playerHighlights: [],
  playerHighlightsRating: [],
  playerAllHighlightsRating: [],
  gearList: [],
  followNum: 0,
  followNumId: undefined,
  isFollow: false,
  playerStatsConfirm: null,
  playersWhoFollowUs: [],
  isLoadingPlayersWhoFollowUs: false,
  playerClubs: [],
  playerUniversityNotification: [],
};

const playerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_PLAYER_RANKINGS_REQ_START:
      return { ...state, isLoadingPlayerRankings: true };
    case FETCH_PLAYER_RANKINGS_REQ_SUCCESS:
      return {
        ...state,
        isLoadingPlayerRankings: false,
        playerRankings: action.data,
        playerRankingsError: null,
      };
    case FETCH_PLAYER_RANKINGS_REQ_FAILED:
      return {
        ...state,
        isLoadingPlayerRankings: false,
        playerRankingsError: action.error,
        playerRankings: [],
      };

    case FETCH_FOLLOWED_PLAYER_REQ_START:
      return { ...state, isLoadingFollowedPlayers: true };
    case FETCH_FOLLOWED_PLAYER_SUCCESS:
      return {
        ...state,
        isLoadingFollowedPlayers: false,
        followedPlayers: action.data,
      };

    case FETCH_PLAYER_SUMMARY_STATS_REQ_START:
      return { ...state, isLoadingPlayerSumaryStats: true };
    case FETCH_PLAYER_SUMMARY_STATS_REQ_SUCCESS:
      return {
        ...state,
        playerSummaryStatsResult: action.data,
        isLoadingPlayerSumaryStats: false,
        playerSummaryStatsError: null,
      };
      case FETCH_PLAYER_EVENT_STATS_REQ_SUCCESS:
        return {
          ...state,
          isLoadingPlayerSumaryStats: false,
          playerEventStatsResult: action.data,
          playerSummaryStatsError: null,
        };
    case FETCH_PLAYER_SUMMARY_STATS_REQ_FAILED:
      return {
        ...state,
        isLoadingPlayerSumaryStats: false,
        playerSummaryStatsError: action.error,
        playerSummaryStatsResult: [],
      };

    case FETCH_PLAYER_GAME_STATS_REQ_START:
      return {
        ...state,
        isLoadingPlayerGamesStats: true,
        playerGamesStatsResult: [],
        playerGamesStatsError: null,
      };
    case FETCH_PLAYER_GAME_STATS_REQ_SUCCESS:
      return {
        ...state,
        isLoadingPlayerGamesStats: false,
        playerGamesStatsResult: action.data,
        playerGamesStatsError: null,
      };
    case FETCH_PLAYER_GAME_STATS_REQ_FAILED:
      return {
        ...state,
        isLoadingPlayerGamesStats: false,
        playerGamesStatsError: action.error,
        playerGamesStatsResult: [],
      };

    case FETCH_PLAYER_REQ_START:
      return {
        ...state,
        isLoadingPlayersWhoFollowUs: true,
        isLoadingPlayers: true,
        players: [],
        playersError: null,
      };
    case FETCH_PLAYER_REQ_SUCCESS:
      return {
        ...state,
        isLoadingPlayers: false,
        players: action.data,
        playersError: null,
      };
    case FETCH_PLAYER_WHO_FOLLOW_US_REQ_SUCCESS:
      return {
        ...state,
        isLoadingPlayersWhoFollowUs: false,
        playersWhoFollowUs: action.data,
        playersError: null,
      };
    case FETCH_PLAYER_REQ_FAILED:
      return {
        ...state,
        isLoadingPlayers: false,
        playersError: action.error,
        players: [],
      };

    case ADD_PLAYER_REQ_START:
      return { ...state, isLoaddingAddPlayer: true, addError: null };
    case ADD_PLAYER_REQ_SUCCESS:
      return { ...state, isLoaddingAddPlayer: false };
    case ADD_PLAYER_REQ_FAILED:
      return { ...state, isLoaddingAddPlayer: false, addError: action.error };

    case FETCH_PLAYER_PROFILE_STATS_REQ_START:
      return { ...state, isLoadingPlayerProfileStats: true };
    case FETCH_PLAYER_PROFILE_REQ_START:
      return { ...state, isLoadingPlayerProfile: true };
    case FETCH_PLAYER_PROFILE_STATS_REQ_SUCCESS:
      return {
        ...state,
        isLoadingPlayerProfileStats: false,
        playerProfileStatsResult: action.payload,
        playerProfileStatsError: null,
      };
    case FETCH_PLAYER_PROFILE_STATS_SUCCESS:
      return {
        ...state,
        playerProfileStats: action.payload,
      };
    case FETCH_PLAYER_PROFILE_REQ_SUCCESS:
      return {
        ...state,
        isLoadingPlayerProfile: false,
        playerProfile: action.data,
      };
    case FETCH_PLAYER_PROFILE_STATS_REQ_FAILED:
      return {
        ...state,
        isLoadingPlayerProfileStats: false,
        playerProfileStatsResult: action.error,
        playerProfileStatsError: [],
      };
    case UPDATE_PLAYER_UNIVERSITY:
      return {
        ...state,
        playerUniversity: state.playerUniversity ? [...state.playerUniversity, action.payload] : action.payload,
      };
    case FETCH_PLAYER_UNIVERSITY:
      return { ...state, playerUniversity: action.payload };
    case FETCH_PLAYER_ACCOLADES:
      return { ...state, playerAccolades: action.payload };
    case ADD_PLAYER_ACCOLADE:
      return {
        ...state,
        playerAccolades: [...state.playerAccolades, action.payload],
      };
    case EDIT_PLAYER_ACCOLADES:
      return { ...state, playerAccolades: action.payload };
    case FETCH_PLAYER_HIGHLIGHTS:
      return { ...state, playerHighlights: action.payload };
    case FETCH_PLAYER_HIGHLIGHTS_RATING:
        return { ...state, playerHighlightsRating: action.payload };
    case FETCH_ALL_PLAYER_HIGHLIGHTS_RATING: {
      return {...state, playerAllHighlightsRating: action.payload};
    }
    case ADD_PLAYER_HIGHLIGHTS:
      return {
        ...state,
        playerHighlights: [...state.playerHighlights, action.payload],
      };
    case DELETE_PLAYER_HIGHLIGHTS:
      return { ...state, playerHighlights: action.payload };
    case FETCH_GEAR_LIST:
      return { ...state, gearList: action.payload };
    case FETCH_TEAMS_REQ_START:
      return { ...state, isLoadingTeams: true, teams: [], teamsError: null };
    case FETCH_TEAMS_REQ_SUCCESS:
      return { ...state, isLoadingTeams: false, teams: action.data };
    case FETCH_TEAMS_REQ_FAILED:
      return {
        ...state,
        isLoadingTeams: false,
        teams: [],
        teamsError: action.error,
      };
    case FETCH_PLAYER_FOLLOWERS_SUCCESS:
      return {
        ...state,
        followNum: action.payload,
      };
    case FETCH_CHECK_FOLLOW_SUCCESS:
      return {
        ...state,
        isFollow: action.payload,
      };
    case FETCH_SET_FOLLOW_NUM_SUCCESS:
      return {
        ...state,
        followNumId: action.payload,
      };
    case FETCH_PLAYER_PROFILE_STATS_CONFIRM_SUCCESS:
      return {
        ...state,
        playerStatsConfirm: action.payload,
      };
      case FETCH_PLAYER_CLUBS_SUCCESS:
      return {
        ...state,
        playerClubs: action.payload,
      };
      case UPDATE_PLAYER_CLUBS:
        return {
          ...state,
          playerClubs: state.playerClubs ? [...state.playerClubs, action.payload] : action.payload,
        };
        case FETCH_PLAYER_UNIVERSITY_NOTIFICATION:
        return {
          ...state,
          playerUniversityNotification: action.payload,
        }
    default:
      return state;
  }
};

export default playerReducer;
