import moment from 'moment';
import MatchupGame from '../models/MatchupGame';

export const getOptionsSelectDays = (games: MatchupGame[]) => {
  const optionsSelectDays = Array.from(
    new Set(games.map((game: MatchupGame) => game.game_date)),
  ).map((day: any) => ({
    value: day,
    label: moment.utc(day).format('LL'),
  }));

  const sortedDays = optionsSelectDays.sort((a, b) => {
    return +new Date(a.label) > +new Date(b.label) ? 1 : -1;
  });

  return [
    {
      value: 'All',
      label: 'All',
    },
    ...sortedDays,
  ];
};

export const dateToMMMDD = (date: string | Date | null | undefined) => {
  if (!date) return '';
  return moment.utc(date).format('MMM D, YYYY');
};

export const dateToMDDYYYY = (date: string) => {
  if (date) {
    return (
      moment
        .utc(date)
        // .add(1, 'days')
        .format('M/DD/YYYY')
    );
  }
  return 'NA';
};

export const dateToMmmYYYY = (date: string) => {
  if (date) {
    return moment.utc(date).format('MMMM YYYY');
  }
  return 'NA';
};

export const dateToYYYY = (date: string) => {
  if (date) {
    return moment.utc(date).format('YYYY');
  }
  return 'NA';
};
