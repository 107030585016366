import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import qs from 'qs';
import _ from 'lodash';
import { MemberAssociation } from '../models/MemberAssociation';

type MemberOptions = {
  member_id?: string | undefined;
  association_id?: string | undefined;
};

export async function fetchMemberAssociations(opts: MemberOptions): Promise<MemberAssociation[]> {
  try {
    const query = qs.stringify({
      member_id: opts.member_id,
      association_id: opts.association_id,
    });

    const response = await fetchAuthorization(`${API_URL}member_associations?${query}`);

    const result = await response.json();

    const data = _.uniqBy(result, function (e: MemberAssociation) {
      return [e.association_fk_id, e.association_id].join();
      // return e.association_fk_id;
    });
    return data;
    
    
  } catch (e: any) {
    throw new Error(`Something went wrong. Error message: ${e.message}`);
  }
}

export default fetchMemberAssociations;
