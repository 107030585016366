import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import { getErrorMessage } from '../helpers';

export async function fetchUSLacrosseDivision(data :any): Promise<any> {
  try {
    const response = await fetchAuthorization(`${API_URL}us_lacrosse_divisions`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data)
    });
    const result = await response.json();

    if (response.status !== 200) {
      const message = getErrorMessage(result);
      throw new Error(message ? message : `Status Code: ${response.status}. Something went wrong. Please try again later.`);
    }

    return result;
  } catch (e: any) {
    throw new Error(`Unable to add the US Lacrosse Data. Error message: ${e.message}`);
  }
}

export default fetchUSLacrosseDivision;
