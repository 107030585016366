import moment from 'moment';
import { Events } from '../apis/fetchVEvents';
import { ProspectDays } from '../apis/fetchProspectDays';
import { Tryouts } from '../apis/fetchClubTryouts';

const dates = {
  today: {
    start: () => moment().format('YYYY-MM-DD 00:00:01'),
    end: () => moment().format('YYYY-MM-DD 23:59:59'),
  },
};
const pastDates = {
  oneweek: {
    start: () => moment().subtract(7, 'days').format('YYYY-MM-DD 00:00:01'),
  },
  onemonth: {
    start: () => moment().subtract(1, 'months').format('YYYY-MM-DD 00:00:01'),
  },
  threemonths: {
    start: () => moment().subtract(3, 'months').format('YYYY-MM-DD 00:00:01'),
  },
  oneyear: {
    start: () => moment().subtract(1, 'years').format('YYYY-MM-DD 00:00:01'),
  },
};

const upcomingDates = {
  oneweek: {
    start: () => moment().add(7, 'days').format('YYYY-MM-DD 00:00:01'),
  },
  onemonth: {
    start: () => moment().add(1, 'months').format('YYYY-MM-DD 00:00:01'),
  },
  threemonths: {
    start: () => moment().add(3, 'months').format('YYYY-MM-DD 00:00:01'),
  },
  oneyear: {
    start: () => moment().add(1, 'years').format('YYYY-MM-DD 00:00:01'),
  },
};

function getByType(type: string) {
  if (type === 'pastDates') {
    return pastDates;
  }
  return upcomingDates;
}

export function filterByTime(slot: string, events: Array<any>, type: string) {
  if (type === 'pastDates') {
    switch (slot) {
      case 'one-week':
        return events.filter((event) => {
          return moment(event.event_startdate).isBetween(getByType(type).oneweek.start(), dates.today.end());
        });
      case 'one-month':
        return events.filter((event) => {
          return moment(event.event_startdate).isBetween(getByType(type).onemonth.start(), dates.today.end());
        });
      case 'three-months':
        return events.filter((event) => {
          return moment(event.event_startdate).isBetween(getByType(type).threemonths.start(), dates.today.end());
        });
      case 'one-year':
        return events.filter((event) => {
          return moment(event.event_startdate).isBetween(getByType(type).oneyear.start(), dates.today.end());
        });
      default:
        return events;
    }
  }
  switch (slot) {
    case 'one-week':
      return events.filter((event) => {
        return moment(event.event_startdate).isBetween(dates.today.end(), getByType(type).oneweek.start());
      });
    case 'one-month':
      return events.filter((event) => {
        return moment(event.event_startdate).isBetween(dates.today.end(), getByType(type).onemonth.start());
      });
    case 'three-months':
      return events.filter((event) => {
        return moment(event.event_startdate).isBetween(dates.today.end(), getByType(type).threemonths.start());
      });
    case 'one-year':
      return events.filter((event) => {
        return moment(event.event_startdate).isBetween(dates.today.end(), getByType(type).oneyear.start());
      });
    default:
      return events;
  }
}

// export function isTodayBetween(start_date: string, end_date: string) {
//   const today = moment.utc().startOf('day');
//   const startDate = moment.utc(start_date).startOf('day');
//   const endDate = moment.utc(end_date).endOf('day');
//   return today.isSameOrAfter(startDate) && today.isSameOrBefore(endDate);
// }

export function isTodayBetween(start_date: string, end_date: string) {
  const today = moment.utc().toISOString();
  const startDate = moment.utc(start_date).toISOString();
  const endDate = moment.utc(end_date).toISOString();
  return startDate <= today && endDate >= today;
}

export function filterPastEvents(data: Events[]) {
  return data.filter((event: any) => moment().isAfter(event.event_enddate));
}

export function filterProspectDay(data: ProspectDays[]) {
  return data.filter((event: any) => moment().isSameOrBefore(event.start_date));
}
export function filterClubTryouts(data: Tryouts[]) {
  return data.filter((event: any) => moment().isSameOrBefore(event.event_dates.startsWith('[') && event.event_dates.endsWith(']') ? event.event_dates.slice(1, event.event_dates.indexOf(',')) || event.event_dates : event.event_dates));
}

export function filterUpcomingEvents(data: Events[]) {
  const today = new Date();
  today.setUTCHours(0, 0, 0, 0); // Set the time to the start of the day in UTC

  return data.filter((event: Events) => {
    const eventEndDate = new Date(event.event_enddate);
    return eventEndDate >= today;
  });
}
