// import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import qs from 'qs';

export interface PlayerRankings {
  player_id: number;
  rank: number;
  no: string;
  name: string;
  team: string;
  position: string;
  ppr: number;
  goals: number;
  assists: number;
  fto: number;
  GB: number;
  is_following: number;
  this_is_me: number;
}

type PlayerRankingsOptions = {
  team_year: number;
  sport_id?: number;
  team_level?: string | undefined;
  team_name?: string | undefined;
  // gender?: string | undefined;
  position_description?: string | undefined;
  state?: string | undefined;
};

async function fetchPlayerRankings(opts: PlayerRankingsOptions): Promise<PlayerRankings[]> {
  const query = qs.stringify({
    limit: 1000,
    sport_id: opts.sport_id,
    team_year: opts.team_year,
    team_level: opts.team_level,
    team_name: opts.team_name,
    // gender: opts.gender,
    position_description: opts.position_description,
    state: opts.state,
  });
  const response = await fetchAuthorization(`https://api.clublacrosse.org/v2/player_rankings?${query}`);

  const result = await response.json();
  if (result && result.success === false) {
    throw new Error(result.message || 'Something went wrong. Please try again later.');
  }
  // if (response.status !== 200) {
  //   throw new Error(
  //     result.error
  //       ? result.error
  //       : `Status Code: ${response.status}. Something went wrong. Please try again later.`,
  //   );
  // }
  return result;
}

export default fetchPlayerRankings;
