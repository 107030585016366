import Amplify from 'aws-amplify';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';

import store from '../../redux/store';
import CognitoAuthProvider from '../CognitoAuth';
import LoggedInApplicationNew from './LoggedInApplicationNew';
import LoginPage from '../LoginPage';
import theme from '../../theme/theme';
import GlobalStyles from './globalStyle';

const awsconfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,

    oauth: {
      domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
      scope: ['openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${window.location.origin}/`,
      redirectSignOut: `${window.location.origin}/`,
      responseType: 'code',
    },
  },
  Storage: {
    bucket: 'clubsportsmedia',
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  },
};

Amplify.configure(awsconfig);

const App: React.FC = () => {
  return (
    <>
      <Provider store={store}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <CognitoAuthProvider loggedOutComponent={<LoginPage />}>
            <LoggedInApplicationNew />
          </CognitoAuthProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
};
export default App;
