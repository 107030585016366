import { ADDED_COMMITMENT_SUCCESS, FETCH_COMMITMENTDATA_SUCCESS, FETCH_COMMITMENT_STARTED, FETCH_HIGHSCHOOLS_SUCCESS, FETCH_ORGANIZATIONS_FAILED, FETCH_ORGANIZATIONS_STARTED, FETCH_ORGANIZATIONS_SUCCESS, FETCH_UNIVERSITIES_SUCCESS } from '../actions/commitmentActions';

let initialState = {
  isLoading: false,
  organizationData: [],
  error: null,
  universities: [],
  highSchools: [],
  commitmentIsAdded: false,
  commitmentData: [],
  isLoadingCommitment: false,
};

const commitmentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_ORGANIZATIONS_STARTED:
      return { ...state, isLoading: true };
    case FETCH_ORGANIZATIONS_SUCCESS:
      return { ...state, organizationData: action.payload, isLoading: false };
      case FETCH_COMMITMENT_STARTED:
      return { ...state, isLoadingCommitment: true };
    case FETCH_COMMITMENTDATA_SUCCESS:
      return { ...state, commitmentData: action.payload, isLoadingCommitment: false };
    case FETCH_ORGANIZATIONS_FAILED:
      return { ...state, isLoading: false, error: action.payload };
    case FETCH_UNIVERSITIES_SUCCESS:
      return { ...state, universities: action.payload };
    case FETCH_HIGHSCHOOLS_SUCCESS:
      return { ...state, highSchools: action.payload };
    case ADDED_COMMITMENT_SUCCESS:
      return { ...state, commitmentIsAdded: action.payload };
    default:
      return state;
  }
};

export default commitmentReducer;
