import qs from 'qs';
import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';

export interface Search {
  search_term: string;
  details: string;
  key_type: string;
  sport_id: number;
  fk_id: string;
  is_active_YN: number;
  created_datetime: string;
}

type SearchOptions = {
  sport_id?: number | undefined;
  key_type?: string | undefined;
  search_term?: string | undefined;
};

export async function fetchSearch(opts: SearchOptions): Promise<Search[]> {
  try {
    const query = qs.stringify(
      {
        search_term: `%${opts.search_term}%`,
        key_type: opts.key_type,
        sport_id: opts.sport_id,
      },
      {
        encodeValuesOnly: true,
      },
    );
    const encodeUrl = `${API_URL}search?${query}`;

    const response = await fetchAuthorization(`${encodeUrl}`);

    const result = await response.json();
    return result;
  } catch (e: any) {
    throw new Error(`Sorry, something went wrong. Error message: ${e.message}`);
  }
}

export default fetchSearch;
