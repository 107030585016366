import React from 'react';
import { Link } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Shirt from '../../images/shirt-icon.svg';

type Props = {
  textLink: string | number;
  route?: string;
  className?: object | {};
  textLinkClassName?: object | {};
};

const useStyles = makeStyles((theme: Theme) => ({
  textLink: {
    color: theme.palette.primary.main,
    fontWeight: 'bold !important',
  },
  shirtIcon: {
    marginRight: '7px !important',
    backgroundPosition: 'center',
    backgroundImage: `url(${Shirt})`,
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 50,
  },
  hiddenLinkText: {
    textDecoration: 'none',
  },
}));

const TShirt: React.FC<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <>
      {props.route ? (
        <div className={`${props.className} ${classes.shirtIcon}`}>
          <Link to={props.route} className={classes.hiddenLinkText}>
            <Typography className={`${props.textLinkClassName} ${classes.textLink}`} variant="body2">
              {props.textLink === '-1' ? '' : props.textLink}
            </Typography>
          </Link>
        </div>
      ) : (
        <div className={`${props.className} ${classes.shirtIcon}`}>
          <Typography className={`${props.textLinkClassName} ${classes.textLink}`} variant="body2">
            {props.textLink === '-1' ? '' : props.textLink}
          </Typography>
        </div>
      )}
    </>
  );
};

export default TShirt;
