import { API_URL } from '../constants';
import { Promocode } from '../models/Promocode';
import fetchWithAuthorization from './fetchAuthorization';

async function fetchPromocode(promo: string): Promise<Promocode[][]> {
  const response = await fetchWithAuthorization(`${API_URL}promo_codes?promo_code=${promo}`);
  const promocode = await response.json();

  if (!promocode) {
    throw new Error(`No promo codes were found.`);
  }

  return promocode;
}

export default fetchPromocode;
