import GameStat from '../../components/GameStats/models/GameStat';
import ClaimedStatisticsConfirm from '../../models/ClaimedStatisticsConfirm';
import { FETCH_GAME_REQ_START, FETCH_GAME_REQ_SUCCESS, FETCH_GAME_REQ_FAILED, ADD_GAME_REQ_START, ADD_GAME_REQ_SUCCESS, ADD_GAME_REQ_FAILED, FETCH_GAME_STATS_SUCCESS, FETCH_GAME_STATS_START, FETCH_GAME_STATS_FAILED, FETCH_POST_CLAIMED_STATS_CONFIRM, FETCH_GET_GAME_STAT_SUMMARY, FETCH_PUT_CLAIMED_STATS_CONFIRM, FETCH_DELETE_CLAIMED_STATS_CONFIRM, FETCH_GET_CLAIMED_STATS_MEMBERLIST } from '../actions/gameActions';

let initialState = {
  isLoadingGameInfo: false,
  gameError: null,
  gameDetails: null,
  isLoadingAddGame: false,
  addGameError: null,
  gameStats: null,
  isLoadingGameStats: false,
  statsConfirm: [],
  statsSummary: [],
  claimedMemberList: [],
};

const gameReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_GAME_REQ_START:
      return { ...state, isLoadingGameInfo: true, updateError: null };
    case FETCH_GAME_REQ_SUCCESS:
      return { ...state, isLoadingGameInfo: false, gameDetails: action.data };
    case FETCH_GAME_REQ_FAILED:
      return { ...state, isLoadingGameInfo: false, gameError: action.error };

    case ADD_GAME_REQ_START:
      return { ...state, isLoadingAddGame: true, addGameError: null };
    case ADD_GAME_REQ_SUCCESS:
      return { ...state, isLoadingAddGame: false, addGameError: null };
    case ADD_GAME_REQ_FAILED:
      return { ...state, isLoadingAddGame: false, addGameError: action.error };
    case FETCH_GAME_STATS_SUCCESS:
      const { fullStatistic, claimedStatisticsConfirm } = action.payload;
      return {
        ...state,
        gameStats: fullStatistic,
        statsConfirm: claimedStatisticsConfirm,
        isLoadingGameStats: false,
      };
    case FETCH_GAME_STATS_START:
      return { ...state, isLoadingGameStats: true };
    case FETCH_GAME_STATS_FAILED:
      return { ...state, isLoadingGameStats: false };
    case FETCH_POST_CLAIMED_STATS_CONFIRM:
      const allStats: GameStat[] = state.gameStats as unknown as GameStat[];
      const newStats = allStats?.map((stat) => {
        if (stat.claim_id === action.payload.claim_id) {
          return {
            ...stat,
            num_agree: action.payload.confirm_value === 1 ? (stat.num_agree || 0) + 1 : stat.num_agree,
            num_disagree: action.payload.confirm_value === -1 ? (stat.num_disagree || 0) + 1 : stat.num_disagree,
          };
        }
        return stat;
      });

      return {
        ...state,
        gameStats: state.gameStats ? newStats : null,
        statsConfirm: state.statsConfirm?.length > 0 ? [...state.statsConfirm, action.payload] : [action.payload],
      };
    case FETCH_PUT_CLAIMED_STATS_CONFIRM:
      const confs = state.statsConfirm?.map((conf: ClaimedStatisticsConfirm) => {
        if (conf.confirm_id === action.payload.confirm_id) {
          return action.payload;
        }
        return conf;
      });
      const gameStats: GameStat[] = state.gameStats as unknown as GameStat[];
      const newAllGameStats = gameStats?.map((stat) => {
        if (stat.claim_id === action.payload.claim_id) {
          return {
            ...stat,
            num_agree: action.payload.confirm_value === 1 ? (stat.num_agree || 0) + 1 : (stat.num_agree || 0) - 1,
            num_disagree: action.payload.confirm_value === -1 ? (stat.num_disagree || 0) + 1 : (stat.num_disagree || 0) - 1,
          };
        }
        return stat;
      });

      return {
        ...state,
        gameStats: state.gameStats ? newAllGameStats : null,
        statsConfirm: confs,
      };
    case FETCH_DELETE_CLAIMED_STATS_CONFIRM:
      const allGameStats: GameStat[] = state.gameStats as unknown as GameStat[];
      const allClaimedConfirm: ClaimedStatisticsConfirm[] = state.statsConfirm as unknown as ClaimedStatisticsConfirm[];

      const currentClaimConfirm = allClaimedConfirm.find((confirm: ClaimedStatisticsConfirm) => confirm.confirm_id === action.payload);
      const newGameStats = allGameStats?.map((stat) => {
        if (stat.claim_id === currentClaimConfirm?.claim_id) {
          return {
            ...stat,
            num_agree: currentClaimConfirm.confirm_value === 1 ? (stat.num_agree || 0) - 1 : stat.num_agree,
            num_disagree: currentClaimConfirm.confirm_value === -1 ? (stat.num_disagree || 0) - 1 : stat.num_disagree,
          };
        }
        return stat;
      });

      return {
        ...state,
        statsConfirm: allClaimedConfirm.filter((confirm) => confirm.confirm_id !== action.payload),
        gameStats: state.gameStats ? newGameStats : null,
      };
    case FETCH_GET_GAME_STAT_SUMMARY:
      return {
        ...state,
        statsSummary: action.payload,
      };
      case FETCH_GET_CLAIMED_STATS_MEMBERLIST:
      return {
        ...state,
        claimedMemberList: action.payload,
      };
    default:
      return state;
  }
};

export default gameReducer;
