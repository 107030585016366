import React, { ReactNode } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ConstrainedWidth from '../ConstrainedWidth';

interface IProps {
  children: ReactNode;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(1)} 0 !important`,
      backgroundColor: theme.palette.secondary.main,
      marginBottom: theme.spacing(3),
    },
  }),
);

const SectionHeader = (props: IProps) => {
  const classes = useStyles(props);

  return (
    <header className={classes.root}>
      <ConstrainedWidth>{props.children}</ConstrainedWidth>
    </header>
  );
};

export default SectionHeader;
