import { FETCH_FACE_TO_FACE_SEARCH_REQ_START, FETCH_FACE_TO_FACE_SEARCH_REQ_SUCCESS, FETCH_FACE_TO_FACE_SEARCH_REQ_FAILED, FETCH_GAME_PLAYER_STATES_REQ_START, FETCH_GAME_PLAYER_STATES_LEFT_REQ_SUCCESS, FETCH_GAME_PLAYER_STATES_RIGHT_REQ_SUCCESS, FETCH_GAME_PLAYER_STATES_REQ_FAILED, FETCH_GAME_PLAYER_CHART_LEFT_STATES_SUCCESS, FETCH_GAME_PLAYER_CHART_RIGHT_STATES_SUCCESS, RESET_PLAYER_DATA } from '../actions/faceToFaceActions';

let initialState = {
  isLoadingFaceToFaceFSearch: false,
  faceToFaceSearchResults: [],
  faceToFaceSearchError: null,
  isLoadingPlayerGameStats: false,
  playerGameStatsLeftResults: [],
  playerGameStatsRightResults: [],
  playerGameStatsSearchError: null,
  leftChartData: [],
  rightChartData: [],
};

const faceToFaceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_FACE_TO_FACE_SEARCH_REQ_START:
      return { ...state, isLoadingFaceToFaceFSearch: true };
    case FETCH_FACE_TO_FACE_SEARCH_REQ_SUCCESS:
      return {
        ...state,
        isLoadingFaceToFaceFSearch: false,
        faceToFaceSearchResults: action.data,
        faceToFaceSearchError: null,
      };
    case FETCH_FACE_TO_FACE_SEARCH_REQ_FAILED:
      return {
        ...state,
        isLoadingFaceToFaceFSearch: false,
        faceToFaceSearchError: action.error,
        faceToFaceSearchResults: [],
      };
    case FETCH_GAME_PLAYER_STATES_REQ_START:
      return { ...state, isLoadingPlayerGameStats: true };
    case FETCH_GAME_PLAYER_STATES_LEFT_REQ_SUCCESS:
      return {
        ...state,
        isLoadingPlayerGameStats: false,
        playerGameStatsLeftResults: action.data,
        playerGameStatsSearchError: null,
      };
    case FETCH_GAME_PLAYER_STATES_RIGHT_REQ_SUCCESS:
      return {
        ...state,
        isLoadingPlayerGameStats: false,
        playerGameStatsRightResults: action.data,
        playerGameStatsSearchError: null,
      };
    case FETCH_GAME_PLAYER_STATES_REQ_FAILED:
      return {
        ...state,
        isLoadingPlayerGameStats: false,
        playerGameStatsSearchError: action.error,
        playerGameStatsLeftResults: [],
        playerGameStatsRightResults: [],
      };
    case FETCH_GAME_PLAYER_CHART_LEFT_STATES_SUCCESS:
      return { ...state, leftChartData: action.data };
    case FETCH_GAME_PLAYER_CHART_RIGHT_STATES_SUCCESS:
      return { ...state, rightChartData: action.data };
    case RESET_PLAYER_DATA:
      return { ...initialState };
    default:
      return state;
  }
};

export default faceToFaceReducer;
