import React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import * as commonActions from '../../redux/actions/commonActions';

type Props = {
  common: any;
  dispatchCloseError: any;
};

const ErrorDialog: React.FC<Props> = ({ common, dispatchCloseError }) => {
  return (
    <Dialog aria-labelledby="error-dialog" open={common.errorDialog}>
      <DialogTitle id="error-dialog">Error</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{common.error}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={dispatchCloseError} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  common: state.commonReducer,
});

const mapDispatchToProps = {
  dispatchCloseError: () => commonActions.closeErrorDialog(),
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog);
