import moment from 'moment';
import _ from 'lodash';
import { PlayerDominantHand, PlayerPosition } from '../enums';
import GearOption from '../models/GearOption';
import { IPlayerProfile } from '../models/PlayerProfile';
import { range } from 'ramda';
import { geographies } from '../constants';

export const DominantHandValue = {
  [PlayerDominantHand.RIGHT]: 0,
  [PlayerDominantHand.LEFT]: 1,
};

export const PositionValue = {
  [PlayerPosition.ATTACK]: 0,
  [PlayerPosition.MIDDIE]: 1,
  [PlayerPosition.DEFENSE]: 2,
  [PlayerPosition.LSM]: 3,
  [PlayerPosition.FOGO_DRAW]: 4,
  [PlayerPosition.GOALIE]: 5,
  //[PlayerPosition.OTHER]: 6,
};

export async function processPlayers(players: any) {
  let normalizedPlayers = _.map(players, function (p) {
    if (
      p.first_name === undefined ||
      p.first_name === null ||
      p.first_name === ''
    ) {
      p.first_name = 'Unknown';
    }
    if (
      p.last_name === undefined ||
      p.last_name === null ||
      p.last_name === ''
    ) {
      p.last_name = 'Player';
    }
    return p;
  });
  const sortedPlayers = _.orderBy(normalizedPlayers, [
    (p) => p.first_name.toLowerCase(),
  ]);

  return sortedPlayers;
}

export async function processPlayerJSON(data: any) {
  if (data && data.length) {
    let players = [];
    for await (const item of data) {
      try {
        let copyItem = Object.assign({}, item);
        copyItem.goals = null;
        copyItem.assists = null;
        copyItem.fto = null;
        copyItem.GB = null;

        let parsedArr: any = `[${copyItem.json_player_stats}]`;
        parsedArr = JSON.parse(parsedArr); // Parse stats from String to Array

        delete copyItem.json_player_stats;

        let finalPlayerData = {
          ...copyItem,
          ...getStats(parsedArr),
        };

        players.push(finalPlayerData);
      } catch (e: any) {
        // 74D4F393
        console.log(e.stack);
      }
    }

    return decideRankings(players);
  } else {
    return [];
  }
}

function isRankAvailable(sortedList: Array<any>, v: any) {
  let index = sortedList.findIndex(
    (item) => item.ppr === v.ppr && item.ppr !== '' && item.ppr !== undefined,
  );
  if (index !== -1) {
    return index + 1;
  }
  return null;
}

function decideRankings(players: Array<any>) {
  let sortedList = players.slice().sort((a, b) => {
    return b.ppr - a.ppr;
  });

  return sortedList.slice().map((v) => {
    return {
      ...v,
      rank: isRankAvailable(sortedList, v),
    };
  });
}

function getStatKeyValue(stat: any) {
  switch (stat.statistic_name) {
    case 'Ground Ball':
      return { key: 'GB', value: stat.value };
    case 'Goal':
      return { key: 'goals', value: stat.value };
    case 'Forced Turnover':
      return { key: 'fto', value: stat.value };
    case 'Goal Even Strength':
      return { key: 'ges', value: stat.value };
    case 'Goal Allowed Even Strength':
      return { key: 'goal_allowed_strength', value: stat.value };
    case 'Save Made Even Strength':
      return { key: 'save_made_strength', value: stat.value };
    case 'Miss Even Strength':
      return { key: 'miss_strength', value: stat.value };
    case 'Save Even Strength':
      return { key: 'save_strength', value: stat.value };
    case 'Player Power Ranking':
      return { key: 'ppr', value: stat.value };
    case 'Save':
      return { key: 'save', value: stat.value };
    case 'Miss':
      return { key: 'miss', value: stat.value };
    case 'Assist':
      return { key: 'assists', value: stat.value };
    case '30 Sec Releasable':
      return { key: 'sec_releasable', value: stat.value };
    case 'Faceoff Lost':
      return { key: 'faceoff_lost', value: stat.value };
    case 'Faceoff Won':
      return { key: 'faceoff_won', value: stat.value };
    case 'Save Man Up':
      return { key: 'save_man_up', value: stat.value };
  }
}

function getStats(stats: Array<any>) {
  let statsObj: any = {};
  for (let i = stats.length - 1; i >= 0; i--) {
    try {
      const stat: any = getStatKeyValue(stats[i]);
      statsObj[stat.key] = stat.value;
    } catch (e: any) {
      console.log(stats[i]);
      console.log(e.stack);
    }
  }
  return statsObj;
}

const dates = {
  today: {
    start: () => moment().format('YYYY-MM-DD 00:00:01'),
    end: () => moment().format('YYYY-MM-DD 23:59:59'),
  },
  yesterday: {
    start: () => moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:01'),
    end: () => moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59'),
  },
  currentweek: {
    start: () => moment().startOf('isoWeek').format('YYYY-MM-DD 00:00:01'),
    end: () => moment().endOf('isoWeek').format('YYYY-MM-DD 23:59:59'),
  },
  lastweek: {
    start: () =>
      moment()
        .subtract(1, 'weeks')
        .startOf('isoWeek')
        .format('YYYY-MM-DD 00:00:01'),
    end: () =>
      moment()
        .subtract(1, 'weeks')
        .endOf('isoWeek')
        .format('YYYY-MM-DD 23:59:59'),
  },
  lastyear: {
    value: () => moment().subtract(1, 'year').year(),
  },
};

export function filterPlayersByTime(time: string, players: Array<any>) {
  switch (time) {
    case 'today':
      return players.filter((player) => {
        return moment(player.createdAt).isBetween(
          dates.today.start(),
          dates.today.end(),
        );
      });
    case 'yesterday':
      return players.filter((player) => {
        return moment(player.createdAt).isBetween(
          dates.yesterday.start(),
          dates.yesterday.end(),
        );
      });
    case 'thisweek':
      return players.filter((player) => {
        return moment(player.createdAt).isBetween(
          dates.currentweek.start(),
          dates.currentweek.end(),
        );
      });
    case 'lastweek':
      return players.filter((player) => {
        return moment(player.createdAt).isBetween(
          dates.lastweek.start(),
          dates.lastweek.end(),
        );
      });
    case 'lastyear':
      return players.filter((player) => {
        const lastyear = dates.lastyear.value();
        const createdYear = moment(player.createdAt).year();
        return lastyear === createdYear;
      });
    default:
      return players;
  }
}

interface CSVData {
  team: string;
  player: string;
  firstname: string;
  lastname: string;
  jersey_number: number;
  position: string;
}

function validatePlayerObject(arg: any) {
  return (
    arg.firstname &&
    typeof arg.firstname == 'string' &&
    arg.lastname &&
    typeof arg.lastname == 'string' &&
    arg.jersey_number &&
    typeof arg.jersey_number == 'number' &&
    arg.position &&
    typeof arg.position == 'string'
  );
}
export function validateCSVData(data: CSVData[]) {
  let isValidCSV = true;
  for (const item of data) {
    isValidCSV = validatePlayerObject(item);
    if (isValidCSV === false) {
      break;
    }
  }
  return isValidCSV;
}

function makeid(length: number) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function convertToValidPlayerJSON(data: CSVData[], teamId: string) {
  let players = [];
  for (const item of data) {
    players.push({
      team_id: teamId,
      player_id: makeid(8),
      first_name: item['firstname'],
      last_name: item['lastname'],
      jersey_number: item['jersey_number'],
      position_description: item['position'],
      active_YN: 1,
      createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
  }
  return players;
}

export const getOptionsDominantHand = () => [
  {
    label: PlayerDominantHand.RIGHT,
    value: DominantHandValue[PlayerDominantHand.RIGHT],
  },
  {
    label: PlayerDominantHand.LEFT,
    value: DominantHandValue[PlayerDominantHand.LEFT],
  },
];

export const getOptionsGear = (gearList: GearOption[]) => {
  const options: { [key: string]: { label: string; value: number }[] } = {};
  gearList.forEach((item) => {
    options[item.param_name] = item.Name_exp_5.map((option) => ({
      label: option.param_value,
      value: option.param_id,
    }));
  });
  return options;
};

export const getOptionsStates = () => {
  return geographies.map((g: any) => {
    return {
      label: g.state_abbr,
      value: g.state_abbr,
    };
  });
};

export const getOptionsPlayerPositions = () => [
  {
    label: PlayerPosition.ATTACK,
    value: PositionValue[PlayerPosition.ATTACK],
  },
  {
    label: PlayerPosition.MIDDIE,
    value: PositionValue[PlayerPosition.MIDDIE],
  },
  {
    label: PlayerPosition.DEFENSE,
    value: PositionValue[PlayerPosition.DEFENSE],
  },
  {
    label: PlayerPosition.LSM,
    value: PositionValue[PlayerPosition.LSM],
  },
  {
    label: PlayerPosition.FOGO_DRAW,
    value: PositionValue[PlayerPosition.FOGO_DRAW],
  },
  {
    label: PlayerPosition.GOALIE,
    value: PositionValue[PlayerPosition.GOALIE],
  },
/*   {
    label: PlayerPosition.OTHER,
    value: PositionValue[PlayerPosition.OTHER],
  }, */
];

export const getOptionsYearGrad = () => {
  const gradYears = range(
    new Date().getFullYear(),
    new Date().getFullYear() + 9,
  );
  return gradYears.map((year) => ({
    label: String(year),
    value: year,
  }));
};

export const getInstHref = (player: IPlayerProfile | null) =>
  player && player?.player_instagram
    ? `https://www.instagram.com/${player?.player_instagram.replace('@', '')}`
    : `https://www.instagram.com`;

export const getYoutubeHref = (player: IPlayerProfile | null) =>
  player && player?.player_instagram
    ? `https://${player?.player_youtube}`
    : `https://www.youtube.com`;
