import fetchCostAppParam from '../../apis/fetchCostAppParam';
import fetchMemberTransactions from '../../apis/fetchMemberTransactions';
import fetchPromocode from '../../apis/fetchPromocodes';
import { Promocode } from '../../models/Promocode';
import { Transaction } from '../../models/Transaction';

export const FETCH_PROMOCODE_SUCCESS = 'FETCH_PROMOCODE_SUCCESS';
export const FETCH_PROMOCODE_FAILED = 'FETCH_PROMOCODE_FAILED';
export const FETCH_COST_SUCCESS = 'FETCH_COST_SUCCESS';
export const FETCH_PROMOCODE_START = 'FETCH_PROMOCODE_START';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';

export const getPromocodeSuccess = (payload: Promocode) => ({
  type: FETCH_PROMOCODE_SUCCESS,
  payload,
});

export const getPromocodeFailed = () => ({
  type: FETCH_PROMOCODE_FAILED,
});

export const getCostSuccess = (payload: number) => ({
  type: FETCH_COST_SUCCESS,
  payload,
});

export const getPromocodeStart = () => ({
  type: FETCH_PROMOCODE_START,
});

export const getTransactionsSuccess = (payload: Transaction[]) => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload,
});

export function checkPromocode(promo: string) {
  return (dispatch: any) => {
    dispatch(getPromocodeStart());
    fetchPromocode(promo)
      .then(async (res: Promocode[][]) => {
        if (!res[0][0]) {
          dispatch(getPromocodeFailed());
          return;
        }
        dispatch(getPromocodeSuccess(res[0][0]));
      })
      .catch((error: any) => {
        console.log('error in promocodes', error);
      });
  };
}

export function getCostAppParam() {
  return (dispatch: any) => {
    fetchCostAppParam()
      .then(async (res: number) => {
        dispatch(getCostSuccess(res));
      })
      .catch((error: any) => {
        console.log('error in app params', error);
      });
  };
}

export function getMemberTransactions(memberId: string) {
  return (dispatch: any) => {
    fetchMemberTransactions(memberId)
      .then(async (res: Transaction[]) => {
        dispatch(getTransactionsSuccess(res));
      })
      .catch((error: any) => {
        console.log('error in app params', error);
      });
  };
}
