import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';
import { getErrorMessage } from '../helpers';

export async function fetchTscoEvents(): Promise<any> {
  try {
    const response = await fetchAuthorization(`${API_URL}v_tmachine_distinct_events`);

    const result = await response.json();

    if (response.status !== 200) {
      const message = getErrorMessage(result);
      throw new Error(message ? message : `Status Code: ${response.status}. Something went wrong. Please try again later.`);
    }

    return result;
  } catch (e: any) {
    throw new Error(`${e.message}`);
  }
}

export default fetchTscoEvents;
