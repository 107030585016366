import React, { ReactNode } from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import './styles.css';

const useStyles = makeStyles({
  root: {
    borderRadius: '3px !important',
    fontWeight: '500 !important',
    letterSpacing: '-0.55px !important',
    padding: '0px',
    paddingTop: '9px !important',
    paddingBottom: '10px !important',
  },
  label: {
    fontFamily: 'Roboto, sans-serif !important',
    fontWeight: '500 !important',
    fontSize: '14px',
    lineHeight: '16px !important',
    letterSpacing: '-0.55px !important',
  },
  padding: {
    padding: '0px !important',
    paddingTop: '4px !important',
    paddingBottom: '4px !important',
  },
});

interface Props {
  label: string;
  width?: string;
  variant?: 'text' | 'outlined' | 'contained';
  btnStyles?: object;
  startIcon?: ReactNode;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  type?: 'button' | 'reset' | 'submit' | undefined;
  disabled?: boolean;
  sx?: object;
  onClick?: () => void;
  title?: string | object | any; 
}

const CustomButton = ({ label, width, variant, btnStyles, startIcon, color, type, disabled, onClick, sx, title }: Props) => {
  const classes = useStyles();

  return (
    <Button
      style={{ width, ...btnStyles }}
      variant={variant || 'contained'}
      startIcon={startIcon}
      color={color || 'primary'}
      classes={{
        root: classes.root,
      }}
      className={label === 'Clear' || label === 'Add' ? `${classes.label} ${classes.padding}` : classes.label}
      sx={sx}
      disabled={disabled}
      onClick={onClick}
      type={type || 'button'}
      title={title}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
