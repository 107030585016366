import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      html: {},
      '*, *::before, *::after': {
        boxSizing: 'inherit',
      },
      body: {
        fontSize: '0.875rem !important',
        lineHeight: '1.43 !important',
        letterSpacing: '0.01071em !important',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
        backgroundColor: '#fafafa !important',
        margin: '0 !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        fontWeight: '400 !important',
      },
      '#root': {},
    },
  }),
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
