export function isFollowed(associations: Array<any>, compare_fk_id: string, association_id: number) {
  const index = associations.findIndex((row: any) => {
    return row.association_fk_id === compare_fk_id && row.association_id === association_id;
  });
  if (index !== -1) {
    return associations[index];
  }
  return null;
}

export function isClaimed(associations: Array<any>, compare_fk_id: string) {
  let index;
  if (associations) {
    // console.log('associations', associations);
    index = associations.findIndex((row: any) => {
      return row.association_fk_id === compare_fk_id && row.association_id === 1;
    });
  } else {
    index = -1;
  }
  return index !== -1;
}
