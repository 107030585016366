import React, { useState, useContext, useEffect } from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, Button, Box, TextField, DialogContentText, DialogActions, FormControl, Select, MenuItem } from '@mui/material';
import { useStyles } from './styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MemberContext, MemberContexts } from '../CognitoAuth';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { postPlayerTeamMap } from '../../redux/actions/playerTeamActions';
import PlayerStat from '../../models/PlayerStat';
import { makeid } from '../../helpers';
import { postTeamPlayersRequest } from '../../redux/actions/playerTeamMapActions';
import { getPlayerSummaryStats, updatePlayerSummaryStats } from '../../redux/actions/playerActions';
// import { blue } from '@mui/material/colors';

type Props = {
  title: string;
  openModel: boolean;
  close: () => void;
  teamIds?: string | undefined;
};

const optionData: any = [
  { id: 1, label: 'Attack', value: 'Attack' },
  { id: 2, label: 'Attack/Middie', value: 'Attack/Middie' },
  { id: 6, label: 'Middie', value: 'Middie' },
  { id: 5, label: 'LSM', value: 'LSM' },
  { id: 3, label: 'Defense', value: 'Defense' },
  { id: 4, label: 'FOGO/Draw', value: 'FOGO/Draw' },

  { id: 7, label: 'Goalie', value: 'Goalie' },
];

const AddTeamPlayer: React.FC<Props> = (props) => {
  const { title, openModel, close, teamIds } = props;
  const { teamId } = useParams();
  const classes = useStyles();
  const { member } = useContext<MemberContexts>(MemberContext);
  const [jerseyNumber, setJerseyNumber] = useState('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [submit, setSubmit] = React.useState<boolean>(false);
  const [isJNumberExist, setIsJNumberExist] = React.useState<boolean | undefined>(undefined);
  const [warning, setWarning] = useState<boolean>(false);
  const teamGameStats = useSelector((state: any) => state.teamReducer.teamGameStats);
  const memberProfile = useSelector((state: any) => state.memberReducer.memberProfile);
  const players = useSelector((state: any) => state.playerReducer.playerSummaryStatsResult);
  const currentPosition = optionData.filter((item: any) => item.value === memberProfile?.player_position);
  const [selectedPosition, setSelectedPosition] = useState(currentPosition[0]?.id!);
  const dispatch = useDispatch();
  let isSubmitJNumber = false;

  useEffect(() => {
    setSubmit(false);
    if (jerseyNumber === '') {
      setIsJNumberExist(false);
    }
  }, [jerseyNumber]);

  const onClose = () => {
    close();
    setJerseyNumber('');
    setSubmit(false);
    setStartDate('');
    setEndDate('');
  };
  const OnkeyDown = (e: any) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  const isSubmitDisabled = () => {
    if (startDate === '' || jerseyNumber === '' || selectedPosition === '') {
      return true;
    }
    return false;
  };

  const onSave = () => {
    setSubmit(true);
    const data = players.find((stat: PlayerStat) => stat.jersey_number === jerseyNumber);

    if (isSubmitDisabled()) {
      setWarning(true);
    }
    if (data) {
      setIsJNumberExist(true);
    }

    if (!data) {
      setIsJNumberExist(false);
    }

    if (!data && !isSubmitDisabled()) {
      const assets = {
        jersey_number: jerseyNumber,
        team_id: teamIds || players[0]?.team_id || teamId,
        player_position: memberProfile.player_position,
        is_active_YN: memberProfile.is_active_YN === 'null' ? 1 : memberProfile.is_active_YN,
        member_id: memberProfile.member_id,
        created_datetime: memberProfile.created_datetime,
        created_by: memberProfile.created_by,
        start_date: startDate,
        end_date: endDate,
      };

      dispatch(postPlayerTeamMap(assets, players[0]?.team_name));

      const playerId = makeid(8);
      const data = {
        player_id: playerId,
        team_id: teamIds || players[0].team_id || teamId,
        event_id: teamGameStats.length > 0 && teamGameStats[0]?.event_id ? teamGameStats[0].event_id : null,
        member_id: memberProfile.member_id,
        jersey_number: jerseyNumber,
        first_name: memberProfile.first_name,
        last_name: memberProfile.last_name,
        position_description: memberProfile.player_position,
        is_active_YN: memberProfile.is_active_YN === 'null' ? 1 : memberProfile.is_active_YN,
      };
      dispatch(postTeamPlayersRequest(data));

      const updatedData =  {
        created_by: memberProfile.created_by,
        created_datetime: memberProfile.created_datetime,
        current_school:memberProfile.current_school,
        player_city: memberProfile.player_city,
        player_state:memberProfile.player_state,
        full_name: memberProfile.first_name + memberProfile.last_name,
        sport_id: teamGameStats[0]?.sport_id ? teamGameStats[0].sport_id : null,
        team_name: teamGameStats[0]?.team_name ? teamGameStats[0].team_name : null,
        team_year: teamGameStats[0]?.team_year ? teamGameStats[0].team_year : null,
        jersey_number: jerseyNumber,
        first_name: memberProfile.first_name,
        last_name: memberProfile.last_name,
      }
      isSubmitJNumber = true;
      setJerseyNumber('');
      onClose();

      let UpdatePlayer = players
      UpdatePlayer.push(updatedData)
      dispatch(updatePlayerSummaryStats(UpdatePlayer))
      const clubTeam = teamIds ? teamIds : players[0]?.team_id
      setTimeout(()=>{
        dispatch(getPlayerSummaryStats({team_id: clubTeam || teamId}))
      },3000)

    }
  };

  const onChangeJerseyNumber = (event: any) => {
    setJerseyNumber(event?.target.value);
  };

  return (
    <>
      <Dialog className={classes.add_to_team_modal} open={openModel} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" onKeyDown={OnkeyDown}>
        {member && member.member_type === 'Player' ? (
          <>
            <DialogTitle id="alert-dialog-title" style={{ textAlign: 'left' }}>
              {title}
            </DialogTitle>
            <DialogContent>
              <div style={{ justifyContent: 'center', alignItems: 'end' }} className={classes.addMeToThisTeamTitle}>
                <label htmlFor="">Please confirm your:</label>
              </div>
              <div style={{ justifyContent: 'center', alignItems: 'end' }} className={classes.addMeToThisTeam}>
                <div>
                  <label htmlFor="">Division *</label>
                  <FormControl className={classes.formFilter} variant="outlined">
                    <Select
                      value={selectedPosition}
                      onChange={(event: any) => {
                        setSelectedPosition(event.target.value);
                      }}
                      className={`${classes.select} ${classes.MuiSelectOutlined}`}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                    >
                      {optionData.map((item: any) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className={classes.addMeToThisTeamSelect}>
                  <label htmlFor="">Team *</label>
                  <FormControl className={classes.formFilter} variant="outlined">
                    <Select
                      value={selectedPosition}
                      onChange={(event: any) => {
                        setSelectedPosition(event.target.value);
                      }}
                      className={`${classes.select} ${classes.MuiSelectOutlined}`}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                    >
                      {optionData.map((item: any) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div style={{ justifyContent: 'center', alignItems: 'end' }} className={classes.addMeToThisTeam}>
                <div>
                  <label htmlFor="">Jersey Number *</label>
                  <TextField className={classes.addJerseyNumber} placeholder="Enter Jersey Number" type="number" onChange={onChangeJerseyNumber} value={jerseyNumber} error={isJNumberExist && submit} helperText={isJNumberExist && submit ? `There is already a number ${jerseyNumber} on this team. Please confirm that you are that number.` : ''} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className={classes.addMeToThisTeamSelect}>
                  <label htmlFor="">Position *</label>
                  <FormControl className={classes.formFilter} variant="outlined">
                    <Select
                      value={selectedPosition}
                      onChange={(event: any) => {
                        setSelectedPosition(event.target.value);
                      }}
                      className={`${classes.select} ${classes.MuiSelectOutlined}`}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                    >
                      {optionData.map((item: any) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {isJNumberExist === false && submit && isSubmitJNumber && <Typography>You are now on this team.</Typography>}
              <div className={`${classes.eventpopupFormSearchInputWrapper} ${classes.addMeToThisTeam}`}>
                <div>
                  <label>Start Date *</label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker value={startDate} onChange={(date) => (date ? setStartDate(date) : setStartDate(''))} className={classes.scoreFiled} renderInput={(params) => <TextField {...params} />} inputFormat="MM/dd/yyyy" label="" disablePast={false} />
                  </LocalizationProvider>
                </div>
                <div>
                  <label>End Date</label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker value={endDate} onChange={(date) => (date ? setEndDate(date) : setEndDate(''))} className={classes.scoreFiled} renderInput={(params) => <TextField {...params} />} inputFormat="MM/dd/yyyy" label="" disablePast={false} />
                  </LocalizationProvider>
                </div>
              </div>
              <div>
                <label htmlFor="">Sometimes players switch teams, so the start date tells us when you joined.</label>
              </div>
              <div>
                <label style={{ fontWeight: 600 }} htmlFor="">
                  Leave the End Date empty if you currently play for this team.
                </label>
              </div>
              <div>
                <label style={{ fontWeight: 600 }} htmlFor="">
                  If you need to change this later, please go to "My Teams" under your profile.
                </label>
              </div>
            </DialogContent>
            <Box display="flex" justifyContent="center" mt={1} mb={3} gap={3}>
              <Button onClick={onClose} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button onClick={onSave} color="primary" variant="contained">
                Confirm
              </Button>
            </Box>
            <div>
              <label htmlFor="">* Required</label>
            </div>
          </>
        ) : (
          <>
            <DialogTitle align="center">Hold on... Only players can add themselves to a team!</DialogTitle>
            <DialogTitle align="center">If you are a player, make this change under the "Account/Subscription Details"</DialogTitle>
            <Box display="flex" justifyContent="center" mt={1} mb={3}>
              <Button onClick={onClose} color="primary" variant="contained">
                Close
              </Button>
            </Box>
          </>
        )}
      </Dialog>
      <Dialog open={warning} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">All fields need are required to submit this request.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setWarning(false)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddTeamPlayer;
