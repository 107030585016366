import { API_URL } from '../../constants';
import fetchAuthorization from '../fetchAuthorization';
import { getErrorMessage } from '../../helpers';
import Accolade from '../../models/Accolade';

export async function editAccolade(editedAccolade: Accolade, memberId: string): Promise<Accolade> {
  try {
    const response = await fetchAuthorization(`${API_URL}player_accolades?member_id=${memberId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(editedAccolade),
    });

    const result = await response.json();

    if (response.status !== 200) {
      const message = getErrorMessage(result);
      throw new Error(message ? message : `Status Code: ${response.status}. Sorry, something went wrong. Please try again later.`);
    }

    return result;
  } catch (e: any) {
    throw new Error(`Unable to edit accolade. ${e.message}`);
  }
}

export default editAccolade;
