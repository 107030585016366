import { fetchClubsv2, fetchClubTeamSummary } from '../../apis';
import * as commonActions from './commonActions';
import { processTeamSummaryStats } from '../../helpers';
import _fetchRequest from '../../apis/_fetchRequest';
import fetchClubsStatistics from '../../apis/fetchClubsStatistics';
import { ClubStatistics } from '../../models/ClubStatistics';

export const GET_CLUBS_REQ_START = 'GET_CLUBS_REQ_START';
export const GET_CLUBS_REQ_SUCCESS = 'GET_CLUBS_REQ_SUCCESS';
export const GET_CLUBS_REQ_FAILED = 'GET_CLUBS_REQ_FAILED';

export const GET_CLUB_DETAIL_REQ_START = 'GET_CLUB_DETAIL_REQ_START';
export const GET_CLUB_DETAIL_REQ_SUCCESS = 'GET_CLUB_DETAIL_REQ_SUCCESS';
export const GET_CLUB_DETAIL_REQ_FAILED = 'GET_CLUB_DETAIL_REQ_FAILED';

export const GET_CLUB_TEAM_SUMMARY_REQ_START = 'GET_CLUB_TEAM_SUMMARY_REQ_START';
export const GET_CLUB_TEAM_SUMMARY_REQ_SUCCESS = 'GET_CLUB_TEAM_SUMMARY_REQ_SUCCESS';
export const GET_CLUB_TEAM_SUMMARY_REQ_FAILED = 'GET_CLUB_TEAM_SUMMARY_REQ_FAILED';

export const ADD_CLUB_REQ_START = 'ADD_CLUB_REQ_START';
export const ADD_CLUB_REQ_SUCCESS = 'ADD_CLUB_REQ_SUCCESS';
export const ADD_CLUB_REQ_FAILED = 'ADD_CLUB_REQ_FAILED';

export const GET_CLUB_LOGO_REQ_START = 'GET_CLUB_LOGO_REQ_START';
export const GET_CLUB_LOGO_REQ_SUCCESS = 'GET_CLUB_LOGO_REQ_SUCCESS';
export const GET_CLUB_LOGO_REQ_FAILED = 'GET_CLUB_LOGO_REQ_FAILED';

export const GET_CLUBS_STATISTICS_REQ_SUCCESS = 'GET_CLUBS_STATISTICS_REQ_SUCCESS';
export const UPDATE_CLUB_FOLLEWED = 'UPDATE_CLUB_FOLLEWED';

export const getClubsStart = () => ({
  type: GET_CLUBS_REQ_START,
});

export const getClubsSuccess = (data: Array<any>) => ({
  type: GET_CLUBS_REQ_SUCCESS,
  data,
});

export const getClubsFailed = (error: string) => ({
  type: GET_CLUBS_REQ_FAILED,
  error,
});

export const getClubDetailStart = () => ({
  type: GET_CLUB_DETAIL_REQ_START,
});

export const getClubDetailSuccess = (data: Array<any>) => ({
  type: GET_CLUB_DETAIL_REQ_SUCCESS,
  data,
});

export const getClubDetailFailed = (error: string) => ({
  type: GET_CLUB_DETAIL_REQ_FAILED,
  error,
});

export const getClubTeamSummaryStart = () => ({
  type: GET_CLUB_TEAM_SUMMARY_REQ_START,
});

export const getClubTeamSummarySuccess = (data: Array<any>) => ({
  type: GET_CLUB_TEAM_SUMMARY_REQ_SUCCESS,
  data,
});

export const getClubTeamSummaryFailed = (error: string) => ({
  type: GET_CLUB_TEAM_SUMMARY_REQ_FAILED,
  error,
});

export const addClubStart = () => ({
  type: ADD_CLUB_REQ_START,
});

export const addClubSuccess = () => ({
  type: ADD_CLUB_REQ_SUCCESS,
});

export const addClubFailed = (error: string) => ({
  type: ADD_CLUB_REQ_FAILED,
  error,
});

export const getClubsStatisticsSuccess = (data: Array<ClubStatistics>) => ({
  type: GET_CLUBS_STATISTICS_REQ_SUCCESS,
  data,
});

export const updateClubFollowed = (data: any) => ({
  type: UPDATE_CLUB_FOLLEWED,
 data
});

export function getClubs(data: any, callback?: any) {
  return (dispatch: any) => {
    dispatch(getClubsStart());
    fetchClubsv2(data)
      .then((res: any) => {
        dispatch(getClubsSuccess(res));
        if (callback) callback(res);
      })
      .catch((error: any) => {
        console.log('error in set ', error);
        dispatch(getClubsFailed('Sorry, something went wrong, Please try later!'));
      });
  };
}

export function getClubDetail(data: any, callback?: any) {
  return (dispatch: any) => {
    dispatch(getClubDetailStart());
    fetchClubsv2(data)
      .then((res: any) => {
        dispatch(getClubDetailSuccess(res));
        if (callback) callback(res);
      })
      .catch((error: any) => {
        console.log('error in set ', error);
        dispatch(getClubDetailFailed('Sorry, something went wrong, Please try later!'));
      });
  };
}

export function getClubStats(data: any, callback?: any) {
  return (dispatch: any) => {
    dispatch(getClubTeamSummaryStart());

    // console.log('getClubStats - data passed in', data);

    fetchClubTeamSummary(data)
      .then(async (res: any) => {
        // console.log('fetchClubTeamSummary - res', res);
        const processedTeamStats = await processTeamSummaryStats(res);
        // console.log('getClubStats: processedTeams', processedTeamStats);

        dispatch(getClubTeamSummarySuccess(processedTeamStats));
        if (callback) callback(processedTeamStats);
      })
      .catch((error: any) => {
        console.log('error in set ', error);
        dispatch(getClubTeamSummaryFailed('Sorry, something went wrong, Please try later!'));
      });
  };
}

export const getClubLogoStart = () => ({
  type: GET_CLUB_LOGO_REQ_START,
});

export const getClubLogoSuccess = (data: Array<any>) => ({
  type: GET_CLUB_LOGO_REQ_SUCCESS,
  data,
});

export const getClubLogoFailed = (error: string) => ({
  type: GET_CLUB_LOGO_REQ_FAILED,
  error,
});

export function getClubLogo(data: any) {
  return (dispatch: any) => {
    dispatch(getClubsStart());
    fetchClubsv2(data)
      .then((res: any) => {
        dispatch(getClubLogoSuccess(res));
      })
      .catch((error: any) => {
        console.log('error in set ', error);
        dispatch(getClubLogoFailed('Sorry, something went wrong, Please try later!'));
      });
  };
}

export function addClub(data: any, callback: any) {
  return (dispatch: any) => {
    dispatch(addClubStart());
    _fetchRequest('POST', 'public_requests', undefined, data)
      .then((res: any) => {
        dispatch(addClubSuccess());
        callback(true);
      })
      .catch((error: any) => {
        console.log('error in set ', error);
        dispatch(addClubFailed(error));
        dispatch(commonActions.handleError(error));
        callback(true);
      });
  };
}

export function resetClubs(callback?: any) {
  return (dispatch: any) => {
    dispatch(getClubsSuccess([]));
    if (callback) callback();
  };
}

export function getClubsStatistics(data: any) {
  return (dispatch: any) => {
    fetchClubsStatistics(data)
      .then((res: any) => {
        dispatch(getClubsStatisticsSuccess(res));
      })
      .catch((error: any) => {
        console.log('error in set ', error);
        dispatch(getClubsFailed('Sorry, something went wrong, Please try later!'));
      });
  };
}