import React from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SchoolIcon from '@mui/icons-material/School';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StarIcon from '@mui/icons-material/Star';
import EventIcon from '@mui/icons-material/Event';
import EditIcon from '@mui/icons-material/Edit';
import DateRangeIcon from '@mui/icons-material/DateRange';
import HeightIcon from '@mui/icons-material/Height';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import PanToolIcon from '@mui/icons-material/PanTool';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import instagram from './../images/instagram.png';
import youtube from './../images/youtube.svg';
import ShareIcon from '@mui/icons-material/Share';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import RemoveIcon from '@mui/icons-material/Remove';
import Search from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SportsIcon from '@mui/icons-material/Sports';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import { CalendarMonthRounded } from '@mui/icons-material';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

export enum Icons {
  ADD = 'AddCircle',
  EDIT = 'Edit',
  NOTE = 'Note',
  SAVE = 'Save',
  STAR = 'Star',
  PLUS = 'Plus',
  CLUB = 'Club',
  FLAG = 'Flag',
  CLEAR = 'CLEAR',
  SHARE = 'Share',
  EVENT = 'Event',
  STATS = 'Stats',
  SPORT = 'Sport',
  REMOVE = 'Remove',
  SEARCH = 'Search',
  HEIGHT = 'Height',
  WEIGHT = 'Weight',
  DELETE = 'Delete',
  FOLLOW = 'Follow',
  YOUTUBE = 'Youtube',
  PLAYERS = 'Players',
  PROFILE = 'Profile',
  DONE_ALL = 'Done All',
  QUESTION = 'Question',
  POSITION = 'Position',
  ACADEMIC = 'Academic',
  DASHBOARD = 'Dashboard',
  INSTAGRAM = 'Instagram',
  VISIBILITY = 'Visibility',
  DATE_RANGE = 'Date Range',
  DOMINANT_HAND = 'Dominant Hand',
  TOURNAMENT_CUP = 'Tournament Cup',
  CHECK_CIRCLE = 'Check Circle',
  UP = 'up',
  DOWN = 'down',
  VIDEO = 'video',
  CALENDAR = 'calendar',
  LEADERDASHBOARD = 'Leaderboard',
}

export const getIcon = (icon: Icons, styles?: object): JSX.Element => {
  switch (icon) {
    case Icons.ADD:
      return <AddCircleOutlineIcon style={styles} />;
    case Icons.PROFILE:
      return <AccountCircleIcon />;
    case Icons.STATS:
      return <EqualizerIcon />;
    case Icons.ACADEMIC:
      return <SchoolIcon />;
    case Icons.VISIBILITY:
      return <VisibilityIcon />;
    case Icons.EVENT:
      return <EventIcon />;
    case Icons.STAR:
      return <StarIcon />;
    case Icons.NOTE:
      return <EmojiObjectsIcon />;
    case Icons.EDIT:
      return <EditIcon />;
    case Icons.DATE_RANGE:
      return <DateRangeIcon />;
    case Icons.HEIGHT:
      return <HeightIcon />;
    case Icons.WEIGHT:
      return <FitnessCenterIcon />;
    case Icons.DOMINANT_HAND:
      return <PanToolIcon />;
    case Icons.POSITION:
      return <AccessibilityIcon />;
    case Icons.CLUB:
      return <PeopleAltIcon />;
    case Icons.INSTAGRAM:
      return <img src={instagram} alt="Inst" />;
    case Icons.YOUTUBE:
      return <img src={youtube} alt="Inst" />;
    case Icons.SHARE:
      return <ShareIcon />;
    case Icons.PLUS:
      return <AddIcon style={styles} />;
    case Icons.CLEAR:
      return <ClearIcon />;
    case Icons.SAVE:
      return <SaveIcon />;
    case Icons.REMOVE:
      return <RemoveIcon />;
    case Icons.SEARCH:
      return <Search />;
    case Icons.QUESTION:
      return <HelpOutlineOutlinedIcon style={styles} />;
    case Icons.DELETE:
      return <DeleteIcon />;
    case Icons.FOLLOW:
      return <GroupAddIcon />;
    case Icons.SPORT:
      return <SportsIcon />;
    case Icons.DONE_ALL:
      return <DoneAllIcon />;
    case Icons.DASHBOARD:
      return <DashboardOutlinedIcon />;
    case Icons.CHECK_CIRCLE:
      return <CheckCircleIcon />;
    case Icons.FLAG:
      return <FlagOutlinedIcon />;
    case Icons.TOURNAMENT_CUP:
      return <EmojiEventsOutlinedIcon />;
    case Icons.PLAYERS:
      return <SupervisedUserCircleOutlinedIcon />;
    case Icons.UP:
      return <KeyboardArrowUpIcon />;
    case Icons.DOWN:
      return <KeyboardArrowDownIcon />;
    case Icons.VIDEO:
      return <VideoCameraFrontIcon />;
    case Icons.CALENDAR:
      return <CalendarMonthRounded />;
    case Icons.LEADERDASHBOARD:
      return <LeaderboardIcon />;
    default: {
      return <StarIcon />;
    }
  }
};
