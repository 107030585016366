import { FETCH_UPCOMING_EVENTS_REQ_START, FETCH_UPCOMING_EVENTS_REQ_SUCCESS, FETCH_UPCOMING_EVENTS_REQ_FAILED, FETCH_PAST_EVENTS_REQ_START, FETCH_PAST_EVENTS_REQ_SUCCESS, FETCH_PAST_EVENTS_REQ_FAILED, FETCH_VEVENTS_REQ_START, FETCH_VEVENTS_REQ_SUCCESS, FETCH_VEVENTS_REQ_FAILED, FETCH_EVENT_GAMES_REQ_START, FETCH_EVENT_GAMES_REQ_SUCCESS, FETCH_EVENT_GAMES_REQ_FAILED, FETCH_EVENT_DIVISIONS_REQ_SUCCESS, FETCH_EVENT_DIVISIONS_REQ_FAILED, FETCH_ALL_EVENT_GAMES_REQ_SUCCESS, FETCH_ALL_EVENT_GAMES_REQ_FAILED, FETCH_GET_FACILITIES_SUCCESS, FETCH_GET_FACILITIES_STARTED, FETCH_GET_FACILITIES_FAILED, FETCH_POST_EVENT_STARTED, FETCH_POST_EVENT_FAILED, FETCH_POST_EVENT_SUCCESS, FETCH_REQUEST_EVENTS_REQ_FAILED, FETCH_REQUEST_EVENTS_REQ_SUCCESS, FETCH_REQUEST_EVENTS_REQ_START, FETCH_REQUEST_MEMBERS_REQ_SUCCESS, FETCH_POST_REQUEST_MEMBERS_REQ_SUCCESS, ADD_EVENT_HIGHLIGHTS, FETCH_EVENT_HIGHLIGHTS_RATING, FETCH_ALL_EVENT_HIGHLIGHTS_RATING, FETCH_PROSPECT_DAYS_REQ_START, FETCH_PROSPECT_DAYS_REQ_SUCCESS, FETCH_PROSPECT_DAYS_REQ_FAILED, FETCH_EVENT_HIGHLIGHTS_SUCCESS, FETCH_EVENT_HIGHLIGHTS_START, FETCH_CLUB_TRYOUT_REQ_START, FETCH_CLUB_TRYOUT_REQ_SUCCESS, FETCH_CLUB_TRYOUT_REQ_FAILED, ADD_CLUB_TRYOUT_REQ_START, ADD_CLUB_TRYOUT_REQ_FAILED, ADD_CLUB_TRYOUT_REQ_SUCCESS, FETCH_POWER_RANKING_START, FETCH_POWER_RANKING_FAILED, FETCH_POWER_RANKING_SUCCESS, FETCH_ALL_EVENT_GAMES_REQ_START } from '../actions/eventsActions';
import { FETCH_ALL_UNIVERSITY } from '../actions/playerActions';

let initialState = {
  teams: [],
  pool: [],
  allGames: [],
  divisions: null,
  eventInfo: null,
  pastEvents: [],
  matchupGames: [],
  upcomingEvents: [],
  allUniversities: [],
  isAllGamesLoading: false,
  allGamesError: null,
  eventInfoError: null,
  divisionsError: null,
  pastEventsError: null,
  requestEventsErrors: null,
  eventGamesError: null,
  upcomingEventsError: null,
  isLoadingGames: false,
  isLoadingEvent: false,
  isLoadingPastEvents: false,
  isLoadingUpcomingEvents: false,
  facilities: [],
  isLoadingFacilities: false,
  isLoadingAddEvent: false,
  isAddEventError: false,
  requestedEvents: [],
  isLoadingRequestEvents: false,
  requestMembers: [],
  eventHighlights: [],
  isLoadingHighlight: false,
  eventHighlightsRating: [],
  eventAllHighlightsRating: [],
  prospectDays: [],
  prospectDaysError: null,
  isLoadingProspectDays: false,
  clubTryouts: [],
  clubTryoutsError: null,
  isLoadingClubTryouts: false,
  isLoadingAddClubTryout: false,
  isAddClubTryoutError: false,
  powerRanking: [],
  isLoadingPowerRanking: false,
  powerRankingError: null,
};

const eventsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_UPCOMING_EVENTS_REQ_START:
      return { ...state, isLoadingUpcomingEvents: true };
    case FETCH_UPCOMING_EVENTS_REQ_SUCCESS:
      return {
        ...state,
        isLoadingUpcomingEvents: false,
        upcomingEvents: action.data,
      };
    case FETCH_UPCOMING_EVENTS_REQ_FAILED:
      return {
        ...state,
        isLoadingUpcomingEvents: false,
        upcomingEventsError: action.error,
        upcomingEvents: [],
      };
    case FETCH_PAST_EVENTS_REQ_START:
      return { ...state, isLoadingPastEvents: true };
    case FETCH_PAST_EVENTS_REQ_SUCCESS:
      return { ...state, isLoadingPastEvents: false, pastEvents: action.data };
    case FETCH_PAST_EVENTS_REQ_FAILED:
      return {
        ...state,
        isLoadingPastEvents: false,
        pastEventsError: action.error,
        pastEvents: [],
      };
    case FETCH_CLUB_TRYOUT_REQ_START:
      return { ...state, isLoadingClubTryouts: true };
    case FETCH_CLUB_TRYOUT_REQ_SUCCESS:
      return { ...state, isLoadingClubTryouts: false, clubTryouts: action.data };
    case FETCH_CLUB_TRYOUT_REQ_FAILED:
      return {
        ...state,
        isLoadingClubTryouts: false,
        clubTryOutsError: action.error,
        clubTryouts: [],
      };
      case ADD_CLUB_TRYOUT_REQ_SUCCESS:
        return { ...state, isLoadingAddClubTryout: false };
      case ADD_CLUB_TRYOUT_REQ_START:
        return { ...state, isLoadingAddClubTryout: true };
      case ADD_CLUB_TRYOUT_REQ_FAILED:
        return { ...state, isAddClubTryoutError: true, isLoadingAddClubTryout: false };
      case FETCH_PROSPECT_DAYS_REQ_START:
        return { ...state, isLoadingProspectDays: true };
      case FETCH_PROSPECT_DAYS_REQ_SUCCESS:
        return { ...state, isLoadingProspectDays: false, prospectDays: action.data };
      case FETCH_PROSPECT_DAYS_REQ_FAILED:
        return {
          ...state,
          isLoadingProspectDays: false,
          prospectDaysError: action.error,
          prospectDays: [],
        };
    case FETCH_VEVENTS_REQ_START:
      return {
        ...state,
        isLoadingEvent: true,
        eventInfoError: null,
        eventInfo: null,
      };
    case FETCH_VEVENTS_REQ_SUCCESS:
      return { ...state, isLoadingEvent: false, eventInfo: action.data };
    case FETCH_VEVENTS_REQ_FAILED:
      return { ...state, isLoadingEvent: false, eventInfoError: action.error };
    case FETCH_EVENT_GAMES_REQ_START:
      return { ...state, isLoadingGames: true, eventGamesError: null };
    case FETCH_EVENT_GAMES_REQ_SUCCESS:
      return { ...state, isLoadingGames: false, matchupGames: action.data };
    case FETCH_EVENT_GAMES_REQ_FAILED:
      return { ...state, isLoadingGames: false, eventGamesError: action.error };
      case FETCH_ALL_EVENT_GAMES_REQ_START:
      return { ...state, isAllGamesLoading: true, allGamesError: null };
    case FETCH_ALL_EVENT_GAMES_REQ_SUCCESS:
      const { allGames, teams, pools } = action.payload;
      return { ...state, allGames,isAllGamesLoading: false, teams, pools };
    case FETCH_ALL_EVENT_GAMES_REQ_FAILED:
      return { ...state.allGames, isAllGamesLoading: false, allGamesError: action.error };
    case FETCH_EVENT_DIVISIONS_REQ_SUCCESS:
      return { ...state, divisions: action.payload };
    case FETCH_EVENT_DIVISIONS_REQ_FAILED:
      return { ...state, divisionsError: action.error };

    case FETCH_ALL_UNIVERSITY:
      return { ...state, allUniversities: action.payload };
    case FETCH_GET_FACILITIES_SUCCESS:
      return {
        ...state,
        facilities: action.payload,
        isLoadingFacilities: false,
      };
    case FETCH_GET_FACILITIES_STARTED:
      return { ...state, isLoadingFacilities: true };
    case FETCH_GET_FACILITIES_FAILED:
      return { ...state, isLoadingFacilities: false };
    case FETCH_POST_EVENT_SUCCESS:
      return { ...state, isLoadingAddEvent: false };
    case FETCH_POST_EVENT_STARTED:
      return { ...state, isLoadingAddEvent: true };
    case FETCH_POST_EVENT_FAILED:
      return { ...state, isAddEventError: true, isLoadingAddEvent: false };
    case FETCH_REQUEST_EVENTS_REQ_START:
      return { ...state, isLoadingRequestEvents: true };
    case FETCH_REQUEST_EVENTS_REQ_SUCCESS:
      return {
        ...state,
        isLoadingRequestEvents: false,
        requestEvents: action.payload,
      };
    case FETCH_REQUEST_EVENTS_REQ_FAILED:
      return {
        ...state,
        isLoadingRequestEvents: false,
        requestEventsErrors: action.payload,
      };
    case FETCH_REQUEST_MEMBERS_REQ_SUCCESS:
      return {
        ...state,
        requestMembers: action.payload,
      };
    case FETCH_POST_REQUEST_MEMBERS_REQ_SUCCESS:
      return {
        ...state,
        requestMembers: [...state.requestMembers, action.payload],
      };
    case FETCH_EVENT_HIGHLIGHTS_START:
      return {
        ...state,
        isLoadingHighlight: true,
        eventHighlights: null,
      }
    case FETCH_EVENT_HIGHLIGHTS_SUCCESS:
      return { ...state, isLoadingHighlight: false, eventHighlights: action.payload };
    case FETCH_EVENT_HIGHLIGHTS_RATING:
      return { ...state, eventHighlightsRating: action.payload };
    case FETCH_ALL_EVENT_HIGHLIGHTS_RATING: {
      return { ...state, eventAllHighlightsRating: action.payload };
    }
    case ADD_EVENT_HIGHLIGHTS:
      return {
        ...state,
        eventHighlights: [...state.eventHighlights, action.payload],
      };
    case FETCH_POWER_RANKING_START:
      return {
        ...state,
        isLoadingPowerRanking: true,
        powerRanking: [],
      };
    case FETCH_POWER_RANKING_SUCCESS:
      return { ...state, isLoadingPowerRanking: false, powerRanking: action.payload };
    case FETCH_POWER_RANKING_FAILED:
      return {
        ...state,
        isLoadingPowerRanking: false,
        powerRanking: [],
        powerRankingError: action.error,
      };

    default:
      return state;
  }
};

export default eventsReducer;
