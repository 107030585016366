import { MemberTeamAssociation } from '../../models/MemberTeamAssociation';
import { FETCH_TEAM_RANKINGS_REQ_START, FETCH_TEAM_RANKINGS_REQ_SUCCESS, FETCH_TEAM_RANKINGS_REQ_FAILED, FETCH_FOLLOWED_TEAMS_REQ_START, FETCH_FOLLOWED_TEAMS_REQ_SUCCESS, FETCH_FOLLOWED_TEAMS_REQ_FAILED, FETCH_TEAM_STATS_REQ_START, FETCH_TEAM_STATS_REQ_SUCCESS, FETCH_TEAM_STATS_REQ_FAILED, FETCH_TEAM_GAMES_REQ_START, FETCH_TEAM_GAMES_REQ_SUCCESS, FETCH_TEAM_GAMES_REQ_FAILED, FETCH_TEAMS_REQ_START, FETCH_TEAMS_REQ_SUCCESS, FETCH_TEAMS_REQ_FAILED, ADD_TEAM_REQ_START, ADD_TEAM_REQ_SUCCESS, ADD_TEAM_REQ_FAILED, FETCH_TEAM_SUMMARY_STATS_REQ_START, FETCH_TEAM_SUMMARY_STATS_REQ_SUCCESS, FETCH_TEAM_SUMMARY_STATS_REQ_FAILED, FETCH_HISTORICAL_GAMES_FAILED, FETCH_HISTORICAL_GAMES_STARTED, FETCH_HISTORICAL_GAMES_SUCCESS, FETCH_MEMBER_TEAM_ASSOCIATIONS_FAILED, FETCH_MEMBER_TEAM_ASSOCIATIONS_SUCCESS, FETCH_MEMBER_TEAM_ASSOCIATIONS_STARTED, FETCH_DELETE_MEMBER_TEAM_ASSOCIATIONS_SUCCESS, FETCH_PUT_MEMBER_TEAM_ASSOCIATIONS_SUCCESS, FETCH_GET_ORGS_STARTED, FETCH_GET_ORGS_FAILED, FETCH_GET_ORGS_SUCCESS, FETCH_POST_MEMBER_TEAM_ASSOCIATIONS_SUCCESS, FETCH_PUT_TEAM_SUCCESS, FETCH_GET_ORG_SUCCESS, FETCH_TEAMS_PLAYERS_STATS_REQ_SUCCESS, FETCH_TEAMS_PLAYERS_STATS_REQ_START, FETCH_TEAMS_PLAYERS_STATS_REQ_FAILED } from '../action-types/teamActionTypes';

let initialState = {
  isLoadingRankings: false,
  teamRankings: [],
  error: null,
  isLoadingFollowedTeams: false,
  teamsFollowed: [],
  teamsFollowedError: null,
  isLoadingTeamStats: false,
  teamStats: [],
  teamStatsError: null,
  isLoadingTeamGameStats: false,
  teamGameStats: [],
  teamGameStatsError: null,
  isLoadingTeams: false,
  teams: [],
  teamsError: null,
  isLoadingAddTeam: false,
  addTeamError: false,
  isLoadingTeamSumaryStats: false,
  teamSummaryStatsResult: [],
  teamSummaryStatsError: null,
  historicalGames: [],
  isLoadingTeamTab: false,
  teamAssociations: [],
  isLoadingTeamAssoc: false,
  orgs: [],
  isLoadingOrgs: false,
  selectedTeam: null,
  teamsPlayersStats: [],
  isLoadingTeamsPlayerStats: false,
  teamsPlyaerStatsError: null,
};

const teamReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_TEAM_RANKINGS_REQ_START:
      return { ...state, isLoadingRankings: true };
    case FETCH_TEAM_RANKINGS_REQ_SUCCESS:
      return { ...state, isLoadingRankings: false, teamRankings: action.data };
    case FETCH_TEAM_RANKINGS_REQ_FAILED:
      return {
        ...state,
        isLoadingRankings: false,
        error: action.error,
        teamRankings: [],
      };
    case FETCH_TEAMS_REQ_START:
      return { ...state, isLoadingTeams: true, teams: [], teamsError: null };
    case FETCH_TEAMS_REQ_SUCCESS:
      return { ...state, isLoadingTeams: false, teams: action.data };
    case FETCH_PUT_TEAM_SUCCESS:
      const updatedTeams = state.teams.map((team: any) => {
        if (team.team_id === action.payload.team_id) {
          return {
            ...team,
            ...action.payload,
          };
        }
        return team;
      });
      return { ...state, teams: updatedTeams };
    case FETCH_TEAMS_REQ_FAILED:
      return {
        ...state,
        isLoadingTeams: false,
        teams: [],
        teamsError: action.error,
      };

    case FETCH_TEAM_STATS_REQ_START:
      return { ...state, isLoadingTeamStats: true, teamStatsError: null };
    case FETCH_TEAM_STATS_REQ_SUCCESS:
      return {
        ...state,
        isLoadingTeamStats: false,
        teamStats: action.data,
        teamStatsError: null,
      };
    case FETCH_TEAM_STATS_REQ_FAILED:
      return {
        ...state,
        isLoadingTeamStats: false,
        teamStats: [],
        teamStatsError: action.error,
      };
    case FETCH_TEAM_GAMES_REQ_START:
      return {
        ...state,
        isLoadingTeamGameStats: true,
        teamGameStatsError: null,
      };
    case FETCH_TEAM_GAMES_REQ_SUCCESS:
      return {
        ...state,
        isLoadingTeamGameStats: false,
        teamGameStats: action.data,
        teamGameStatsError: null,
      };
    case FETCH_TEAM_GAMES_REQ_FAILED:
      return {
        ...state,
        isLoadingTeamGameStats: false,
        teamGameStats: [],
        teamGameStatsError: action.error,
      };
    case FETCH_FOLLOWED_TEAMS_REQ_START:
      return {
        ...state,
        isLoadingFollowedTeams: true,
        teamsFollowedError: null,
      };
    case FETCH_FOLLOWED_TEAMS_REQ_SUCCESS:
      return {
        ...state,
        isLoadingFollowedTeams: false,
        teamsFollowed: action.data,
      };
    case FETCH_FOLLOWED_TEAMS_REQ_FAILED:
      return {
        ...state,
        isLoadingFollowedTeams: false,
        teamsFollowedError: action.error,
      };
    case ADD_TEAM_REQ_START:
      return { ...state, isLoadingAddTeam: true, addTeamError: null };
    case ADD_TEAM_REQ_SUCCESS:
      return { ...state, isLoadingAddTeam: false, addTeamError: null };
    case ADD_TEAM_REQ_FAILED:
      return { ...state, isLoadingAddTeam: false, addTeamError: action.error };

    case FETCH_TEAM_SUMMARY_STATS_REQ_START:
      return { ...state, isLoadingTeamSumaryStats: true };
    case FETCH_TEAM_SUMMARY_STATS_REQ_SUCCESS:
      return {
        ...state,
        isLoadingTeamSumaryStats: false,
        teamSummaryStatsResult: action.data,
        teamSummaryStatsError: null,
      };
    case FETCH_TEAM_SUMMARY_STATS_REQ_FAILED:
      return {
        ...state,
        isLoadingTeamSumaryStats: false,
        teamSummaryStatsResult: [],
        teamSummaryStatsError: action.error,
      };
    case FETCH_HISTORICAL_GAMES_STARTED:
      return { ...state, isLoadingTeamTab: true };
    case FETCH_HISTORICAL_GAMES_SUCCESS:
      return {
        ...state,
        isLoadingTeamTab: false,
        historicalGames: action.payload,
      };
    case FETCH_HISTORICAL_GAMES_FAILED:
      return { ...state, isLoadingTeamTab: true };
    case FETCH_MEMBER_TEAM_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        isLoadingTeamAssoc: false,
        teamAssociations: action.payload,
      };
    case FETCH_MEMBER_TEAM_ASSOCIATIONS_FAILED:
      return {
        ...state,
        isLoadingTeamAssoc: false,
      };
    case FETCH_MEMBER_TEAM_ASSOCIATIONS_STARTED:
      return {
        ...state,
        isLoadingTeamAssoc: true,
      };
    case FETCH_DELETE_MEMBER_TEAM_ASSOCIATIONS_SUCCESS:
      const newTeamAssociations = state.teamAssociations.filter((t: MemberTeamAssociation) => t.player_team_map_id !== action.payload);
      return {
        ...state,
        teamAssociations: newTeamAssociations,
        isLoadingTeamAssoc: false,
      };
    case FETCH_PUT_MEMBER_TEAM_ASSOCIATIONS_SUCCESS:
      const updatedTeamAssociations = state.teamAssociations.map((t: MemberTeamAssociation) => {
        if (t.player_team_map_id === action.payload.player_team_map_id) {
          return {
            ...t,
            ...action.payload,
          };
        }
        return t;
      });
      return {
        ...state,
        teamAssociations: updatedTeamAssociations,
        isLoadingTeamAssoc: false,
      };
    case FETCH_GET_ORGS_STARTED:
      return {
        ...state,
        isLoadingOrgs: true,
      };
    case FETCH_GET_ORGS_FAILED:
      return {
        ...state,
        isLoadingOrgs: false,
      };
    case FETCH_GET_ORGS_SUCCESS:
      return {
        ...state,
        orgs: action.payload,
        isLoadingOrgs: false,
      };
    case FETCH_POST_MEMBER_TEAM_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        teamAssociations: [...state.teamAssociations, action.payload],
      };
    case FETCH_GET_ORG_SUCCESS:
      return {
        ...state,
        selectedTeam: action.payload,
      };
    case FETCH_TEAMS_PLAYERS_STATS_REQ_START:
      return { ...state, isLoadingTeamsPlayerStats: true, teamsPlayersStats: [], teamsPlyaerStatsError: null };
    case FETCH_TEAMS_PLAYERS_STATS_REQ_SUCCESS:
      return { ...state, isLoadingTeamsPlayerStats: false, teamsPlayersStats: action.data };
    case FETCH_TEAMS_PLAYERS_STATS_REQ_FAILED:
      return {
        ...state,
        isLoadingTeamsPlayerStats: false,
        teamsPlayersStats: [],
        teamsPlyaerStatsError: action.error,
      };
    default:
      return state;
  }
};

export default teamReducer;
