import _fetchRequest from "../../apis/_fetchRequest";
import fetchPlayerTeamMap from "../../apis/fetchPlayerTeamMap";
import addTeamPlayers from "../../apis/fetchTeamPlayers";
import * as commonActions from './commonActions';


import { PlayerTeamMap } from "../../models/PlayerTeamMap";

export const FETCH_PLAYER_TEAM_MAP_REQ_START =
  'FETCH_PLAYER_TEAM_MAP_REQ_START';
export const FETCH_PLAYER_TEAM_MAP_REQ_SSUCCESS =
  'FETCH_PLAYER_TEAM_MAP_REQ_SSUCCESS';

  export const FETCH_PLAYER_TEAM_MAP_REQ_FAILED = 'FETCH_PLAYER_TEAM_MAP_REQ_FAILED';
  export const FETCH_POST_PLAYER_TEAM_MAP_SUCCESS = 'FETCH_POST_PLAYER_TEAM_MAP_SUCCESS';
  export const FETCH_POST_PLAYER_TEAM_MAP_FAILED = 'FETCH_POST_PLAYER_TEAM_MAP_FAILED';
  export const ADD_TEAM_PLAYER_REQ_START = 'ADD_TEAM_PLAYER_REQ_START';
  export const ADD_TEAM_PLAYER_REQ_SUCCESS = 'ADD_TEAM_PLAYER_REQ_SUCCESS';
  export const ADD_TEAM_PLAYER_REQ_FAILED = 'ADD_TEAM_PLAYER_REQ_FAILED';

  export const getPlayerTeamMapStart = () => ({
    type: FETCH_PLAYER_TEAM_MAP_REQ_START,
  });

  export const  getPlayerTeamMapSuccess = (data: PlayerTeamMap[]) => ({
    type: FETCH_PLAYER_TEAM_MAP_REQ_SSUCCESS,
    data
  });

  export const getPlayerTeamMapFailed = (error: any) => ({
    type: FETCH_PLAYER_TEAM_MAP_REQ_FAILED,
    error,
  });

  export const addTeamPlayerStart = () => ({
    type: ADD_TEAM_PLAYER_REQ_START,
  });
  
  export const addTeamPlayerSuccess = () => ({
    type: ADD_TEAM_PLAYER_REQ_SUCCESS,
  });
  
  export const addTeamPlayerFailed = (error: any) => ({
    type: ADD_TEAM_PLAYER_REQ_FAILED,
    error,
  });

export function getPlayerTeamMap(memberId: string) {
    return (dispatch: any) => {
      dispatch(getPlayerTeamMapStart());
      fetchPlayerTeamMap(memberId)
        .then(async (res: any) => {
          dispatch(getPlayerTeamMapSuccess(res));
        })
        .catch((error: any) => {
          console.log('error in getPlayerSummaryStats', error);
          dispatch(getPlayerTeamMapFailed(error));
        });
    };
  }

  export function postPlayerTeamMap(teamAss: Partial<PlayerTeamMap>) {
  
    return (dispatch: any) => {
      _fetchRequest<PlayerTeamMap[]>('POST', 'player_team_map', {}, teamAss)
        .then(async (res) => {
            console.log('PostRes', res);
        })
        .catch((error: any) => {
          console.log('error in getTeamSummaryStats', error);
          
        });
    };
  }

  // POST team players
export function postTeamPlayersRequest(data: any) {
  return (dispatch: any) => {
    dispatch(addTeamPlayerStart());
    addTeamPlayers(data)
      .then((res: any) => {
        dispatch(addTeamPlayerSuccess());
      })
      .catch((error: any) => {
        dispatch(addTeamPlayerFailed(error));
        dispatch(commonActions.handleError(error));
      });
  };
}