import { API_URL } from '../constants';
import fetchAuthorization from './fetchAuthorization';

export async function fetchAllGames(eventId: string): Promise<any> {
  try {
    const data = await fetchAuthorization(`${API_URL}games_normalized?event_id=${eventId}`);

    return await data.json();
  } catch (e: any) {
    throw new Error(`Sorry, something went wrong. Error message: ${e.message}`);
  }
}

export default fetchAllGames;
