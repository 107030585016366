import _ from 'lodash';

function getStatKeyValue(stat: any) {
  switch (stat.statistic_name) {
    case 'Ground Ball':
      return { gb: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'Goal':
      return {
        goals: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Forced Turnover':
      return { fto: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'Goal Even Strength':
      return { ges: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'Goal Allowed Even Strength':
      return {
        goal_allowed_strength: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Save Made Even Strength':
      return {
        save_made_strength: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Miss Even Strength':
      return {
        miss_strength: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Save Even Strength':
      return {
        save_strength: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Player Power Ranking':
      return { ppr: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'Save':
      return { save: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'Miss':
      return { miss: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'Assist':
      return {
        assists: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case '30 Sec Releasable':
      return {
        sec_releasable: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Faceoffs':
      return {
        faceoffs: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Faceoff Lost':
      return {
        faceoff_lost: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Faceoff Won':
      return {
        faceoff_won: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Save Man Up':
      return {
        save_man_up: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case '1 Min Releasable':
      return {
        min_releasable: stat.statistic_value ? stat.statistic_value : stat.value,
      };
  }
}

function getSummaryStatKeyValue(stat: any) {
  switch (stat.statistic_name) {
    case 'Rank':
      return {
        rank: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Wins':
      return { wins: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'Losses':
      return {
        losses: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Goals For':
      return {
        goals: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Goals Against':
      return {
        goals_against: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'WP':
      return { wp: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'AVG Pts/Game':
      return { pts_scored: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'AVG Goal Diff':
      return {
        agd: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'FO %':
      return {
        fo_percent: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'Save %':
      return {
        save_percent: stat.statistic_value ? stat.statistic_value : stat.value,
      };
    case 'SOS':
      return { sos: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'oSOS':
      return { osos: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'Ties':
      return { ties: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'AVG Pts Allowed/Game':
     return { pts_allowed: stat.statistic_value ? stat.statistic_value : stat.value };
    case 'RPI':
     return { rpi: stat.statistic_value ? stat.statistic_value : stat.value };
  }
}

export async function getStats(stats: Array<any>) {
  let statsObj: any = _.map(stats, (stat) => {
    return stat ? getStatKeyValue(stat) : {};
  });
  return _.assign.apply(_, statsObj); // combine stats into a single object
}

export async function getSummaryStats(stats: Array<any>) {
  let statsObj: any = _.map(stats, (stat) => {
    return stat ? getSummaryStatKeyValue(stat) : {};
  });
  return _.assign.apply(_, statsObj); // combine stats into a single object
}
