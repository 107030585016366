import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import SignupForm from './SignupForm';
import Logo from '../../images/logo.png';
import React, { useState } from 'react';
import SignInWithGoogle from './SignInWithGoogle';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Backdrop, CircularProgress, Link } from '@mui/material';
import AppFooter from '../AppFooter';

const LOGO_HEIGHT = 100;
const useStyles = makeStyles((theme: Theme) => ({
  centeredLogo: {
    background: [
      `url(${Logo}) no-repeat center/${LOGO_HEIGHT}px`,
      `linear-gradient(to bottom, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.secondary.main} 50%,${theme.palette.secondary.main} 100%)`,
    ].join(', '),
    height: LOGO_HEIGHT * 1.25,
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    paddingBottom: theme.spacing(1),
  },
  googleButton: {
    marginTop: theme.spacing(2),
  },
  orText: {
    marginTop: `16px !important`,
    marginBottom: `16px !important`,
  },
  main: {},
  root: {
    textAlign: 'center',
  },
  backdrop: {
    color: '#ffffff',
    zIndex: 1000,
  },
  forgotLink:{
    '& a': {
      textDecoration : 'none',
      '&:hover': {
        textDecoration : 'underline'
      }
    },
  }
}));

const LoginPage: React.FC = () => {
  const classes = useStyles();

  const [pageType, togglePage] = useState<string>('login');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const startLoading = () => setIsLoading(true);
  const endLoading = () => setIsLoading(false);

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <div className={classes.centeredLogo} />
        <Typography variant="h1">
          Register For/Login Into Your Club Lacrosse Account Here!
        </Typography>
        <p> </p>
      </header>
      <p> </p>
      <p> </p>
      <div className={classes.main}>
        <SignInWithGoogle className={classes.googleButton} />
        <p> </p>
        <Typography className={classes.orText} variant="h4">
          OR
        </Typography>
        <p> </p>
        {pageType === 'login' ? (
          <LoginForm endLoading={endLoading} startLoading={startLoading} />
        ) : pageType === 'forgot' ? (
          <ForgotPasswordForm
            success={() => togglePage('login')}
            noExistingUser={() => togglePage('signup')}
          />
        ) : (
          <SignupForm success={() => togglePage('login')} />
        )}

        {pageType === 'login' ? (
          <p className={classes.forgotLink}>
            <Link href="#" onClick={() => togglePage('forgot')} variant="h5">
              Forgot My Password
            </Link>
            <Typography className={classes.orText} variant="h2">
              Don't have an account yet??&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link
                href="#"
                onClick={() => togglePage('signup')}
                style={{ textDecoration: 'underline' }}
              >
                Sign Me Up!
              </Link>
            </Typography>
          </p>
        ) : pageType === 'forgot' ? (
          <>
            {/* <br /> */}
            <Link href="#" onClick={() => togglePage('login')}>
              Back
            </Link>
          </>
        ) : (
          <p className={classes.forgotLink}>
            Already have an account?&nbsp;&nbsp;
            <Link href="#" onClick={() => togglePage('login')}>
              Go Back to Login
            </Link>
          </p>
        )}
      </div>
      <Backdrop className={classes.backdrop} open={isLoading || false}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppFooter/>
    </div>
  );
};

export default LoginPage;
