import React, { useState } from 'react';
import { useInputValue } from '../../hooks';
import { Auth } from 'aws-amplify';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import ErrorBlock from '../ErrorBlock';
import ConstrainedWidth from '../ConstrainedWidth';
import { Button, TextField } from '@mui/material';
import { validateEmail, validatePassword } from '../../helpers';
import { InfoDialog } from '../Common';

interface Props {
  success: () => void;
  noExistingUser: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    marginBottom: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(4),
    width: 375,
  },
  root: {
    textAlign: 'center',
  },
  button: {
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 25,
    paddingLeft: 25,
  },
}));

const ForgotPasswordForm = ({ success, noExistingUser }: Props) => {
  const classes = useStyles();

  const [error, setError] = useState<Error | undefined>(undefined);
  const [pageState, setPage] = useState<string>('request');
  const [isOpenWarningPopup, setIsOpenWarningPopup] = useState<boolean>(false);
  const { inputValue: username, changeInput: setUsername } = useInputValue('');
  const { inputValue: code, changeInput: setCode } = useInputValue('');
  const { inputValue: newPassword, changeInput: setNewPassword } = useInputValue('');

  const submitForgotPassword = () => {
    Auth.forgotPassword(username)
      .then((data) => {
        console.log('Request forgot password');
        console.log(data);
        setPage('confirm');
      })
      .catch((err) => {
        console.log('Error has been occurred');
        if (err.message === 'Username/client id combination not found.') {
          setIsOpenWarningPopup(true);
          return;
        }
        setError(new Error(err.message));
      });
  };

  const resetPassword = () => {
    Auth.forgotPasswordSubmit(username, code, newPassword)
      .then((data) => {
        success();
      })
      .catch((err) => {
        setError(new Error(err.message));
      });
  };

  const onConfirmResetPassword = () => {
    if (!newPassword) {
      setError(new Error('New Password is required.'));
      return;
    }

    if (!validatePassword(newPassword)) {
      setError(new Error('New Password must have a length greater than or equal to 8.'));
      return;
    }
    // Clear out the previous error
    setError(undefined);

    resetPassword();
  };

  return (
    <ConstrainedWidth>
      {pageState === 'request' ? (
        <form className={classes.root}>
          {error && <ErrorBlock className={classes.error} error={error} />}

          <TextField
            className={classes.input}
            error={!!error}
            id="username"
            InputLabelProps={{
              shrink: true,
            }}
            label="Email Address"
            onChange={setUsername}
            required
            type="text"
            value={username}
          />
         <p></p>
          <br />

          <Button
            color="primary"
            className={classes.button}
            onClick={() => {
              if (!username) {
                setError(new Error('Email Address is required.'));
                return;
              }
              if (!validateEmail(username)) {
                setError(new Error('That is not a valid email. Please enter your correct email.'));
                return;
              }

              // Clear out the previous error
              setError(undefined);

              submitForgotPassword();
            }}
            variant="contained"
          >
            Submit
          </Button>
        </form>
      ) : pageState === 'confirm' ? (
        <form className={classes.root}>
          {error && <ErrorBlock className={classes.error} error={error} />}

          <TextField
            className={classes.input}
            error={!!error}
            id="code"
            InputLabelProps={{
              shrink: true,
            }}
            label="Confirmation Code (from the email we sent you)"
            onChange={setCode}
            required
            type="number"
            value={code}
          />

          <br />
          <p></p>
          <Button
            color="primary"
            className={classes.button}
            onClick={() => {
              if (!code) {
                setError(new Error('Confirmation Code is required.'));
                return;
              }

              // Clear out the previous error
              setError(undefined);

              setPage('reset');
            }}
            variant="contained"
          >
            Confirm
          </Button>
        </form>
      ) : (
        <form className={classes.root}>
          {error && <ErrorBlock className={classes.error} error={error} />}

          <TextField
            className={classes.input}
            error={!!error}
            id="newPassword"
            InputLabelProps={{
              shrink: true,
            }}
            label="New Password"
            onChange={setNewPassword}
            required
            type="password"
            value={newPassword}
          />

          <br />

          <Button color="primary" className={classes.button} onClick={onConfirmResetPassword} variant="contained">
            Reset Password
          </Button>
        </form>
      )}
      <InfoDialog title="" showNo={true} customNo="Sign In Page" customYes="Sign Up Page" textMessage="This user does not exist. You should register first." isOpen={isOpenWarningPopup} onClose={() => setIsOpenWarningPopup(false)} onCancel={success} onConfirm={noExistingUser} />
    </ConstrainedWidth>
  );
};

export default ForgotPasswordForm;
