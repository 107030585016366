import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > label': {
        fontSize: '20px',
        fontWeight: 'bold',
      },
      '& > div': {
        borderRadius: 5,
        boxShadow: '0 0 5px #ccc',
        marginTop: '24px !important',
      },
      '& > div > input': {
        borderRadius: 5,
        padding: '8px 10px',
        fontSize: '18px',
        fontWeight: '500',
        height: '1.1876em !important'
      },
      '& > div:before': {
        display: 'none',
      },
    },
    searchSection: {
      alignSelf: 'center',
      textAlign: 'center',
      maxWidth: 660,
      margin: '10px auto'
    },
    searchTerm: {
      fontWeight: 600,
      width: '33%',
      minWidth: '200px',
    },
    searchResult: {
      boxShadow: '0 0 4px rgba(0, 0, 0, 0.4)',
      maxHeight: 250,
      overflowY: 'auto',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },
    dataNotFound: {
      color: 'black',
      paddingLeft: 15,
    },
    loader: {
      margin: '5px auto',
      display: 'block',
    },
    listRoot: {
      width: '100%',
      background: 'white',
    },
    searchResultSection: {
      position: 'relative',
      zIndex: 999,
    },
    closeIcon: {
      cursor: 'pointer',
      width: '35px !important',
      height: '35px !important',
      padding: '5px',
      marginRight: '-10px',
    },
  }),
);
