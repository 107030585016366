import _fetchRequest from '../../apis/_fetchRequest';
import fetchTscoAdmin from '../../apis/fetchTscoAdmin';
import fetchTscoEvents from '../../apis/fetchTscoEvents';
import { successToast } from '../../components/Common/Toasts/showToasts';
import * as commonActions from './commonActions';
import { updateClub } from './memberOnboardingActions';

export const FETCH_TEAM_MAPPED_SUCCESS = 'FETCH_TEAM_MAPPED_SUCCESS';
export const FETCH_TEAM_UNMAPPED_SUCCESS = 'FETCH_TEAM_UNMAPPED_SUCCESS';
export const FETCH_ALL_TSCO_EVENTS_SUCCESS = 'FETCH_ALL_TSCO_EVENTS_SUCCESS';

export const fetchAllTiscoEventsSuccess = (data: TscoAdminTeams[]) => ({
  type: FETCH_ALL_TSCO_EVENTS_SUCCESS,
  data,
});

export const fetchTeamMapSuccess = (data: TscoAdminTeams[]) => ({
  type: FETCH_TEAM_MAPPED_SUCCESS,
  data,
});

export const fetchTeamUnMappedSuccess = (data: TscoAdminTeams[]) => ({
  type: FETCH_TEAM_UNMAPPED_SUCCESS,
  data,
});

export const resetTscoAdminSuccess = () => {
  return (dispatch: any) => {
    dispatch(fetchTeamMapSuccess([]));
    dispatch(fetchTeamUnMappedSuccess([]));
    dispatch(updateClub(''));
  };
};

export function fetchTeamMapEvents() {
  return (dispatch: any) => {
    fetchTscoEvents()
      .then(async (res: TscoAdminTeams[]) => {
        if (res) {
          dispatch(fetchAllTiscoEventsSuccess(res));
        }
      })
      .catch((error: any) => {
        dispatch(commonActions.handleError(error));
      });
  };
}

export function fetchTeamMap(event_id?: string) {
  return (dispatch: any) => {
    fetchTscoAdmin(event_id)
      .then(async (res: TscoAdminTeams[]) => {
        if (res) {
          if (event_id) {
            const Mapped = res.filter((r) => r.team_id !== null);
            dispatch(fetchTeamMapSuccess(Mapped));
            const UnMapped = res.filter((r) => r.team_id === null);
            dispatch(fetchTeamUnMappedSuccess(UnMapped));
          } else {
            dispatch(fetchAllTiscoEventsSuccess(res));
          }
        }
      })
      .catch((error: any) => {
        dispatch(commonActions.handleError(error));
      });
  };
}

export function PutTeamMap(payload: any) {
  return (dispatch: any) => {
    _fetchRequest('PUT', 'tmachine_division_team_map', {}, payload)
      .then(async (res: any) => {
        if (res.success === false) {
          dispatch(commonActions.handleError(res.message));
        } else {
          if(payload.team_id !== null){
            successToast('Team successfully mapped!');
          }else{
            successToast('Team successfully unmapped!');
          }
        }
      })
      .catch((error: any) => {
        dispatch(commonActions.handleError(error));
      });
  };
}
