import { fetchVEvents, fetchMutchupGames, fetchDivisions, fetchAllGames } from '../../apis';
import fetchAuthorization from '../../apis/fetchAuthorization';
import _fetchRequest from '../../apis/_fetchRequest';
import { errorToast, successToast } from '../../components/Common/Toasts/showToasts';
import { API_URL } from '../../constants';
import { getPoolsFromGames, getTeamsFromGames } from '../../helpers';
import { filterClubTryouts, filterPastEvents, filterProspectDay, filterUpcomingEvents } from '../../helpers/events';
import getVarcharEight from '../../helpers/get-random-id';
import Division from '../../models/Division';
import { Event } from '../../models/Event';
import NormalizeGame from '../../models/NormalizeGame';
import Pool from '../../models/Pool';
import Team from '../../models/Team';
import { AllHighlightsRatings, Highlight, HighlightsRatings } from '../../models/Highlight';
import { addEventHighlights } from '../../apis/addHighlights';
import fetchEventHighlights from '../../apis/fetchEventHighlight';
import fetchPlayerHighlightsRating from '../../apis/fetchplayerHighlightsRating';
import fetchAllPlayerHighlightsRating from '../../apis/fetchAllplayerHighlightsRating';
import fetchProspectDays, { ProspectDays } from '../../apis/fetchProspectDays';
import fetchClubTryouts, { Tryouts } from '../../apis/fetchClubTryouts';
import { FetchEventsOptions } from '../../apis/fetchEvents';
import * as commonActions from './commonActions';
import { Events } from '../../apis/fetchVEvents';

export const FETCH_UPCOMING_EVENTS_REQ_START = 'FETCH_UPCOMING_EVENTS_REQ_START';
export const FETCH_UPCOMING_EVENTS_REQ_SUCCESS = 'FETCH_UPCOMING_EVENTS_REQ_SUCCESS';
export const FETCH_UPCOMING_EVENTS_REQ_FAILED = 'FETCH_UPCOMING_EVENTS_REQ_FAILED';
export const FETCH_PAST_EVENTS_REQ_START = 'FETCH_PAST_EVENTS_REQ_START';
export const FETCH_PAST_EVENTS_REQ_SUCCESS = 'FETCH_PAST_EVENTS_REQ_SUCCESS';
export const FETCH_PAST_EVENTS_REQ_FAILED = 'FETCH_PAST_EVENTS_REQ_FAILED';

export const FETCH_VEVENTS_REQ_START = 'FETCH_VEVENTS_REQ_START';
export const FETCH_VEVENTS_REQ_SUCCESS = 'FETCH_VEVENTS_REQ_SUCCESS';
export const FETCH_VEVENTS_REQ_FAILED = 'FETCH_VEVENTS_REQ_FAILED';

export const FETCH_EVENT_GAMES_REQ_START = 'FETCH_EVENT_GAMES_REQ_START';
export const FETCH_EVENT_GAMES_REQ_SUCCESS = 'FETCH_EVENT_GAMES_REQ_SUCCESS';
export const FETCH_EVENT_GAMES_REQ_FAILED = 'FETCH_EVENT_GAMES_REQ_FAILED';

export const FETCH_ALL_EVENT_GAMES_REQ_START = 'FETCH_ALL_EVENT_GAMES_REQ_START';
export const FETCH_ALL_EVENT_GAMES_REQ_SUCCESS = 'FETCH_ALL_EVENT_GAMES_REQ_SUCCESS';
export const FETCH_ALL_EVENT_GAMES_REQ_FAILED = 'FETCH_ALL_EVENT_GAMES_REQ_FAILED';

export const FETCH_EVENT_DIVISIONS_REQ_SUCCESS = 'FETCH_EVENT_DIVISIONS_REQ_SUCCESS';
export const FETCH_EVENT_DIVISIONS_REQ_FAILED = 'FETCH_EVENT_DIVISIONS_REQ_FAILED';

export const FETCH_GET_FACILITIES_SUCCESS = 'FETCH_GET_FACILITIES_SUCCESS';
export const FETCH_GET_FACILITIES_FAILED = 'FETCH_GET_FACILITIES_FAILED';
export const FETCH_GET_FACILITIES_STARTED = 'FETCH_GET_FACILITIES_STARTED';

export const FETCH_POST_EVENT_STARTED = 'FETCH_POST_EVENT_STARTED';
export const FETCH_POST_EVENT_SUCCESS = 'FETCH_POST_EVENT_SUCCESS';
export const FETCH_POST_EVENT_FAILED = 'FETCH_POST_EVENT_FAILED';

export const FETCH_REQUEST_EVENTS_REQ_START = 'FETCH_REQUEST_EVENTS_REQ_START';
export const FETCH_REQUEST_EVENTS_REQ_SUCCESS = 'FETCH_REQUEST_EVENTS_REQ_SUCCESS';
export const FETCH_REQUEST_EVENTS_REQ_FAILED = 'FETCH_REQUEST_EVENTS_REQ_FAILED';
export const FETCH_REQUEST_MEMBERS_REQ_SUCCESS = 'FETCH_REQUEST_MEMBERS_REQ_SUCCESS';
export const FETCH_POST_REQUEST_MEMBERS_REQ_SUCCESS = 'FETCH_POST_REQUEST_MEMBERS_REQ_SUCCESS';

export const FETCH_EVENT_HIGHLIGHTS_START = 'FETCH_EVENT_HIGHLIGHTS_START';
export const FETCH_EVENT_HIGHLIGHTS_SUCCESS = 'FETCH_EVENT_HIGHLIGHTS_SUCCESS';
export const ADD_EVENT_HIGHLIGHTS = 'ADD_EVENT_HIGHLIGHTS';
export const FETCH_EVENT_HIGHLIGHTS_RATING = 'FETCH_EVENT_HIGHLIGHTS_RATING';
export const FETCH_ALL_EVENT_HIGHLIGHTS_RATING = 'FETCH_ALL_EVENT_HIGHLIGHTS_RATING';
export const FETCH_PROSPECT_DAYS_REQ_START = 'FETCH_PROSPECT_DAYS_REQ_START';
export const FETCH_PROSPECT_DAYS_REQ_SUCCESS = 'FETCH_PROSPECT_DAYS_REQ_SUCCESS';
export const FETCH_PROSPECT_DAYS_REQ_FAILED = 'FETCH_PROSPECT_DAYS_REQ_FAILED';

export const ADD_PROSPECT_REQ_START = 'ADD_PROSPECT_REQ_START';
export const ADD_PROSPECT_REQ_SUCCESS = 'ADD_PROSPECT_REQ_SUCCESS';
export const ADD_PROSPECT_REQ_FAILED = 'ADD_PROSPECT_REQ_FAILED';

export const FETCH_CLUB_TRYOUT_REQ_START = 'FETCH_CLUB_TRYOUT_REQ_START';
export const FETCH_CLUB_TRYOUT_REQ_SUCCESS = 'FETCH_CLUB_TRYOUT_REQ_SUCCESS';
export const FETCH_CLUB_TRYOUT_REQ_FAILED = 'FETCH_CLUB_TRYOUT_REQ_FAILED';
export const ADD_CLUB_TRYOUT_REQ_START = 'ADD_CLUB_TRYOUT_REQ_START';
export const ADD_CLUB_TRYOUT_REQ_SUCCESS = 'ADD_CLUB_TRYOUT_REQ_SUCCESS';
export const ADD_CLUB_TRYOUT_REQ_FAILED = 'ADD_CLUB_TRYOUT_REQ_FAILED';

export const FETCH_POWER_RANKING_SUCCESS = 'FETCH_POWER_RANKING_SUCCESS';
export const FETCH_POWER_RANKING_FAILED = 'FETCH_POWER_RANKING_FAILED';
export const FETCH_POWER_RANKING_START = 'FETCH_POWER_RANKING_START';

export const getUpcomingEventsStart = () => ({
  type: FETCH_UPCOMING_EVENTS_REQ_START,
});

export const updateUpcomingEvents = (data: Events[]) => ({
  type: FETCH_UPCOMING_EVENTS_REQ_SUCCESS,
  data,
});

export const getUpcomingEventsFailed = (error: any) => ({
  type: FETCH_UPCOMING_EVENTS_REQ_FAILED,
  error,
});

export const getPastEventsStart = () => ({
  type: FETCH_PAST_EVENTS_REQ_START,
});

export const updatePastEvents = (data: Events[]) => ({
  type: FETCH_PAST_EVENTS_REQ_SUCCESS,
  data,
});

export const getPastEventsFailed = (error: any) => ({
  type: FETCH_PAST_EVENTS_REQ_FAILED,
  error,
});

export const getVEventsStart = () => ({
  type: FETCH_VEVENTS_REQ_START,
});

export const getVEventsSuccess = (data: any) => ({
  type: FETCH_VEVENTS_REQ_SUCCESS,
  data,
});

export const getVEventsFailed = (error: any) => ({
  type: FETCH_VEVENTS_REQ_FAILED,
  error,
});

export const getEventGamesStart = () => ({
  type: FETCH_EVENT_GAMES_REQ_START,
});

export const getEventGamesSuccess = (data: any) => ({
  type: FETCH_EVENT_GAMES_REQ_SUCCESS,
  data,
});

export const getEventGamesFailed = (error: any) => ({
  type: FETCH_EVENT_GAMES_REQ_FAILED,
  error,
});

export const getDivisionsSuccess = (payload: Division[]) => ({
  type: FETCH_EVENT_DIVISIONS_REQ_SUCCESS,
  payload,
});

export const getDivisionsFailed = (error: any) => ({
  type: FETCH_EVENT_DIVISIONS_REQ_FAILED,
  error,
});

export const getAllGamesStart = () => ({
  type: FETCH_ALL_EVENT_GAMES_REQ_START,
});

export const getAllGamesSuccess = (payload: { allGames: NormalizeGame[]; teams: Team[]; pools: Pool[] }) => ({
  type: FETCH_ALL_EVENT_GAMES_REQ_SUCCESS,
  payload,
});

export const getAllGamesFailed = (error: any) => ({
  type: FETCH_ALL_EVENT_GAMES_REQ_FAILED,
  error,
});
export const getEventHighlightsStart = () => ({
  type: FETCH_EVENT_HIGHLIGHTS_START,
});
export const getEventHighlightsSuccess = (payload: Highlight[]) => ({
  type: FETCH_EVENT_HIGHLIGHTS_SUCCESS,
  payload,
});
export const addEventPlayerHighlight = (payload: Highlight) => ({
  type: ADD_EVENT_HIGHLIGHTS,
  payload,
});

export const getEventHighlightsRating = (payload: HighlightsRatings[]) => ({
  type: FETCH_EVENT_HIGHLIGHTS_RATING,
  payload,
});
export const getAllEventHighlightsRating = (payload: AllHighlightsRatings[]) => ({
  type: FETCH_ALL_EVENT_HIGHLIGHTS_RATING,
  payload,
});

export const getProspectDayStart = () => ({
  type: FETCH_PROSPECT_DAYS_REQ_START,
});

export const updateProspectDay = (data: ProspectDays[]) => ({
  type: FETCH_PROSPECT_DAYS_REQ_SUCCESS,
  data,
});

export const getProspectDayFailed = (error: any) => ({
  type: FETCH_PROSPECT_DAYS_REQ_FAILED,
  error,
});

export const addProspectStart = () => ({
  type: ADD_PROSPECT_REQ_START,
});

export const addProspectSuccess = () => ({
  type: ADD_PROSPECT_REQ_SUCCESS,
});

export const addProspectFailed = (error: string) => ({
  type: ADD_PROSPECT_REQ_FAILED,
  error,
});

export const getClubTryoutStart = () => ({
  type: FETCH_CLUB_TRYOUT_REQ_START,
});
export const updateClubTryout = (data: Tryouts[]) => ({
  type: FETCH_CLUB_TRYOUT_REQ_SUCCESS,
  data,
});

export const getClubTryoutFailed = (error: any) => ({
  type: FETCH_CLUB_TRYOUT_REQ_FAILED,
  error,
});

export const addClubTryoutStart = () => ({
  type: ADD_CLUB_TRYOUT_REQ_START,
});

export const addClubTryoutSuccess = () => ({
  type: ADD_CLUB_TRYOUT_REQ_SUCCESS,
});

export const addClubTryoutFailed = (error: string) => ({
  type: ADD_CLUB_TRYOUT_REQ_FAILED,
  error,
});

export function getPowerRanking(eventId: string) {
  return (dispatch: any) => {
    dispatch({
      type: FETCH_POWER_RANKING_START,
    } as any);
    fetchAuthorization(`${API_URL}event_team_stats?event_id=${eventId}`)
      .then(async (res: any) => {
        const powerRanking = await res.json();

        const parsingResponse =
          powerRanking &&
          powerRanking.map((item: any) => {
            const parseData = `[${item.json_team_stats}]`;
            return {
              ...item,
              json_team_stats: JSON.parse(parseData),
            };
          });

        dispatch({
          type: FETCH_POWER_RANKING_SUCCESS,
          payload: parsingResponse,
        });
      })
      .catch((error: any) => {
        dispatch({
          type: FETCH_POWER_RANKING_FAILED,
          payload: error,
        });
      });
  };
}

export function getUpcomingsEvents(data: FetchEventsOptions) {
  return (dispatch: any) => {
    dispatch(getUpcomingEventsStart());
    fetchVEvents(data)
      .then((res: Events[]) => {
        const events = filterUpcomingEvents(res);
        dispatch(updateUpcomingEvents(events));
      })
      .catch((error: any) => {
        console.log('error in upcoming events', error);
        dispatch(getUpcomingEventsFailed(error));
      });
  };
}

export function getPastEvents(data: FetchEventsOptions) {
  return (dispatch: any) => {
    dispatch(getPastEventsStart());
    fetchVEvents(data)
      .then((res: Events[]) => {
        const events = filterPastEvents(res);
        dispatch(updatePastEvents(events));
      })
      .catch((error: any) => {
        console.log('error in past events', error);
        dispatch(getPastEventsFailed(error));
      });
  };
}

export function getProspectDays(data: FetchEventsOptions) {
  return (dispatch: any) => {
    dispatch(getProspectDayStart());
    fetchProspectDays(data)
      .then((res: ProspectDays[]) => {
        const events = filterProspectDay(res);
        dispatch(updateProspectDay(events));
      })
      .catch((error: any) => {
        console.log('error in past events', error);
        dispatch(getProspectDayFailed(error));
      });
  };
}

export function getRequestEvents() {
  return (dispatch: any) => {
    dispatch({
      type: FETCH_REQUEST_EVENTS_REQ_START,
    });
    _fetchRequest('GET', 'public_requests', { request_type: 'event' })
      .then((res: any) => {
        dispatch({
          type: FETCH_REQUEST_EVENTS_REQ_SUCCESS,
          payload: res,
        });
      })
      .catch((error: any) => {
        console.log('error in public requests events', error);
        dispatch({
          type: FETCH_REQUEST_EVENTS_REQ_FAILED,
          payload: error,
        });
      });
    _fetchRequest('GET', 'public_requests_members')
      .then((res: any) => {
        dispatch({
          type: FETCH_REQUEST_MEMBERS_REQ_SUCCESS,
          payload: res,
        });
      })
      .catch((error: any) => {
        console.log('error in public requests members events', error);
      });
  };
}

export const postRequestEventCoverage = (memberId: string, requestId: number, count: number) => async (dispatch: any) => {
  _fetchRequest('POST', 'public_requests_members', undefined, {
    member_id: memberId,
    request_id: requestId,
  })
    .then((res: any) => {
      dispatch({
        type: FETCH_POST_REQUEST_MEMBERS_REQ_SUCCESS,
        payload: {
          member_id: memberId,
          request_id: requestId,
        },
      });
    })
    .catch((error: any) => {
      console.log('error in post public requests events', error);
    });
  _fetchRequest('PUT', 'public_requests', undefined, {
    request_id: requestId,
    request_count: count + 1,
  })
    .then((res: any) => {})
    .catch((error: any) => {
      console.log('error in put public request events', error);
    });
  dispatch(getRequestEvents());
};

export function getEventInfo(data: any) {
  return (dispatch: any) => {
    dispatch(getVEventsStart());
    fetchVEvents(data)
      .then((res: any) => {
        if (res && res.length) {
          dispatch(getVEventsSuccess(res[0]));
        } else {
          dispatch(getVEventsFailed('Event is not found!'));
        }
      })
      .catch((error: any) => {
        dispatch(getVEventsFailed(error));
      });
  };
}

export function getMatchupGames(data: any, callback?: any) {
  return (dispatch: any) => {
    dispatch(getEventGamesStart());
    fetchMutchupGames(data)
      .then((res: any) => {
        dispatch(getEventGamesSuccess(res));
        if (callback) callback(res);
      })
      .catch((error: any) => {
        dispatch(getEventGamesFailed(error));
      });
  };
}

export const getEventDivisions = (eventId: string) => async (dispatch: any) => {
  try {
    const divisions = await fetchDivisions(eventId);
    dispatch(getDivisionsSuccess(divisions));
  } catch (error: any) {
    dispatch(getDivisionsFailed(error));
  }
};

export const getAllGames = (eventId: string, eventInfo: Event) => async (dispatch: any) => {
  try {

    dispatch(getAllGamesStart());

    const allGames = await fetchAllGames(eventId);
    const pools = getPoolsFromGames(allGames);
    const teams = getTeamsFromGames(allGames, eventInfo);

    dispatch(
      getAllGamesSuccess({
        allGames,
        teams,
        pools,
      }),
    );
  } catch (error: any) {
    dispatch(getAllGamesFailed(error));
  }
};

export const getFacilities = (eventId: string) => async (dispatch: any) => {
  dispatch({
    type: FETCH_GET_FACILITIES_STARTED,
  });
  try {
    const data = await fetchAuthorization(`${API_URL}facilities?event_id=${eventId}`);

    const facilities = await data.json();

    dispatch({
      type: FETCH_GET_FACILITIES_SUCCESS,
      payload: facilities,
    });
  } catch (error: any) {
    dispatch({
      type: FETCH_GET_FACILITIES_FAILED,
      payload: error,
    });
  }
};

export const addEvent = (event: Partial<Event>) => async (dispatch: any) => {
  dispatch({
    type: FETCH_POST_EVENT_STARTED,
  });
  try {
    const publicEvent = {
      request_id: getVarcharEight(),
      request_type: 'event',
      item_name: event.event_desc,
      item_city: event.event_city,
      item_state: event.event_state,
      item_website_URL: event.event_website_URL,
      sport_id: event.sport_id,
      start_date: event.start_date,
      event_lat: event.event_lat,
      event_long: event.event_long,
    };

    await _fetchRequest('POST', 'public_requests', undefined, publicEvent);

    dispatch({
      type: FETCH_POST_EVENT_SUCCESS,
    });
  } catch (error: any) {
    errorToast('Error in creation event.');
    dispatch({
      type: FETCH_POST_EVENT_FAILED,
      payload: error,
    });
  }
};

export function getEventHighlight(data: any) {
  return (dispatch: any) => {
    dispatch(getEventHighlightsStart());
    fetchEventHighlights(data)
      .then(async (res: Highlight[]) => {
        dispatch(getEventHighlightsSuccess(res));
      })
      .catch((error: any) => {
        console.log('error in fetchEventHighlights', error);
      });
  };
}

export const addNewEventHighlights = (newHighlight: Highlight, eventId: string) => (dispatch: any) => {
  addEventHighlights(newHighlight, eventId)
    .then((_res: any) => {
      newHighlight = { ...newHighlight, member_media_id: _res.insertId };
      successToast('Highlight added successfully.');
      setTimeout(() => {
        dispatch(getEventHighlight(eventId));
      }, 3000);
    })
    .catch((_error: any) => {
      errorToast("Couldn't add highlight.");
    });
};

// Event Ratings

export function getEventRatings() {
  return (dispatch: any) => {
    fetchPlayerHighlightsRating()
      .then((res: HighlightsRatings[]) => {
        dispatch(getEventHighlightsRating(res));
      })
      .catch((error: any) => {
        dispatch(commonActions.handleError(error));
      });
  };
}

export function getAllEventRatings() {
  return (dispatch: any) => {
    fetchAllPlayerHighlightsRating()
      .then((res: AllHighlightsRatings[]) => {
        dispatch(getAllEventHighlightsRating(res));
      })
      .catch((error: any) => {
        dispatch(commonActions.handleError(error));
      });
  };
}

export function addProspect(data: any, callback: any) {
  return (dispatch: any) => {
    dispatch(addProspectStart());
    _fetchRequest('POST', 'prospect_days', undefined, data)
      .then((res: any) => {
        dispatch(addProspectSuccess());
        callback(true);
      })
      .catch((error: any) => {
        console.log('error in set ', error);
        dispatch(addProspectFailed(error));
        dispatch(commonActions.handleError(error));
        callback(true);
      });
  };
}

export function getClubTryouts(data: any) {
  return (dispatch: any) => {
    dispatch(getClubTryoutStart());
    fetchClubTryouts(data)
      .then((res: Tryouts[]) => {
        const events = filterClubTryouts(res);
        dispatch(updateClubTryout(events));
      })
      .catch((error: any) => {
        console.log('error in past events', error);
        dispatch(getClubTryoutFailed(error));
      });
  };
}

export function addClubTryout(data: any, callback: any) {
  return (dispatch: any) => {
    dispatch(addClubTryoutStart());
    _fetchRequest('POST', 'club_tryouts', undefined, data)
      .then((res: any) => {
        dispatch(addClubTryoutSuccess());
        callback(true);
      })
      .catch((error: any) => {
        console.log('error in set ', error);
        dispatch(addClubTryoutFailed(error));
        dispatch(commonActions.handleError(error));
        callback(true);
      });
  };
}
